import { useState, useEffect} from "react";
import { API, graphqlOperation } from "aws-amplify";
import { CheckIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import Loading from "../../../utils/Loading";
const Checklist = ({
  profileData,
  setProfileData,
  setConfirmButtonMessage,
  checklists,
  selectedChecklist,
  setSelectedChecklist,
  checklistResponses,
  newResponses,
  setNewResponses,
}) => {    
  const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [CheckedChecklist, setCheckedChecklist] = useState("");
    console.log("profileData", profileData);
    useEffect(() => {
        setLoading(true); // Set loading to true when starting data fetching

        // Simulated asynchronous data fetching process
        // Replace this with your actual data fetching logic
        const fetchData = async () => {
            console.log("ChecklistsResponses 'newResponses'", checklists);
            const filteredChecklist = checklists?.filter(obj => obj?.MemberId === profileData?.ItemId);
            const defaultChecklistItemId = filteredChecklist && filteredChecklist?.length > 0
                ? filteredChecklist[0]?.ItemId
                : "";
            console.log("defaultChecklistItemId", defaultChecklistItemId);
            setCheckedChecklist(defaultChecklistItemId);

            setLoading(false); // Set loading to false after data fetching is complete
        };

        fetchData();
    }, [checklists, profileData]); 
    if (loading) return <Loading />;
    
   
    
  return (
      <div className="rounded-lg border border-gray-200 shadow-md h-full">
      <div
       className={`bg-white text-gray-900 flex justify-center items-center align-middle pt-4 p-6 min-h-[250px]`}
      >
          <div className="overflow-y-auto" style={{ maxHeight: "250px" }}>
          <div className="mb-2">
            <b className="text-lg">
              {
                checklists?.filter(
                  (obj) => obj.ItemId == newResponses?.ChecklistId
                )[0]?.Title
              }
            </b>
                  </div>
                  
      
          {newResponses?.Responses?.map((obj, i) => (
            <div key={i}>
              <label className="flex text-sm mb-1 z-1">
                <input
                  type="checkbox"
                  className="h-3 w-3 accent-gray-200 text-white bg-white appearance-none border-gray-300 border rounded-sm mt-1 relative left-2"
                  checked={obj.Checked}
                />{" "}
                <CheckIcon
                  onClick={async (e) => {
                    let temp = newResponses;
                    let newTimeStamp = new Date().toISOString();
                    temp.Responses[i].Checked = !temp.Responses[i].Checked;
                    setNewResponses({ ...temp });

                    await API.graphql(
                      graphqlOperation(`mutation {
                        update_ChecklistsResponses_by_pk(pk_columns: {ItemId: "${
                          newResponses.ItemId
                        }"}, 
                          _set: { 
                            DateUpdated: "${newTimeStamp}",
                            Responses: [${temp.Responses?.map(
                              (obj) =>
                                `{ 
                                  Name: "${obj.Name}", 
                                  Checked: ${obj.Checked},
                                }`
                            ).join(`,`)}] 
                          })
                          {
                            ItemId
                            Responses
                          }
                        }`)
                    ).then(async ({ data }) => {
                      console.log("onCheck Edited", data, temp);
                        
                      if (data.update_ChecklistsResponses_by_pk == null) {
                        await API.graphql(
                          graphqlOperation(`mutation {
                            insert_ChecklistsResponses_one(
                              object:
                              {
                                ItemId: "${uuidv4()}",
                                MemberId: "${location.pathname.split("/")[2]}",
                                Assigned: "${newResponses.Assigned}",
                                ChecklistId: "${temp.ChecklistId}",
                                Responses: [${temp.Responses?.map(
                                  (obj) =>
                                    `{ 
                                      Name: "${obj.Name}", 
                                      Checked: ${obj.Checked},
                                    }`
                                ).join(`,`)}]     
                              })
                              {
                                ItemId
                                Responses
                                DateUpdated
                              }
                        }`)
                        ).then(({ data }) => {
                          console.log("onCheck Created", data);
                        });
                      }
                     
                    });
                  }}
                  className={`${
                    newResponses.Responses[i].Checked ? "" : "opacity-0"
                  } h-4 w-4 cursor-pointer teckpert-text-green z-10 relative right-1 top-.5`}
                  aria-hidden="true"
                />
                {obj.Name}
              </label>
            </div>
          ))}

        </div>
      </div>
      <div className="sm:col-span-2 flex pb-8 justify-end mr-4">
        <select
                  className="placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md border shadow-sm px-2.5 py-3 text-sm bg-white 
          hover:placeholder:text-white text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"

                  onChange={async (e) => {
                      setLoading(true);
                      const selectedChecklistId = e.target.value;
                      setCheckedChecklist(selectedChecklistId);

                      const newChecklist = checklists.find(obj => obj.ItemId === selectedChecklistId);

                      if (!newChecklist) {
                          setLoading(false);
                          return; // Exit early if the checklist is not found
                      }

                      const temp = { ...newResponses };
                      temp.ChecklistId = selectedChecklistId;

                      if (checklistResponses.filter(obj => obj.ChecklistId === selectedChecklistId).length < 1) {
                          console.log("No existing checklists");

                          temp.Responses = newChecklist.Items.map(obj => ({
                              Name: obj.Name,
                              Checked: false,
                          }));
                          await API.graphql(
                              graphqlOperation(`mutation {
                  insert_ChecklistsResponses_one(
                    object:
                    {
                      ItemId: "${uuidv4()}",
                      MemberId: "${location.pathname.split("/")[2]}",
                      Assigned: "${newResponses.Assigned}",
                      ChecklistId: "${temp.ChecklistId}",
                      Responses: [${temp.Responses?.map(
                                  (obj) =>
                                      `{ 
                            Name: "${obj.Name}", 
                            Checked: ${obj.Checked},
                          }`
                              ).join(`,`)}]     
                    })
                    {
                      ItemId
                      Responses
                    }
                }`)
                          ).then(({ data }) => {
                              console.log("created", data, temp);
                              temp.ItemId = data.insert_ChecklistsResponses_one.ItemId;

                              setSelectedChecklist({ ...temp });
                              setLoading(false);
                          });
                      } else {
                          // if selected checklist has existing responses
                          console.log("switched", temp);

                          temp.Responses = newChecklist.Items.map((obj, i) => ({
                              Name: obj.Name,
                              Checked: checklistResponses.filter(
                                  (item) => item.ChecklistId == e.target.value
                              )[0].Responses[i]?.Checked,
                          }));
                          temp.ItemId = checklistResponses.filter(
                              (obj) => obj.ChecklistId == e.target.value
                          )[0]?.ItemId;

                          setSelectedChecklist({ ...temp });
                          setLoading(false);
                      }
                      setNewResponses({ ...temp });
                  }}
                  
                  value={CheckedChecklist}
                      
                 
              >
                  
                  <option className="text-gray-300" value="">
                      Select Checklist
                  </option>

                  {checklists.filter(obj => obj.MemberId == null || obj?.MemberId == profileData?.ItemId).map((obj, i) => (
                       (
                          <option key={obj.ItemId} value={obj.ItemId}>
                              {obj.Title}{" "}
                              {obj.ItemId == checklistResponses[i]?.ChecklistId &&
                                  `(${checklistResponses[i].Responses.filter(
                                      (obj) => obj.Checked == true
                                  ).length
                                  }/${obj.Items.length})`}
                          </option>
                      )
                  ))}
        </select>
      </div>
    </div>
    );
    
};
export default Checklist;
