import { useState } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import UpdateSpecializationsModal from "../Modals/UpdateSpecializationsModal";

const Specialization = ({
  originalData,
  profileData,
  setProfileData,
  setConfirmButtonMessage,
}) => {
  const [showSpecializations, setShowSpecializations] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 ">
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Specializations
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setModalOpen(!modalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
          <UpdateSpecializationsModal
            open={modalOpen}
            setOpen={setModalOpen}
            originalData={originalData}
            tableData={profileData}
            setTableData={setProfileData}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
          <div
            onClick={() => setShowSpecializations(!showSpecializations)}
            className="flex flex-none mr-5"
          >
            {!showSpecializations ? (
              <ChevronUpIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
      {/* List Section */}
      <div className={`${showSpecializations ? "hidden" : "block"}`}>
        <div className="flex p-3 mt-3">
          {profileData?.Meta?.Specializations?.length > 0 &&
          Array.isArray(profileData?.Meta?.Specializations) ? (
            profileData.Meta.Specializations?.map((obj, i) => (
              <span
                key={i}
                className="mx-3 bg-gray-200 p-3 rounded-lg shadow ring-1 ring-black ring-opacity-5"
              >
                {obj}
              </span>
            ))
          ) : (
            <div className="m-auto p-4 align-middle justify-center text-gray-300 font-bold">
              No Specializations Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Specialization;
