import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import Loading from "../../../../utils/Loading";
import JobHeader from "./JobHeader";
import ApplicationForm from "./ApplicationForm";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIOS,
  isAndroid,
} from "react-device-detect";

const JobDetails = () => {
  const location = useLocation();
  // const [paragraph, setParagraph] = useState("");
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [jobsData, setJobsData] = useState();
  const [questionnaireData, setQuestionnaireData] = useState();
  const [responsesData, setResponsesData] = useState();
  const [applicantsData, setApplicantsData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [appliedMsg, setAppliedMsg] = useState(false);
  const [newResponse, setNewResponse] = useState({
    OpportunityId: location.pathname.split("/")[3],
    MemberId: "",
    QuestionnaireId: "",
    DateCreated: new Date().toISOString(),
    Responses: [{ Answer: "", Knockout: false, Question: "", AnswerType: "" }],
  });

  useEffect(() => {
    const async = async () => {
      const { data: response } = await API.post("public", "/graphql", {
        body: {
          query: `{
            Opportunities(where: {ItemId: {_eq: "${location.pathname.split("/")[3]
            }"}}) {
                ItemId
                ClientId
                Applicants
                Assignee
                BillRateCompensationUnit
                BillRateContract
                Compensation
                Contract
                ContractLength
                CutOffDate
                DateCreated
                DateUpdated
                Department
                Description
                DocRefNum
                DueDate
                HighEndRate
                Hours
                HoursPerWeek
                HoursType
                JobNum
                Length
                Location
                LowEndRate
                NonApplicants
                PayRateCompensationUnit
                QuestionnaireFormId
                RFQNum
                RecruiterId
                Requirements
                RequisitionNum
                Responsibilities
                SendEmail
                Specializations
                StartDate
                Status
                Support
                Syndication_Google
                Syndication_Indeed
                Syndication_LinkedIn
                Title
                TotalCandidatesToSubmit
                TotalPositionsOpen
                Type
                WorkOrder
                WorkType
                Client {
                  Name
                }
                Contracts {
                  Name
                }
                ApplicationQuestionnaire {
                  Title
                }
              }
              Opportunities_aggregate {
                aggregate {
                  count
                }
              }
              ApplicationQuestionnaire {
                ItemId
                Title
                Questions
              }
              ApplicationQuestionnaireResponses {
                ItemId
                OpportunityId
                MemberId
                Responses
                QuestionnaireId
              }
              Applicants(where: {OpportunityId: {_eq: "${location.pathname.split("/")[3]
            }"}}) {
                ItemId
                DateCreated
                DateUpdated
                OpportunityId
                MemberId
                Interested
                WorkFlowStatus
              }
              Members {
                ItemId
                Meta
                FirstName
                LastName
              }
          }`,
        },
      });
      setJobsData(response.Opportunities[0]);
      let questionnaire = response.ApplicationQuestionnaire?.filter(
        (obj) => obj.ItemId == response.Opportunities[0]?.QuestionnaireFormId
      )[0];
      setQuestionnaireData(questionnaire);

      let tempObj = questionnaire?.Questions.map((obj, i) => ({
        Question: obj.Question,
        AnswerType: obj.AnswerType,
        Answer: "",
        Knockout: false,
      }));

      let tempNewResponse = newResponse;
      if (localStorage.getItem("attributes"))
        if (JSON.parse(localStorage?.getItem("attributes"))[["email"]])
          tempNewResponse.MemberId = response.Members?.filter(
            (obj) =>
              obj.Meta.Email ==
              JSON.parse(localStorage?.getItem("attributes"))[["email"]]
          )[0]?.ItemId;
      tempNewResponse.QuestionnaireId = questionnaire?.ItemId;
      tempNewResponse.Responses = tempObj;
      setNewResponse({ ...tempNewResponse });

      setApplicantsData(response.Applicants);
      let tempList = response.Applicants?.map((item) => item.MemberId);
      setMembersData(response.Members);
      setEmailList(
        response.Members?.filter((obj) => tempList.includes(obj.ItemId)).map(
          (obj) => obj.Meta?.Email
        )
      );
    };
    async();
  }, [location.pathname]);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
        ItemId
        DateCreated
        DateUpdated
        FirstName
        LastName
        UserType
        Email
        CalendlyProfileUrl
      }
    }      
  `,
    }).then(({ data }) => {
      if (data.Users)
        setUserIsAdmin(
          data.Users?.filter(
            (obj) =>
              obj.ItemId ==
              JSON.parse(localStorage.getItem("attributes"))[["custom:itemId"]]
          ).length > 0
        );
    });
  }, []);

  if (!jobsData) return <Loading />;

  return (
    <div className="pt-2 lg:px-10 sm:px-3">
      <JobHeader jobsData={jobsData} />
      <div className="grid lg:grid-cols-6 sm:grid-cols-3 lg:gap-9 lg:gap-6">
        <div className="col-span-3 p-6">
          <div className="text-2xl font-bold mb-4">Description</div>
          <div className="border-2 border-white z-10 lg:mb-4 sm:mb-1">
            <ReactQuill theme="bubble" value={jobsData.Description} readOnly />
          </div>
          <BrowserView>
            <div className="lg:block sm:hidden mt-1 mb-8 p-8 teckpert-bg-green text-white rounded">
              <div>
                <div className="font-bold text-xl mb-3 tracking-wide">
                  Next Steps
                </div>
                <p className="p-2 text-sm">
                  Download our mobile app, sign up and complete your profile.
                  Upload your resume and express interest for this opportunity
                  by viewing the opportunity and giving the thumbs up. Note, you
                  will also receive push notifications of additional
                  opportunities as they become available. Go to our website and
                  click on the link at the top for iOS or Android.
                </p>
              </div>
            </div>
          </BrowserView>
        </div>
        <div className="col-span-3">
          {userIsAdmin && (
            <div className="py-[22%] m-auto rounded-lg border border-gray-200 shadow-md">
              <div className="m-auto text-center align-middle">
                <div>
                  <ExclamationCircleIcon
                    className="m-auto h-40 w-40 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="py-2 font-bold text-3xl text-gray-700">
                  Warning! You are an admin.
                </div>
                <div className="pb-5 text-lg">
                  You are not allowed to apply to an opportunity.
                </div>
              </div>
            </div>
          )}
          {appliedMsg & !userIsAdmin ? (
            <div className="py-[22%] m-auto rounded-lg border border-gray-200 shadow-md">
              <div className="m-auto text-center align-middle">
                <div>
                  <CheckCircleIcon
                    className="m-auto h-40 w-40 teckpert-text-green"
                    aria-hidden="true"
                  />
                </div>
                <div className="py-2 font-bold text-3xl text-gray-700">
                  Congrats!
                </div>
                <div className="pb-5 text-lg">
                  You have successfully applied to this position.
                </div>
              </div>
            </div>
          ) : !appliedMsg & !userIsAdmin ? (
            <ApplicationForm
              applicantsData={applicantsData}
              setApplicantsData={setApplicantsData}
              setAppliedMsg={setAppliedMsg}
              jobsData={jobsData}
              membersData={membersData}
              emailList={emailList}
              newResponse={newResponse}
              setNewResponse={setNewResponse}
              questionnaire={questionnaireData}
              setQuestionnaire={setQuestionnaireData}
              responsesData={responsesData}
              setResponsesData={setResponsesData}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <MobileView>
        <div
          className={`${
            isMobile ? "cursor-pointer" : ""
          } sm:block md:mx-5 mb-1 lg:hidden p-8 teckpert-bg-green text-white rounded`}
        >
          <div>
            <div className="font-bold text-xl mb-3 tracking-wide">
              Next Steps
            </div>
            <a
              className="p-2 text-sm"
              href={
                isAndroid
                  ? "https://play.google.com/store/search?q=teckpert&c=apps"
                  : isIOS
                  ? "https://apps.apple.com/us/app/teckpert-digital-tech-work/id1474005004"
                  : ""
              }
              target="_blank"
            >
              Download our mobile app, sign up and complete your profile. Upload
              your resume and express interest for this opportunity by viewing
              the opportunity and giving the thumbs up. Note, you will also
              receive push notifications of additional opportunities as they
              become available. Go to our website and click on the link at the
              top for iOS or Android.
            </a>
          </div>
        </div>
      </MobileView>
    </div>
  );
};
export default JobDetails;
