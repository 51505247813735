import { API, graphqlOperation } from "aws-amplify";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { v4 as uuidv4 } from "uuid";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";
import { Editor } from "@tinymce/tinymce-react";
import Loading from "../../../../utils/Loading";

const CreateOpportunitiesModal = ({
  modalOpen,
  setModalOpen,
  tableData,
  setTableData,
  setConfirmButtonMessage,
  action,
}) => {
  const [client, setClient] = useState([]);
  const [contract, setContract] = useState([]);
  const [user, setUser] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [interviewGuide, setInterviewGuide] = useState([]);
  const [workflow, setWorkflow] = useState([]);
  const [error, setError] = useState([]);
  const [tempLocation, setTempLocation] = useState("");
  const [tempText, setTempText] = useState("");
  const [tempData, setTempData] = useState({
    Title: "",
    Description: "",
    DateCreated: new Date().toISOString(),
    DateUpdated: new Date().toISOString(),
    Status: "Draft",
    Location: "",
    Type: "",
    WorkType: "",
    ClientId: "",
    Contract: "",
    ContractLength: "",
    ContractTerm: "",
    Department: "",
    RecruiterId: "",
    RequisitionNum: 0,
    RFQNum: 0,
    DocRefNum: 0,
    HighEndRate: 0.0,
    LowEndRate: 0.0,
    PayRateCompensationUnit: "",
    BillRateContract: 0.0,
    BillRateCompensationUnit: "",
    HoursPerWeek: 0,
    TotalPositionsOpen: 0,
    TotalCandidatesToSubmit: 0,
    CutOffDate: new Date().toISOString(),
    DueDate: new Date().toISOString(),
    Support: "",
    WorkType: "",
    Assignee: "",
    Compensation: "",
    QuestionnaireFormId: "",
    InterviewGuideId: "",
  });

  // GET/SET USERS, CLIENTS, CONTRACTS, APPLICATION QUESTIONNAIRES AND INTERVIEW GUIDES DATA FOR DROPDOWNS
  useEffect(() => {
    API.graphql({
      query: `{
        Clients(order_by: {Name: asc}) {
          ItemId
          Name
        }
        Contracts(order_by: {Name: asc}) {
          ItemId
          Name
          ClientId
        }
        ApplicationQuestionnaire {
          ItemId
          Title
        }
        InterviewGuides {
          ItemId
          Title
        }
        Users(where: {UserType: {_neq: "member"}}) {
          ItemId
          FirstName
          LastName
        }
        Workflows {
          ItemId
          Title
        }
      }      
  `,
    }).then(({ data }) => {
      setClient(data.Clients);
      setContract(data.Contracts);
      setQuestionnaire(data.ApplicationQuestionnaire);
      setInterviewGuide(data.InterviewGuides);
      setUser(data.Users);
      setWorkflow(data.Workflows);
    });
  }, []);

  useEffect(() => {
    if (tempLocation) {
      let temp = tempData;
      temp.Location = tempLocation;
      setTempData({ ...temp });
    }
  }, [tempLocation]);

  //useEffect(() => {
  //    if (tempText) {
        
  //    let temp = tempData;
  //    temp.Description = tempText;
  //    setTempData({ ...temp });
  //  }
  //}, [tempText]);

  useEffect(() => {
      if (action == "cloning") {
          setTempData({ ...tableData });
          setTempText(tempData.Description)
    }
  }, [modalOpen, tableData]);

  if (modalOpen && !tableData) return <Loading />;

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[1000px] sm:w-[750px] h-[800px] relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:ml-6 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => setModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Opportunity
                    </Dialog.Title>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow mt-6">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Title
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Title = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Title}
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="title"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>

                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Location
                    </label>
                    <Autocomplete
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white"
                      apiKey={"AIzaSyAVDbuhk4LVrRFHVjyB4Zk5bHs3QgWiX_Y"}
                      onPlaceSelected={(place) => {
                        setTempLocation(place.formatted_address);
                      }}
                      defaultValue={tempData.Location}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Type
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Type = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Type}
                    >
                      <option className="text-gray-300" value="">
                        Select Type
                      </option>
                      {["Contract", "Full-Time", "On Demand", "Part-Time"].map(
                        (obj, i) => (
                          <option key={i} value={obj}>
                            {obj}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Work Type
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.WorkType = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.WorkType}
                    >
                      <option className="text-gray-300" value="">
                        Select Work Type
                      </option>
                      {["Hybrid", "Onsite", "Remote"].map((obj, i) => (
                        <option key={i} value={obj}>
                          {obj}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Department
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Department = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Department}
                      type="text"
                      name="department"
                      id="department"
                      autoComplete="department"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Client
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.ClientId = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.ClientId}
                    >
                      <option className="text-gray-300" value="">
                        Select Client
                      </option>
                      {client
                        ?.sort((a, b) => a.Name.localeCompare(b.Name))
                        ?.map((obj) => (
                          <option key={obj.ItemId} value={obj.ItemId}>
                            {obj.Name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Contract
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Contract = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Contract}
                    >
                      <option className="text-gray-300" value="">
                        Select Contract
                      </option>
                      {contract
                        ?.sort((a, b) => a.Name.localeCompare(b.Name))
                        .map((obj) => {
                          if (obj.ClientId === tempData.ClientId)
                            return (
                              <option key={obj.ItemId} value={obj.ItemId}>
                                {obj.Name}
                              </option>
                            );
                        })}
                    </select>
                  </div>
                </div>

                <div className="flex gap-1">
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Lead Recruiter
                      </label>
                      <select
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                        onChange={(e) => {
                          let temp = tempData;
                          temp.RecruiterId = e.target.value;
                          setTempData({ ...temp });
                        }}
                        value={tempData.RecruiterId}
                      >
                        <option className="text-gray-300" value="">
                          Select Recruiter
                        </option>
                        {user
                          ?.sort((a, b) =>
                            a.FirstName.localeCompare(b.FirstName)
                          )
                          .map((obj) => (
                            <option key={obj.ItemId} value={obj.ItemId}>
                              {obj.FirstName} {obj.LastName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Support Recruiter
                      </label>
                      <select
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                        onChange={(e) => {
                          let temp = tempData;
                          temp.Support = e.target.value;
                          setTempData({ ...temp });
                        }}
                        value={tempData.Support}
                      >
                        <option className="text-gray-300" value="">
                          Select Recruiter
                        </option>
                        {user
                          ?.sort((a, b) =>
                            a.FirstName.localeCompare(b.FirstName)
                          )
                          ?.map((obj) => (
                            <option key={obj.ItemId} value={obj.ItemId}>
                              {obj.FirstName} {obj.LastName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Requisition Number
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        if (
                          e.target.value === "" ||
                          /^[0-9]+$/.test(e.target.value)
                        ) {
                          temp.RequisitionNum = e.target.value;
                          setTempData({ ...temp });
                        }
                      }}
                      value={tempData.RequisitionNum}
                      type="text"
                      name="number"
                      id="number"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      RFQ Number
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        if (
                          e.target.value === "" ||
                          /^[0-9]+$/.test(e.target.value)
                        ) {
                          temp.RFQNum = e.target.value;
                          setTempData({ ...temp });
                        }
                      }}
                      value={tempData.RFQNum}
                      type="text"
                      name="number"
                      id="number"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Document Reference Number
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        if (
                          e.target.value === "" ||
                          /^[0-9]+$/.test(e.target.value)
                        ) {
                          temp.DocRefNum = e.target.value;
                          setTempData({ ...temp });
                        }
                      }}
                      value={tempData.DocRefNum}
                      type="text"
                      name="number"
                      id="number"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="flex gap-1">
                  <div className="sm:col-span-2">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Low End Pay Rate
                      </label>
                      <span className="inline absolute px-2 mt-2.5">$</span>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value)
                          ) {
                            temp.LowEndRate = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.LowEndRate}
                        type="text"
                        name="rate"
                        id="rate"
                        className="w-full pl-5 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Pay Rate Up To
                      </label>
                      <span className="inline absolute px-2 mt-2.5">$</span>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value)
                          ) {
                            temp.HighEndRate = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.HighEndRate}
                        type="text"
                        name="rate"
                        id="rate"
                        className="w-full pl-5 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Pay Rate Compensation Unit
                      </label>
                      <select
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                        onChange={(e) => {
                          let temp = tempData;
                          temp.PayRateCompensationUnit = e.target.value;
                          setTempData({ ...temp });
                        }}
                        value={tempData.PayRateCompensationUnit}
                      >
                        <option className="text-gray-300" value="">
                          Select Compensation Unit
                        </option>
                        {["Per Hour", "Per Week", "Per Month", "Per Year"].map(
                          (obj, i) => (
                            <option key={i} value={obj}>
                              {obj}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex gap-1">
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Hours Per Week
                      </label>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value)
                          ) {
                            temp.HoursPerWeek = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.HoursPerWeek}
                        type="text"
                        name="hours"
                        id="hours"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Total Positions Open
                      </label>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value)
                          ) {
                            temp.TotalPositionsOpen = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.TotalPositionsOpen}
                        type="text"
                        name="total"
                        id="total"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Total Candidates To Submit
                      </label>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value)
                          ) {
                            temp.TotalCandidatesToSubmit = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.TotalCandidatesToSubmit}
                        type="text"
                        name="total"
                        id="total"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-1">
                  <div className="sm:col-span-2">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Contract Length
                      </label>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value) &
                              (e.target.value.length <= 2)
                          ) {
                            temp.ContractLength = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.ContractLength}
                        type="text"
                        name="length"
                        id="length"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-white">
                        Term
                      </label>
                      <select
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                        onChange={(e) => {
                          let temp = tempData;
                          temp.ContractTerm = e.target.value;
                          setTempData({ ...temp });
                        }}
                        value={tempData.ContractTerm}
                      >
                        <option className="text-gray-300" value="">
                          Select Term
                        </option>
                        {["Day(s)", "Week(s)", "Month(s)", "Year(s)"].map(
                          (obj, i) => (
                            <option key={i} value={obj}>
                              {obj}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex gap-1">
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Bill Rate
                      </label>
                      <span className="inline absolute px-2 mt-2.5">$</span>
                      <input
                        onChange={(e) => {
                          let temp = tempData;
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(e.target.value)
                          ) {
                            temp.BillRateContract = e.target.value;
                            setTempData({ ...temp });
                          }
                        }}
                        value={tempData.BillRateContract}
                        type="text"
                        name="rate"
                        id="rate"
                        className="w-full pl-5 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Bill Rate Compensation Unit
                      </label>
                      <select
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                        onChange={(e) => {
                          let temp = tempData;
                          temp.BillRateCompensationUnit = e.target.value;
                          setTempData({ ...temp });
                        }}
                        value={tempData.BillRateCompensationUnit}
                      >
                        <option className="text-gray-300" value="">
                          Select Compensation Unit
                        </option>
                        {["Per Hour", "Per Week", "Per Month", "Per Year"].map(
                          (obj, i) => (
                            <option key={i} value={obj}>
                              {obj}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex gap-1">
                  <div className="sm:col-span-3 flex-grow">
                    <div className="mt-2" style={{ zIndex: 2000 }}>
                      <label className="block text-sm font-medium text-gray-700">
                        Cut Off Date
                      </label>
                      <DatePicker
                        className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                        selected={
                          tempData?.CutOffDate
                            ? new Date(tempData.CutOffDate)
                            : new Date()
                        }
                        onChange={(date) => {
                          let temp = tempData;
                          temp.CutOffDate = date;
                          setTempData({ ...temp });
                        }}
                        fixedHeight
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 flex-grow">
                    <div className="mt-2" style={{ zIndex: 2000 }}>
                      <label className="block text-sm font-medium text-gray-700">
                        Due Date
                      </label>
                      <DatePicker
                        className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                        selected={
                          tempData?.DueDate
                            ? new Date(tempData.DueDate)
                            : new Date()
                        }
                        onChange={(date) => {
                          let temp = tempData;
                          temp.DueDate = date;
                          setTempData({ ...temp });
                        }}
                        fixedHeight
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Questionnaire Form
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.QuestionnaireFormId = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.QuestionnaireFormId}
                    >
                      <option className="text-gray-300" value="">
                        Select Questionnaire
                      </option>
                      {questionnaire?.map((obj) => (
                        <option key={obj.ItemId} value={obj.ItemId}>
                          {obj.Title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Interview Guide
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.InterviewGuideId = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.InterviewGuideId}
                    >
                      <option className="text-gray-300" value="">
                        Select Interview Guide
                      </option>
                      {interviewGuide?.map((obj) => (
                        <option key={obj.ItemId} value={obj.ItemId}>
                          {obj.Title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Workflow
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.WorkflowId = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.WorkflowId}
                    >
                      <option className="text-gray-300" value="">
                        Select Workflow
                      </option>
                      {workflow
                        ?.sort((a, b) => a.Title.localeCompare(b.Title))
                        ?.map((obj) => (
                          <option key={obj.ItemId} value={obj.ItemId}>
                            {obj.Title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div>
                    <Editor
                      apiKey={
                        "2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"
                      }
                      onEditorChange={(value) => {
                        setTempText(value);
                      }}
                      initialValue={tempData.Description}
                      init={{
                        height: 400,
                        menubar: false,
                        plugins: [
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | casechange blocks | bold italic backcolor | " +
                          "alignleft aligncenter alignright alignjustify | " +
                          "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        content_style:
                          "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                      }}
                    />
                  </div>
                </div>

                {error.length > 0 && MissingFieldPrompt(error)}

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let errorArray = [];
                      if (tempData.Title?.trim().length === 0)
                        errorArray.push("Title");
                      if (tempData.Location?.trim().length === 0)
                        errorArray.push("Location");
                      if (tempData.Type?.trim().length === 0)
                        errorArray.push("Type");
                      if (tempData.WorkType?.trim().length === 0)
                        errorArray.push("Work Type");
                      if (tempData.ClientId?.trim().length === 0)
                        errorArray.push("Client");
                      if (tempData.RecruiterId?.trim().length === 0)
                        errorArray.push("Lead Recruiter");
                      if (tempData.Support?.trim().length === 0)
                        errorArray.push("Support Recruiter");
                      if (tempData.LowEndRate?.length === 0)
                        errorArray.push("Low End Pay Rate");
                      if (tempData.HighEndRate?.length === 0)
                        errorArray.push("Pay Rate Up To");
                      if (tempData.PayRateCompensationUnit?.trim().length === 0)
                        errorArray.push("Pay Rate Compensation Unit");
                      if (tempData.BillRateContract?.length === 0)
                        errorArray.push("Bill Rate");
                      if (
                        tempData.BillRateCompensationUnit?.trim().length === 0
                      )
                        errorArray.push("Bill Rate Compensation Unit");
                      if (tempData.HoursPerWeek?.length === 0)
                        errorArray.push("Hours Per Week");
                      if (tempData.ContractLength?.trim().length === 0)
                        errorArray.push("Contract Length");
                      if (tempData.ContractTerm?.trim().length === 0)
                        errorArray.push("Contract Term");
                      if (tempData.TotalCandidatesToSubmit?.length === 0)
                        errorArray.push("Total Candidates To Submit");
                      if (tempData.CutOffDate?.length === 0)
                        errorArray.push("Cut Off Date");
                      if (tempData.DueDate?.length === 0)
                        errorArray.push("Due Date");

                      setError(errorArray);

                      if (errorArray.length > 0) {
                        return;
                      }

                      let temp = tempData;
                      if (!temp.CutOffDate) temp.CutOffDate = new Date();
                      if (!temp.DueDate) temp.DueDate = new Date();

                      await API.graphql(
                        graphqlOperation(`mutation {
                          insert_Opportunities_one(
                            object: 
                              {
                                Assignee: "${tempData.Assignee}", 
                                BillRateCompensationUnit: "${
                                  tempData.BillRateCompensationUnit
                                }", 
                                BillRateContract: ${tempData.BillRateContract},
                                LowEndRate: ${tempData.LowEndRate},
                                HighEndRate: ${tempData.HighEndRate},
                                ClientId: "${tempData.ClientId}", 
                                Compensation: "${tempData.Compensation}", 
                                Contract: "${tempData.Contract}", 
                                ContractLength: "${tempData.ContractLength}", 
                                ContractTerm: "${tempData.ContractTerm}", 
                                Department: "${tempData.Department}", 
                                Description: ${JSON.stringify(tempText)}, 
                                DocRefNum: ${tempData.DocRefNum}, 
                                HoursPerWeek: ${tempData.HoursPerWeek}, 
                                ItemId: "${uuidv4()}",
                                Location: "${tempLocation}", 
                                PayRateCompensationUnit: "${
                                  tempData.PayRateCompensationUnit
                                }", 
                                QuestionnaireFormId: "${
                                  tempData.QuestionnaireFormId
                                }",
                                InterviewGuideId: "${
                                  tempData.InterviewGuideId
                                }", 
                                RFQNum: ${tempData.RFQNum}, 
                                RecruiterId: "${tempData.RecruiterId}", 
                                RequisitionNum: ${tempData.RequisitionNum}, 
                                Status: "${tempData.Status}", 
                                Support: "${tempData.Support}", 
                                Title: "${tempData.Title}", 
                                TotalCandidatesToSubmit: ${
                                  tempData.TotalCandidatesToSubmit
                                }, 
                                TotalPositionsOpen: ${
                                  tempData.TotalPositionsOpen
                                }, 
                                Type: "${tempData.Type}", 
                                WorkType: "${tempData.WorkType}",
                                CutOffDate: "${temp.CutOffDate}",
                                DueDate: "${temp.DueDate}",
                              }, on_conflict: {constraint: Opportunities_pkey}
                            )       
                            {
                              ItemId
                              Title
                              Location
                              Description
                            }            
                      }`)
                      ).then(({ data }) => {
                        setModalOpen(false);
                        if (action === "creating")
                          setTableData([...tableData, tempData]);
                        if (action === "cloning") setConfirmButtonMessage(true);
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    sm:mt-0 sm:w-auto sm:text-sm focus:outline-none"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CreateOpportunitiesModal;
