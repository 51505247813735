import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Table from "./Table";
import BasicSearchBar from "../../utils/BasicSearchbar";
import CreateCourseModal from "./CreateCourseModal";
import Loading from "../../utils/Loading";

const Courses = () => {
  const itemsPerPage = 100;
  const [tableData, setTableData] = useState([]);
  const [tempTableData, setTempTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [btnFilter, setBtnFilter] = useState("");
  const [memberTotal, setMemberTotal] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.graphql({
      query: `{
        Courses(offset: ${
          (page - 1) * itemsPerPage
        }, limit: ${itemsPerPage}, order_by: {DateCreated: asc}) {
          ItemId
          Category
          DateCreated
          DateUpdated
          Title
          Track
          Institution
          Length
          HoursPerWeek
          Cost
          VerifiedCost
          StartDate
          Link
          Description
        }
        Courses_aggregate {
          aggregate {
            count
          }
        }
      }      
    `,
    }).then(({ data }) => {
      setTableData(data.Courses);
      setTempTableData(data.Courses);
        setMemberTotal(data.Courses_aggregate.aggregate.count);
        setLoading(false);
    });
  }, [page]);

  useEffect(() => {
    setTempTableData(
      tableData.filter((course) =>
        course.Title?.toLowerCase().startsWith(searchVal.toLowerCase())
      )
    );
  }, [searchVal]);

  useEffect(() => {
    setTempTableData(
      tableData?.filter((course) =>
        course.Category?.toLowerCase().startsWith(btnFilter.toLowerCase())
      )
    );
  }, [btnFilter]);

    if (loading) return <Loading />
  return (
    <div className="w-full">
      <div className="pb-6">
        <button
          type="button"
          onClick={() => setModalOpen(true)}
          className="float-right mr-6 inline-flex items-center justify-center rounded-md border border-transparent hover:opacity-90
          teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
        >
          Create
        </button>
      </div>
      <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
      <Table
        tableData={tableData}
        setTableData={setTableData}
        tempTableData={tempTableData}
        setTempTableData={setTempTableData}
        searchVal={btnFilter}
        setSearchVal={setBtnFilter}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        memberTotal={memberTotal}
      />
      <CreateCourseModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
      />
    </div>
  );
};
export default Courses;
