import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  CheckIcon,
  XCircleIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";
import AddCertificationsModal from "../Modals/AddCertificationsModal";
import UpdateCertificationsModal from "../Modals/UpdateCertificationsModal";

const Certifications = ({
  originalData,
  profileData,
  setProfileData,
  confirmButtonMessage,
  setConfirmButtonMessage,
}) => {
  const [enabled, setEnabled] = useState(false);
  const [id, setId] = useState(0);
  const [selected, setSelected] = useState({});
  const [verified, setVerified] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showCertifications, setShowCertifications] = useState(false);
  const [newVerCertification, setNewVerCertification] = useState("");
  const [newUnverCertification, setNewUnverCertification] = useState("");
  const [noCertifications, setNoCertifications] = useState(false);
  const [tempData, setTempData] = useState({
    ItemId: profileData.ItemId,
    Meta: profileData.Meta,
  });

  useEffect(() => {
    if (
      !profileData.Meta?.UnverifiedCertifications ||
      profileData.Meta?.UnverifiedCertifications.length < 1
    ) {
      if (
        !profileData.Meta?.VerifiedCertifications ||
        profileData.Meta?.VerifiedCertifications.length < 1
      ) {
        setNoCertifications(true);
      }
    } else setNoCertifications(false);
  }, [profileData.Meta, confirmButtonMessage]);

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Certifications
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setAddModalOpen(!addModalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
          <div
            onClick={() => setShowCertifications(!showCertifications)}
            className="flex flex-none mr-5"
          >
            {!showCertifications ? (
              <ChevronUpIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
      {/* Table Section */}
      <div className={`${showCertifications ? "hidden" : "block"}`}>
        <div className="sm:-mx-6 lg:mx-0">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-5">
            {noCertifications ? (
              <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
                No Certifications Found
              </div>
            ) : null}
            {profileData.Meta?.VerifiedCertifications
              ? profileData.Meta?.VerifiedCertifications?.map((obj, i) => (
                  <div
                    key={i}
                    className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                  >
                    <div className="flex pl-10 pr-2">
                      <div className="py-3 px-20 flex-grow relative right-8">
                        <CheckIcon
                          className="inline h-5 w-5 cursor-pointer mr-3 teckpert-text-green"
                          aria-hidden="true"
                        />
                        <span className="px-4">{obj}</span>
                      </div>

                      <div className="relative right-3 flex flex-col items-center justify-center overflow-hidden">
                        <div className="flex">
                          <label className="inline-flex relative items-center mr-5 cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              checked={enabled}
                              readOnly
                            />
                            <div
                              onClick={async () => {
                                setEnabled(false);
                                let temp = tempData;
                                temp.Meta.VerifiedCertifications =
                                  temp.Meta.VerifiedCertifications.filter(
                                    (item) => item !== obj
                                  );
                                temp.Meta.UnverifiedCertifications.push(obj);
                                API.graphql({
                                  query: `mutation($meta: jsonb) {
                                    update_Members_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
                                      _set: { Meta: $meta } )       
                                        {
                                          ItemId
                                          Meta
                                        }            
                                    }`,
                                  variables: { meta: temp.Meta },
                                }).then(async ({ data }) => {
                                  setProfileData({ ...temp });
                                  setTempData({ ...temp });
                                  setConfirmButtonMessage(true);
                                });
                              }}
                              className="w-11 h-6 rounded-full peer  peer-focus:ring-[#6fbe47]  
                              after:translate-x-full after:border-white after:content-[''] 
                              after:absolute after:top-0.5 after:left-[2px] after:bg-white
                              after:border after:rounded-full after:h-5 after:w-5 after:transition-all bg-[#6fbe47]"
                            ></div>
                            <span className="text-sm ml-3 mr-10">Verified</span>
                          </label>
                        </div>
                      </div>
                      <div className="flex-none px-3 py-5 text-sm text-gray-900 cursor-pointer">
                        <PencilIcon
                          onClick={() => {
                            setId(i);
                            setVerified(true);
                            setEditModalOpen(true);
                          }}
                          className="-ml-1 h-5 w-5 text-gray-300 hover:text-gray-500 cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                        <XCircleIcon
                          className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                          aria-hidden="true"
                          onClick={() => {
                            setSelected(obj);
                            setDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              : null}

            {profileData.Meta?.UnverifiedCertifications
              ? profileData.Meta?.UnverifiedCertifications?.map((obj, i) => (
                  <div
                    key={i}
                    className="bg-gray-100 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                  >
                    <div className="flex pl-10 pr-2">
                      <div className="py-3 px-20 flex-grow">
                        <span className="px-4">{obj}</span>
                      </div>

                      <div className="relative flex flex-col items-center justify-center overflow-hidden">
                        <div className="flex">
                          <label className="inline-flex relative items-center mr-5 cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              checked={verified}
                              readOnly
                            />
                            <div
                              onClick={async () => {
                                setEnabled(true);
                                let temp = tempData;
                                temp.Meta.UnverifiedCertifications =
                                  temp.Meta.UnverifiedCertifications.filter(
                                    (item) => item !== obj
                                  );
                                temp.Meta.VerifiedCertifications.push(obj);

                                setEnabled(false);

                                API.graphql({
                                  query: `mutation($meta: jsonb) {
                                    update_Members_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
                                      _set: { Meta: $meta } )       
                                        {
                                          ItemId
                                          Meta
                                        }            
                                    }`,
                                  variables: { meta: temp.Meta },
                                }).then(async ({ data }) => {
                                  setProfileData({ ...temp });
                                  setTempData({ ...temp });
                                  setConfirmButtonMessage(true);
                                });
                              }}
                              className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                              after:content-[''] 
                              after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                              after:border after:rounded-full after:h-5 after:w-5 after:transition-all"
                            ></div>
                            <span className="text-sm ml-3 mr-10">
                              Unverified
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                        <PencilIcon
                          onClick={() => {
                            setId(i);
                            setVerified(false);
                            setEditModalOpen(true);
                          }}
                          className="-ml-1 mt-1 h-5 w-5 text-gray-300 hover:text-gray-500  cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                        <XCircleIcon
                          className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                          aria-hidden="true"
                          onClick={() => {
                            setSelected(obj);
                            setDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <AddCertificationsModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        profileData={profileData}
        setProfileData={setProfileData}
        tempData={tempData}
        newUnverCertification={newUnverCertification}
        setNewUnverCertification={setNewUnverCertification}
        newVerCertification={newVerCertification}
        setNewVerCertification={setNewVerCertification}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteItem={async () => {
          let temp = tempData;
          temp.Meta.VerifiedCertifications =
            temp.Meta.VerifiedCertifications.filter(
              (item) => item !== selected
            );
          temp.Meta.UnverifiedCertifications =
            temp.Meta.UnverifiedCertifications.filter(
              (item) => item !== selected
            );
          API.graphql({
            query: `mutation($meta: jsonb) {
              update_Members_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
                _set: { Meta: $meta } )       
                {
                  ItemId
                  Meta
                }            
              }`,
            variables: { meta: temp.Meta },
          }).then(async ({ data }) => {
            setDeleteModalOpen(false);
            setProfileData({ ...temp });
            setConfirmButtonMessage(true);
          });
        }}
      />
      {Array.isArray(tempData.Meta.VerifiedCertifications) ||
        (Array.isArray(tempData.Meta.UnverifiedCertifications) && (
          <UpdateCertificationsModal
            open={editModalOpen}
            setOpen={setEditModalOpen}
            originalData={originalData}
            tempData={tempData}
            setTempData={setTempData}
            setConfirmButtonMessage={setConfirmButtonMessage}
            verified={verified}
            id={id}
            profileData={profileData}
          />
        ))}
    </div>
  );
};
export default Certifications;
