import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  XCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { API, Storage } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { formatDate } from "../../../../utils/FormatDate";
import Info from "../../../People/Profile/Info";
import Documents from "../../../People/Profile/Tabs/Documents";
import Notes from "../../../People/Profile/Tabs/Notes";
import Logs from "../../../People/Profile/Tabs/Logs";
import PDFViewer from "../../../People/Profile/Tabs/PDFViewer";
import Interviews from "../../../People/Profile/Tabs/Interviews";
import Preferences from "../../../People/Profile/Tabs/Preferences";
import References from "../../../People/Profile/Tabs/References";
import Education from "../../../People/Profile/Tabs/Education";
import Specialization from "../../../People/Profile/Tabs/Specialization";
import Certifications from "../../../People/Profile/Tabs/Certifications";
import Opportunities from "../../../People/Profile/Tabs/Opportunities";
import Contracts from "../../../People/Profile/Tabs/Contracts";
import Timesheet from "../../../People/Profile/Tabs/Timesheet";
import Loading from "../../../../utils/Loading";
import TabsMenu from "../../../People/Profile/TabsMenu";

const Profile = ({
  profileData,
  setProfileData,
  showProfile,
  setShowProfile,
  applicantsData,
  setApplicantsData,
  guideFilter,
  workflows,
  setConfirmButtonMessage,
}) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("tab0");
  const [admins, setAdmins] = useState([]);
  const [interviewGuides, setInterviewGuides] = useState([]);
  const [responseGuides, setResponseGuides] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [timesheets, setTimesheets] = useState([]);
  const [img, setImg] = useState("");
  const [lastContacted, setLastContacted] = useState(" ");
  const [progress, setProgress] = useState(0);
  const [showList, setShowList] = useState(false);
  const [msgModalOpen, setMsgModalOpen] = useState(false);

  let count = 0;

  useEffect(() => {
    const getStorageFile = async (key, redirect = false) => {
      const url = await Storage.get(key, {
        level: "public",
        expires: 60,
      });
      if (redirect === true && url !== "") {
        window.open(url, "_blank");
      }
      return url;
    };

    getStorageFile(profileData?.Meta?.ProfilePicture).then((url) => {
      setImg(url);
    });
  }, [profileData.ItemId]);

  useEffect(() => {
    API.graphql({
      query: `{
        InterviewGuides {
          ItemId
          DateCreated
          DateUpdated
          Title
          Duration
          Questions
          Notes
          Description
          CreatedBy
        }
        InterviewGuidesResponses {
          ItemId
          DateCreated
          DateUpdated
          DateCompleted
          InterviewGuideId
          Notes
          Rating
          MemberId
          Responses
        }
        Users(order_by: {FirstName: asc}, where: {UserType: {_neq: "member"}}) {
          ItemId
          DateCreated
          DateUpdated
          FirstName
          LastName
          UserType
          Email
        }
      }
    `,
    }).then(({ data }) => {
      setInterviewGuides(
        data.InterviewGuides.filter(
          (item) => guideFilter.InterviewGuideId === item.ItemId
        )
      ); // SET INTERVIEW GUIDES
      setResponseGuides(data.InterviewGuidesResponses); // SET INTERVIEW GUIDES RESPONSES
      setAdmins(data.Users); // SET ADMIN USERS
    });
  }, [location.pathname, guideFilter]);

  if (!profileData.Meta) return <Loading />;
  return (
    <React.Fragment>
      {/* Main content */}
      <div className="overflow-y-auto">
        <XCircleIcon
          onClick={() => setShowProfile(false)}
          className="float-right text-gray-300 hover:text-red-500 h-7 w-7 cursor-pointer"
          aria-hidden="true"
        />
        {/* Member Bio */}
        <div className="px-12 flex">
          <div className="mr-10">
            <div className="">
              {!img.includes("undefined") ? (
                <img
                  src={img}
                  className="max-h-[120px] max-w-[120] min-w-[120] rounded-full"
                />
              ) : (
                <img
                  src={"/logo512.png"}
                  width="120"
                  height="60"
                  className="rounded-full"
                />
              )}
            </div>
          </div>
          <div>
            <h5 className="text-xl font-bold text-gray-900">
              {profileData?.FirstName + " " + profileData?.LastName}
            </h5>
            <h5 className="text-xl font-bold teckpert-text-green">
              {profileData?.Meta?.PrimaryRole?.label
                ? profileData.Meta.PrimaryRole.label
                : "-"}
            </h5>
            <p className="text-gray-900 text-sm">
              {profileData?.Meta?.Description
                ? profileData.Meta.Description
                : "-"}
            </p>
            <div className="mt-2">
              <p className="italic text-gray-400 text-sm">
                Registered on&nbsp;
                <Link target="_blank" to="/tools/emails">
                  {profileData?.DateCreated
                    ? formatDate(profileData.DateCreated)
                    : "-"}
                </Link>
              </p>
              <p className="italic text-gray-400 text-sm">
                Last Contacted on&nbsp;
                {lastContacted ? (
                  <Link target="_blank" to="/tools/emails">
                    {lastContacted ? formatDate(lastContacted) : "-"}
                  </Link>
                ) : (
                  "N/A"
                )}
              </p>
            </div>
          </div>
        </div>

        <Info
          profileData={profileData}
          applicantsData={applicantsData}
          setApplicantsData={setApplicantsData}
          admins={admins}
          msgModalOpen={msgModalOpen}
          setMsgModalOpen={setMsgModalOpen}
          workflows={workflows}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />

        <div className="teckpert-bg-green p-3 rounded flex justify-center gap-8">
          <div className="text-center">
            <h2 className="text-white font-bold text-xl mr-5">
              Talent Checklist
            </h2>
          </div>
          <div className="relative pt-2 w-[300px]">
            <div className="overflow-hidden h-4 mb-2 text-xs flex rounded-full bg-[#489b37]">
              <div
                className={`${
                  progress === 0
                    ? "w-0"
                    : progress === 1
                    ? "w-[12%]"
                    : progress === 2
                    ? "w-[24%]"
                    : progress === 3
                    ? "w-[36%]"
                    : progress === 4
                    ? "w-[48%]"
                    : progress === 5
                    ? "w-[60%]"
                    : progress === 6
                    ? "w-[72%]"
                    : progress === 7
                    ? "w-[84%]"
                    : "w-[100%]"
                } 
              shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-200`}
              ></div>
            </div>
            <div>
              {showList ? (
                <ChevronUpIcon
                  onClick={() => setShowList(false)}
                  className="float-right text-white mt-2 h-5 w-5 cursor-pointer"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => setShowList(true)}
                  className="float-right text-white mt-2 h-5 w-5 cursor-pointer"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>

        <div className="bg-white">
          <TabsMenu activeTab={activeTab} setActiveTab={setActiveTab} />
          {/* Resume Tab */}
          <div className={activeTab === "tab0" ? "block" : "hidden"}>
            <PDFViewer
              profileData={profileData}
              setProfileData={setProfileData}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
          </div>
          {/* Interviews Tab */}
          <div className={activeTab === "tab1" ? "block" : "hidden"}>
            {interviewGuides ? (
              <Interviews
                tableData={interviewGuides}
                setTableData={setInterviewGuides}
                responsesData={responseGuides.filter(
                  (x) => x.MemberId === location.pathname.split("/")[2]
                )}
                profileData={profileData}
                setProfileData={setProfileData}
                setConfirmButtonMessage={setConfirmButtonMessage}
              />
            ) : null}
          </div>
          {/* Profile Tab */}
          <div className={activeTab === "tab2" ? "block" : "hidden"}>
            <Preferences
              profileData={profileData}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <References
              profileData={profileData}
              setProfileData={setProfileData}
              url={"/members"}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <Education
              profileData={profileData}
              setProfileData={setProfileData}
              url={"/members"}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <Specialization
              profileData={profileData}
              setProfileData={setProfileData}
              url={"/members"}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <Certifications
              profileData={profileData}
              setProfileData={setProfileData}
              url={"/members"}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
          </div>
          {/* Opportunities Tab */}
          <div className={activeTab === "tab3" ? "block" : "hidden"}>
            <Opportunities profileData={profileData} />
          </div>
          {/* Contracts Tab */}
          <div className={activeTab === "tab4" ? "block" : "hidden"}>
            <Contracts
              contractData={contractData}
              setContractData={setContractData}
            />
            {/* {timesheets.length > 0 ? (
                  <Timesheet
                    timesheets={timesheets}
                    setTimesheets={setTimesheets}
                  />
                ) : null} */}
          </div>
          {/* Documents Tab */}
          <div className={activeTab === "tab5" ? "block" : "hidden"}>
            <Documents
              tableData={documentData}
              setTableData={setDocumentData}
              documentUrl={documentUrl}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
          </div>
          {/* Notes Tab */}
          <div className={activeTab === "tab6" ? "block" : "hidden"}>
            <div className="mt-4 p-3 flex">
              <div className="flex-grow">
                <Notes
                  profileData={profileData}
                  setProfileData={setProfileData}
                  url={"/members"}
                  setConfirmButtonMessage={setConfirmButtonMessage}
                />
              </div>
              <div className="flex-grow">
                <Logs
                  profileData={profileData}
                  setProfileData={setProfileData}
                  url={"/members"}
                  setConfirmButtonMessage={setConfirmButtonMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Profile;
