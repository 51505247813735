import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import Table from "./Table";
import CreateInterviewModal from "./Modals/CreateInterviewModal";

const InterviewGuides = () => {
  const [user, setUser] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [type, setType] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editQuestionsModalOpen, setEditQuestionsModalOpen] = useState(false);
  const [questionsModalOpen, setQuestionsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const [newData, setNewData] = useState({
    Title: "",
    Description: "",
    Type: "",
    Duration: "",
    DateCreated: new Date().toISOString(),
    DateUpdated: new Date().toISOString(),
    CreatedBy: "",
    Notes: "",
    Questions: [
      {
        Question: "",
        AnswerType: "",
        Order: 0,
        AnswerOptions: [
          {
            Option: "",
            Order: 0,
          },
        ],
      },
    ],
  });

  // GET DATA FOR INTERVIEW GUIDES AND OPPORTUNITIES
  useEffect(() => {
    API.graphql({
      query: `{
        InterviewGuides {
          ItemId
          DateCreated
          DateUpdated
          Title
          Type
          Duration
          Questions
          Notes
          Description
          CreatedBy
        }
      }      
    `,
    }).then(({ data }) => {
      setTableData(data.InterviewGuides);
      setTempData(data.InterviewGuides);
    });

    API.graphql({
      query: `{
      Opportunities(offset: 0, limit: 25 order_by: {DateCreated: asc}) {
        ItemId
        ClientId
        Applicants
        Contract
        ContractLength
        DateCreated
        HighEndRate
        Location
        LowEndRate
        PayRateCompensationUnit
        QuestionnaireFormId
        Title
        WorkType
        Client {
          Name
        }
        Contracts {
          Name
        }
        ApplicationQuestionnaire {
          Title
        }
      }
    }      
  `,
    }).then(({ data }) => {
      setJobsData("Opportunities", data.Opportunities);
    });
  }, []);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {  
          ItemId
          FirstName
          LastName
          Email
          UserType
        }
      }      
    `,
    }).then(({ data }) => {
      setUser(data.Users[0]);
    });
  }, []);

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6 mb-4">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={() => setCreateModalOpen(!createModalOpen)}
            type="button"
            className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <Table
        tableData={tableData}
        setTableData={setTableData}
        tempData={tempData}
        setTempData={setTempData}
        jobsData={jobsData}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        editQuestionsModalOpen={editQuestionsModalOpen}
        setEditQuestionsModalOpen={setEditQuestionsModalOpen}
      />
      <CreateInterviewModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        tableData={tableData}
        setTableData={setTableData}
        tempData={newData}
        setTempData={setNewData}
        type={type}
        setType={setType}
        setQuestionsModalOpen={setQuestionsModalOpen}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        setEditQuestionsModalOpen={setEditQuestionsModalOpen}
        user={user}
      />
    </div>
  );
};
export default InterviewGuides;
