const BasicSearchBar = (props) => {
  return (
    <div>
      <div className="flex justify-center bg-[#f5f5f5] w-full">
        <div className="my-5 mx-8 w-full">
          <div className="input-group relative flex flex-wrap items-stretch rounded-full bg-white">
            <input
              onChange={(e) => props.setSearchVal(e.target.value)}
              value={props.searchVal}
              type="search"
              className="w-100 form-control relative flex-auto block px-7 py-5 
              text-base font-normal text-gray-700 bg-white bg-clip-padding transition 
              ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
              focus:outline-none rounded-full"
              placeholder="Search by Name or Title"
              aria-label="Search"
              aria-describedby="button-addon3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicSearchBar;
