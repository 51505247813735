import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Table from "./Table";
import BasicSearchBar from "../../utils/BasicSearchbar";
import { SendForApprovalModal } from "./SendForApprovalModal";
import Loading from "../../utils/Loading";
const Timesheets = ({ show, selectedTimesheets = [], setSelectedTimesheets, userData = () => { } }) => {
  const itemsPerPage = 25;
  const [contractData, setContractData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [selectContract, setSelectContract] = useState("");
  const [selectDuration, setSelectDuration] = useState("");
  const [page, setPage] = useState(1);
  const [itemsTotal, setItemsTotal] = useState(1000);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [emailTemplateData, setEmailTemplateData] = useState([]);
  const [updateTableData, setUpdateTableData] = useState(false);
  useEffect(() => {
    const async = async () => {
      setLoading(true);

      const convertDurationToDate = (string) => {
        const date = new Date();
        if (string === 'A Week Ago') {
          date.setDate(date.getDate() - 7);
        }
        else if (string === 'Two Weeks Ago') {
          date.setDate(date.getDate() - 14);
        }
        else if (string === 'A Month Ago') {
          date.setMonth(date.getMonth() - 1);
        }
        else if (string === 'Two Months Ago') {
          date.setMonth(date.getMonth() - 2);
        }
        else if (string === 'Three Months Ago') {
          date.setMonth(date.getMonth() - 3);
        }
        return date.toISOString().split('T')[0];
      }

      const whereQuery = `{
        ${(searchVal || selectContract) ? `JobOrder: {
          ${searchVal ? `Member: {
            _or: [
              {FirstName: {_ilike: "${searchVal}%"}},
              {LastName: {_ilike: "${searchVal}%"}},
              {JobTitle: {Name:{ _ilike: "${searchVal}%"}}}
            ]
          },` : ''}
        ContractId: {${selectContract ? `_eq: "${selectContract}"` : `_is_null: false`}}
      },` : ''}
        ${selectStatus ? `Status: {_eq: "${selectStatus}"},` : ''}
        ${selectDuration ? `` : ''}
        ${selectDuration ? `PeriodStart: {_lte: "${convertDurationToDate(selectDuration)}"},` : ''}
        ItemId: {_is_null: false}
      }`;
      const { data } = await API.graphql({
        query: `{
          Timesheets(offset: ${(page - 1) * itemsPerPage}, limit: ${itemsPerPage}, order_by: {DateCreated: desc}, where: ${whereQuery}) {
          ItemId
          DateCreated
          DateUpdated         
          JobOrder {
            BillableRate
            Rate
            Member {
              ItemId
              FirstName
              LastName
              Meta
            }
            Contract {
              ItemId
              Name
              Clients {
                ItemId
                Name
              }
            }
          }
          PeriodStart
          PeriodEnd         
          ApprovedBy
          ApprovalDate
          Status
          PayrollId
           Hours: TimesheetEntries_aggregate {
            aggregate {
              sum {
                Hours
              }
            }
          }
          TimesheetEntries(order_by: {Date: asc}) {
            Date
            Hours
            Category
            Details
            Status
            ItemId
          }
        }
        Contracts(order_by: {Name: asc}) {
          ItemId
          Name
        }
        EmailTemplates(where: { 
                          Type: { _eq: "System Templates" },
                          Name: { _eq: "Timesheet Approval" }
                       }) {
                            Body
        }
      }`});
      setTableData(data.Timesheets);
      setContractData(data.Contracts);
      setEmailTemplateData(data.EmailTemplates);
      setLoading(false);
      setUpdateData(false);
      setUpdateTableData(false);        
    }
    async();
  }, [page, searchVal, selectStatus,  selectContract, selectDuration, updateData, updateTableData]);
   
  const calculateDuration = (obj, selectDuration) => {
    if (selectDuration.length === 0) return true;
    return (
      new Date(obj?.PeriodEnd).getTime() >=
      new Date().getTime() -
      (86400000 * selectDuration === "A Week Ago"
        ? 7
        : selectDuration === "Two Weeks Ago"
          ? 14
          : selectDuration === "A Month Ago"
            ? 30
            : selectDuration === "Two Months Ago"
              ? 60
              : 90)
    );
  };

  const sendForApprovalDisabled = [...new Set(selectedTimesheets.map(({ JobOrder: { Contract: { ItemId } } }) => ItemId))].length !== 1;

  return (
    <div>
      {show && (
        <>
          <div className="flex justify-end mx-4">
            <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
              <button
                style={sendForApprovalDisabled ? { backgroundColor: '#ccc' } : {}}
                disabled={sendForApprovalDisabled}
                onClick={() => setModal(!modal)}
                type="button"
                className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
              >
                Send for approval
              </button>
            </div>
          </div>
          <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
        </>
      )}
      <Table
              contractData={contractData}
              tableData={tableData}
              setTableData={setTableData}
              selectStatus={selectStatus}
              setSelectStatus={setSelectStatus}
              selectContract={selectContract}
              setSelectContract={setSelectContract}
              selectDuration={selectDuration}
              setSelectDuration={setSelectDuration}
              page={page}
              setPage={setPage}
              itemsTotal={itemsTotal}
              itemsPerPage={itemsPerPage}
              selectedTimesheets={selectedTimesheets}
              setSelectedTimesheets={setSelectedTimesheets}
              updateData={updateData}
              setUpdateData={setUpdateData}
              updateTableData={updateTableData}
              setUpdateTableData={setUpdateTableData}
              userData={userData }
      />
      <SendForApprovalModal
              modal={modal}
              setModal={setModal}
              selectedTimesheets={selectedTimesheets}
              setSelectedTimesheets={setSelectedTimesheets}
              tableData={tableData}
              setTableData={setTableData}              
              emailTemplateData={emailTemplateData}
      />
      {loading && <Loading />}
    </div>
  );
};
export default Timesheets;
