import { useState, useEffect } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { API } from "aws-amplify";

const Questionnaire = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md">
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow p-2">
          <h4 className="text-2xl font-normal mt-0 font-bold text-gray-800 ml-5">
            Tech Qualifications - Miami Dade
          </h4>
          <p className="mt-3 mb-3 text-md font-normal text-gray-800 ml-5">
            This is a questionnaire for the Miami Dade Schools IT Contract
          </p>
        </div>
      </div>
      {/* Content Section */}
      <div className="mx-5 flex flex-col">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
            {/* Table Section */}
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg hover:bg-gray-100">
              <div className="flex px-4">
                <div className="flex-grow px-3 py-4 text-sm text-gray-900">
                  <span className="font-bold">Are you a U.S. Citizen?</span>
                </div>
                <div className="flex-none px-3 pt-5 text-sm text-gray-900 cursor-pointer">
                  Required
                </div>
                <div className="flex-none px-3 pt-5 text-sm text-gray-900 cursor-pointer">
                  <PencilIcon
                    onClick={() => setEditQuestion(!editQuestion)}
                    className="-ml-1 h-5 w-5 text-gray-300 hover:teckpert-text-green"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-none px-3 py-4 text-sm text-gray-900">
                  <XCircleIcon
                    className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                    aria-hidden="true"
                  />
                </div>
                {/* <div className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                  <div
                    onClick={() => setShowTable(!showTable)}
                    className="flex flex-none mr-5"
                  >
                    {!showTable ? (
                      <ChevronUpIcon
                        className="h-5 w-5 cursor-pointer mt-1"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="h-5 w-5 cursor-pointer mt-1"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div> */}
              </div>

              {/* Edit Question Section */}
              <div
                className={`${
                  editQuestion ? "block" : "hidden"
                } mt-4 text-sm p-7`}
              >
                <p>Question Text</p>
                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <textarea
                      // onChange={(e) => {
                      //   let temp = tempData;
                      //   temp.Description = e.target.value;
                      //   setTempData({ ...temp });
                      // }}
                      // value={tempData.Description}
                      type="text"
                      name="description"
                      className="h-52 appearance-none block w-full bg-white text-gray-700 border border-gray-200
                        rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                      id="message"
                      autoComplete="title"
                    />
                    <div className="flex align-middle my-auto gap-8">
                      <div className="my-auto">Answer Type</div>
                      <div>
                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2 grid grid-cols-5">
                            <select
                              className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                              // onChange={(e) => {
                              //   let temp = tempData;
                              //   temp.Status = e.target.value;
                              //   setTempData({ ...temp });
                              // }}
                              // value={tempData.Status}
                            >
                              <option className="text-gray-300" value="">
                                Single Line Text
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <input type="checkbox" />
                        <div className="ml-2 my-auto">Make it required</div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="flex">
                        <div>Possible Answers</div>
                        <input
                          // onChange={(e) => {
                          //   let temp = tempData;
                          //   temp.Name = e.target.value;
                          //   setTempData({ ...temp });
                          // }}
                          // value={tempData.Name}
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                          text-gray-900 hover:teckpert-bg-green hover:text-white"
                        />
                      </div>
                      <div className="teckpert-text-green text-sm font-bold mt-5">
                        Add Answer
                      </div>
                    </div>
                    <div className="mt-5 m-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                            font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium 
                          text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Add Question Section */}
            <div className="hover:bg-gray-100 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-1 bg-white">
              <div className="cursor-pointer px-2 py-4 mt-2 mb-3 mt-0 text-gray-800 ml-5">
                <div
                  onClick={() => setAddQuestion(!addQuestion)}
                  className={`${
                    addQuestion
                      ? "text-gray-800 text-md"
                      : "teckpert-text-green text-sm"
                  } font-bold flex`}
                >
                  <div className="flex-grow">Add Question</div>
                  <PlusCircleIcon
                    className="float-right mr-5 h-7 w-7 text-gray-300 teckpert-text-green"
                    aria-hidden="true"
                  />
                </div>
                <div
                  className={`${addQuestion ? "block" : "hidden"} mt-4 text-sm`}
                >
                  <p>Question Text</p>
                  <div className="sm:col-span-2 flex-grow">
                    <div className="mt-2">
                      <textarea
                        // onChange={(e) => {
                        //   let temp = tempData;
                        //   temp.Description = e.target.value;
                        //   setTempData({ ...temp });
                        // }}
                        // value={tempData.Description}
                        type="text"
                        name="description"
                        className="h-52 appearance-none block w-full bg-white text-gray-700 border border-gray-200
                        rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                        id="message"
                        autoComplete="title"
                      />
                      <div className="flex align-middle my-auto gap-8">
                        <div className="my-auto">Answer Type</div>
                        <div>
                          <div className="sm:col-span-2 flex-grow">
                            <div className="mt-2 grid grid-cols-5">
                              <select
                                className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                // onChange={(e) => {
                                //   let temp = tempData;
                                //   temp.Status = e.target.value;
                                //   setTempData({ ...temp });
                                // }}
                                // value={tempData.Status}
                              >
                                <option className="text-gray-300" value="">
                                  Single Line Text
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          <input type="checkbox" />
                          <div className="ml-2 my-auto">Make it required</div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="flex">
                          <div>Possible Answers</div>
                          <input
                            // onChange={(e) => {
                            //   let temp = tempData;
                            //   temp.Name = e.target.value;
                            //   setTempData({ ...temp });
                            // }}
                            // value={tempData.Name}
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                          text-gray-900 hover:teckpert-bg-green hover:text-white"
                          />
                        </div>
                        <div className="teckpert-text-green text-sm font-bold mt-5">
                          Add Answer
                        </div>
                      </div>
                      <div className="mt-5 m-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                            font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium 
                          text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Questionnaire;
