/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import placeholderImg from "../../../img/logo192.png";
import { PlusCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { Storage } from "aws-amplify";
import { formatDate } from "../../utils/FormatDate";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import TimesheetEdit from "./TimesheetEdit";
const TableDetails = ({ open, setOpen, tableData, updateData,
    setUpdateData, setUpdateTableData, updateTableData, userData }) => {  
   const [selectedStatus, setSelectedStatus] = useState(tableData.Status);        
    const [img, setImg] = useState(null);
    const [timesheetmodalOpen, setTimesheetModalOpen] = useState(false);
    const [itemId, setItemId] = useState();
    const [memberId, setMemberId] = useState();
   const getStorageFile = async (redirect = false) => {
    const url = await Storage.get(tableData?.JobOrder?.Member?.Meta?.ProfilePicture, {
      level: "public",
      expires: 60,
    });
    if (redirect === true && url !== "") {
      window.open(url, "_blank");
    }
    setImg(url);
    };
    
    const handleStatusChange = (e) => {        
        setSelectedStatus(e.target.value);
        updateStatus(tableData?.ItemId, e.target.value);
        //setTimeout(() => {
        //    setUpdateTableData(true);
        //}, 2000);
    };
 
    const updateStatus = async (id, newStatus) => {
        try {
            let approvedBy = "";
            let approvalDate = null;

            if (newStatus === "Approved") {
                approvedBy = userData.FirstName + " " + userData.LastName;
                approvalDate = new Date().toISOString();
            }
            const response = await API.graphql(
                graphqlOperation(`
                    mutation UpdateStatus($id: String!, $newStatus: String!,$approvedBy:String,$approvalDate:timestamp) {
                        update_Timesheets_by_pk(
                            pk_columns: { ItemId: $id },
                            _set: {
                                Status: $newStatus
                                ApprovedBy:$approvedBy
                                ApprovalDate:$approvalDate
                            }
                        ) {
                            ItemId
                        }
                    }
                `,
                    {
                        id,
                        newStatus,
                        approvedBy: approvedBy || null,
                        approvalDate: approvalDate || null
                    })
            );

            //setTableData(tableData.map(obj =>
            //    ({ ...obj, Status: selectedTimesheets.map(({ ItemId }) => ItemId).includes(obj.ItemId) ? 'Sent for Approval' : obj.Status })
            //));
            //setUpdateTableData(true);
            console.log("Updated Timesheet Entry status", response);
           
        } catch (error) {
            console.error("Error updating Timesheet Entry status:", error);
           
        }
    };

    const handleEditClick = (itemId, memberId) => {
        setTimesheetModalOpen(!timesheetmodalOpen);
        setItemId(itemId);
        setMemberId(memberId);
    };

    const downloadToExcel = () => {
        //header shown in excel
        let content = [
            ["Worker", "Contract", "Client", "Date", "Hours", "Pay Rate", "Bill Rate", "Category", "Description"],
        ];
        tableData.TimesheetEntries.forEach((data) => {
            let date = new Date(data.Date);
            let day = date.toLocaleDateString('en-US', { weekday: 'long', timeZone: "utc" });
            let formattedDate = date.toLocaleDateString('en-US', { timeZone: "utc" });
           
            content.push([                
                tableData?.JobOrder?.Member?.FirstName + " " + tableData?.JobOrder?.Member?.LastName,
                tableData?.JobOrder?.Contract?.Name,
                tableData?.JobOrder?.Contract?.Clients?.Name,
                `${day} ${" "} ${formattedDate}`,
                 data.Hours,
                tableData?.JobOrder?.Rate,
                tableData?.JobOrder?.BillableRate,
                data?.Category,
                data?.Details
            ]);
        });
            
      let csvContent =
            "data:text/csv;charset=utf-8," +
            content.map((e) => e.join(",")).join("\n");        
        let link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;base64,77u/" + btoa(csvContent));       
        link.setAttribute(
            "download",
            `_timesheet_${formatDate(new Date())}.csv`
        );
        document.body.appendChild(link);
        link.click();
    };
  getStorageFile();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
            setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                        setOpen(false);
                        setUpdateTableData(true);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                              <div className="border-b mb-5 pb-2">Timesheet Period : {new Date(tableData?.PeriodStart).toLocaleDateString(
                                  "en-US",
                                  { day: "numeric", month: "short", timeZone: "utc"  }
                              )}{" "}
                                  -{" "}
                                  {new Date(tableData?.PeriodEnd).toLocaleDateString(
                                      "en-US",
                                      { day: "numeric", month: "short", timeZone: "utc"  }
                                  )}({new Date(tableData?.PeriodStart).toLocaleDateString(
                                      "en-US",
                                      { timeZone: "utc", weekday: 'long' }
                                  )}{" "}
                                  -{" "}
                                  {new Date(tableData?.PeriodEnd).toLocaleDateString(
                                      "en-US",
                                      { timeZone: "utc", weekday: 'long' }
                                  )})</div>
                <div className="flex block text-sm text-gray-700">
                  <img
                    className=" h-20 w-20 flex-shrink-0 rounded-full"
                    src={img ? img : placeholderImg}
                    alt="contact pic"
                  />
                  <div className="pl-7">
                    <div className="teckpert-text-green text-2xl font-semibold">
                      {tableData?.JobOrder?.Member?.FirstName} {tableData?.JobOrder?.Member?.LastName}
                    </div>
                  <div className="text-gray-400 pt-1">                                         
                        Status:
                        <select
                            className="ml-2 rounded-full justify-center rounded-md 
                                    border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
                                    text-gray-900 hover:teckpert-bg-green hover:text-white"
                            value={selectedStatus}
                            onChange={handleStatusChange}
                        >                                              
                            <option value="Pending">Pending</option>
                            <option value="Sent for Approval">Sent for Approval</option>
                            <option value="Approved">Approved</option>
                            <option value="Paid">Paid</option>
                            <option value="Submitted">Submitted</option>
                        </select>
                  </div>
                  </div>
                </div>

                             
                <div className="mx-5 flex flex-col p-10 overflow-x-auto">
                                  <div className="sm:-mx-6 lg:-mx-8">
                                      <div
                                          className="teckpert-text-green cursor-pointer mr-5 text-right"
                                          onClick={() => {

                                              downloadToExcel();
                                          }}
                                      >
                                          Download to Excel
                                      </div>
                    <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                          <thead className="bg-gray-100">
                            <tr>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Date
                                </th>
                                 
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Hours
                              </th>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                               Pay Rate
                               </th>
                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Bill Rate
                                </th>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Category
                              </th>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Description
                              </th>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                       
                            </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {tableData.TimesheetEntries.map((obj) => (
                              <tr key={obj.ItemId}>
                                    <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 font-bold">
                                        {new Date(obj.Date).toLocaleDateString('en-US', { weekday: 'long', timeZone: "utc" })}{" "}
                                        {new Date(obj.Date).toLocaleDateString('en-US', { timeZone: "utc" })}
                                    </td>

                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                                  {obj.Hours?.toFixed(2)}
                                    </td>
                                    <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                                        {tableData?.JobOrder?.Rate}
                                    </td>
                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                                  {tableData?.JobOrder?.BillableRate}
                                    </td>
                                   
                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 ">
                                  {obj.Category}
                                </td>
                                    <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 ">
                                        {obj.Details.length >30
                                            ? obj.Details.match(/.{1,35}/g).map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))
                                            : obj.Details}
                                    </td>
                                    <td className="w-[50px] py-4 text-sm text-gray-900">
                                        <div>
                                            <PencilIcon
                                                onClick={() => handleEditClick(obj.ItemId, tableData?.JobOrder?.Member?.ItemId)}
                                                className="h-5 w-5 cursor-pointer ml-3 text-gray-300 hover:teckpert-text-green"
                                                aria-hidden="true"
                                            />
                                            {timesheetmodalOpen && (
                                                <TimesheetEdit
                                                    open={timesheetmodalOpen}
                                                    setOpen={setTimesheetModalOpen}
                                                    itemId={itemId}
                                                    memberId={memberId}
                                                    updateData={updateData}
                                                    setUpdateData={setUpdateData}
                                                    tableModalOpen={open}
                                                    setTableModalOpen={setOpen }
                                                />
                                            )}
                                        </div>
                                    </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                        setOpen(false);
                        setUpdateTableData(true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                          
                </Dialog.Panel>
                 
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TableDetails;
