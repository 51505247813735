import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Table from "./Table";
import CreateResourceModal from "./CreateResourceModal";

const Resources = () => {
  const [tableData, setTableData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    API.graphql({
      query: `{
        Documents(where: {Folder: {_eq: "TECKpert"}}) {
        ItemId
        DateCreated
        DateUpdated
        Name
        Folder
        Type
        Key
        Link
        ClientId
        MemberId
        JobOrderId
        ContractId
        CreatorId
        CreatorName
      }
    }      
  `,
    }).then(({ data }) => {
      setTableData(data.Documents);
      setTempData(data.Documents);
    });
  }, []);

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6 mb-4">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={() => setModalOpen(true)}
            type="button"
            className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white 
            shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <Table
        tableData={tableData}
        setTableData={setTableData}
        tempData={tempData}
        setTempData={setTempData}
      />
      <CreateResourceModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
        setTempTableData={setTempData}
      />
    </div>
  );
};
export default Resources;
