import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import UpdateUserModal from "../Dashboard/Users/UpdateUserModal";
import ResetPassword from "./ResetPassword";

const AdminProfile = () => {
  const [tableData, setTableData] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
        ItemId
        DateCreated
        DateUpdated
        FirstName
        LastName
        UserType
        Email
        CalendlyProfileUrl
      }
    }      
  `,
    }).then(({ data }) => {
      setCurrentAdmin(
        data.Users.filter(
          (obj) =>
            obj.ItemId === JSON.parse(localStorage.attributes)["custom:itemId"]
        )[0]
      );
    });
  }, [localStorage.attributes]);

  return (
    <div className="p-16">
      <div className="p-8 bg-white shadow mt-24">
        <div>
          <button
            onClick={() => setEditModalOpen(true)}
            className="float-right text-white py-2 px-4 uppercase rounded teckpert-bg-green shadow hover:shadow-lg font-medium 
            transition transform hover:-translate-y-0.5"
          >
            Edit
          </button>
        </div>

        <div className="mt-20 text-center border-b pb-12">
          <h1 className="text-4xl font-medium text-gray-700">
            {currentAdmin
              ? currentAdmin?.FirstName + " " + currentAdmin?.LastName
              : "-"}
          </h1>
          <p className="mt-8 text-gray-700 font-medium">
            <span className="text-gray-400 font-normal">
              Email Address:&nbsp;
            </span>
            {currentAdmin ? currentAdmin?.Email : "-"}
          </p>
          <p className="mt-2 text-gray-700 font-medium">
            <span className="text-gray-400 font-normal">Password:&nbsp;</span>
            ********
          </p>
          <p className="mt-2 text-gray-700 font-medium">
            <span className="text-gray-400 font-normal">
              Calendly URL:&nbsp;
            </span>
            <a
              href={currentAdmin.CalendlyProfileUrl}
              target="_blank"
              className="cursor-pointer hover:text-blue-500"
              rel="noopener noreferrer"
            >
              {currentAdmin?.CalendlyProfileUrl
                ? currentAdmin.CalendlyProfileUrl
                : "-"}
            </a>
          </p>
          <p className="mt-2 text-gray-700 font-medium">
            <span className="text-gray-400 font-normal">User Type:&nbsp;</span>
            {currentAdmin ? currentAdmin?.UserType : "-"}
          </p>
        </div>

        <div className="mt-6 flex flex-col justify-center">
          <button
            onClick={() => setResetModalOpen(true)}
            className="teckpert-text-green py-2 px-4 font-medium mt-2"
          >
            Reset Password
          </button>
        </div>
      </div>
      <UpdateUserModal
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        tempData={currentAdmin}
        setTempData={setTableData}
      />
      <ResetPassword
        modalOpen={resetModalOpen}
        setModalOpen={setResetModalOpen}
        tempData={currentAdmin}
        setTempData={setTableData}
      />
    </div>
  );
};

export default AdminProfile;
