import { Link, useLocation } from "react-router-dom";
import { DocumentTextIcon, MinusCircleIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { formatDate } from "../../utils/FormatDate";
import TableDetails from "./TableDetails";
import ReactTooltip from "react-tooltip";

const Table = ({
    contractData,
    tableData,
    setTableData,
    selectStatus,
    setSelectStatus,
    selectContract,
    setSelectContract,
    selectDuration,
    setSelectDuration,
    page,
    setPage,
    itemsTotal,
    itemsPerPage,
    selectedTimesheets,
    setSelectedTimesheets,
    updateData,
    setUpdateData,
    setUpdateTableData,
    updateTableData,
    userData,
}) => {
    const location = useLocation();
    const [status, setStatus] = useState("");
    const [modalOpen, setModalOpen] = useState("");
    const [tableDataSingle, setTableDataSingle] = useState("");
    const [sortOrder, setSortOrder] = useState({
        column: 'Period',
        ascending: false,
    });

    const toggleSortOrder = (column) => {
        if (sortOrder.column === column) {
            setSortOrder({
                column,
                ascending: !sortOrder.ascending,
            });
        } else {
            setSortOrder({
                column,
                ascending: true,
            });
        }
    };

    const sortedTableData = tableData.slice().sort((a, b) => {
        if (sortOrder.column === 'Period') {
            
            return sortOrder.ascending
                ? new Date(a.PeriodStart) - new Date(b.PeriodStart)
                : new Date(b.PeriodStart) - new Date(a.PeriodStart);
        } else if (sortOrder.column === 'Hours') {
           
            return sortOrder.ascending
                ? a.Hours.aggregate.sum.Hours - b.Hours.aggregate.sum.Hours
                : b.Hours.aggregate.sum.Hours - a.Hours.aggregate.sum.Hours;
        } else if (sortOrder.column === 'Status') {
           
            return sortOrder.ascending
                ? a.Status.localeCompare(b.Status)
                : b.Status.localeCompare(a.Status);
        } else {
            
            return 0;
        }
    });

    const downloadToExcel = () => {
        //header shown in excel
       
        let content = [
            ["Worker", "Contract", "Client", "Date", "Day", "Hours", "Pay Rate", "Bill Rate", "Category", "Description"],
        ];
        tableData.map((obj) => {
            if (selectedTimesheets
                .map(({ ItemId }) => ItemId)
                .includes(obj.ItemId)) {
                // Use forEach instead of map for TimesheetEntries
                obj.TimesheetEntries.forEach((data) => {
                    content.push([
                        obj?.JobOrder?.Member?.FirstName + " " + obj?.JobOrder?.Member?.LastName,
                        obj?.JobOrder?.Contract?.Name,
                        obj?.JobOrder?.Contract?.Clients?.Name,
                        new Date(data?.Date).toLocaleDateString('en-US', { timeZone: "utc" }),
                        new Date(data?.Date).toLocaleDateString('en-US', { weekday: 'long', timeZone: "utc" }),
                        data?.Hours,
                        obj?.JobOrder?.Rate,
                        obj?.JobOrder?.BillableRate,
                        data?.Category,
                        data?.Details?.replace(/\n/g, " ")
                    ]);
                });
            }
        });
        

        let csvContent =            
        content.map((e) => e.join(",")).join("\n");                  
        let link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;base64,77u/" + btoa(unescape(encodeURIComponent(csvContent))));
        link.setAttribute(
            "download",
            `${status}_timesheet_${formatDate(new Date())}.csv`
        );
        document.body.appendChild(link);
        link.click();
    };

    //figure out what day of week it is and then determine how many days is it from monday this week
    const daysFromMonday = () => {
        const date = new Date();
        let dayOfWeek = date.getDay();
        //if saturday or sunday, set monday to the monday next week
        if (dayOfWeek === 0) return -1;
        if (dayOfWeek === 6) return -2;
        return 1 - dayOfWeek;
    };
    //sum of total hours 
    const sumOfHours = tableData?.reduce((total, currentItem) => {
        const hours = currentItem.Hours?.aggregate?.sum?.Hours;
        return total + hours;
    }, 0);

    return (
        <div className="">
            {!location.pathname.includes("/people/") ? (
                <div>
                    <div className="flex">
                        <form style={{ margin: 5 }}>
                            <select
                                className="w-auto mr-3 rounded-full justify-center rounded-md border shadow-sm px-4 py-3 
                text-sm bg-white cursor-pointer text-gray-900 hover:teckpert-bg-green hover:text-white"

                                onChange={(e) => setSelectStatus(e.target.value)}
                                value={selectStatus}
                            >
                                <option value="" className="text-gray-400">
                                    Select Status
                                </option>
                                <option value="Pending">Pending</option>
                                <option value="Sent for Approval">Sent for Approval</option>
                                <option value="Approved">Approved</option>
                                <option value="Payroll in Progress">Payroll in Progress</option>
                                <option value="Paid">Paid</option>
                                <option value="Submitted">Submitted</option>
                            </select>
                            <br />
                        </form>
                        <form style={{ margin: 5 }}>
                            <select
                                className="w-auto mr-3 rounded-full justify-center rounded-md 
                border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
                text-gray-900 hover:teckpert-bg-green hover:text-white"
                                value={selectContract}
                                onChange={(e) => setSelectContract(e.target.value)}
                            >
                                <option value="" className="text-gray-400">
                                    Select Contract
                                </option>
                                {contractData.map((obj, i) => (
                                    <option key={i} value={obj.ItemId}>
                                        {obj.Name}
                                    </option>
                                ))}
                            </select>
                            <br />
                        </form>
                        <form style={{ margin: 5 }}>
                            <select
                                className="w-auto mr-3 rounded-full justify-center rounded-md 
                border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
                text-gray-900 hover:teckpert-bg-green hover:text-white"
                                value={selectDuration}
                                onChange={(e) => setSelectDuration(e.target.value)}
                            >
                                <option value="" className="text-gray-400">
                                    Select Duration
                                </option>
                                <option value="A Week Ago">A Week Ago</option>
                                <option value="Two Weeks Ago">Two Weeks Ago</option>
                                <option value="A Month Ago">A Month Ago</option>
                                <option value="Two Months Ago">Two Months Ago</option>
                                <option value="Three Months Ago">Three Months Ago</option>
                            </select>
                            <br />
                        </form>
                    </div>
                    <div className="flex justify-between mt-5 mx-4">
                        <div className="p-3 pt-0">
                            Quick DL (Period:{" "}
                            {/* (selectDuration === "A Week Ago"
                      ? 7 + daysFromMonday()
                      : selectDuration === "Two Weeks Ago"
                      ? 14 + daysFromMonday()
                      : selectDuration === "A Month Ago"
                      ? 30 + daysFromMonday()
                      : selectDuration === "Two Months Ago"
                      ? 60 + daysFromMonday()
                      : 90 + daysFromMonday()) */}
                            {formatDate(
                                new Date(
                                    new Date().getTime() - (14 + daysFromMonday()) * 86400000
                                )
                            )}{" "}
                            -{" "}
                            {formatDate(
                                new Date(
                                    new Date().getTime() - (7 + daysFromMonday() - 4) * 86400000
                                )
                            )}{" "}
                            ):{" "}
                            <span
                                className="teckpert-text-green ml-5 cursor-pointer"
                                onClick={() => {
                                    setStatus("Contract");
                                    downloadToExcel();
                                }}
                            >
                                W2
                            </span>{" "}
                            <span className="mx-2">|</span>
                            <span
                                className="teckpert-text-green cursor-pointer"
                                onClick={() => {
                                    setStatus("1099");
                                    downloadToExcel();
                                }}
                            >
                                1099
                            </span>
                        </div>
                        <div className="flex">
                            <div
                                className="teckpert-text-green cursor-pointer mr-5"
                                onClick={() => {
                                    setStatus("");
                                    downloadToExcel();
                                }}
                            >
                                Download to Excel
                            </div>
                            <div>Total Hours: {sumOfHours.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
            {tableData.length > 0 ? (
                <div className="mx-5 flex flex-col">
                    <div className="sm:-mx-6 lg:-mx-8">
                        <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto">
                                <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input appearance-none h-4 w-4 border 
                          border-gray-300 rounded-sm bg-white checked:bg-[#6FBE47]
                          checked:border-[#6FBE47] focus:outline-none transition 
                          duration-200 mt-1 align-top bg-no-repeat bg-center 
                          bg-contain float-right mr-2 cursor-pointer"
                                                        type="checkbox"
                                                        id="flexCheckDefault"
                                                        onClick={() => {
                                                            if (
                                                                selectedTimesheets.length !== tableData.length
                                                            ) {
                                                                setSelectedTimesheets(tableData);
                                                            } else {
                                                                setSelectedTimesheets([]);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            {[
                                                "Worker",
                                                "Contract",
                                                "Client",
                                                <th
                                            key="Period"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            onClick={() => toggleSortOrder('Period')}
                                        >
                                            Period{' '}
                                            
                                                <span>
                                                    {sortOrder.ascending && sortOrder.column === 'Period' ? (
                                                        <ChevronUpIcon className="w-4 h-4 inline-block" />
                                                    ) : (
                                                        <ChevronDownIcon className="w-4 h-4 inline-block" />
                                                    )}
                                                </span>
                                           
                                        </th>,
                                        <th
                                            key="Hours"
                                            className=" text-left  text-sm font-semibold text-gray-900 whitespace-nowrap"
                                            onClick={() => toggleSortOrder('Hours')}
                                        >
                                            Hours{' '}
                                            
                                                <span>
                                                {sortOrder.ascending && sortOrder.column === 'Hours' ? (
                                                        <ChevronUpIcon className="w-4 h-4 inline-block" />
                                                    ) : (
                                                        <ChevronDownIcon className="w-4 h-4 inline-block" />
                                                    )}
                                                </span>
                                           
                                        </th>,
                                        <th
                                            key="Status"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            onClick={() => toggleSortOrder('Status')}
                                        >
                                            Status{' '}
                                            
                                                <span>
                                                {sortOrder.ascending && sortOrder.column === 'Status' ? (
                                                        <ChevronUpIcon className="w-4 h-4 inline-block" />
                                                    ) : (
                                                        <ChevronDownIcon className="w-4 h-4 inline-block" />
                                                    )}
                                                </span>
                                            
                                        </th>
,
                                                "Payroll",
                                                "Details",
                                            ].map((obj, index) => (
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap" key={index}>{obj}</th>
                                            ))}


                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {sortedTableData?.map((timesheet, i) => (

                                            <tr key={timesheet?.ItemId}>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input appearance-none h-4 w-4 border 
                            border-gray-300 rounded-sm bg-white checked:bg-[#6FBE47]
                            checked:border-[#6FBE47] focus:outline-none transition 
                            duration-200 mt-1 align-top bg-no-repeat bg-center 
                            bg-contain float-left ml-4 cursor-pointer"
                                                            type="checkbox"
                                                            checked={selectedTimesheets
                                                                .map(({ ItemId }) => ItemId)
                                                                .includes(timesheet.ItemId)}
                                                            id="flexCheckDefault"
                                                            onClick={() => {
                                                                if (
                                                                    selectedTimesheets.find(
                                                                        ({ ItemId }) => ItemId === timesheet.ItemId
                                                                    )
                                                                ) {
                                                                    setSelectedTimesheets(
                                                                        selectedTimesheets.filter(
                                                                            ({ ItemId }) =>
                                                                                ItemId !== timesheet.ItemId
                                                                        )
                                                                    );
                                                                } else {
                                                                    setSelectedTimesheets([
                                                                        ...selectedTimesheets,
                                                                        timesheet,
                                                                    ]);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                    <div className="flex items-center">
                                                        <Link
                                                            to={`/people/${timesheet?.JobOrder?.Member?.ItemId}`}
                                                        >
                                                            <div className="font-medium text-[#6FBE47]">
                                                                {timesheet?.JobOrder?.Member?.FirstName}{" "}
                                                                {timesheet?.JobOrder?.Member?.LastName}
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                    {timesheet?.JobOrder?.Contract?.Name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {timesheet?.JobOrder?.Contract?.Clients?.Name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {new Date(timesheet?.PeriodStart).toLocaleDateString(
                                                        "en-US",
                                                        { day: "numeric", month: "short", timeZone: "utc" }
                                                    )}{" "}
                                                    -{" "}
                                                    {new Date(timesheet?.PeriodEnd).toLocaleDateString(
                                                        "en-US",
                                                        { day: "numeric", month: "short", timeZone: "utc" }
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-900">
                                                    {timesheet?.Hours?.aggregate?.sum?.Hours?.toFixed(2)}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                    <div className="inline-block">
                                                        <span>{timesheet?.Status} </span>
                                                        {timesheet?.Status === 'Approved' && (
                                                            <span
                                                                data-tip={`Approved by: ${timesheet?.ApprovedBy} - ${timesheet?.ApprovalDate}`}
                                                                data-place="top"
                                                                data-type="success"
                                                            >
                                                                <div className="flex items-center justify-center hover:text-green-500" style={{ display: 'inline-block' }}>
                                                                    <MinusCircleIcon className="bg-green-500 text-green-500 rounded-full h-3 w-3" aria-hidden="true" />
                                                                </div>
                                                                {timesheet?.ApprovedBy && (
                                                                    <ReactTooltip place="top" type="success" effect="solid" />
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                    -{" "}
                                                </td>
                                                <td
                                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                                                    onClick={() => {
                                                        setTableDataSingle(timesheet);
                                                        setModalOpen(!modalOpen);
                                                    }}
                                                >
                                                    {modalOpen && (
                                                        <TableDetails
                                                            open={modalOpen}
                                                            setOpen={setModalOpen}
                                                            tableData={tableDataSingle}
                                                            updateData={updateData}
                                                            setUpdateData={setUpdateData}
                                                            updateTableData={updateTableData}
                                                            setUpdateTableData={setUpdateTableData}
                                                            userData={userData}
                                                        />
                                                    )}
                                                    <DocumentTextIcon className="w-7 h-7 teckpert-text-green cursor-pointer" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
                    No Timesheets Found
                </div>
            )}
            {tableData.length > 25 ? (
                <div className="flex justify-center mt-5 pb-8">
                    <nav aria-label="Page navigation">
                        <ul className="flex list-style-none">
                            <li
                                className="page-item"
                                //decrement pagination by one when clicking arrow
                                onClick={() => {
                                    if (page === 1) return;
                                    setPage(page - 1);
                                }}
                            >
                                <a
                                    className="page-link relative block py-1.5 px-3 rounded-full border-0 
                  bg-transparent outline-none transition-all duration-300 rounded 
                  text-gray-800 hover:text-gray-800 focus:shadow-none"
                                    aria-label="Previous"
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {itemsTotal &&
                                itemsPerPage &&
                                [...Array(Math.ceil(itemsTotal / itemsPerPage))]?.map(
                                    (_, pageNumber) => {
                                        //only displays pagination within a certain range
                                        if (page - pageNumber + 1 >= 0 && page < pageNumber + 6)
                                            return (
                                                <li
                                                    className="page-item"
                                                    onClick={() => setPage(pageNumber + 1)}
                                                    key={pageNumber + 1}
                                                >
                                                    <a
                                                        className={`rounded-full page-link relative block py-1.5 px-3
                          bg-transparent outline-none transition-all duration-300 rounded 
                          text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${pageNumber + 1 === page && "font-bold"
                                                            }`}
                                                        // border-solid border-2 border-[#6FBE47]
                                                        href="#"
                                                    >
                                                        {pageNumber + 1}
                                                    </a>
                                                </li>
                                            );
                                    }
                                )}
                            <li
                                className="page-item"
                                onClick={() => {
                                    //increment pagination by one when clicking arrow
                                    if (page === Math.ceil(itemsTotal / itemsPerPage)) return;
                                    setPage(page + 1);
                                }}
                            >
                                <a
                                    className="page-link relative block py-1.5 px-3 rounded-full border-0 
                bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                                    aria-label="Next"
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Table;
