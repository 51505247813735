/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon, FlagIcon } from "@heroicons/react/outline";

export default function QuestionnaireModal({ open, setOpen, questionnaire }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] overflow-y-auto relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left border border-gray-200 rounded-lg p-4 w-full bg-gray-100">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Questionnaire{" "}
                      <span className="underline">
                        {questionnaire.ApplicationQuestionnaire?.Title}
                      </span>{" "}
                      Review
                    </Dialog.Title>
                  </div>
                </div>
                <div className="p-4 bg-white rounded-lg border border-gray-200 divide-y divide-dashed">
                  <div className="flex justify-between">
                    <div>
                      <span className="font-medium">Candidate:</span>{" "}
                      {questionnaire.Members?.FirstName}{" "}
                      {questionnaire.Members?.LastName}
                    </div>
                    <div>
                      <span className="font-medium">Opportunity:</span>{" "}
                      {questionnaire.Opportunities?.Title}
                    </div>
                  </div>
                  {questionnaire?.Responses &&
                    questionnaire.Responses.map((obj, i) => (
                      <div className="py-4" key={i}>
                        <div className="flex">
                          <div className="font-medium">
                            {i + 1}.&nbsp;&nbsp;
                          </div>
                          <div className="font-medium">{obj.Question}</div>
                        </div>
                        <div className="ml-5 flex">
                          {obj.Answer.replaceAll(",", ", ")}
                          <div>
                            {obj.Knockout ? (
                              <FlagIcon
                                className="ml-5 text-red-500 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
