import Table from "./Table";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import CreateUserModal from "./CreateUserModal";
import Loading from "../../utils/Loading";

const Users = () => {
  const itemsPerPage = 25;
  const [tableData, setTableData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [memberTotal, setMemberTotal] = useState(1);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
        ItemId
        DateCreated
        DateUpdated
        FirstName
        LastName
        UserType
        Email
        CalendlyProfileUrl
        DirectLine
      }
    }      
  `,
    }).then(({ data }) => {
        setTableData(data.Users);
        setLoading(false);
    });
  }, []);

  useEffect(() => {
    setTempData(tableData);
  }, [tableData]);
    if (loading) return <Loading />
  return (
    <div>
      <div className="pb-6">
        <button
          type="button"
          onClick={() => setModalOpen(true)}
          className="float-right mr-6 inline-flex items-center justify-center rounded-md border border-transparent 
          teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto hover:opacity-90"
        >
          Create
        </button>
      </div>
      <Table
        tableData={tableData}
        setTableData={setTableData}
        tempData={tempData}
        setTempData={setTempData}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        memberTotal={memberTotal}
      />
      <CreateUserModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
      />
    </div>
  );
};
export default Users;
