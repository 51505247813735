import { useState } from "react";
import { API } from "aws-amplify";
import {
  MailIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  XCircleIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { formatPhone } from "../../../../utils/FormatPhone";
import EditReferencesModal from "../Modals/EditReferencesModal";
import AddProfile from "../../../../utils/AddProfile";
// import SendMessage from "../../../Tools/Messages/SendMessage";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";

const References = ({
  originalData,
  profileData,
  setProfileData,
  setMsgModalOpen,
  setConfirmButtonMessage,
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const [id, setId] = useState(0);

  const [tempData, setTempData] = useState({
    ItemId: profileData.ItemId,
    Meta: profileData.Meta,
  });

  const [newReference, setNewReference] = useState({
    Name: "",
    Relationship: "",
    Email: "",
    Phone: "",
  });

  const onSave = async () => {
    API.graphql({
      query: `mutation($meta: jsonb) {
        update_Members_by_pk(pk_columns: {ItemId: "${profileData.ItemId}"}, _set: { Meta: $meta } )       
        {
          ItemId
          Meta
        }   
      }`,
      variables: { meta: profileData.Meta },
    }).then(async ({ data }) => {
      setEditModalOpen(false);
      setProfileData({ ...tempData });
      setConfirmButtonMessage(true);
    });
  };

  const onSubmit = async () => {
    let temp = tempData;
    if (!temp.Meta.References) {
      temp.Meta.References = [];
    }
    temp.Meta.References.push(newReference);
    API.graphql({
      query: `mutation($meta: jsonb) {
        update_Members_by_pk(pk_columns: {ItemId: "${profileData.ItemId}"}, 
          _set: { Meta: $meta } )       
            {
              ItemId
              Meta
            }            
        }`,
      variables: { meta: temp.Meta },
    }).then(async ({ data }) => {
      setTempData({ ...tempData, newReference });
      setProfileData({ ...tempData, newReference });
      setAddModalOpen(false);
      setConfirmButtonMessage(true);
      setNewReference({
        Name: "",
        Relationship: "",
        Email: "",
        Phone: "",
      });
    });
  };

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      <AddProfile
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        profileData={profileData}
        setProfileData={setProfileData}
        tempData={tempData}
        setTempData={setTempData}
        onSave={onSubmit}
        newData={newReference}
        setNewData={setNewReference}
        inputField={["Name", "Relationship", "Email", "Phone"]}
      />
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            References
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setAddModalOpen(!addModalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
          <div onClick={() => setShowReferences(!showReferences)}>
            {!showReferences ? (
              <ChevronUpIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
      {/* Table Section */}
      {profileData.Meta.References?.length > 0 ? (
        Array.isArray(profileData.Meta.References) ? (
          <div
            className={`${
              showReferences ? "hidden" : "block"
            } mx-5 flex flex-col`}
          >
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                    <thead className="bg-gray-100">
                      <tr>
                        {[
                          "Name",
                          "Relationship",
                          "Email",
                          "Phone Number",
                          "",
                          "",
                          "",
                        ].map((header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {profileData.Meta.References?.map((reference, i) => {
                        return (
                          <tr key={i}>
                            <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900 font-bold">
                              {reference.Name ? reference.Name : "-"}
                            </td>
                            <td className="whitespace-nowrap pl-6 text-sm text-gray-900">
                              {reference.Relationship
                                ? reference.Relationship
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap pl-6 py-4 text-sm teckpert-text-green font-bold">
                              {reference.Email ? reference.Email : "-"}
                            </td>
                            <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                              {reference.Phone
                                ? formatPhone(reference.Phone)
                                : "-"}
                            </td>
                            <td
                              onClick={() => setMsgModalOpen(true)}
                              className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900 w-15 cursor-pointer"
                            >
                              <MailIcon
                                className="mr-2 mb-1 mt-1 h-4 w-4 inline teckpert-text-green"
                                aria-hidden="true"
                              />
                              Send Email
                            </td>
                            <td className="flex-none px-3 pt-1 text-sm text-gray-900 cursor-pointer">
                              <PencilIcon
                                onClick={() => {
                                  setId(i);
                                  setEditModalOpen(true);
                                }}
                                className="-ml-1 h-5 w-5 text-gray-300 hover:teckpert-text-green cursor-pointer"
                                aria-hidden="true"
                              />
                              {tempData.Meta.References?.length > 0 ? (
                                <EditReferencesModal
                                  modalOpen={editModalOpen}
                                  id={id}
                                  setModalOpen={setEditModalOpen}
                                  originalData={originalData}
                                  profileData={profileData}
                                  tempData={tempData}
                                  setTempData={setTempData}
                                  onSave={onSave}
                                  inputField={[
                                    "Name",
                                    "Relationship",
                                    "Email",
                                    "Phone",
                                  ]}
                                />
                              ) : null}
                            </td>
                            <td className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                              <XCircleIcon
                                onClick={() => setDeleteModalOpen(true)}
                                className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                                aria-hidden="true"
                              />
                              <ConfirmDeleteModal
                                open={deleteModalOpen}
                                setOpen={setDeleteModalOpen}
                                deleteItem={async () => {
                                  let temp = tempData;
                                  temp.Meta.References =
                                    profileData.Meta.References.filter(
                                      (item) => item.Email !== reference.Email
                                    );
                                  API.graphql({
                                    query: `mutation($meta: jsonb) {
                                    update_Members_by_pk(pk_columns: {ItemId: "${profileData.ItemId}"}, 
                                      _set: { Meta: $meta } )       
                                        {
                                          ItemId
                                          Meta
                                        }            
                                    }`,
                                    variables: { meta: temp.Meta },
                                  }).then(async ({ data }) => {
                                    setEditModalOpen(false);
                                    setConfirmButtonMessage(true);
                                    setProfileData({ ...temp });
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
            No References Found
          </div>
        )
      ) : (
        <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
          No References Found
        </div>
      )}
    </div>
  );
};
export default References;
