import { useState } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { formatDate } from "../../../../utils/FormatDate";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
//Modals
import UploadFileModal from "../../../../utils/UploadFileModal";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";
// import { v4 as uuidv4 } from "uuid";

const Files = ({
  tableData,
  setTableData,
  fileType,
  setConfirmButtonMessage,
}) => {
  const [showTab, setShowTab] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");

  const deleteItem = async () => {
    let tempTableData = tableData;
    tempTableData.Documents = tempTableData.Documents.filter((item) => {
      return deleteItemId !== item.ItemId;
    });

    let response = await API.graphql(
      graphqlOperation(`
        mutation {
            delete_Documents_by_pk(ItemId: "${deleteItemId}") {
              ItemId
              Name
            }
          }
      `)
    );
    if (response) {
      setDeleteModalOpen(false);
      setConfirmButtonMessage(true);
    }
  };

  const getStorageFile = async (key) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    window.open(url, "_blank");
  };

  return (
    <div
      className={`${
        !showTab ? "bg-white" : "bg-[#f5f5f5]"
      } p-6 rounded-lg mt-1 border border-gray-200 shadow-md`}
    >
      <UploadFileModal
        open={modalOpen}
        setOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
        fileType={fileType}
        setConfirmButtonMessage={setConfirmButtonMessage}
        lookupType="DocTypesOpportunity"
      />
      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteItem={() => deleteItem(deleteItemId)}
      />
      {/* Header Section */}
      <div className="flex">
        <div className="grow">
          <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">Files</h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setModalOpen(!modalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
          {!showTab ? (
            <ChevronDownIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
              onClick={() => setShowTab(!showTab)}
            />
          ) : (
            <ChevronUpIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
              onClick={() => setShowTab(!showTab)}
            />
          )}
        </div>
      </div>
      {/* Table Section */}
      {showTab ? (
        <div className="mx-5 flex flex-col mt-5">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {tableData?.Documents ? (
                  <table className="min-w-full overflow-x-auto">
                    <thead>
                      <tr>
                        {["Name", "Type", "Uploaded", ""].map((header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {tableData?.Documents?.map((obj, i) => (
                        <tr key={obj.ItemId}>
                          <td className="font-bold pl-6 py-4 text-sm teckpert-text-green cursor-pointer">
                            <a onClick={() => getStorageFile(obj.Name)}>
                              {obj.Name
                                ? obj.Name.length > 15
                                  ? obj.Name.substring(0, 15) + "..."
                                  : obj.Name
                                : "-"}
                            </a>
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-900">
                            {obj?.Type ? obj.Type : "-"}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-900">
                            {obj?.DateCreated
                              ? formatDate(obj.DateCreated)
                              : "-"}
                          </td>

                          <td className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                            <XCircleIcon
                              className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                              aria-hidden="true"
                              onClick={() => {
                                setDeleteModalOpen(!deleteModalOpen);
                                setDeleteItemId(obj.ItemId);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="w-full py-5 justify-center text-center mb-8 font-bold text-gray-300 text-lg">
                    No Files Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default Files;
