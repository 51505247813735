import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { OfficeBuildingIcon } from "@heroicons/react/outline";
import listOfIndustryTypes from "../utils/dropdownOptions/listOfIndustryTypes";
import listOfTimezones from "../utils/dropdownOptions/listOfTimezones";
import { formatPhone } from "../utils/FormatPhone";
import ConfirmMessage from "../utils/ConfirmMessage";
import Autocomplete from "react-google-autocomplete";
import MissingFieldPrompt from "../utils/MissingFieldPrompt";

const CompanyProfile = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState({});
  const [autoFill, setAutoFill] = useState({
    Name: "",
    Address: "",
    Location: "",
    Website: "",
  });
  const [confirmButtonMessage, setConfirmButtonMessage] = useState(false); //Shows/Hides saved confirmation message
  const [error, setError] = useState([]);
  let date = new Date();
  {
    /* {console.log(
                  "TEST",
                  new Date(
                    date.getUTCFullYear(),
                    date.getUTCMonth(),
                    date.getUTCDate(),
                    date.getUTCHours(),
                    date.getUTCMinutes(),
                    date.getUTCSeconds()
                  ).getTimezoneOffset()
                )} */
  }
  useEffect(() => {
    API.graphql({
      query: `{
        Accounts {
          Address
          Email
          IndeedEmail
          Industry
          ItemId
          LinkedInId
          Name
          Phone
          Timezone
          Website
       }
    }      
  `,
    }).then(({ data }) => {
      setTableData(data.Accounts[0]);
      let tempFill = autoFill;
      if (!autoFill.Name) tempFill.Address = data.Accounts[0].Address;
      if (autoFill.Name) {
        let temp = data.Accounts[0];
        temp.Name = autoFill.Name;
        temp.Address = autoFill.Address;
        temp.Phone = autoFill.Phone;
        temp.Website = autoFill.Website;
        setTableData({ ...temp });
      }
    });
  }, [location.pathname, autoFill]);

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  return (
    <div className="pt-0 p-10">
      {confirmButtonMessage && <ConfirmMessage />}
      <div className="pt-0 bg-white shadow mt-6">
        <div className="border border-gray-300 bg-gray-200 p-4">
          <h1 className="font-medium">Your Company</h1>
        </div>
        <div className="p-8">
          <div className="flex">
            <OfficeBuildingIcon
              className="h-6 w-6 cursor-pointer mr-3 mt-1"
              aria-hidden="true"
            />
            <div className="font-medium text-lg">COMPANY INFO</div>
          </div>
          <form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="pt-4">
                <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Company Name <span className="text-red-500">*</span>
                    </label>
                    <Autocomplete
                      className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white"
                      apiKey={"AIzaSyAVDbuhk4LVrRFHVjyB4Zk5bHs3QgWiX_Y"}
                      onPlaceSelected={async (place) => {
                        console.log("place", place);
                        let temp = autoFill;
                        temp.Name = place.name;
                        temp.Address = place.formatted_address;
                        temp.Phone = place.formatted_phone_number;
                        temp.Website = place.website;
                        setAutoFill({ ...temp });
                      }}
                      placeholder=""
                      options={{
                        fields: [
                          "formatted_address",
                          "address_components",
                          "name",
                          "formatted_phone_number",
                          "utc_offset_minutes",
                          "website",
                          "types",
                        ],
                        types: ["establishment"],
                      }}
                      defaultValue={tableData.Name}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Website <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          let temp = tableData;
                          temp.Website = e.target.value;
                          setTableData({ ...temp });
                        }}
                        value={tableData.Website}
                        type="text"
                        name="website"
                        id="website"
                        autoComplete="website"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Default Timezone <span className="text-red-500">*</span>
                    </label>
                    <select
                      className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                      value={tableData.Timezone}
                      onChange={(e) => {
                        let temp = tableData;
                        temp.Timezone = e.target.value;
                        setTableData({ ...temp });
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {listOfTimezones()?.map((obj, i) => (
                        <option key={i} value={obj.value}>
                          {obj.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          let temp = tableData;
                          temp.Email = e.target.value;
                          setTableData({ ...temp });
                        }}
                        value={tableData.Email}
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Phone Number
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          let temp = tableData;
                          temp.Phone = e.target.value
                            .replace(/\D+/g, "")
                            .slice(0, 10);
                          setTableData({ ...temp });
                        }}
                        value={formatPhone(tableData.Phone)}
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        autoComplete="linkedin"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Indeed Feed Email <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          let temp = tableData;
                          temp.IndeedEmail = e.target.value;
                          setTableData({ ...temp });
                        }}
                        value={tableData.IndeedEmail}
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Industry
                    </label>
                    <select
                      className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                      value={tableData.Industry}
                      onChange={(e) => {
                        let temp = tableData;
                        temp.Industry = e.target.value;
                        setTableData({ ...temp });
                      }}
                    >
                      <option value="" disabled>
                        Select Industry
                      </option>
                      {listOfIndustryTypes()?.map((obj, i) => (
                        <option key={i} value={obj.value}>
                          {obj.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      LinkedIn Company ID
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          let temp = tableData;
                          temp.LinkedInId = e.target.value;
                          setTableData({ ...temp });
                        }}
                        value={tableData.LinkedInId}
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        autoComplete="linkedin"
                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                        border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                      Address
                    </label>
                    <Autocomplete
                      className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white"
                      apiKey={"AIzaSyAVDbuhk4LVrRFHVjyB4Zk5bHs3QgWiX_Y"}
                      onPlaceSelected={async (place) => {
                        let temp = autoFill;
                        temp.Address = place.formatted_address;
                        setAutoFill({ ...temp });
                      }}
                      options={{
                        fields: ["formatted_address"],
                        types: ["address"],
                      }}
                      defaultValue={autoFill.Address}
                    />
                  </div>
                </div>

                {error.length > 0 && MissingFieldPrompt(error)}
                <div className="mt-7 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let errorArray = [];
                      if (tableData.Name?.trim().length === 0)
                        errorArray.push("Name");
                      if (tableData.Email?.trim().length === 0)
                        errorArray.push("Email");
                      if (tableData.Timezone?.trim().length === 0)
                        errorArray.push("Timezone");
                      if (tableData.IndeedEmail?.trim().length === 0)
                        errorArray.push("Indeed Feed Email");
                      if (tableData.Website?.trim().length === 0)
                        errorArray.push("Website");
                      setError(errorArray);
                      if (errorArray.length > 0) {
                        return;
                      }

                      await API.graphql(
                        graphqlOperation(`mutation {
                          update_Accounts_by_pk(pk_columns: {ItemId: "${tableData.ItemId}"},
                            _set:
                              {
                                Name: "${tableData.Name}",
                                Website: "${tableData.Website}",
                                Email: "${tableData.Email}",
                                IndeedEmail: "${tableData.IndeedEmail}",
                                Timezone: "${tableData.Timezone}",
                                Phone: "${tableData.Phone}",
                                LinkedInId: "${tableData.LinkedInId}",
                                Address: "${tableData.Address}",
                                Industry: "${tableData.Industry}"
                              })
                              {
                                ItemId
                                Name
                                Address
                              }
                          }`)
                      ).then(({ data }) => {
                        console.log(data);
                        setConfirmButtonMessage(true);
                        setTableData({
                          Name: tableData.Name,
                          Website: tableData.Website,
                          Email: tableData.Email,
                          IndeedEmail: tableData.IndeedEmail,
                          Timezone: tableData.Timezone,
                          Phone: tableData.Phone,
                          LinkedInId: tableData.LinkedInId,
                          Address: tableData.Address,
                          Industry: tableData.Industry,
                        });
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 
                    text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setError([])}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
