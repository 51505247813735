import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Table from "./Table";
import Loading from "../../utils/Loading";
import { CheckCircleIcon } from "@heroicons/react/outline";
const Mytimesheet = ({ user }) => {
    const itemsPerPage = 25;
    const [contractData, setContractData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectStatus, setSelectStatus] = useState("");
    const [selectContract, setSelectContract] = useState("");
    const [selectDuration, setSelectDuration] = useState("");
    const [page, setPage] = useState(1);
    const [itemsTotal, setItemsTotal] = useState(1000);
    const [loading, setLoading] = useState(false);
    const [pendingEntries, setPendingEntries] = useState([]);
    const [mondayDatePeriod, setMondayDatePeriod] = useState(null);
    
    useEffect(() => {
        const async = async () => {
            setLoading(true);

            const convertDurationToDate = (string) => {
                const date = new Date();
                if (string === 'A Week Ago') {
                    date.setDate(date.getDate() - 7);
                }
                else if (string === 'Two Weeks Ago') {
                    date.setDate(date.getDate() - 14);
                }
                else if (string === 'A Month Ago') {
                    date.setMonth(date.getMonth() - 1);
                }
                else if (string === 'Two Months Ago') {
                    date.setMonth(date.getMonth() - 2);
                }
                else if (string === 'Three Months Ago') {
                    date.setMonth(date.getMonth() - 3);
                }
                return date.toISOString().split('T')[0];
            }

            const whereQuery = `{
     JobOrder: {ContractId: {${selectContract ? `_eq: "${selectContract}"` : `_is_null: false`}},MemberId: { _eq: "${user?.AccessId}" }},
  ${selectStatus ? `Status: { _eq: "${selectStatus}" },` : ''}
  ${selectDuration ? `PeriodStart: { _lte: "${convertDurationToDate(selectDuration)}" },` : ''}
 
}`;
            console.log("whereQuery", whereQuery)

            const { data } = await API.graphql({
                query: `{
          Timesheets(offset: ${(page - 1) * itemsPerPage}, limit: ${itemsPerPage}, order_by: {DateCreated: desc}, where: ${whereQuery}) {
          ItemId
          DateCreated
          DateUpdated
          JobOrder {
         
            BillableRate
            Rate
          MemberId
            Member {
              ItemId
              FirstName
              LastName
              Meta
             
            }
        
            Contract {
              ItemId
              Name
              Clients {
                ItemId
                Name
              }
            }
          }
          PeriodStart
          PeriodEnd
          Status
          PayrollId
          Hours: TimesheetEntries_aggregate {
            aggregate {
              sum {
                Hours
              }
            }
          }
          TimesheetEntries (order_by: {Date: asc}){
            ItemId
            Date
            Hours
            Category
            Details
            Status
          }
        }
 JobOrders(
  where: ${user.AccessId ? `{ MemberId: { _eq: "${user.AccessId}" } },` : ''}
) {
  MemberId
  ItemId
  Contract {
    Name
    ItemId
    Categories
  }
}


      }`}); 
            setTableData(data.Timesheets);
            console.log(data.Timesheets);
            const Contract = data.JobOrders.map(jobOrder => jobOrder.Contract);//getting all contrect data 
            const filteredContract = Contract.filter(Contract => Contract !== null && Contract !== undefined);//filtering a undefined data
            // console.log(filteredContract)
            setContractData(filteredContract);

            setLoading(false);
        }
        async();
    }, [page, selectStatus, selectContract, selectDuration, user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
              
                setLoading(true);
                function getMondayDates() {
                    const today = new Date();
                    const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

                    // Calculate the difference to Monday 
                    const differenceToMonday = currentDay - 1;
                   

                    // Calculate dates for Monday
                    const mondayDate = new Date(today);
                    mondayDate.setDate(today.getDate() - differenceToMonday);                   
                    const formattedMondayDate = mondayDate.toISOString().split('T')[0];

                    // Assuming setMondayDatePeriod is a state-setting function
                     setMondayDatePeriod(formattedMondayDate);
                }
                getMondayDates();
                
                const whereQuery = `{
        JobOrder: { MemberId: { _eq: "${user?.AccessId}" }},

        ${mondayDatePeriod 
                    ? `PeriodStart: { _lt: "${mondayDatePeriod}" }` : ''},
                     Status:  { _eq: "Pending"} ,
      }`;

                console.log("whereQuery123", whereQuery);

                const { data } = await API.graphql({
                    query: `{
          Timesheets(where: ${whereQuery}) {
           JobOrder {
              MemberId
              Contract {
                 Name
              }
            }
            PeriodStart
            PeriodEnd
            Status
          }
        }`
                });

              
                const pendingEntries = data.Timesheets || []  ;
                setPendingEntries(pendingEntries);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData(); // Invoke the async function directly inside useEffect

    }, [user?.AccessId, mondayDatePeriod]); // Dependencies for useEffect


    const calculateDuration = (obj, selectDuration) => {
        if (selectDuration.length === 0) return true;
        return (
            new Date(obj?.PeriodEnd).getTime() >=
            new Date().getTime() -
            (86400000 * selectDuration === "A Week Ago"
                ? 7
                : selectDuration === "Two Weeks Ago"
                    ? 14
                    : selectDuration === "A Month Ago"
                        ? 30
                        : selectDuration === "Two Months Ago"
                            ? 60
                            : 90)
        );
    };



    return (
        <div className="ml-1 lg:ml-2" >
              {pendingEntries?.length > 0 &&   (
                <div
                    id="section-top"
                    className="bg-[#D3EBC7] border-t-4 border-[#6fbe47] rounded-b teckpert-text-blue px-4 py-3 shadow-md mb-2 ml-2 mt-10 lg:mt-1"
                    role="alert"
                >
                    {pendingEntries.map((entry, index) => (
                        <div className="flex mt-10 lg:mt-0">
                        <div className="py-1">
                            <CheckCircleIcon
                                className="h-6 w-6 cursor-pointer mr-3"
                                aria-hidden="true"
                            />
                        </div>
                       
                            <div >
                                <p className="font-bold"> Review and submit your timesheet for the week of{' '}
                                    {`${entry.PeriodStart}   ${entry.JobOrder.Contract.Name}`}.
                                    
                                </p>
                               
                            </div>
                      
                    </div>
                          ))}
                </div>
            )}
         
            <Table
                contractData={contractData}
                tableData={tableData}
                setTableData={setTableData}
                selectStatus={selectStatus}
                setSelectStatus={setSelectStatus}
                selectContract={selectContract}
                setSelectContract={setSelectContract}
                selectDuration={selectDuration}
                setSelectDuration={setSelectDuration}
                page={page}
                userdata={user}
                setPage={setPage}
                itemsTotal={itemsTotal}
                itemsPerPage={itemsPerPage}
                
            />
            {loading && <Loading />}
        </div>
    );
};
export default Mytimesheet;
