/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { Editor } from "@tinymce/tinymce-react";

const UpdateDescriptionModal = ({
  open,
  setOpen,
  tableData,
  setTableData,
  input,
  setInput,
  setConfirmButtonMessage,
}) => {
  useEffect(() => {
    setTimeout(() => {
      setInput(JSON.parse(tableData.Description));
    }, 0);
  }, [open]);

  let tempText = tableData.Description;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="w-[75%] h-[600px] mx-14 relative transform overflow-hidden rounded-lg bg-white 
                px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6"
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Update Description
                  </label>
                  <div className="m-5 h-60 md:h-48">
                    <Editor
                      apiKey={
                        "2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"
                      }
                      onEditorChange={(value) => {
                        tempText = value;
                      }}
                      initialValue={input}
                      init={{
                        height: 450,
                        menubar: false,
                        plugins: [
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | casechange blocks | bold italic backcolor | " +
                          "alignleft aligncenter alignright alignjustify | " +
                          "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        content_style:
                          "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2 flex absolute bottom-8 right-10">
                  <button
                    type="button"
                    className="mt-3 w-full justify-center rounded-md border 
                    border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 
                    shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let temp = tableData;
                      temp.Description = JSON.stringify(tempText);
                      await API.graphql(
                        graphqlOperation(`mutation {
                          update_Opportunities_by_pk(pk_columns: {ItemId: "${tableData.ItemId}"}, _set: { 
                            Description: ${temp.Description}}) 
                            {
                              ItemId
                              Description
                            }
                        }`)
                      ).then(({ data }) => {
                        setTableData({ ...temp });
                        setOpen(false);
                        setConfirmButtonMessage(true);
                      });
                    }}
                  >
                    Update
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateDescriptionModal;
