import { useState, useEffect } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ComboBox({
  comboBoxLabel,
  data,
  searchVal,
  setSearchVal,
}) {
  const [query, setQuery] = useState("");

  const filteredData =
    query === ""
      ? data
      : data?.filter((data) => {
          return data?.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div>
      <Combobox
        as="div"
        value={searchVal}
        onChange={(value) => {
          setSearchVal(value);
        }}
      >
        <Combobox.Button>
          <div className="relative mt-1 absolute">
            <Combobox.Input
              className="w-72 rounded-full justify-center rounded-md border shadow-sm px-2.5 py-3 text-sm placeholder:text-gray-900 
              hover:placeholder:text-white hover:teckpert-bg-green hover:text-white cursor-pointer text-gray-900"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(item) => item}
              placeholder={comboBoxLabel}
            />
            <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronDownIcon
                className="h-4 w-4 text-gray-900"
                aria-hidden="true"
              />
            </div>
            {filteredData?.length > 0 && (
              <Combobox.Options
                className="fixed w-52 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 
                focus:outline-none sm:text-sm text-left"
              >
                {filteredData?.map((obj, i) => (
                  <Combobox.Option
                    key={i}
                    value={obj}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-pointer select-none py-2 pl-3 pr-9 capitalize text-left",
                        active ? "bg-[#6FBE47] text-white" : "text-gray-900"
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            "block truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {obj}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              "absolute inset-y-0 left-0 flex items-center pl-1.5",
                              active ? "text-white" : "text-[#6FBE47]"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox.Button>
      </Combobox>
    </div>
  );
}
