import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import Table from "./Table";
import CreateQuestionnaireModal from "./Modals/CreateQuestionnaireModal";
import Loading from "../../../utils/Loading";

const AppQuestionnnaire = () => {
  const [questionsData, setQuestionsData] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [type, setType] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editQuestionsModalOpen, setEditQuestionsModalOpen] = useState(false);
  const [questionsModalOpen, setQuestionsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const [tempQuestionsData, setTempQuestionsData] = useState({
    Title: "",
    Description: "",
    DateCreated: new Date().toISOString(),
    CreatedBy: localStorage.getItem("name"),
    Questions: [
      {
        Question: "",
        Required: false,
        AnswerType: "",
        Order: 0,
        AnswerOptions: [
          {
            Option: "",
            Knockout: false,
            Order: 0,
          },
        ],
      },
    ],
  });

  // GET DATA FOR APPLICATION QUESTIONNAIRES AND OPPORTUNITIES
  useEffect(() => {
    API.graphql({
      query: `{
        ApplicationQuestionnaire {
          ItemId
          DateCreated
          Title
          Questions
          Description
          CreatedBy
        }
      }      
    `,
    }).then(({ data }) => {
      setQuestionsData(data.ApplicationQuestionnaire);
    });

    API.graphql({
      query: `{
        Opportunities(offset: 0, limit: 25 order_by: {DateCreated: asc}) {
          ItemId
          ClientId
          Applicants
          Contract
          ContractLength
          DateCreated
          HighEndRate
          Location
          LowEndRate
          PayRateCompensationUnit
          QuestionnaireFormId
          Title
          WorkType
          Client {
            Name
          }
          Contracts {
            Name
          }
          ApplicationQuestionnaire {
            Title
          }
        }
      }
    `,
    }).then(({ data }) => {
      setJobsData(data.Opportunities);
    });
  }, []);

  if (questionsData.length === 0) return <Loading />;

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6 mb-4">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={() => setCreateModalOpen(!createModalOpen)}
            type="button"
            className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white hover:opacity-90
            shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <Table
        questionsData={questionsData}
        setQuestionsData={setQuestionsData}
        jobsData={jobsData}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        editQuestionsModalOpen={editQuestionsModalOpen}
        setEditQuestionsModalOpen={setEditQuestionsModalOpen}
      />
      <CreateQuestionnaireModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        questionsData={questionsData}
        setQuestionsData={setQuestionsData}
        tempData={tempQuestionsData}
        setTempData={setTempQuestionsData}
        type={type}
        setType={setType}
        setQuestionsModalOpen={setQuestionsModalOpen}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        setEditQuestionsModalOpen={setEditQuestionsModalOpen}
      />
    </div>
  );
};
export default AppQuestionnnaire;
