import { useState } from "react";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { formatDate } from "../../../utils/FormatDate";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import UploadFileModal from "../../../utils/UploadFileModal";

const Documents = ({
  tableData,
  setTableData,
  documentUrl,
  setConfirmButtonMessage,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");

  const getStorageFile = async (key) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    window.open(url, "_blank");
  };

  const deleteItem = async (id) => {
    if (id) {
      let response = await API.graphql(
        graphqlOperation(`
          mutation {
              delete_Documents_by_pk(ItemId: "${id}") {
                ItemId
                Name
              }
            }
        `)
      );
      if (response) {
        setTableData(tableData.filter((document) => document.ItemId !== id));
        setConfirmButtonMessage(true);
        setDeleteModalOpen(false);
      }
    }
  };

  return (
    <div>
      <UploadFileModal
        open={modalOpen}
        setOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
        lookupType={"DocTypesClientContract"}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteItem={() => deleteItem(deleteItemId)}
      />
      <div className="my-5 mx-8 p-6 bg-white rounded-lg border shadow-md hover:bg-gray-100">
        <div className="flex justify-between">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Documents
          </h4>
          <div
            onClick={() => setModalOpen(!modalOpen)}
            className="flex flex-none mr-5"
          >
            <PlusCircleIcon
              className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
              aria-hidden="true"
            />
          </div>
        </div>

        {/* Table Section */}
        <div className="mx-5 flex flex-col  p-3">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                  <thead className="bg-gray-100">
                    <tr>
                      {["Name", "Type", "Uploaded", "Created By", ""].map(
                        (header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableData?.map((obj, i) => (
                      <tr key={obj.ItemId}>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold teckpert-text-green sm:pl-6 cursor-pointer">
                          <a onClick={() => getStorageFile(obj.Name)}>
                            {obj.Name}
                          </a>
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          {obj.Type ? obj.Type : "-"}
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          {obj.CreatorName ? obj.CreatorName : "-"}
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          <XCircleIcon
                            className="h-7 w-7 cursor-pointer  ml-7 inline text-gray-300 hover:text-red-500"
                            aria-hidden="true"
                            onClick={() => {
                              setDeleteModalOpen(!deleteModalOpen);
                              setDeleteItemId(obj.ItemId);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
