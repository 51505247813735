import { Fragment } from "react";
import logo from "../../img/teckpert-logo.png";
import bottomlogo from "../../img/Quantum-Recruit-3.0- Final-03.png"
import Bottomlogotext from "../../img/Quantum-Recruit-3.0- Final-04.png"



import {
  CogIcon,
  XIcon,
  UserGroupIcon,
  UsersIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  CalendarIcon,
  ReceiptTaxIcon,
  UserIcon,
  AcademicCapIcon,   
} from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const Sidebar = ({
  tabName,
  setTabName,
  mobileMenuOpen,
    setMobileMenuOpen,
  usertype
}) => {
    let sidebarNavigation = [];
   if (usertype?.attributes?.["custom:userType"] === "member") {
        sidebarNavigation = [
            { name: "My Timesheets", href: "#", icon: CalendarIcon, current: false },
            { name: "Enter Time", href: "#", icon: CalendarIcon, current: false },
            
        ];
    }
    else
    {
        sidebarNavigation =
            [

            { name: "People", href: "#", icon: UserGroupIcon, current: false },
            { name: "Opportunities", href: "#", icon: BriefcaseIcon, current: true },
            { name: "Clients", href: "#", icon: UsersIcon, current: false },
            { name: "Contracts", href: "#", icon: DocumentTextIcon, current: false },
            { name: "Timesheets", href: "#", icon: CalendarIcon, current: false },
            { name: "Enter Time", href: "#", icon: CalendarIcon, current: false },
            { name: "Reports", href: "#", icon: ReceiptTaxIcon, current: false },
            { name: "Invoice", href: "#", icon: ReceiptTaxIcon, current: false },
            { name: "Tools", href: "#", icon: CogIcon, current: false },
            { name: "Users", href: "#", icon: UserIcon, current: false },
            { name: "Courses", href: "#", icon: AcademicCapIcon, current: false },
            ];
    }






    console.log("UserType:-" + usertype?.attributes?.["custom:userType"])
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
    }
return (
    <>
      {/* Narrow sidebar */}
      <div className="hidden w-35 bg-zinc-700 overflow-y-auto md:block z-20">
        <div className="w-full py-6 flex flex-col items-center">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-5 w-auto" src={logo} alt="Logo" />
          </div>
          <div className="flex-1 mt-6 w-full px-2 space-y-1">
                      {sidebarNavigation.map((item) => (
                          <Link
                              to={item.name.replaceAll(/\s/g, '').toLowerCase()}
                              onClick={() => {
                                  setTabName(item.name);
                              }}
                              key={item.name}
                              className={classNames(
                                  tabName.toLowerCase().startsWith(item.name.replaceAll(/\s/g, '').toLowerCase())
                                      ? "teckpert-bg-green text-white"
                                      : "text-white hover:teckpert-bg-green",
                                  "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                          >
                              <item.icon
                                  className={classNames(
                                      item.current
                                          ? "text-white"
                                          : "text-white group-hover:text-white",
                                      "h-6 w-6"
                                  )}
                                  aria-hidden="true"
                              />
                              <span className="mt-2">{item.name}</span>
                          </Link>
                      ))}
                </div>
                
            </div>
            <div class="absolute bottom-0 left-0 ml-1 mb-3 ">
                <div className="flex-shrink-0 flex ml-1 ">
                    <img className="h-7 w-auto" src={bottomlogo} alt="Logo" />  <img className="h-7 mr-1 w-auto" src={Bottomlogotext} alt="Logo" />
                </div>
            </div>
           
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative max-w-xs w-full teckpert-bg-green pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                  <nav className="h-full flex flex-col">
                    <div
                      className="space-y-1"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {sidebarNavigation.map((item) => (
                        <Link
                              to={item.name.replaceAll(/\s/g, '').toLowerCase()}
                          key={item.name}
                          className={classNames(
                            item.current
                              ? "teckpert-bg-green text-white"
                              : "text-white hover:teckpert-bg-green hover:text-white",
                            "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-white group-hover:text-white",
                              "mr-3 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Sidebar;
