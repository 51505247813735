import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";

const EditEducationModal = ({
  modalOpen,
  setModalOpen,
  originalData,
  tempData,
  setTempData,
  onSave,
  inputField,
  id,
}) => {
  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setModalOpen(false);
          // setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[600px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setModalOpen(false);
                      setTempData(originalData);
                      // setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Profile Information
                    </Dialog.Title>
                  </div>
                </div>

                {tempData.Meta.Education[id] &&
                  inputField.map((obj, i) => (
                    <div className="mt-7" key={i}>
                      <label className="block text-sm font-medium text-gray-700">
                        {obj}
                      </label>
                      <input
                        name={tempData.Meta.Education[id][obj]}
                        onChange={async (e) => {
                          let temp = tempData;
                          temp.Meta.Education[id][obj] = e.target.value;
                          setTempData({ ...temp });
                        }}
                        value={tempData.Meta.Education[id][obj]}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 
                        placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                      />
                    </div>
                  ))}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-80
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setModalOpen(false);
                      setTempData(originalData);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default EditEducationModal;
