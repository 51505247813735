import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API } from "aws-amplify";

export default function AddInvoiceModal({ open, setOpen, selectedTimesheets }) {
  const [tempData, setTempData] = useState({});
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const async = async () => {
      const response = await API.get('auth', '/invoices?companies=true');
      setCompanies(response);
    }
    async();
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-20 mx-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden min-w-full rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Invoice
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-8">
                      <div>
                        <div className="sm:col-span-3">
                          <div className="mt-1 flex w-full items-center">
                            <div>Select Company from Quickbooks:</div>
                            <select
                              className="ml-4 w-1/6 mr-3 rounded-full justify-center rounded-md cursor-pointer
                              border shadow-sm px-2.5 py-2 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                              onChange={(e) => {
                                let temp = tempData;
                                temp.company = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.company}
                            >
                              <option className="text-gray-300" value="">
                                Company
                              </option>
                              {companies.map((obj, i) => (
                                <option key={i} value={obj.value}>
                                  {obj.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 sm:px-6 lg:px-8">
                        <div className="mt-8 flex flex-col">
                          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      {[
                                        "Description",
                                        "Hours",
                                        "Rate",
                                        "Percentage",
                                        "Amount",
                                      ].map((header, i) => (
                                        <th
                                          key={i}
                                          className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                          {header}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>

                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {selectedTimesheets?.map((timesheet) => (
                                      <tr key={timesheet?.ItemId}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                          <div className="flex items-center font-medium text-[#6FBE47]">
                                            {timesheet?.JobOrder?.Member?.FirstName} {timesheet?.JobOrder?.Member?.LastName}{` - `}
                                                    {new Date(timesheet?.PeriodStart).toLocaleDateString('en-US', { day: 'numeric', month: 'short', timeZone: "utc" })} - {new Date(timesheet?.PeriodEnd).toLocaleDateString('en-US', { day: 'numeric', month: 'short', timeZone: "utc" })}
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                          {timesheet?.Hours?.aggregate?.sum?.Hours?.toFixed(2)}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                          ${timesheet?.JobOrder?.BillableRate}
                                        </td>
                                        <td className="flex-none py-4 text-sm">
                                          N/A
                                        </td>
                                        <td className="flex-none py-4 text-sm">
                                          {Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          }).format(
                                            timesheet?.Hours?.aggregate?.sum?.Hours *
                                            timesheet?.JobOrder?.BillableRate
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent 
                          teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={async () => {
                            console.log(tempData, selectedTimesheets);
                            await API.post("auth", "/invoices", {
                              body: {
                                QuickbooksCompanyId: tempData.company,
                                Workers: selectedTimesheets.map(timesheet => ({
                                  Description: `${timesheet?.JobOrder?.Member?.FirstName} ${timesheet?.JobOrder?.Member?.LastName} - 
                                  ${new Date(timesheet?.PeriodStart).toLocaleDateString('en-US', { day: 'numeric', month: 'short', timeZone: "utc" })} - ${new Date(timesheet?.PeriodEnd).toLocaleDateString('en-US', { day: 'numeric', month: 'short', timeZone: "utc" })}`,
                                  Quantity: timesheet?.Hours?.aggregate?.sum?.Hours,
                                  Rate: timesheet?.JobOrder?.BillableRate,
                                })),
                                ContractId: selectedTimesheets[0]?.JobOrder?.Contract?.ItemId,
                                Amount: selectedTimesheets[0]?.Hours?.aggregate?.sum?.Hours * selectedTimesheets[0]?.JobOrder?.BillableRate,
                                Status: 'Invoice sent',
                              }
                            });
                            setOpen(false);
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
