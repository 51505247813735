import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  MenuIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
import Loading from "../../../utils/Loading";
import MissingFieldPrompt from "../../../utils/MissingFieldPrompt";
// Modals
import CreateChecklistModal from "./Modals/CreateChecklistModal";
import UnsavedChangesModal from "./Modals/UnsavedChangesModal";
import ConfirmMessage from "../../../utils/ConfirmMessage";

const ChecklistItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [originalData, setOriginalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [inputBox, setInputBox] = useState(true);
  const [editModeOn, setEditModeOn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmButtonMessage, setConfirmButtonMessage] = useState(false); //Shows/Hides saved confirmation message
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [error, setError] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [contractData, setContractData] = useState([]);
  // Drag and Drop
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    originalOrderList: [],
    updatedOrder: [],
    updatedOrderList: [],
  };
  const [list, setList] = useState([]);
  const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);
    let newTempList = tableData;
  
  useEffect(() => {
    let temp = [];
    if (tableData.Items) {
      tableData.Items.map((obj, i) => {
        temp.push(obj?.Order);
      });
      setList(temp);
    }
  }, [tableData.Items, newItem]);

  useEffect(() => {
    if (tableData.Title == "") {
      setEditModeOn(true);
      setEditModal(true);
    }
  }, [tableData.Title]);

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  // GET/SET WORKFLOW DATA
  useEffect(() => {
    API.graphql({
      query: `{
        Checklists(where: {ItemId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          Title
          Items
          Assign
          Description
          CreatedBy
          MemberId
      }
    }      
  `,
    }).then(({ data }) => {
      setTableData(
        data.Checklists[0] || {
          ItemId: location.pathname.split("/")[2],
          Title: "",
          Description: "",
          OpportunityId: "",
          Assign: "",
          DateCreated: new Date().toISOString(),
          CreatedBy: localStorage.getItem("name"),
          Items: [],
          MemberId:"",

        }
      );
    });
  }, [location.pathname.split("/")[2], confirmButtonMessage]);
    
  // Initial data (before edit)
  useEffect(() => {
    API.graphql({
      query: `{
        Checklists(where: {ItemId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          Title
          Items
          Assign
          Description
          CreatedBy
          MemberId
      }
    }      
  `,
    }).then(({ data }) => {
      setOriginalData(data.Checklists[0]);
    });
  }, [confirmButtonMessage]);
    useEffect(() => {
        API.graphql({
            query: `{
        Members {
          FirstName
          ItemId
          LastName
        }
        Contracts {
          ItemId
          Name
        }
      }      
    `,
        }).then(({ data }) => {
            setMemberData(data.Members.filter((obj) => obj.FirstName));
            setContractData(data.Contracts.filter((obj => obj.Name)));
        });
    }, []);
  useEffect(() => {
    setErrorMessage(false);
  }, [newItem]);

  // onDragStart fires when an element starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    if (editModeOn) {
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition,
        isDragging: true,
        originalOrder: list,
      });
      event.dataTransfer.setData("text/html", "");
    }
  };

  // onDragOver fires when an element being dragged enters a droppable area.
  const onDragOver = (event) => {
    // in order for the onDrop event to fire, we have
    // to cancel out this one
    event.preventDefault();

    if (editModeOn) {
      let newList = dragAndDrop.originalOrder;
      // index of the item being dragged
      const draggedFrom = dragAndDrop.draggedFrom;
      // index of the droppable area being hovered
      const draggedTo = Number(event.currentTarget.dataset.position);
      const itemDragged = newList[draggedFrom];
      const remainingItems = newList.filter(
        (item, index) => index !== draggedFrom
      );
      newList = [
        ...remainingItems.slice(0, draggedTo),
        itemDragged,
        ...remainingItems.slice(draggedTo),
      ];
      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    }
  };

  const onDrop = (event) => {
    if (editModeOn) {
      newTempList = tableData;
      setList(dragAndDrop.updatedOrder);
      tableData.Items = newTempList.Items.map(
        (obj, i) => tableData.Items[dragAndDrop.updatedOrder[i]]
      );
      tableData.Items.map((obj, i) => (obj.Order = i));
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
      });
      setTableData({ ...newTempList });
    }
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  if (!tableData) return <Loading />;

  return (
    <div>
      {confirmButtonMessage && <ConfirmMessage />}
      {console.log("Items", tableData.Items)}
      <div className="overflow-y-auto h-full">
        <div className="cursor-pointer absolute top-5 left-20 z-10">
          <Link className="italic text-sm ml-10" to="/checklists">
            <ChevronLeftIcon
              className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
              aria-hidden="true"
            />
          </Link>
        </div>
        <div className="mx-4 mt-4">
          <div className="border border-gray-200 shadow-md rounded p-3 w-full flex justify-between">
            <div>
              {/* Title */}
              <div className="flex gap-10">
                <div
                  className={`${
                    tableData.Title ? "text-gray-700" : "text-gray-400"
                  } min-w-[400px]`}
                >
                  <h1
                    onClick={() => {
                      if (editModeOn) setEditModal(true);
                    }}
                    className={`${
                      editModeOn
                        ? "border border-gray-300 rounded cursor-pointer"
                        : ""
                    } text-2xl font-medium p-1 flex gap-20`}
                  >
                    {tableData.Title ? tableData.Title : "Write Title..."}
                  </h1>
                </div>
              </div>
              <CreateChecklistModal
                open={editModal}
                setOpen={setEditModal}
                tableData={originalData}
                setTableData={setOriginalData}
                tempData={tableData}
                setTempData={setTableData}
                setConfirmButtonMessage={setConfirmButtonMessage}
                memberData={memberData }
                contractData={contractData}
              />
              {/* Description */}
              <div
                className={`${
                  tableData.Description ? "text-gray-700" : "text-gray-400"
                } max-w-[950px] text-sm`}
                onClick={() => {
                  if (editModeOn) setEditModal(true);
                }}
              >
                <p
                  className={`${
                    editModeOn
                      ? "border border-gray-300 rounded cursor-pointer"
                      : ""
                  } p-1 flex`}
                >
                  {tableData.Description
                    ? tableData.Description
                    : "Write Description..."}{" "}
                  {editModeOn && (
                    <div className="animate-[pulse_1s_infinite] text-blue-700 font-light text-lg relative bottom-1.5">
                      |
                    </div>
                  )}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                let equals =
                  originalData.Items.length === tableData.Items.length &&
                  originalData.Items.sort((a, b) => a.Order - b.Order).every(
                    (e, i) => e.Name === tableData.Items[i].Name
                  );
                if (equals) {
                  setEditModeOn(!editModeOn);
                  setNewItem("");
                } else setConfirmModal(true);
              }}
              type="button"
              className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white hover:opacity-90
              shadow-sm focus:outline-none sm:w-auto h-[40px]"
            >
              Edit Items
            </button>
            <UnsavedChangesModal
              open={confirmModal}
              setOpen={setConfirmModal}
              confirm={() => {
                setTableData(originalData);
                setEditModeOn(false);
              }}
            />
          </div>
        </div>

        <div className={`h-screen bg-[#f5f5f5] pt-3 p-4`}>
          <div>
            {tableData.Items?.length > 0 ? (
              tableData.Items.sort((a, b) => a.Order - b.Order).map(
                (obj, i) => (
                  <div
                    key={i}
                    className={`${
                      dragAndDrop && dragAndDrop.draggedTo === Number(i)
                        ? "relative rounded text-gray-500 bg-white border border-dashed border-blue-800 align-center w-100 before:h-100"
                        : ""
                    } p-4 bg-white rounded-lg mt-1 border border-gray-200 shadow-md`}
                    data-position={i}
                    draggable
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onDragLeave={onDragLeave}
                  >
                    <div className="flex">
                      <div className="grow mt-1">
                        {!editModeOn ? (
                          <h4 className="text-lg font-bold text-gray-800 ml-5 p-2">
                            {obj.Name}
                          </h4>
                        ) : (
                          <input
                            className="text-lg font-bold text-gray-800 ml-5 border border-gray-200 rounded p-2 w-[50%]"
                            type="text"
                            onChange={(e) => {
                              let temp = tableData;
                              temp.Items[i].Name = e.target.value;
                              setTableData({ ...temp });
                            }}
                            value={obj?.Name}
                          />
                        )}
                      </div>

                      <div
                        className={`${
                          editModeOn ? "" : "hidden"
                        } flex gap-5 mr-4`}
                      >
                        <TrashIcon
                          onClick={() => {
                            let temp = tableData;
                            temp.Items = temp.Items.filter(
                              (item) => item.Name != obj.Name
                            );
                            setTableData({ ...temp });
                          }}
                          className="h-5 w-5 cursor-pointer mt-2 text-gray-300 hover:text-red-500"
                          aria-hidden="true"
                        />
                        <MenuIcon
                          className="h-5 w-5 cursor-pointer mt-2"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div
                className={`${
                  !editModeOn ? "" : "hidden"
                } w-full py-5 justify-center text-center mb-8 font-bold text-gray-300 text-lg border boder-gray-300 rounded`}
              >
                No Checklist items have been added.
              </div>
            )}

            <div
              className={`${
                editModeOn & inputBox ? "" : "hidden"
              } bg-gray-200 p-3 rounded-lg mt-1 border border-gray-200 shadow-md`}
            >
              <div className="flex">
                <div className="grow mt-1 flex">
                  <input
                    placeholder="Enter an item"
                    className="text-lg font-medium placeholder:text-gray-400 ml-5 bg-white rounded p-2 w-[50%]"
                    type="text"
                    onChange={(e) => {
                      setNewItem(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      let temp = tableData;
                      let itemList = tableData.Items.map((obj) => obj.Name);
                      if (e.key === "Enter") {
                        if (newItem.length > 0) {
                          if (!itemList.includes(newItem.trim())) {
                            console.log("testing");
                            temp.Items.push({
                              Name: newItem,
                              Order: tableData.Items.length,
                            });
                            let tempList = list;
                            tempList.push(tableData.Items.length - 1);
                            setList({ ...temp });
                            setTableData({ ...temp });
                            setNewItem("");
                          } else {
                            setErrorMessage("Checklist item already exists.");
                          }
                        } else {
                          setErrorMessage("Checklist item cannot be blank.");
                        }
                      }
                      console.log("new & list", newTempList, list);
                    }}
                    value={newItem}
                  />
                  <div
                    className={`${
                      errorMessage?.length > 0 ? "" : "hidden"
                    } m-auto flex gap-3 border border-red-600 rounded p-2`}
                  >
                    <XCircleIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                    <div className="font-medium text-red-600">
                      {errorMessage}
                    </div>
                  </div>
                </div>

                <div className="flex flex-none mr-6 gap-5">
                  <XCircleIcon
                    onClick={() => setInputBox(false)}
                    className="h-6 w-6 text-gray-400 hover:text-red-500 mt-3 cursor-pointer"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>

          {error.length > 0 && MissingFieldPrompt(error)}

          <div className={`${editModeOn ? "" : "hidden"}  mt-2`}>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between">
              <div>
                <button
                  onClick={() => {
                    let equals =
                      originalData.Items.length === tableData.Items.length &&
                      originalData.Items.sort(
                        (a, b) => a.Order - b.Order
                      ).every((e, i) => e.Name === tableData.Items[i].Name);
                    if (equals) setEditModeOn(!editModeOn);
                    else setConfirmModal(true);
                  }}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 
                  shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    let errorArray = [];
                    if (tableData.Title?.trim().length === 0)
                      errorArray.push("Title");
                    if (tableData.Description?.trim().length === 0)
                      errorArray.push("Description");
                    setError(errorArray);

                    if (errorArray.length > 0) {
                      return;
                    }

                    let temp = tableData;
                    await API.graphql(
                      graphqlOperation(`mutation {
                        update_Checklists_by_pk(pk_columns: {ItemId: "${
                          temp.ItemId
                        }"}, 
                           _set: { Items: [${temp.Items?.map(
                             (obj) =>
                               `{ Name: "${obj.Name}", Order: ${obj.Order} }`
                           ).join(`,`)}]                                      
                            })
                            {
                              ItemId
                              Items
                            }
                      }`)
                    ).then(({ data }) => {
                      console.log("response", data);
                      setConfirmButtonMessage(true);
                      setEditModeOn(false);
                      setNewItem("");
                    });
                  }}
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                  font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                >
                  Save Changes
                </button>
              </div>
              <button
                onClick={() => {
                  if (!inputBox) setInputBox(true);
                  else {
                    let temp = tableData;
                    let itemList = tableData.Items.map((obj) => obj.Name);
                    if (newItem.length > 0) {
                      if (!itemList.includes(newItem.trim())) {
                        temp.Items.push({
                          Name: newItem,
                          Order: temp.Items.length,
                        });
                        setTableData({ ...temp });
                        setNewItem("");
                      } else {
                        setErrorMessage("Checklist item already exists.");
                      }
                    } else {
                      setErrorMessage("Checklist item cannot be blank.");
                    }
                  }
                }}
                type="button"
                className="mt-3 flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base
                font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
              >
                <PlusCircleIcon
                  className={`mt-1 mr-2 h-7 w-7 m-auto teckpert-text-green cursor-pointer`}
                  aria-hidden="true"
                />
                <span className="mt-2">Add To-Do</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChecklistItems;
