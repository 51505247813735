import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  XCircleIcon,
  PencilIcon,
  ArrowCircleRightIcon,
  QrcodeIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/FormatDate";
import Loading from "../../../utils/Loading";
import UpdatePortalModal from "./Modals/UpdatePortalModal";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import QrModal from "../../Opportunities/Details/Modals/QrModal";

const Table = ({
  tableData,
  setTableData,
  tempData,
  setSearchModalOpen,
  opportunityField,
  setOpportunityField,
  selectedJob,
  setSelectedJob,
  editModalOpen,
  setEditModalOpen,
  criteriaFilter,
  setCriteriaFilter,
  client,
  contract,
  locationList,
  jobsData,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [criteria, setCriteria] = useState({});
  const [rows, setRows] = useState({});
  const [dragOver, setDragOver] = useState("");
  const [cols, setCols] = useState([]);

  // SET TABLE DATA ROWS
  useEffect(() => {
    setRows(tempData);
  }, [tempData]);

  if (tempData.length === 0) return <Loading />;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Name",
                      "Date Created",
                      "Status",
                      "Creator",
                      "QR",
                      "",
                      "",
                      "",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tempData?.map((obj) => (
                    <tr key={obj.ItemId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center font-medium text-[#6FBE47]">
                          {obj.Name ? obj.Name : "-"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.Status ? obj.Status : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.Creator ? obj.Creator : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-3 py-4 text-sm text-gray-900">
                        <QrcodeIcon
                          onClick={() => {
                            setSelectedJob(obj);
                            setQrModalOpen(true);
                          }}
                          className="h-5 w-5 inline cursor-pointer mx-3"
                          aria-hidden="true"
                        />
                        <QrModal
                          open={qrModalOpen}
                          setOpen={setQrModalOpen}
                          value={`http://teckpert-platform-web-dev.s3-website.us-east-2.amazonaws.com/${selectedJob.Url}`}
                        />
                      </td>
                      <td className="w-[100px] cursor-pointer whitespace-nowrap py-2 text-sm">
                        <Link
                          key={obj.Url}
                          to={`/jobportals/public/${obj.Url}`}
                          target="_blank"
                        >
                          <button
                            type="button"
                            className="mr-8 mt-2 items-center justify-center rounded-md border border-transparent teckpert-bg-green 
                            px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto hover:border-white"
                          >
                            Launch
                            <ArrowCircleRightIcon
                              className="h-6 w-6 inline cursor-pointer mx-3"
                              aria-hidden="true"
                            />
                          </button>
                        </Link>
                      </td>
                      <td className="py-4 text-sm">
                        <PencilIcon
                          onClick={() => {
                            setSelectedJob(obj);
                            setCriteria(obj.Criteria);
                            setCriteriaFilter(
                              obj.Criteria.Location ||
                                obj.Criteria.Contract ||
                                obj.Criteria.Client
                            );
                            setOpportunityField(
                              obj.Criteria.Location
                                ? "Location"
                                : obj.Criteria.Contract
                                ? "Contract"
                                : obj.Criteria.Client
                                ? "Client"
                                : "Manual"
                            );
                            setEditModalOpen(true);
                          }}
                          className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                          aria-hidden="true"
                        />
                        {selectedJob ? (
                          <UpdatePortalModal
                            open={editModalOpen}
                            setOpen={setEditModalOpen}
                            tempData={selectedJob}
                            setTempData={setSelectedJob}
                            setSearchModalOpen={setSearchModalOpen}
                            opportunityField={opportunityField}
                            setOpportunityField={setOpportunityField}
                            setSelectedJob={setSelectedJob}
                            criteria={criteria}
                            criteriaFilter={criteriaFilter}
                            setCriteriaFilter={setCriteriaFilter}
                            client={client}
                            contract={contract}
                            locationList={locationList}
                            jobsData={jobsData}
                          />
                        ) : null}
                      </td>
                      <td className="py-4 text-sm">
                        <XCircleIcon
                          value={obj.ItemId}
                          onClick={() => {
                            setSelectedJob(obj);
                            setDeleteModalOpen(true);
                          }}
                          className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                          aria-hidden="true"
                        />
                        <ConfirmDeleteModal
                          open={deleteModalOpen}
                          setOpen={setDeleteModalOpen}
                          deleteItem={async () => {
                            if (selectedJob.ItemId) {
                              let response = await API.graphql(
                                graphqlOperation(`
                                mutation {
                                    delete_JobPortals_by_pk(ItemId: "${selectedJob.ItemId}") {
                                      ItemId
                                      Name
                                    }
                                  }
                              `)
                              );
                              if (response) {
                                setTableData(
                                  tableData.filter(
                                    (item) => item.ItemId !== selectedJob.ItemId
                                  )
                                );
                                setSelectedJob([]);
                              }
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
