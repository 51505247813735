import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import placeholderImg from "../../../img/logo192.png";
import React from "react";
const TableDetails = ({ open, setOpen, timesheet }) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="border-b mb-5 pb-2">Timesheet Period : {new Date(timesheet?.PeriodStart).toLocaleDateString(
                                    "en-US",
                                    { day: "numeric", month: "short", timeZone: "utc" }
                                )}{" "}
                                    -{" "}
                                    {new Date(timesheet?.PeriodEnd).toLocaleDateString(
                                        "en-US",
                                        { day: "numeric", month: "short", timeZone: "utc" }
                                    )}({new Date(timesheet?.PeriodStart).toLocaleDateString(
                                        "en-US",
                                        { timeZone: "utc", weekday: 'long' }
                                    )}{" "}
                                    -{" "}
                                    {new Date(timesheet?.PeriodEnd).toLocaleDateString(
                                        "en-US",
                                        { timeZone: "utc", weekday: 'long' }
                                    )})</div>
                                <div className="flex block text-sm text-gray-700">
                                    <img
                                        className=" h-20 w-20 flex-shrink-0 rounded-full"
                                        src={placeholderImg}
                                        alt="contact pic"
                                    />
                                    <div className="pl-7">
                                        <div className="teckpert-text-green text-2xl font-semibold">
                                            {timesheet?.JobOrder?.Member?.FirstName} {timesheet?.JobOrder?.Member?.LastName}
                                        </div>
                                        <div className="text-gray-400 pt-1">
                                            Status: {timesheet.Status}
                                        </div>
                                    </div>
                                </div>

                                {/* Table Section */}
                                <div className="mx-5 flex flex-col p-10 overflow-x-auto">
                                    <div className="sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                                                    <thead className="bg-gray-100">
                                                        <tr>
                                                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Date
                                                            </th>
                                                           
                                                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Hours
                                                            </th>
                                                           
                                                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Category
                                                            </th>
                                                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Description
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {timesheet.TimesheetEntries.map((obj) => (
                                                            <tr key={obj.ItemId}>
                                                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 font-bold">
                                                                    {new Date(obj.Date).toLocaleDateString('en-US', { timeZone: "utc", weekday: 'long' })}{" " }
                                                                    {new Date(obj.Date).toLocaleDateString('en-US',{ timeZone: "utc"})}
                                                                </td>
                                                                
                                                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                                                                    {obj.Hours?.toFixed(2)}
                                                                </td>
                                                               
                                                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 ">
                                                                    {obj.Category}
                                                                </td>
                                                                <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 ">
                                                                    {obj.Details.length > 30
                                                                        ? obj.Details.match(/.{1,35}/g).map((line, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {line}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))
                                                                        : obj.Details}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default TableDetails;
