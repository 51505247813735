import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  XCircleIcon,
  PencilIcon,
  DocumentDuplicateIcon,
    LockClosedIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/FormatDate";
import { v4 as uuidv4 } from "uuid";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";

const Table = ({ data, setData, selectedRow, setSelectedRow }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Name",
                      "Type",
                      "Subject",
                      "Date Created",
                      "Default From",
                      "",
                      "",
                      "",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((obj) => {
                    return (
                      <tr key={obj.ItemId}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center font-medium teckpert-text-green ">
                                    {obj.Type === "System Templates" ? (
                                        <>
                                            <div title="System Template used for sending Timesheets for approval" className="cursor-pointer teckpert-text-green inline-flex items-center">
                                            <span className="cursor-pointer">
                                                {obj.Name}
                                            </span>
                                            <LockClosedIcon className="h-5 w-5 mr-1 ml-2 text-gray-500 teckpert-text-green" />
                                            </div>
                                        </>
                                    ) : (
                                        obj.Name
                                    )}
                                </div>
                            </td>
                            <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900 items-center font-medium teckpert-text-green">
                                {obj.Type }
                            </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div
                            onClick={() =>
                              navigate("/emailtemplates/" + obj.ItemId)
                            }
                            className="flex items-center font-medium teckpert-text-green cursor-pointer"
                          >
                            {obj.Subject ? obj.Subject : "-"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                          {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                        </td>
                        <td className="pl-6 py-4 text-sm text-gray-900">
                          {obj?.FromUser
                            ? `${obj?.FromUser?.FirstName} ${obj?.FromUser?.LastName} <${obj?.FromUser?.Email}>`
                            : "-"}
                        </td>
                        <td className="w-[100px] cursor-pointer whitespace-nowrap py-2 text-sm">
                          <button
                            onClick={async () => {
                              let temp = obj;
                              temp.Subject = `${temp.Subject} (2)`;
                              const Body = JSON.stringify(temp?.Body);
                              await API.graphql(
                                graphqlOperation(`mutation {
                                insert_EmailTemplates_one(
                                  object: 
                                    {
                                      ItemId: "${uuidv4()}",    
                                      DateCreated: "${temp.DateCreated}",
                                      DateUpdated: "${temp.DateUpdated}",
                                      From: "${temp.From}",
                                      To: "${temp.To}",
                                      Subject: "${temp.Subject}",
                                      Body: ${Body},           
                                      Type: "${temp.Type}",
                                      Name: "${temp.Name}",
                                    })
                                    {
                                      ItemId
                                      DateCreated
                                      DateUpdated
                                      From
                                      To
                                      Subject
                                      Body
                                      Type
                                      Name
                                      FromUser {
                                        FirstName
                                        LastName
                                        Email
                                      }
                                    }         
                                  }`)
                              ).then(
                                ({
                                  data: { insert_EmailTemplates_one: newRow },
                                }) => {
                                  setData([...data, newRow]);
                                }
                              );
                            }}
                            type="button"
                            className="mr-8 mt-2 items-center justify-center rounded-md border border-transparent teckpert-bg-green 
                            px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto hover:border-white"
                          >
                            Clone
                            <DocumentDuplicateIcon
                              className="h-6 w-6 inline cursor-pointer mx-3"
                              aria-hidden="true"
                            />
                          </button>
                        </td>
                        <td className="w-[60px] py-4 text-sm">
                          <PencilIcon
                            onClick={() =>
                              navigate("/emailtemplates/" + obj.ItemId)
                            }
                            className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                            aria-hidden="true"
                          />
                        </td>
                            <td className="w-[60px] py-4 text-sm">
                                {obj.Type !== "System Templates" && (
                                    <XCircleIcon
                                        value={obj.ItemId}
                                        onClick={() => {
                                            setSelectedRow(obj);
                                            setDeleteModalOpen(true);
                                        }}
                                        className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                                        aria-hidden="true"
                                    />
                                )}
                          <ConfirmDeleteModal
                            open={deleteModalOpen}
                            setOpen={setDeleteModalOpen}
                            deleteItem={async () => {
                              if (selectedRow.ItemId) {
                                await API.graphql(
                                  graphqlOperation(`
                                  mutation {
                                    delete_EmailTemplates_by_pk(ItemId: "${selectedRow.ItemId}") {
                                      ItemId
                                      Subject
                                    }
                                  }`)
                                );
                                setData(
                                  data.filter(
                                    ({ ItemId }) =>
                                      ItemId !== selectedRow.ItemId
                                  )
                                );
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
