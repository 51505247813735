import logo from "../../img/teckpert-logo.png";
import { useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const userType = 'member';
  const ItemId = uuidv4();
  const UserAccessId = uuidv4();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    
  
    const handleSignup = async (e) => {
        setError("");
        e.preventDefault();

        try {
            // Error handling for blank email or password
            if (email.trim().length === 0 || password.trim().length === 0) {
                setError("Email and password cannot be blank");
                return;
            }

            // Signup user
            const signUpResult = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    'custom:firstName': firstName,
                    'custom:lastName': lastName,
                    'custom:itemId': ItemId,
                    'custom:userType': userType,
                    'custom:accessId': UserAccessId,
                    
                }
            });
            console.log("Signup result new", signUpResult.userSub);
            // call api to insert a data into database
            // insert a data into a table using this data
            if (signUpResult.userSub)
            {
                await API.post("public", "/createMember",
                    {
                        body: {
                            username: email,
                        },
                    });
               
            }
             
       // Navigate to the login page
            navigate("/login?confirm");
        } catch (error) {
            console.log("Error during signup or data insertion:", error);
            setError("Email or password is incorrect ");
        }
    };

  return (
    <div className="h-screen">
      <div className="p-3 bg-zinc-700 h-1/5 max-h-24 w-full relative top-0">
        <img src={logo} className="mx-auto h-full py-3" />
      </div>
      <div className="h-[73%]">
        <div>
          {/* Login Header */}
          <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-5/6">
            <div>
              <h2 className="mt-1 text-center text-3xl tracking-tight font-bold teckpert-text-green">
                Sign up now!
              </h2>
            </div>

            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                      onFocus={() => setError("")}
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        id="firstName"
                        name="firstName"
                        type="text"
                        autoComplete="firstName"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                      onFocus={() => setError("")}
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        id="lastName"
                        name="lastName"
                        type="text"
                        autoComplete="lastName"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                      onFocus={() => setError("")}
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        autoComplete="current-confirm"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      onClick={(e) => handleSignup(e)}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white teckpert-bg-green focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      Sign up
                    </button>
                  </div>
                  {error.length > 0 && (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            Your signup attempt has failed
                          </h3>
                          <div className="mt-2 text-sm text-red-700">
                            <div>{error}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 bg-zinc-700 h-1/6 max-h-60 w-full relative bottom-0">
        <div className="text-2xl text-neutral-100 text-center p-3">
          Need to speak with someone?
        </div>
        <div className="text-lg text-neutral-100 text-center teckpert-text-green">
          Get in touch with us
        </div>
      </div>
    </div>
  );
};

export default Signup;
