import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { XCircleIcon, PencilIcon } from "@heroicons/react/outline";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import UpdateResourceModal from "./UpdateResourceModal";

const Table = ({ tableData, setTableData, tempData, setTempData }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {["Name", "Type", "Link", "", ""].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tempData
                    ?.sort((a, b) => a.Name.localeCompare(b.Name))
                    .map((resource, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center font-medium teckpert-text-green">
                            {resource.Name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {resource.Type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {resource.Link}
                        </td>
                        <td className="flex-none py-4 text-sm">
                          <PencilIcon
                            onClick={() => {
                              setEditModalOpen(!editModalOpen);
                              setSelectedUser(resource);
                            }}
                            className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                            aria-hidden="true"
                          />
                          <UpdateResourceModal
                            modalOpen={editModalOpen}
                            setModalOpen={setEditModalOpen}
                            tempData={selectedUser}
                            setTempData={setTableData}
                          />
                        </td>
                        <td className="flex-none py-4 text-sm">
                          <XCircleIcon
                            onClick={() => {
                              setDeleteModalOpen(!deleteModalOpen);
                            }}
                            className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                            aria-hidden="true"
                          />
                          <ConfirmDeleteModal
                            open={deleteModalOpen}
                            setOpen={setDeleteModalOpen}
                            deleteItem={async () => {
                              let response = await API.graphql(
                                graphqlOperation(`
                                mutation {
                                    delete_Documents_by_pk(ItemId: "${resource.ItemId}") {
                                      ItemId
                                      Name
                                    }
                                  }
                              `)
                              );
                              if (response) {
                                setTempData(
                                  tempData.filter(
                                    (obj) => obj.ItemId !== resource.ItemId
                                  )
                                );
                              }
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
