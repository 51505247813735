import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
    XCircleIcon,
    LockClosedIcon,
    LockOpenIcon,
} from "@heroicons/react/outline";
import Autocomplete from "react-google-autocomplete";
import { API } from "aws-amplify";
import { formatPhone } from "../../../../utils/FormatPhone";

const EditProfileModal = ({
    modalOpen,
    setModalOpen,
    originalData,
    tempData,
    setTempData,
    onSave,
    inputField,
}) => {
    const [employmentStatusList, setEmploymentStatusList] = useState([]);
    const [locationPreferenceList, setLocationPreferenceList] = useState([]);
    const [emailLock, setEmailLock] = useState(false);

    useEffect(() => {
        API.graphql({
            query: `{
      Lookup {
        ItemId
        Name   
        Options
      }
    }      
  `,
        }).then(({ data }) => {
            setEmploymentStatusList(
                data.Lookup.filter((obj) => obj.Name === "CurrentEmploymentStatus")[0]
                    .Options
            );
            setLocationPreferenceList(
                data.Lookup.filter((obj) => obj.Name === "WorkLocationPreference")[0]
                    .Options
            );
        });
    }, []);

    return (
        <Transition.Root show={modalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setModalOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="lg:w-[600px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Update Profile Information
                                        </Dialog.Title>
                                    </div>
                                </div>

                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-red-500"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                {inputField?.map((obj, i) => (
                                    <div className="mt-7" key={i}>
                                        <label className="block text-sm font-medium text-gray-700">
                                            {obj == "PrimaryRole"
                                                ? "Primary Role"
                                                : obj == "ScreeningStatus"
                                                    ? "Screening Status"
                                                    : obj == "EmploymentStatus"
                                                        ? "Employment Status"
                                                        : obj == "WeeklyAvailability"
                                                            ? "Weekly Availability"
                                                            : obj == "LocationAvailability"
                                                                ? "Location"
                                                                : obj == "HourlyRate"
                                                                    ? "Hourly Rate"
                                                                    : obj}
                                        </label>
                                        {obj == "Location" ? (
                                            <div className="sm:col-span-2 flex-grow">
                                                <div className="mt-2">
                                                    <Autocomplete
                                                        className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white"
                                                        apiKey={"AIzaSyAVDbuhk4LVrRFHVjyB4Zk5bHs3QgWiX_Y"}
                                                        onPlaceSelected={(place) => {
                                                            let temp = tempData;
                                                            temp.Meta[obj] = place.formatted_address;
                                                            setTempData({ ...temp });
                                                        }}
                                                        defaultValue={tempData.Meta && tempData.Meta?.[obj]}
                                                    />
                                                </div>
                                            </div>
                                        ) : obj === "EmploymentStatus" ||
                                            obj === "WeeklyAvailability" ||
                                            obj === "LocationAvailability" ||
                                            obj === "ScreeningStatus" ? (
                                            <select
                                                className="max-h-60 w-full mr-3 rounded-full justify-center rounded-md 
                        border shadow-sm px-2.5 py-3 text-sm bg-white inline cursor-pointer
                        text-gray-900 hover:teckpert-bg-green hover:text-white"
                                                onChange={(e) => {
                                                    let temp = tempData;
                                                    temp.Meta[obj] = e.target.value;
                                                    setTempData({ ...temp });
                                                }}
                                                value={tempData.Meta && tempData.Meta?.[obj]}
                                            >
                                                {obj == "EmploymentStatus" && (
                                                    <option value="" className="text-gray-400">
                                                        Select Status
                                                    </option>
                                                )}
                                                {obj == "EmploymentStatus"
                                                    ? employmentStatusList?.map((obj, i) => (
                                                        <option key={i} value={obj}>
                                                            {obj}
                                                        </option>
                                                    ))
                                                    : obj == "LocationAvailability" && (
                                                        <option value="" className="text-gray-400">
                                                            Select Location Preference
                                                        </option>
                                                    )}
                                                {obj == "ScreeningStatus" && (
                                                    <>
                                                        <option value="" className="text-gray-400">
                                                            Select Screening Status
                                                        </option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Pending">Pending</option>
                                                    </>
                                                )}
                                                {obj == "LocationAvailability" ? (
                                                    locationPreferenceList?.map((obj, i) => (
                                                        <option key={i} value={obj}>
                                                            {obj}
                                                        </option>
                                                    ))
                                                ) : obj == "WeeklyAvailability" ? (
                                                    <>
                                                        <option value="" className="text-gray-400">
                                                            Select Availability
                                                        </option>
                                                        <option value="15">15 hours</option>
                                                        <option value="20">20 hours</option>
                                                        <option value="25">25 hours</option>
                                                        <option value="30">30 hours</option>
                                                        <option value="35">35 hours</option>
                                                        <option value="40">40 hours</option>
                                                    </>
                                                ) : null}
                                            </select>
                                        ) : (
                                            <div className="flex">
                                                {obj == "HourlyRate" ? (
                                                    <span className="inline absolute px-2 mt-2.5">$</span>
                                                ) : null}
                                                {obj === "Mobile" ? (
                                                    <input
                                                        name={formatPhone(tempData.Meta && tempData.Meta?.[obj])}
                                                        onChange={(e) => {
                                                            let temp = tempData;
                                                            temp.Meta[obj] = e.target.value
                                                                .replace(/\D+/g, "")
                                                                .slice(0, 10);
                                                            setTempData({ ...temp });
                                                        }}
                                                        value={formatPhone(tempData.Meta && tempData.Meta?.[obj])}
                                                        className="w-full pl-5 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                            border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                                                    />
                                                ) : (
                                                    <input
                                                        name={tempData.Meta && tempData.Meta?.[obj]}
                                                        onChange={(e) => {
                                                            let temp = tempData;
                                                            if (obj === "Email") {
                                                                if (emailLock) temp.Meta[obj] = e.target.value;
                                                                else return;
                                                            } else if (obj == "PrimaryRole") {
                                                                if (temp.Meta[obj])
                                                                    if (temp.Meta[obj].label)
                                                                        temp.Meta[obj].label = e.target.value;
                                                                    else
                                                                        temp.Meta[obj] = {
                                                                            ...temp.Meta[obj],
                                                                            label: e.target.value,
                                                                        };
                                                                else {
                                                                    temp.Meta = {
                                                                        ...temp.Meta,
                                                                        PrimaryRole: { label: e.target.value },
                                                                    };
                                                                }
                                                            } else temp.Meta[obj] = e.target.value;
                                                            console.log("temp", temp);
                                                            setTempData({ ...temp });
                                                        }}
                                                        value={
                                                            obj !== "PrimaryRole"
                                                                ? tempData.Meta?.[obj]
                                                                : tempData.Meta[obj]?.label
                                                        }
                                                        className="w-full pl-5 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                            border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                                                    />
                                                )}
                                                {obj === "Email" ? (
                                                    !emailLock ? (
                                                        <LockClosedIcon
                                                            onClick={() => setEmailLock(true)}
                                                            className="mt-2 h-8 w-6 cursor-pointer hover:teckpert-text-green"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <LockOpenIcon
                                                            onClick={() => setEmailLock(false)}
                                                            className="mt-2 h-8 w-6 cursor-pointer hover:teckpert-text-green"
                                                            aria-hidden="true"
                                                        />
                                                    )
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                ))}

                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={onSave}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            setTempData(originalData);
                                            setModalOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
export default EditProfileModal;
