/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, UserIcon } from "@heroicons/react/solid";
import { API, Storage } from "aws-amplify";
import { useLocation } from "react-router-dom";
import placeholderImg from "../../../../img/logo192.png";
import UpdateContactModal from "./UpdateContactModal";
import { formatPhone } from "../../../utils/FormatPhone";

const ClientList = ({
  contact,
  setContact,
  img,
  setImg,
  setModalOpenAddContact,
  setRefresh,
  setConfirmButtonMessage,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});
   
  return (
    <div>
      <UpdateContactModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={selectedContact}
        contact={contact}
        setContact={setContact}
        setRefresh={setRefresh}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
      {contact.length === 0 && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-6 opacity-40">
          <li className="col-span-1 flex flex-col divide-y divide-gray-300 rounded-lg bg-white text-center border-2 h-[300px] w-[200px]">
            <UserIcon
              className="h-10 w-10 text-gray-300 m-auto"
              aria-hidden="true"
            />
            <button
              onClick={() => setModalOpenAddContact(true)}
              className="flex gap-2 p-3 text-gray-400 cursor-pointer text-sm font-bold"
              type="button"
            >
              <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              Create Contact
            </button>
          </li>
        </ul>
      )}
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-12">
        {contact.length > 0 &&
          contact?.map((person, i) => {
            return (
              <li
                key={person?.ItemId + i}
                className="col-span-1 flex flex-col divide-y divide-gray-300 rounded-lg bg-white text-center border-2"
              >
                <div className="flex flex-1 flex-col p-8">
                  <img
                    className="mx-auto h-20 w-20 flex-shrink-0 rounded-full"
                    src={
                      person.ProfilePictureUrl
                        ? person.ProfilePictureUrl
                        : "/logo512.png"
                    }
                    alt="contact pic"
                  />
                  <h3 className="mt-6 text-sm font-medium text-gray-900">
                    {person?.FirstName} {person?.LastName}
                  </h3>
                  <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <dd className="text-sm text-gray-500">{person?.Title}</dd>
                    <dd className="mt-3">
                      <span className="text-sm font-medium">Phone:</span>
                      <span className="px-2 text-sm">
                        {person?.Phone ? formatPhone(person.Phone) : "-"} - {person?.OfficePhoneExtension || "-"}
                      </span>
                    </dd>
                    <dd className="mt-3">
                      <span className="text-sm font-medium">Mobile:</span>
                      <span className="px-2 text-sm">
                        {person?.Mobile ? formatPhone(person.Mobile) : "-"}
                      </span>
                    </dd>
                    <dd className="mt-3">
                      <span className="text-sm font-medium">Fax:</span>
                      <span className="px-2 text-sm">
                        {person?.Fax ? formatPhone(person.Fax) : "-"}
                      </span>
                    </dd>
                    <dd className="mt-3">
                      <span className="text-sm font-medium">Email:</span>
                      <span className="px-2 text-sm sm:block sm:px-0 sm:mx-auto">
                        {person?.Email ? person.Email : "-"}
                      </span>
                    </dd>
                  </dl>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div
                      onClick={() => {
                        setSelectedContact(person);
                        setModalOpen(!modalOpen);
                      }}
                      className="-ml-px flex w-0 flex-1"
                    >
                      <a className="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3">Edit</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ClientList;
