import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { API } from "aws-amplify";
import MemberBio from "./MemberBio"; // Section 1
import Info from "./Info"; // Section 2
import Checklist from "./Checklist"; // Section 3
import AddOpportunitiesModal from "./Modals/AddOpportunitiesModal";

const Header = ({
  originalData,
  profileData,
  setProfileData,
  opportunitiesData,
  applicantData,
  setApplicantData,
  opportunitiesApplied,
  setConfirmButtonMessage,
  resumeUrl,
  setMsgModalOpen,
  msgModalOpen,
  applicantsData,
  setApplicantsData,
  checklists,
  selectedChecklist,
  setSelectedChecklist,
  checklistResponses,
  newResponses,
  setNewResponses,
  admins,
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [img, setImg] = useState("");
  const [lastContacted, setLastContacted] = useState("");
  const [defaultWorkFlow, setDefaultWorkFlow] = useState({});

  // GET PROFILE PICTURE
  useEffect(() => {
    const getStorageFile = async (key, redirect = false) => {
      const url = await Storage.get(key, {
        level: "public",
        expires: 60,
      });
      if (redirect === true && url !== "") {
        window.open(url, "_blank");
      }
      return url;
    };

    getStorageFile(profileData?.Meta?.ProfilePicture).then((url) => {
      setImg(url);
    });
  }, []);

  useEffect(() => {
    API.graphql({
      query: `{
        Workflows (where: {Default: {_eq: ${true}}}) {
          ItemId
          Title
          Stages
          Default
        }
    }      
  `,
    }).then(({ data }) => {
        console.log("data?.Workflows[0]", data?.Workflows?.[0]);
      setDefaultWorkFlow(data?.Workflows?.[0]);
    });
  }, []);

  // GET/SET MEMBER DATA
  useEffect(() => {
    const async = async () => {
      // Grab latest call logs
      let filteredCallLogs = [];
      if (profileData.CallLogs) filteredCallLogs = profileData.CallLogs[0];
      const latestLogDate = filteredCallLogs?.length
        ? new Date(filteredCallLogs[0]?.LogDate)
        : null;
      // Grab latest emails sent
      const {
        data: { Emails: filteredEmails },
      } = await API.graphql({
        query: `{
          Emails(where: {To: {_eq: "${profileData.ItemId}"}}, order_by: {DateCreated: desc}) {
          DateCreated
        }
      }
      `,
      });
      const latestEmailDate = filteredEmails.length
        ? new Date(filteredEmails[0]?.DateCreated)
        : null;
      const latestDate =
        latestLogDate > latestEmailDate ? latestLogDate : latestEmailDate;
        if (latestDate) setLastContacted(latestDate.toLocaleDateString('en-US', { timeZone: "utc" }));
    };
    async();
  }, []);

  return (
    <React.Fragment>
      <div className="grid grid-cols-8 bg-white">
        {/* Member Bio */}
        <MemberBio
          profileData={profileData}
          lastContacted={lastContacted}
          addModalOpen={addModalOpen}
          setAddModalOpen={setAddModalOpen}
          img={img}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        {/* Member Info */}
        <div className="col-span-4">
          <Info
            originalData={originalData}
            profileData={profileData}
            setProfileData={setProfileData}
            url={"/members"}
            opportunitiesData={opportunitiesData}
            opportunitiesApplied={opportunitiesApplied}
            applicantData={applicantData}
            setApplicantData={setApplicantData}
            setConfirmButtonMessage={setConfirmButtonMessage}
            resumeUrl={resumeUrl}
            msgModalOpen={msgModalOpen}
            setMsgModalOpen={setMsgModalOpen}
            admins={admins}
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            defaultWorkFlow={defaultWorkFlow}
          />
        </div>
        {/* Checklists */}
        <div className="col-span-2">
          <Checklist
            profileData={profileData}
            setProfileData={setProfileData}
            setConfirmButtonMessage={setConfirmButtonMessage}
            checklists={checklists}
            selectedChecklist={selectedChecklist}
            setSelectedChecklist={setSelectedChecklist}
            checklistResponses={checklistResponses}
            newResponses={newResponses}
            setNewResponses={setNewResponses}
          />
        </div>
      </div>
      {applicantData ? (
        <AddOpportunitiesModal
          opportunitiesData={opportunitiesData}
          profileData={profileData}
          applicantData={applicantData}
          setApplicantData={setApplicantData}
          setAdminsData={setApplicantData}
          addModalOpen={addModalOpen}
          setAddModalOpen={setAddModalOpen}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
      ) : null}
    </React.Fragment>
  );
};
export default Header;
