import { useEffect, useState } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import UpdateDescriptionModal from "../Modals/UpdateDescriptionModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Description = ({ tableData, setTableData, setConfirmButtonMessage }) => {
  const [showTab, setShowTab] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newDescription, setNewDescription] = useState(false);
  const [input, setInput] = useState("");
  // const [expand, setExpand] = useState(false);

  useEffect(() => {
    setInput(tableData.Description);
  }, [tableData.Description]);

  return (
    <div className="p-6 bg-white rounded-lg mt-4 border border-gray-200 shadow-md ">
      {/* Header Section */}
      <div className="flex">
        <div className="grow">
          <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">
            Description
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <div className="flex flex-none">
            <PencilIcon
              onClick={() => setOpenModal(!openModal)}
              className="h-5 w-5 cursor-pointer mr-3 text-gray-500"
              aria-hidden="true"
            />
            <div onClick={() => setShowTab(!showTab)}>
              {showTab ? (
                <ChevronUpIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Content Section */}
      <div className={`${showTab ? "block pb-3 px-4 my-4" : "hidden"}`}>
        <ReactQuill
          theme="bubble"
          value={input}
          style={{ marginTop: "1px", marginBottom: "2px" }}
          readOnly
        />
      </div>
      {openModal && (
        <UpdateDescriptionModal
          tableData={tableData}
          setTableData={setTableData}
          newDescription={newDescription}
          setNewDescription={setNewDescription}
          open={openModal}
          setOpen={setOpenModal}
          input={input}
          setInput={setInput}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
      )}
    </div>
  );
};
export default Description;
