export const formatPhone = (string) => {
    const number = string?.replace(/\D+/g, '')?.slice(0, 10)?.replace(/\D/g, '');
    if (!number || number?.length === 0) {
        return ``;
    }
    else if (number?.length < 4) {
        return `(${number.slice(0, 3)}`;
    }
    else if (number?.length < 7) {
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}`
    }
    else {
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`
    }
}