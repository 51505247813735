import React, { useEffect, useState, Fragment, useRef } from "react";
import Modal from "react-modal";
import { MenuIcon, XCircleIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";

Modal.setAppElement("#root");

export default function HideColumnsModal(props) {
  const [tempColumns1, setTempColumns1] = useState({
    people: [
      {
        name: "Role",
        hidden: false,
        order: 0,
      },
      {
        name: "Status",
        hidden: false,
        order: 1,
      },
      {
        name: "Availability",
        hidden: false,
        order: 2,
      },
      {
        name: "Location",
        hidden: false,
        order: 3,
      },
      {
        name: "Recent Education",
        hidden: false,
        order: 4,
      },
      {
        name: "Date Registered",
        hidden: false,
        order: 5,
      },
      {
        name: "Vetted",
        hidden: false,
        order: 6,
      },
      {
        name: "Links",
        hidden: false,
        order: 7,
      },
    ],
    opportunities: [
      {
        name: "Department",
        hidden: false,
        order: 0,
      },
      {
        name: "Type",
        hidden: false,
        order: 1,
      },
      {
        name: "Client",
        hidden: false,
        order: 2,
      },
      {
        name: "Hours",
        hidden: false,
        order: 3,
      },
      {
        name: "Compensation",
        hidden: false,
        order: 4,
      },
      {
        name: "Location",
        hidden: false,
        order: 5,
      },
      {
        name: "Recruiting Cut Off Date",
        hidden: false,
        order: 6,
      },
      {
        name: "Due Date",
        hidden: false,
        order: 7,
      },
      {
        name: "Posted On",
        hidden: false,
        order: 8,
      },
      {
        name: "Assignee",
        hidden: false,
        order: 9,
      },
      {
        name: "Support",
        hidden: false,
        order: 10,
      },
      {
        name: "Status",
        hidden: false,
        order: 11,
      },
      {
        name: "Total Applicants",
        hidden: false,
        order: 12,
      },
      {
        name: "New Applicants",
        hidden: false,
        order: 13,
      },
      {
        name: "Active Applicants",
        hidden: false,
        order: 14,
      },
    ],
  });

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    originalOrderList: [],
    updatedOrder: [],
    updatedOrderList: [],
  };
  const [tempColumns, setTempColumns] = useState({});
  const [list, setList] = useState([]);
  const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);
  const location = useLocation();

  useEffect(() => {
    let temp = [];
    if (tempColumns[location.pathname.split("/")[1]]) {
      tempColumns[location.pathname.split("/")[1]].map((obj, i) => {
        temp.push(obj.order);
      });
      setList(temp);
    }
  }, [tempColumns]);

  // onDragStart fires when an element
  // starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list,
    });

    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData("text/html", "");
  };

  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = (event) => {
    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;
    let newListNames = dragAndDrop.originalOrderList;

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedFromName =
      tempColumns[location.pathname.split("/")[1]][draggedFrom].name;

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position);
    const draggedToName =
      tempColumns[location.pathname.split("/")[1]][draggedTo].name;

    const itemDragged = newList[draggedFrom];
    // const itemDraggedName = newListNames[draggedFromName];

    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    const remainingItemsNames = newListNames.filter(
      (item, index) => index !== draggedFromName
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    newListNames = [
      ...remainingItemsNames.slice(0, draggedToName),
      itemDragged,
      ...remainingItemsNames.slice(draggedToName),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        updatedOrderList: newListNames,
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop = (event) => {
    let newTempList = tempColumns;

    setList(dragAndDrop.updatedOrder);
    newTempList[location.pathname.split("/")[1]] = newTempList[
      location.pathname.split("/")[1]
    ].map(
      (obj, i) =>
        newTempList[location.pathname.split("/")[1]][
          dragAndDrop.updatedOrder[i]
        ]
    );

    newTempList[location.pathname.split("/")[1]].map(
      (obj, i) => (obj.order = i)
    );

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });

    setTempColumns({ ...newTempList });
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  useEffect(() => {
    if (props.hiddenColumns) {
      setTempColumns(props.hiddenColumns);
    }
  }, [tempColumns]);

  if (!tempColumns[location.pathname.split("/")[1]]) return <div></div>;

  return (
    <Transition.Root show={props.columnsModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.setColumnsModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${
                  location.pathname === "/jobportals" ? "lg:w-[700px]" : "auto"
                } max-h-[800px] relative transform overflow-y-auto overflow-x-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl 
                transition-all sm:my-8 sm:p-6`}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => props.setColumnsModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Display Columns:
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-7">
                  {/* Disabled column */}
                  <div className="opacity-60 grid grid-cols-7 shadow-sm border border-solid border-[#f5f5f5] mb-1 py-3 px-5">
                    <div className="col-span-6">
                      <MenuIcon
                        className="h-5 w-5 inline mr-7 mb-1"
                        aria-hidden="true"
                      />
                      {location.pathname.includes("people")
                        ? "Name"
                        : "Job Title"}
                    </div>
                    <div className="flex">
                      <label className="inline-flex relative items-center mr-5">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={true}
                          readOnly
                        />
                        <div
                          className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                          peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                          after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                          after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
                        ></div>
                      </label>
                    </div>
                  </div>
                  {/* Columns */}
                  {props.hiddenColumns[location.pathname.split("/")[1]].map(
                    (obj, i) => {
                      return (
                        <div
                          key={obj.name}
                          data-position={i}
                          draggable
                          onDragStart={onDragStart}
                          onDragOver={onDragOver}
                          onDrop={onDrop}
                          onDragLeave={onDragLeave}
                          className={`${
                            dragAndDrop && dragAndDrop.draggedTo === Number(i)
                              ? "relative rounded text-gray-500 bg-white border border-dashed border-blue-800 align-center w-100 before:h-100"
                              : ""
                          } grid grid-cols-7 rounded cursor-pointer shadow-sm border border-solid border-[#f5f5f5] mb-1 py-3 px-5`}
                        >
                          <div className="col-span-6">
                            <MenuIcon
                              className="h-5 w-5 cursor-pointer inline mr-7 mb-1"
                              aria-hidden="true"
                            />
                            {obj.name}
                          </div>
                          <div className="flex">
                            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={
                                  !tempColumns[location.pathname.split("/")[1]][
                                    i
                                  ].hidden
                                }
                                readOnly
                              />
                              <div
                                onClick={() => {
                                  let temp = tempColumns;
                                  let tabName = location.pathname.split("/")[1];
                                  if (
                                    temp.hasOwnProperty(
                                      tabName + "-" + obj.name
                                    )
                                  )
                                    delete temp[
                                      location.pathname.split("/")[1]
                                    ][i];
                                  else
                                    temp[location.pathname.split("/")[1]][
                                      i
                                    ].hidden =
                                      !temp[location.pathname.split("/")[1]][i]
                                        .hidden;
                                  setTempColumns({ ...temp });
                                  console.log("tempColumns", tempColumns);
                                }}
                                className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                                after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
                              ></div>
                            </label>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="mt-6 justify-end flex ml-8">
                  {props.backButton ? (
                    <button
                      onClick={() => {
                        props.setColumnsModal(false);
                        props.openPrevModal(true);
                      }}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium 
                      text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        props.setColumnsModal(false);
                      }}
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium 
                      text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                  )}
                  <button
                    onClick={async () => {
                      await API.graphql(
                        graphqlOperation(`mutation {
                          update_Users_by_pk(pk_columns: {ItemId: "${
                            props.userData.ItemId
                          }"}, 
                            _set: 
                              {
                                HiddenColumns: {
                                  people: [${tempColumns["people"]
                                    .map(
                                      (obj) =>
                                        `{
                                      name: "${obj.name}",
                                      hidden: ${obj.hidden},
                                      order: ${obj.order},
                                    }`
                                    )
                                    .join(",")}
                                  ]
                                  opportunities: [${tempColumns["opportunities"]
                                    .map(
                                      (obj) =>
                                        `{
                                      name: "${obj.name}",
                                      hidden: ${obj.hidden},
                                      order: ${obj.order},
                                    }`
                                    )
                                    .join(",")}
                                  ]
                                }    
                              }
                            )       
                            {
                              ItemId
                              HiddenColumns
                            }            
                      }`)
                      ).then(({ data }) => {
                        props.setColumnsModal(false);
                        props.setHiddenColumns(tempColumns);
                      });
                    }}
                    className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                    font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                  >
                    Apply
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
