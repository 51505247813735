export async function FilterLogic(
  filterName,
  data,
  tempTable,
  setTempTable,
  selectedValue,
  filterSearch,
  setFilterSearch,
  isSelected
) {
  const today = new Date();
  const sevenDaysDate = new Date(new Date().setDate(today.getDate() - 7));
  const thirtyDaysDate = new Date(new Date().setDate(today.getDate() - 30));
  const sixtyDaysDate = new Date(new Date().setDate(today.getDate() - 60));

  let filteredResume = data.filter((person) => person.Meta?.Resume);
  let filteredLinkedIn = data.filter((person) => person.Meta?.LinkedIn);
  let filteredPortfolio = data.filter((person) => person.Meta?.Portfolio);
  let filteredPhoto = data.filter((person) => person.Meta?.ProfilePicture);
  let filteredShortList = data.filter((person) => person?.Starred);
  let filteredArray = [];

  //Opportunities' Basic Filters

  // if (filterName === "Open") {
  //   setTempTable(data.filter((person) => person.Status == "Open"));
  // } else if (filterName === "Closed") {
  //   setTempTable(data.filter((person) => person.Status == "Closed"));
  // } else if (filterName === "Draft") {
  //   setTempTable(data.filter((person) => person.Status == "Draft"));
  // }

  if (!filterSearch.includes(filterName)) {
    //People's Basic Filters
    if (filterName === "LinkedIn") {
      setTempTable(tempTable.filter((person) => person.Meta.LinkedIn));
      filteredArray.push(filteredLinkedIn);
    } else if (filterName === "Portfolio") {
      setTempTable(tempTable.filter((person) => person.Meta.Portfolio));
      filteredArray.push(filteredPortfolio);
    } else if (filterName === "Resume") {
      setTempTable(tempTable.filter((person) => person.Resume));
      filteredArray.push(filteredResume);
    } else if (filterName === "Photo") {
      setTempTable(tempTable.filter((person) => person.Meta.ProfilePicture));
      filteredArray.push(filteredPhoto);
    } else if (filterName === "ShortList") {
      setTempTable(tempTable.filter((person) => person.Starred));
      filteredArray.push(filteredShortList);
    }
    //Opportunities' Basic Filters
    if (filterName === "Open") {
      setTempTable(data.filter((person) => person.Status == "Open"));
    } else if (filterName === "Closed") {
      setTempTable(data.filter((person) => person.Status == "Closed"));
    } else if (filterName === "On Hold") {
      setTempTable(data.filter((person) => person.Status == "On Hold"));
    }
    //People's ComboBoxes
    if (filterName === "Status") {
      setTempTable(
        data.filter((person) => person.ScreeningStatus == selectedValue)
      );
    } else if (filterName === "Job Title") {
      setTempTable(data.filter((person) => person.Meta?.PrimaryRole?.label));
    } else if (filterName === "Location") {
      setTempTable(data.filter((person) => person.Meta?.Location));
    } else if (filterName === "Opportunity") {
      // setTempTable(data.filter((person) => person.Starred));
    }
    //People's Advanced Filters
    if (filterName === "Recruiter") {
      setTempTable(
        tempTable.filter((person) => person.RecruiterId == selectedValue)
      );
    } else if (filterName === "Employment Preference") {
      setTempTable(
        tempTable.filter(
          (person) => person.Meta.EmploymentStatus == selectedValue
        )
      );
    } else if (filterName === "TECKpert Employment") {
      setTempTable(
        tempTable.filter((person) => person.EmploymentType == selectedValue)
      );
    } else if (filterName === "Registered") {
      setTempTable(
        tempTable.filter(
          (person) =>
            (person.DateCreated === selectedValue) &
              (selectedValue === sevenDaysDate) ||
            (person.DateCreated === selectedValue) &
              (selectedValue === thirtyDaysDate) ||
            (person.DateCreated === selectedValue) &
              (selectedValue === sixtyDaysDate)
        )
      );
    } else if (filterName === "Has References") {
      setTempTable(tempTable.filter((person) => person.Meta.References));
    } else if (filterName === "Currently Onboarding") {
      setTempTable(tempTable.filter((person) => person.Onboarding !== 0));
    }
  } else if (filterSearch.includes(filterName)) {
    setFilterSearch(filterSearch.filter((search) => search !== isSelected));

    setTempTable(data);
  }
}
