import { useState } from "react";
import { PlusCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { formatDate } from "../../../../utils/FormatDate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UpdateNotes from "../Modals/UpdateNotesModal";
import CreateNoteModal from "../Modals/CreateNoteModal";

const Logs = ({
  profileData,
  setProfileData,
  url,
  setConfirmButtonMessage,
}) => {
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [selectedNote, setSelectedNote] = useState({});
  const [logDate, setLogDate] = useState(new Date());
  const [newLog, setNewLog] = useState({
    Creator: localStorage.getItem("name"),
    DateCreated: logDate,
    CallLog: "",
    ItemId: "",
  });

  return (
    <div className="flex">
      <div className=" rounded-lg w-full h-full shadow-md hover:bg-gray-100 mx-8">
        {/* Header Section */}
        <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg relative top-1 py-3">
          <div className="grow">
            <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
              Call Logs
            </h4>
          </div>
          <div className="flex flex-none mr-5">
            <PlusCircleIcon
              className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
              aria-hidden="true"
              onClick={() => setOpenModalAdd(!openModalAdd)}
            />
          </div>
        </div>

        {/* Table Section */}
        <div className="mx-5 flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div>
                <div className="p-5">
                  {Array.isArray(profileData?.CallLogs) &&
                  profileData?.CallLogs?.length > 0 ? (
                    profileData?.CallLogs?.map((log, i) =>
                      log.CallLog ? (
                        <div key={i} className="my-5 border-b">
                          <div className="flex justify-between pb-3">
                            <div>
                              <span className="text-lg font-bold teckpert-text-green mr-8">
                                {log.Creator}
                              </span>
                              <span className="text-gray-400">
                                {log.DateCreated
                                  ? formatDate(log.DateCreated)
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex">
                              <PencilIcon
                                className="h-5 w-5 mr-4 cursor-pointer mt-1 text-gray-300 hover:teckpert-text-green"
                                aria-hidden="true"
                                onClick={() => {
                                  setOpenModalEdit(!openModalEdit);
                                  setNoteId(log.ItemId);
                                  setSelectedNote(log);
                                }}
                              />
                            </div>
                          </div>
                          <ReactQuill
                            theme="bubble"
                            value={log.CallLog}
                            style={{ marginBottom: "2px" }}
                            readOnly
                          />
                        </div>
                      ) : null
                    )
                  ) : (
                    <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
                      No Logs Found
                    </div>
                  )}
                </div>
                <CreateNoteModal
                  profileData={profileData}
                  setProfileData={setProfileData}
                  url={url}
                  open={openModalAdd}
                  setOpen={setOpenModalAdd}
                  setConfirmButtonMessage={setConfirmButtonMessage}
                  newData={newLog}
                  setNewData={setNewLog}
                  title={"Log a Call"}
                  datePicker={true}
                  logDate={logDate}
                  setLogDate={setLogDate}
                />
                {profileData.CallLogs?.length > 0 && (
                  <UpdateNotes
                    open={openModalEdit}
                    setOpen={setOpenModalEdit}
                    profileData={profileData}
                    setProfileData={setProfileData}
                    noteId={noteId}
                    selectedNote={selectedNote}
                    setSelectedNote={setSelectedNote}
                    url={url}
                    setConfirmButtonMessage={setConfirmButtonMessage}
                    title={"Update Log"}
                    datePicker={true}
                    logDate={logDate}
                    setLogDate={setLogDate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logs;
