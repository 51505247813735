import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { API } from "aws-amplify";
import { Table } from "./Table";
import Loading from "../../utils/Loading";
import ApprovedModal from "./ApprovedModal";
import ErrorModal from "./ErrorModal";
import ApprovalTimesheetModal from "./ApprovalTimesheetModal";

export const ApproveTimesheets = () => {
    const [loading, setLoading] = useState(true);
    const [timesheets, setTimesheets] = useState([]);
    const [contact, setContact] = useState({});
    const [selectedTimesheets, setSelectedTimesheets] = useState([]);
    const [searchParams] = useSearchParams();
    const [approvedModal, setApprovedModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const approveDisabled = !selectedTimesheets.length;
    const timesheetIds = searchParams.get('timesheets');
    const contactId = searchParams.get('contact');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const async = async () => {
            setLoading(true);
            const response = await API.get('public', '/approveTimesheets', { queryStringParameters: { timesheets: timesheetIds, contact: contactId } });
            setTimesheets(response.Timesheets);
            setContact(response.Contacts_by_pk);
            setLoading(false);
        }
        async();
    }, [timesheetIds, contactId]);
   
    const approve = async (name) => {
        setLoading(true);
        try {
            await API.post('public', '/approveTimesheets', { body: { timesheets: selectedTimesheets.map(({ ItemId }) => ItemId), contact: contactId, approvedByName: name } });
            const response = await API.get('public', '/approveTimesheets', { queryStringParameters: { timesheets: timesheetIds, contact: contactId } });
            setTimesheets(response.Timesheets);
            setLoading(false);
            setApprovedModal(true);
            setSelectedTimesheets([]);
        }
        catch (err) {
            setLoading(false);
            setSelectedTimesheets([]);
            setErrorModal(true);
        }
    }
    const handleApprove = async () => {
        setIsModalOpen(true);
    };

  

    return (
        <div className="pt-2 px-10">
            <div className="text-center justify-center py-8 px-6 bg-[#2e2e2e] mb-4 rounded">
                <div className="justify-center">
                    <div className="mb-4"><img alt="TECKpert" src="/logo.png" width="240" height="60" className="rounded-full m-auto" /></div>
                    <div className="text-3xl font-bold text-white mb-2">{`Approve Timesheets`}</div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    {contact.FirstName ?
                        <>{`Hello `}<b>{`${contact.FirstName} ${contact.LastName}`}</b>{`, please approve the following timesheets.`}</>
                        : ''}
                </div>
                <div className="flex justify-end mx-4">
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
                        <button
                            
                            onClick={handleApprove}
                            type="button"
                            className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
                        >
                            Approve
                        </button>
                    </div>
                </div>
            </div>
            {timesheets.length > 0 ? <Table timesheets={timesheets} selectedTimesheets={selectedTimesheets} setSelectedTimesheets={setSelectedTimesheets} /> : null}
            {loading ? <Loading /> : null}
            <ApprovedModal setOpen={setApprovedModal} open={approvedModal} />
            <ErrorModal setOpen={setErrorModal} open={errorModal} />
            <ApprovalTimesheetModal onApprove={approve}
                                    isOpen={isModalOpen}
                                    onClose={() => setIsModalOpen(false)}
                                    contact={contact} />
            
        </div>
    );
};
