import { useState, useEffect } from "react";
import { formatDate } from "../../utils/FormatDate";
import { PencilIcon, XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import UpdateCourseModal from "./UpdateCourseModal";
import ComboBox from "../../utils/ComboBox";
import ConfirmDeleteModal from "../../utils/ConfirmDeleteModal";

const Table = ({
  tableData,
  setTableData,
  tempTableData,
  setTempTableData,
  searchVal,
  setSearchVal,
  page,
  setPage,
  itemsPerPage,
  memberTotal,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selected, setSelected] = useState([]);
  const [comboBoxLabel, setComboBoxLabel] = useState({
    category: { name: "Category", dataStatus: "hasCategory", data: [] },
  });

  useEffect(() => {
    let tempComboBoxLabel = comboBoxLabel;
    comboBoxLabel["category"].data = tableData
      .map((cat, i) => cat.Category)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    setComboBoxLabel(tempComboBoxLabel);
  }, [tableData]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Dropdown button filter*/}
      <div className="flex">
        {Object.keys(comboBoxLabel)?.map((obj, i) => (
          <div className="basis-5/7 flex flex-wrap sm:justify-center" key={i}>
            <div className="mr-2 w-full">
              <div className="flex mr-2">
                <div className="relative top-3 w-full">
                  <ComboBox
                    comboBoxLabel={comboBoxLabel[obj].name}
                    data={comboBoxLabel[obj].data}
                    tableData={tableData}
                    tempTableData={tempTableData}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Title",
                      "Category",
                      "Institution",
                      "Length",
                      "Start Date",
                      "Cost",
                      "Verified Cost",
                      "",
                      "",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData
                    ?.sort((a, b) => a.Title.localeCompare(b.Title))
                    ?.map((course) => {
                      return (
                        <tr key={course.ItemId}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center font-medium teckpert-text-green">
                              <a href={course.Link} target="_blank">
                                {course.Title}
                              </a>
                            </div>
                          </td>
                          <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                            {course.Category ? course.Category : "-"}
                          </td>
                          <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                            {course.Institution ? course.Institution : "-"}
                          </td>
                          <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                            {course.Length ? course.Length : "-"}
                          </td>
                          <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                            {course.StartDate
                              ? formatDate(course.StartDate)
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                            {course.Cost ? "$" + course.Cost : "-"}
                          </td>
                          <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                            {course.VerifiedCost
                              ? "$" + course.VerifiedCost
                              : "-"}
                          </td>
                          <td className="w-[50px] py-4 text-sm text-gray-900">
                            <PencilIcon
                              onClick={() => {
                                setSelected(course);
                                setModalOpen(!modalOpen);
                                setSelectedId(course.ItemId);
                              }}
                              className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                              aria-hidden="true"
                            />
                            {selected && (
                              <UpdateCourseModal
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                                tableData={tableData}
                                setTableData={setTableData}
                                tempData={selected}
                                setTempData={setSelected}
                              />
                            )}
                          </td>
                          <td className="flex-none w-[60px] pr-2 py-4 text-sm">
                            <XCircleIcon
                              onClick={() => {
                                setSelectedId(course.ItemId);
                                setDeleteModalOpen(!deleteModalOpen);
                              }}
                              className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                              aria-hidden="true"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <ConfirmDeleteModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                deleteItem={async () => {
                  if (selectedId) {
                    let response = await API.graphql(
                      graphqlOperation(`
                      mutation {
                        delete_Courses_by_pk(ItemId: "${selectedId}") {
                          ItemId
                          Title
                        }
                    }
                  `)
                    );
                    if (response) {
                      setTableData(
                        tableData.filter((item) => item.ItemId !== selectedId)
                      );
                    }
                  }
                }}
              />
            </div>

            {tableData.length > 99 ? (
              <div className="flex justify-center mt-5 pb-8">
                <nav aria-label="Page navigation">
                  <ul className="flex list-style-none">
                    <li
                      className="page-item"
                      //decrement pagination by one when clicking arrow
                      onClick={() => {
                        if (page === 1) return;
                        setPage(page - 1);
                      }}
                    >
                      <a
                        className="cursor-pointer page-link relative block py-1.5 px-3 rounded-full border-0 
                        bg-transparent outline-none transition-all duration-300 rounded 
                        text-gray-800 hover:text-gray-800 focus:shadow-none"
                        aria-label="Previous"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    {memberTotal &&
                      itemsPerPage &&
                      [...Array(Math.ceil(memberTotal / itemsPerPage))]?.map(
                        (_, tempPage) => {
                          //only displays pagination within a certain range
                          if (page - tempPage + 1 >= 0 && page < tempPage + 6)
                            return (
                              <li
                                className="page-item"
                                onClick={() => setPage(page + 1)}
                                key={tempPage + 1}
                              >
                                <a
                                  className={`rounded page-link relative block py-1.5 px-3
                                  bg-transparent outline-none transition-all duration-300 rounded 
                                  text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${
                                    tempPage + 1 === page &&
                                    "font-bold border-solid border-2 border-[#6FBE47]"
                                  }`}
                                  href="#"
                                >
                                  {tempPage + 1}
                                </a>
                              </li>
                            );
                        }
                      )}
                    <li
                      className="page-item"
                      onClick={() => {
                        //increment pagination by one when clicking arrow
                        if (page === Math.ceil(memberTotal / itemsPerPage))
                          return;
                        setPage(page + 1);
                      }}
                    >
                      <a
                        className="cursor-pointer page-link relative block py-1.5 px-3 rounded-full border-0 
                        bg-transparent outline-none transition-all duration-300 rounded 
                        text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                        aria-label="Next"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : (
              <div className="h-20"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
