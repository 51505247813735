import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";

export const QuickStats = () => {
  const [stats, setStats] = useState({
    "Total People": "-",
    "People on Contract": "-",
    "People Vetted": "-",
  });
  useEffect(() => {
    const async = async () => {
      const response = await API.get("auth", "/quickstats");
      setStats(response);
    };
    async();
  }, []);

  return (
    <div>
      <div className="mx-20">
        <dl className="mt-0 grid grid-cols-1 gap-5 sm:grid-cols-3 text-center">
          {Object.entries(stats).map(([key, value]) => (
            <div
              key={key}
              className="px-4 py-0 rounded-lg overflow-hidden sm:p-6"
            >
              <dd className="mt-1 text-4xl tracking-tight font-semibold text-[#6FBE47] mb-3">
                {value}
              </dd>
              <dt className="text-lg font-medium text-gray-900 truncate">
                {key}
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default QuickStats;
