import { MinusCircleIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import HideColumnsModal from "../../utils/HideColumnsModal";
import { formatDate } from "../../utils/FormatDate";

const smFilterButtons = [
  { name: "Open", dataStatus: "isOpen" },
  { name: "Closed", dataStatus: "isClosed" },
  { name: "On Hold", dataStatus: "onHold" },
];

const Table = (props) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isSelected, setIsSelected] = useState("");

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [columnsModal, setColumnsModal] = useState(false);

  const [rows, setRows] = useState([]);
  const [dragOver, setDragOver] = useState("");
  const [cols, setCols] = useState([
    "Client",
    "Hours",
    "Compensation",
    "Department",
    "Location",
    "Recruiting Cut Off Date",
    "Due Date",
    "Posted On",
    "Assignee",
    "Support",
    "Type",
    "Status",
    "Total Applicants",
    "New Applicants",
    "Active Applicants",
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  // HANDLE HIDDEN COLUMNS OBJECT
  useEffect(() => {
    if (props?.userData?.HiddenColumns)
      setHiddenColumns(props?.userData.HiddenColumns);
  }, [props.userData?.HiddenColumns]);

  // SET TABLE DATA ROWS
  useEffect(() => {
    setRows(props?.tempTable);
  }, [props?.tempTable]);

  // SELECT ALL CHECKBOXES
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(props?.tableData.map((person) => person.ItemId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // SELECT SINGLE CHECKBOX
  const handleClick = (e) => {
    const { name, checked } = e.target;
    setIsCheck([...isCheck, name]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== name));
    }
  };

  return (
    <div className="px-4 sm:px-7 lg:px-0">
      {!location.pathname.includes("/people/") &&
      !location.pathname.includes("/opportunities/") ? (
        <div className="mx-7 mb-2 sm:flex sm:overflow-x-auto sm:items-center justify-between">
          {/* Small button filter list */}
          <div className="flex">
            {smFilterButtons?.map((obj, i) => (
              <div className="lg:basis-1/7 flex flex-wrap mr-2" key={i}>
                <div className="lg:mr-2 md:mr-2">
                  <button
                    key={i}
                    value={obj?.name}
                    onClick={async (e) => {
                      let temp = props.userData;
                      if (
                        obj.name === "Open" ||
                        obj.name === "Closed" ||
                        obj.name === "On Hold"
                      ) {
                        let tempSearch = props?.filterSearch.includes(obj.name)
                          ? props?.filterSearch.filter(
                              (key) => key !== obj.name
                            )
                          : [...props?.filterSearch, obj.name];

                        props?.setFilterSearch(tempSearch);
                        setIsSelected(e.target.value);
                        temp.TableFilters.opportunities = tempSearch;

                        await API.graphql(
                          graphqlOperation(`mutation {
                          update_Users_by_pk(pk_columns: {ItemId: "${
                            props.userData.ItemId
                          }"}, 
                            _set: 
                              {
                                TableFilters: {
                                  people: [${props.userData.TableFilters.people
                                    .map((obj) => `"${obj}"`)
                                    .join(",")}],
                                  opportunities: [${temp.TableFilters.opportunities
                                    .map((obj) => `"${obj}"`)
                                    .join(",")}],
                                  contracts: [${props.userData.TableFilters.contracts
                                    .map((obj) => `"${obj}"`)
                                    .join(",")}]
                                }    
                              }
                            )       
                            {
                              ItemId
                              TableFilters
                            }            
                      }`)
                        ).then(({ data }) => {
                          console.log("data", data);
                        });
                      }
                    }}
                    className={`${
                      props?.filterSearch?.includes(obj.name)
                        ? "teckpert-bg-green text-white"
                        : "bg-white text-gray-900"
                    } w-[80px] mr-3 rounded-full justify-center rounded-md 
                    border shadow-sm px-2.5 py-3 text-sm
                    hover:border-[#6fbe47] hover:border-solid`}
                    data-status={obj.dataStatus}
                  >
                    {obj.name}
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div>
            {[
              { title: "Location", key: "Location" },
              { title: "Recruiter", key: "Recruiter" },
              { title: "Client", key: "Client" },
            ].map((obj) => (
              <select
                onChange={(e) => {
                  console.log(obj.key, e.target.value);
                  props?.setDropdownSearch({
                    ...props.dropdownSearch,
                    [obj.key]: e.target.value,
                  });
                }}
                className={`${
                  props?.dropdownSearch[obj.key]?.includes(obj.name)
                    ? "teckpert-bg-green text-white"
                    : "bg-white text-gray-900"
                } ${
                  !obj.title ? "border-[#6fbe47] border-solid" : ""
                } w-[130px] mr-3 rounded-full justify-center rounded-md 
                  border shadow-sm px-2.5 py-3 text-sm
                  hover:border-[#6fbe47] hover:border-solid cursor-pointer`}
              >
                <option value="">{obj.title}</option>
                {obj.title == "Recruiter"
                  ? props?.comboBoxLabel[obj.key]?.data?.map((obj, i) => (
                      <option value={obj.id}>{obj.name}</option>
                    ))
                  : props?.comboBoxLabel[obj.key]?.data?.map((obj) => (
                      <option value={obj}>{obj}</option>
                    ))}
              </select>
            ))}
          </div>

          <div className="basis-1/7">
            <button
              onClick={() => setColumnsModal(true)}
              className="w-[100px] mr-3 rounded-full justify-center rounded-md border shadow-sm px-2.5 py-3 text-sm bg-white
              text-gray-900 hover:border-[#6fbe47] hover:border-solid"
            >
              ||| &nbsp;Columns
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="mx-5 flex flex-col">
        {props.tempTable.length > 0 ? (
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto">
                <table className="min-w-[98%] divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        className={`${
                          location.pathname == "/opportunities" ? "" : "hidden"
                        } px-3 py-3.5 text-left text-sm font-semibold text-gray-900`}
                      >
                        <div className={`form-check ml-5`}>
                          <input
                            id="bordered-checkbox"
                            type="checkbox"
                            name="bordered-checkbox"
                            className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          ></input>
                        </div>
                      </th>
                      <th
                        className={`${
                          location.pathname == "/opportunities" ? "" : "hidden"
                        } px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[30px]`}
                      ></th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Job Title
                      </th>
                      {location.pathname == "/opportunities" &&
                        cols
                          .sort(
                            (a, b) =>
                              hiddenColumns?.opportunities?.findIndex(
                                (x) => x.name === a
                              ) -
                              hiddenColumns?.opportunities?.findIndex(
                                (x) => x.name === b
                              )
                          )
                          .map((col) => {
                            let index = hiddenColumns?.opportunities?.findIndex(
                              (x) => x.name === col
                            );
                            return (
                              <th
                                className={`${
                                  hiddenColumns?.opportunities &&
                                  hiddenColumns?.opportunities[index]?.hidden
                                    ? "hidden"
                                    : "default"
                                } px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer`}
                                id={col}
                                key={col}
                                draggable
                                dragOver={col === dragOver}
                              >
                                {col}
                              </th>
                            );
                          })}
                      {location.pathname !== "/opportunities" &&
                        [
                          "Candidate Status (workflow)",
                          "Location",
                          "Client",
                          "Recruiter",
                          "Status",
                          "Due Date",
                        ].map((header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody className={`divide-y divide-gray-200 bg-white`}>
                    {location.pathname === "/opportunities" &&
                      rows.map((row, i) => {
                        let index = props.users?.findIndex(
                          (x) => x.ItemId === row.Support
                        );
                        let tempAssignee = props.users?.findIndex(
                          (x) => x.ItemId === row.Assignee
                        );
                        return (
                          <tr key={row.ItemId}>
                            <td>
                              <div className="form-check mr-6">
                                <input
                                  key={i}
                                  type="checkbox"
                                  name={row.ItemId}
                                  id={i}
                                  onChange={handleClick}
                                  className="ml-8 w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                                  checked={isCheck.includes(row.ItemId)}
                                />
                              </div>
                            </td>
                            <td className="text-gray-400 w-[30px]">
                              <MinusCircleIcon
                                className="bg-gray-400 rounded-full h-3 w-3"
                                aria-hidden="true"
                              />
                            </td>
                            <td
                              className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6`}
                            >
                              <div className="flex">
                                <div
                                  className="font-medium teckpert-text-green cursor-pointer"
                                  onClick={() =>
                                    navigate("/opportunities/" + row.ItemId)
                                  }
                                >
                                  {row.Title}
                                </div>
                              </div>
                            </td>
                            {Object.entries(row).map(([k, v], idx) =>
                              cols.includes(cols[idx]) ? (
                                <td
                                  className={`${
                                    hiddenColumns?.opportunities &&
                                    hiddenColumns?.opportunities[
                                      hiddenColumns?.opportunities?.findIndex(
                                        (x) => x.name === cols[idx]
                                      )
                                    ]?.hidden
                                      ? "hidden"
                                      : "default"
                                  } ${
                                    cols[idx].includes("Date") ||
                                    cols[idx] === "Type" ||
                                    cols[idx] === "Posted On" ||
                                    cols[idx] === "Location"
                                      ? "whitespace-nowrap"
                                      : ""
                                  } min-w-[80px] px-3 py-4 text-sm text-gray-900`}
                                >
                                  {cols[idx] === "Job Title"
                                    ? row.Title
                                    : cols[idx] === "Recruiting Cut Off Date"
                                    ? row.CutOffDate
                                      ? formatDate(row.CutOffDate)
                                      : "-"
                                    : cols[idx] === "Due Date"
                                    ? row.DueDate
                                      ? formatDate(row.DueDate)
                                      : "-"
                                    : cols[idx] === "Posted On"
                                    ? row.DateCreated
                                      ? formatDate(row.DateCreated)
                                      : "-"
                                    : cols[idx] === "Compensation"
                                    ? row.Compensation &
                                      (row.Compensation != "null")
                                      ? row.Compensation
                                      : "-"
                                    : cols[idx] === "Total Applicants"
                                    ? row.TotalApplicants_aggregate?.aggregate
                                        ?.count || 0
                                    : cols[idx] === "New Applicants"
                                    ? row.NewApplicants_aggregate?.aggregate
                                        ?.count || 0
                                    : cols[idx] === "Active Applicants"
                                    ? row.ActiveApplicants_aggregate?.aggregate
                                        ?.count || 0
                                    : cols[idx] === "Client"
                                    ? row.Client
                                      ? row.Client.Name
                                      : "-"
                                    : cols[idx] === "Support"
                                    ? props.users[index]
                                      ? props.users[index].FirstName +
                                        " " +
                                        props.users[index].LastName
                                      : "-"
                                    : cols[idx] === "Assignee"
                                    ? props.users[tempAssignee]
                                      ? props.users[tempAssignee].FirstName +
                                        " " +
                                        props.users[tempAssignee].LastName
                                      : "-"
                                    : row[cols[idx]]}
                                </td>
                              ) : null
                            )}
                          </tr>
                        );
                      })}
                    {location.pathname !== "/opportunities" &&
                      props.tempTable?.map((opportunity) => {
                        return (
                          <tr key={opportunity.ItemId}>
                            <td
                              onClick={() =>
                                navigate("/opportunities/" + opportunity.ItemId)
                              }
                              className="cursor-pointer whitespace-nowrap font-bold px-3 py-4 text-sm teckpert-text-green"
                            >
                              {opportunity.Title ? opportunity.Title : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {opportunity.WorkFlowStatus
                                ? opportunity.WorkFlowStatus
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {opportunity.Location
                                ? opportunity.Location
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {opportunity.Client
                                ? opportunity.Client.Name
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {opportunity.Users?.FirstName &&
                                opportunity.Users.FirstName +
                                  " " +
                                  opportunity.Users.LastName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {opportunity.Status ? opportunity.Status : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {opportunity.DueDate
                                ? formatDate(opportunity.DueDate)
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
            No Opportunities Found
          </div>
        )}
      </div>
      {/* Pagination */}
      {props.tableData?.length > 25 ? (
        <div className="flex justify-center mt-5 pb-8">
          <nav aria-label="Page navigation">
            <ul className="flex list-style-none">
              <li
                className="page-item"
                //decrement pagination by one when clicking arrow
                onClick={() => {
                  if (props?.page === 1) return;
                  props?.setPage(props?.page - 1);
                }}
              >
                <a
                  className="page-link relative block py-1.5 px-3 rounded-full border-0 bg-transparent outline-none 
                  transition-all duration-300 rounded text-gray-800 hover:text-gray-800 focus:shadow-none"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {props?.memberTotal &&
                props?.itemsPerPage &&
                [
                  ...Array(Math.ceil(props?.memberTotal / props?.itemsPerPage)),
                ]?.map((_, page) => {
                  //only displays pagination within a certain range
                  if (props?.page - page + 1 >= 0 && props?.page < page + 6)
                    return (
                      <li
                        className="page-item"
                        onClick={() => props?.setPage(page + 1)}
                        key={page + 1}
                      >
                        <a
                          className={`rounded page-link relative block py-1.5 px-3 bg-transparent outline-none transition-all 
                        duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${
                          page + 1 === props?.page &&
                          "font-bold border-solid border-2 border-[#6FBE47]"
                        }`}
                          href="#"
                        >
                          {page + 1}
                        </a>
                      </li>
                    );
                })}
              <li
                className="page-item"
                onClick={() => {
                  //increment pagination by one when clicking arrow
                  if (
                    props?.page ===
                    Math.ceil(props?.memberTotal / props?.itemsPerPage)
                  )
                    return;
                  props?.setPage(props?.page + 1);
                }}
              >
                <a
                  className="page-link relative block py-1.5 px-3 rounded-full border-0 bg-transparent outline-none 
                  transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <div className="h-20"></div>
      )}
      {!location.pathname.includes("/people/") ? (
        hiddenColumns.opportunities && (
          <HideColumnsModal
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
            setColumnsModal={setColumnsModal}
            columnsModal={columnsModal}
            userData={props.userData}
            setUserData={props.setUserData}
          />
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Table;
