import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { XCircleIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import MissingFieldPrompt from "../../utils/MissingFieldPrompt";
import { formatDate } from "../../utils/FormatDate";
import { Editor } from "@tinymce/tinymce-react";

const CreateCourseModal = ({
  modalOpen,
  setModalOpen,
  tableData,
  setTableData,
}) => {
  const [tempData, setTempData] = useState({
    Title: "",
    Description: "",
    Category: "",
    Institution: "",
    Length: "",
    StartDate: new Date().toISOString(),
    Cost: "",
    VerifiedCost: "",
    HoursPerWeek: "",
    Link: "",
    Track: "",
  });
  const [error, setError] = useState([]);
  const [initialValue, setInitialValue] = useState("");

    const onSave = async () => {
        let errorArray = [];
        if (tempData.Title?.trim().length === 0) errorArray.push("Title");
        if (tempData.Description?.trim().length === 0)
            errorArray.push("Description");
        if (tempData.Category?.trim().length === 0) errorArray.push("Category");
        if (tempData.Institution?.trim().length === 0)
            errorArray.push("Institution");
        if (tempData.Length?.trim().length === 0) errorArray.push("Length");
        if (tempData.Cost?.trim().length === 0) errorArray.push("Cost");
        if (tempData.VerifiedCost?.trim().length === 0)
            errorArray.push("Verified Cost");
        if (tempData.HoursPerWeek?.trim().length === 0)
            errorArray.push("Hours Per Week");

        setError(errorArray);

        if (errorArray.length > 0) {
            return;
        }

        let temp = tempData;
        temp.StartDate = formatDate(temp.StartDate);       
        await API.graphql(
            graphqlOperation(`mutation {
        insert_Courses_one(
          object: 
            {
              ItemId: "${uuidv4()}",
              Title: "${tempData.Title}",
              Description: ${JSON.stringify(tempData?.Description)},
              Category: "${tempData.Category}",
              Institution: "${tempData.Institution}",
              Length: "${tempData.Length}",
              StartDate: "${temp.StartDate}",
              Cost: "${tempData.Cost}",
              VerifiedCost: "${tempData.VerifiedCost}",
              HoursPerWeek: "${tempData.HoursPerWeek}",
              Link: "${tempData.Link}",
              Track: "${tempData.Track}",
              
            }
          )       
          {
            ItemId
            Title
          }            
    }`)
        ).then(({ data }) => {
            setModalOpen(false);
            setTableData([...tableData, tempData]);
        });
    };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setModalOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[85%] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setModalOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start mb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Course
                    </Dialog.Title>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Course Title
                        </label>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Title = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Title}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Category
                        </label>
                        <select
                          className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                          border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                          text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Category = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Category}
                        >
                          <option className="text-gray-300" value="">
                            Select Category
                          </option>
                          <option value="Computer Science">
                            Computer Science
                          </option>
                          <option value="Marketing">Marketing</option>
                          <option value="Data Analysis">Data Analysis</option>
                          <option value="Math">Math</option>
                          <option value="Math">IT & Cloud</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Track
                        </label>
                        <select
                          className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                          border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                          text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Track = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Track}
                        >
                          <option className="text-gray-300" value="">
                            Select Track
                          </option>
                          <option value="Web">Web</option>
                          <option value="Data Analysis">Data Analysis</option>
                          <option value="Web/Front-End">Web/Front-End</option>
                          <option value="Data Science">Data Science</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Institution
                        </label>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Institution = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Institution}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Length
                        </label>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Length = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Length}
                          type="text"
                          name="city"
                          id="city"
                          autoComplete="address-level2"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Link
                        </label>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Link = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Link}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Hours Per Week
                        </label>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            if (
                              e.target.value === "" ||
                              /^[0-9]+$/.test(e.target.value)
                            ) {
                              temp.HoursPerWeek = e.target.value;
                              setTempData({ ...temp });
                            }
                          }}
                          value={tempData.HoursPerWeek}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Cost
                        </label>
                        <span className="inline absolute px-2 mt-2.5">$</span>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            if (
                              e.target.value === "" ||
                              /^[0-9]+$/.test(e.target.value)
                            ) {
                              temp.Cost = e.target.value;
                              setTempData({ ...temp });
                            }
                          }}
                          value={tempData.Cost}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Verified Cost
                        </label>
                        <span className="inline absolute px-2 mt-2.5">$</span>
                        <input
                          onChange={(e) => {
                            let temp = tempData;
                            if (
                              e.target.value === "" ||
                              /^[0-9]+$/.test(e.target.value)
                            ) {
                              temp.VerifiedCost = e.target.value;
                              setTempData({ ...temp });
                            }
                          }}
                          value={tempData.VerifiedCost}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full pl-5 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3 flex-grow">
                      <div className="mt-2" style={{ zIndex: 2000 }}>
                        <label className="block text-sm font-medium text-gray-700">
                          Start Date
                        </label>
                        <DatePicker
                          className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                          border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                          text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                          selected={
                            tempData.StartDate
                              ? new Date(tempData.StartDate)
                              : new Date()
                          }
                          onChange={(date) => {
                            let temp = tempData;
                            temp.StartDate = date;
                            setTempData({ ...temp });
                          }}
                          fixedHeight
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div>
                    <Editor
                      apiKey={
                        "2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"
                      }
                      onEditorChange={(value) => {
                        let temp = tempData;
                        temp.Description = value;
                        setTempData(temp);
                      }}
                      value={tempData.Description}
                      initialValue={initialValue}
                      init={{
                        height: 250,
                        menubar: false,
                        plugins: [
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | casechange blocks | bold italic backcolor | " +
                          "alignleft aligncenter alignright alignjustify | " +
                          "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        content_style:
                          "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                      }}
                    />
                  </div>
                </div>

                {error.length > 0 && MissingFieldPrompt(error)}
                <div className="mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setModalOpen(false);
                      setError([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CreateCourseModal;
