import { API } from "aws-amplify";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import Table from "../../../Opportunities/Table";
import Loading from "../../../../utils/Loading";

const Opportunities = ({
  profileData,
  applicantData,
  activeTab,
  adminList,
}) => {
  const [tableData, setTableData] = useState([]);
  const [opportunitiesApplied, setOpportunitiesApplied] = useState(new Map());

  useEffect(() => {
    API.graphql({
      query: `{
      Opportunities {
        ItemId
        Location
        Title
        Status
        DueDate
        RecruiterId
        Client { 
          Name 
        }
        Users {
          FirstName
          LastName
        }
      }
    }      
  `,
    }).then(({ data }) => {
      setTableData(data.Opportunities);
    });

    if (profileData.ItemId)
      API.graphql({
        query: `{
          Applicants(where: {MemberId: {_eq: "${profileData.ItemId}"}}) {  
            ItemId
            OpportunityId
            MemberId
            Interested
            WorkFlowStatus  
          }
        }      
      `,
      }).then(({ data }) => {
        //create hashmap to store data. hash map is more effecient in this case.
        let map = new Map();
        data.Applicants.map((obj) => map.set(obj.OpportunityId, obj));
        setOpportunitiesApplied(map);
      });
  }, [applicantData, activeTab]);

  if (tableData.length === 0 || opportunitiesApplied === {}) return <Loading />;

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Opportunities
          </h4>
        </div>
      </div>
      {/* Table Section */}
      {tableData ? (
        <Table
          adminList={adminList}
          tempTable={tableData.filter((obj) => {
            if (opportunitiesApplied.get(obj.ItemId)) {
              let tempObj = obj;
              obj.WorkFlowStatus = opportunitiesApplied.get(
                obj.ItemId
              ).WorkFlowStatus;
              return tempObj;
            }
          })}
        />
      ) : null}
    </div>
  );
};
export default Opportunities;
