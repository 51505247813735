import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  PlusCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import Loading from "../../../../utils/Loading";

const AddOpportunitiesModal = ({
  opportunitiesData,
  profileData,
  applicantData,
  setApplicantData,
  addModalOpen,
  setAddModalOpen,
  setConfirmButtonMessage,
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [opportunitiesApplied, setOpportunitiesApplied] = useState(new Map());

  useEffect(() => {
    API.graphql({
      query: `{
        Applicants(where: {MemberId: {_eq: "${profileData.ItemId}"}}) {  
          ItemId
          OpportunityId
          MemberId
          Interested
          WorkFlowStatus  
        }
      }      
    `,
    }).then(({ data }) => {
      //create hashmap to store data. hash map is more effecient in this case.
      let map = new Map();
      data.Applicants.map((obj) => map.set(obj.OpportunityId, obj));
      setOpportunitiesApplied(map);
    });
  }, []);

  //  APPLY SEARCHBAR FILTER
  useEffect(() => {
    setFilteredData(
      opportunitiesData.filter((obj) =>
        obj.Title?.toLowerCase().startsWith(searchVal.toLowerCase())
      )
    );
  }, [searchVal]);

  if (opportunitiesData.length === 0) return <Loading />;

  return (
    <Transition.Root show={addModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setAddModalOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] h-[800px] relative transform overflow-x-hidden overflow-y-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setAddModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Member to Opportunities
                    </Dialog.Title>
                  </div>
                </div>
                <div>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-red-500"
                      onClick={() => setAddModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="mx-5 mt-5">
                    <div className="sm:-mx-6 lg:-mx-8">
                      <input
                        onChange={(e) => setSearchVal(e.target.value)}
                        value={searchVal}
                        type="search"
                        className="ml-6 w-[93%] form-control px-7 py-3 ml-3 mb-1
                        text-base text-gray-700 bg-gray-100 bg-clip-padding transition 
                        ease-in-out m-0 focus:bg-white focus:outline-none rounded-full"
                        placeholder="Search by Title"
                        aria-label="Search"
                        aria-describedby="button-addon"
                      />

                      <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="mb-4 h-[600px] overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                            <thead>
                              <tr>
                                {["Title", "Location", ""].map((header, i) => (
                                  <th
                                    key={i}
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {filteredData &&
                                filteredData
                                  ?.filter(
                                    (item) =>
                                      item.Status != "Closed" &&
                                      item.Status != "Cancelled"
                                  )
                                  ?.map((obj) => {
                                    let index = applicantData?.findIndex(
                                      (x) => obj.ItemId === x.OpportunityId
                                    );
                                    return (
                                      <tr
                                        className={`${
                                          obj.ItemId !==
                                          applicantData[index]?.OpportunityId
                                            ? "bg-white"
                                            : "bg-gray-100"
                                        }`}
                                        key={obj.ItemId}
                                      >
                                        <td className="whitespace-nowrap px-3 py-4 text-sm teckpert-text-green font-bold pl-5 cursor-pointer">
                                          {obj.Title ? obj.Title : "-"}&nbsp;
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 pl-5">
                                          {obj.Location ? obj.Location : "-"}
                                        </td>
                                        <td>
                                          {obj.ItemId !==
                                          applicantData[index]
                                            ?.OpportunityId ? (
                                            <PlusCircleIcon
                                              value={obj.ItemId}
                                              className="h-6 w-6 cursor-pointer mr-3 teckpert-text-green"
                                              aria-hidden="true"
                                              onClick={async () => {
                                                await API.graphql(
                                                  graphqlOperation(`mutation {
                                                  insert_Applicants_one(
                                                    object: 
                                                      {
                                                        ItemId: "${uuidv4()}",
                                                        MemberId: "${
                                                          profileData.ItemId
                                                        }",
                                                        OpportunityId: "${
                                                          obj.ItemId
                                                        }",
                                                        Interested: "Yes",
                                                        WorkFlowStatus: "New",
                                                      }, on_conflict: {constraint: Applicants_pkey}
                                                    )       
                                                    {
                                                      ItemId
                                                      MemberId
                                                    }            
                                              }`)
                                                ).then(({ data }) => {
                                                  let tempData = applicantData;
                                                  tempData = [
                                                    {
                                                      ItemId: data.ItemId,
                                                      MemberId:
                                                        profileData.ItemId,
                                                      OpportunityId: obj.ItemId,
                                                      Interested: "Yes",
                                                      WorkFlowStatus: "New",
                                                    },
                                                    ...applicantData,
                                                  ];

                                                  setApplicantData(tempData);
                                                  setConfirmButtonMessage(true);
                                                });
                                              }}
                                            />
                                          ) : (
                                            <CheckCircleIcon
                                              className="h-6 w-6 cursor-not-allowed mr-3 teckpert-bg-green text-white rounded-full"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default AddOpportunitiesModal;
