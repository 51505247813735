import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const SearchBar = (props) => {
  const location = useLocation();
  return (
    <div>
      <div className="flex justify-center bg-[#f5f5f5]">
        <div className="my-8 mx-8 w-full">
          <div className="input-group relative flex flex-wrap items-stretch rounded-full bg-white">
            <input
              onChange={(e) => props.setSearchVal(e.target.value)}
              value={props.searchVal}
              type="search"
              className="w-100 form-control relative flex-auto block px-7 py-5 
              text-base font-normal text-gray-700 bg-white bg-clip-padding transition 
              ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
              focus:outline-none rounded-full"
              placeholder="Search by Name or Title"
              aria-label="Search"
              aria-describedby="button-addon3"
            />
            {props.advFilterSearch && props?.advFilterSearch.length > 0 ? (
              <div className="border rounded-full h-9 my-auto px-3 py-1 bg-[#6FBE47] text-white">
                {props.advFilterSearch.length}
              </div>
            ) : (
              <div></div>
            )}
            {!location.pathname.startsWith("/people/") ? (
              <button
                onClick={() => {
                  props.setFiltersModal(true);
                }}
                className="btn inline-block px-6 py-2 text-gray-900 rounded-full
                font-medium text-sm leading-tight rounded 
                hover:text-gray-400 focus:outline-none focus:ring-0 transition duration-150 
                ease-in-out"
                type="button"
                id="button-addon3"
              >
                Advanced Filters
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
