import React, { useState } from "react";

const JobPortalHeader = ({ tempData, img }) => {
  return (
    <div className="text-center justify-center p-8 bg-[#333333] mb-4 rounded mt-2">
      <div className="text-center mb-9 justify-center">
        {console.log("img", img)}
        {img ? (
          <img
            src={img}
            className="max-h-[200px] max-w-[200px] rounded-full m-auto"
          />
        ) : null}
      </div>
      <div className="text-4xl font-bold teckpert-text-green mb-2">
        {tempData.Name ? tempData.Name : "-"}
      </div>
      <div className="p-7 text-center justify-center text-white">
        <div className="font-bold tracking-wide text-lg">
          {tempData.Headline ? tempData.Headline : "-"}
        </div>
        <div className="w-[650px] m-auto mt-2">
          {tempData.Description ? tempData.Description : "-"}
        </div>
      </div>
    </div>
  );
};
export default JobPortalHeader;
