import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  MenuAlt2Icon,
  BellIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { Auth } from "aws-amplify";

const Navbar = ({ setMobileMenuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userNavigation = [
    {
      name: "Your Profile",
      href: "/adminprofile",
      // clickEvent: () => {
      //   navigate("/adminprofile");
      // },
    },
    {
      name: "Company Profile",
      href: "/companyprofile",
    },
    {
      name: "Sign out",
      href: "#",
      clickEvent: () => {
        localStorage.removeItem("name");
        Auth.signOut();
        navigate("/login");
      },
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <header className="z-10 w-full bg-white absolute lg:flex md:flex sm:block">
        <div className="shadow-sm sm:hidden md:block lg:block ml-[175px] mt-4 text-gray-700 z-12 flex-grow font-bold text-3xl">
          {location.pathname === "/people"
            ? "People"
            : location.pathname.includes("/people/")
              ? "Profile"
              : location.pathname === "/opportunities"
                ? "Opportunities"
                : location.pathname.includes("/opportunities/")
                  ? "Opportunity Details"
                  : location.pathname === "/clients"
                    ? "Clients"
                    : location.pathname.includes("/clients/")
                      ? "Client Details"
                      : location.pathname === "/contracts"
                        ? "Contracts"
                        : location.pathname.includes("/contracts/")
                          ? "Contract Details"
                          : location.pathname === "/timesheets"
                            ? "Timesheets"
                             : location.pathname === "/reports"
                            ? "Reports"
                            : location.pathname === "/reports/payrollW2Report"
                            ? "Payroll W2 Report"
                            : location.pathname === "/invoice"
                              ? "Invoices"
                              : location.pathname === "/courses"
                                ? "Courses"
                                : location.pathname === "/users"
                                  ? "Users"
                                  : location.pathname === "/tools"
                                    ? "Tools"
                                    : location.pathname === "/jobportals"
                                      ? "Job Portals"
                                      : location.pathname === "/appquestionnaire"
                                        ? "Application Questionnaires"
                                        : location.pathname === "/interviewguides"
                                          ? "Interview Guides"
                                          : location.pathname === "/resources"
                                            ? "Resources"
                                            : location.pathname === "/messageslog"
                                              ? "Messages Log"
                                              : location.pathname === "/emailtemplates"
                                                ? "Email Templates"
                                                : location.pathname === "/emailtemplates/new"
                                                  ? "New Template"
                                                  : location.pathname.includes("/emailtemplates/")
                                                    ? "Update Template"
                                                    : location.pathname === "/workflows"
                                                      ? "Workflows"
                                                      : location.pathname.includes("/workflows/")
                                                        ? "Workflow Stages"
                                                        : location.pathname === "/checklists"
                                                          ? "Checklists"
                                                          : location.pathname.includes("/checklists/")
                                                            ? "Checklist Items"
                                                              : location.pathname === "/mytimesheets"
                                                                ? "My Timesheets"
                                                                   : location.pathname === "/entertime"
                                                                      ? "Enter Time"
                                                                      : location.pathname.includes("/entertime/")  
                                                                      ? "Enter Time"
                                                                       :""
                                                                    }
        </div>
        <div className="relative z-10 flex-shrink-0 h-16 bg-gray border-b border-gray-200 shadow-sm flex">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none md:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 flex justify-end px-4 sm:px-6 bg-white">
            <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
              {/* Profile dropdown */}
              <Menu as="div" className="relative flex-shrink-0">
                <Menu.Button className="bg-white rounded-full flex flex-row-reverse text-sm focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <ChevronDownIcon
                    className="h-3 w-3 cursor-pointer mt-1 ml-2"
                    aria-hidden="true"
                  />
                  <div>{localStorage.getItem("name")}</div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.filter((obj, index) => localStorage.getItem("userType") === "member" ? index !== 0 : true).map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            onClick={item.clickEvent}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
