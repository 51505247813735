/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import listOfStates from "../../../utils/dropdownOptions/listOfStates";
import listOfIndustryTypes from "../../../utils/dropdownOptions/listOfIndustryTypes";
import MissingFieldPrompt from "../../../utils/MissingFieldPrompt";
import { formatPhone } from "../../../utils/FormatPhone";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import { useNavigate } from "react-router-dom";

export default function UpdateClientModal({
  open,
  setOpen,
  tableData,
  setTableData,
  setConfirmButtonMessage,
}) {
  const [tempData, setTempData] = useState(tableData);
  const [error, setError] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const deleteItem = async () => {
    await API.graphql(
      graphqlOperation(`
        mutation {
            delete_Clients_by_pk(ItemId: "${tempData.ItemId}") {
              ItemId
              Email
            }
          }
      `)
    );
    setDeleteModalOpen(false);
    setOpen(false);
    setConfirmButtonMessage(true);
    navigate("/clients");
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] max-h-[800px] relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Client
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-8">
                      <div className="pb-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Name = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Name}
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Industry
                          </label>
                          <div className="mt-1">
                            <select
                              className="w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                              onChange={(e) => {
                                let temp = tempData;
                                temp.IndustryType = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.IndustryType}
                            >
                              <option className="text-gray-300" value="">
                                Select
                              </option>
                              {listOfIndustryTypes().map((obj, i) => (
                                <option key={i} value={obj.value}>
                                  {obj.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Address
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Address = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Address}
                              type="text"
                              name="street-address"
                              id="street-address"
                              autoComplete="street-address"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            City
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.City = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.City}
                              type="text"
                              name="city"
                              id="city"
                              autoComplete="address-level2"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            State
                          </label>
                          <div className="mt-1">
                            <select
                              className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                              onChange={(e) => {
                                let temp = tempData;
                                temp.State = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.State}
                            >
                              <option className="text-gray-300" value="">
                                Select
                              </option>
                              {listOfStates().map((obj, i) => (
                                <option key={i} value={obj.value}>
                                  {obj.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Zip
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Zip = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Zip}
                              type="text"
                              name="postal-code"
                              id="postal-code"
                              autoComplete="postal-code"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Phone
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Phone = e.target.value
                                  .replace(/\D+/g, "")
                                  .slice(0, 10);
                                setTempData({ ...temp });
                              }}
                              value={formatPhone(tempData.Phone)}
                              type="text"
                              name="phone"
                              id="phone"
                              autoComplete="phone"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Fax
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Fax = e.target.value
                                  .replace(/\D+/g, "")
                                  .slice(0, 10);
                                setTempData({ ...temp });
                              }}
                              value={formatPhone(tempData.Fax)}
                              type="text"
                              name="fax"
                              id="fax"
                              autoComplete="fax"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Website
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Website = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Website}
                              type="text"
                              name="website"
                              id="website"
                              autoComplete="website"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Email = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Email}
                              type="text"
                              name="email"
                              id="email"
                              autoComplete="email"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                      </div>
                      {error.length > 0 && MissingFieldPrompt(error)}

                      <div className="flex justify-between mt-3">
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 
                            text-base font-medium text-white shadow-sm sm:w-auto sm:text-sm hover:opacity-90"
                            onClick={() => setDeleteModalOpen(true)}
                          >
                            Delete
                          </button>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 
                            text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                            onClick={async () => {
                              let errorArray = [];
                              if (tempData.Name?.trim().length === 0)
                                errorArray.push("Company Name");
                              if (tempData.IndustryType?.trim().length === 0)
                                errorArray.push("Industry");
                              if (tempData.Address?.trim().length === 0)
                                errorArray.push("Address");
                              if (tempData.City?.trim().length === 0)
                                errorArray.push("City");
                              if (tempData.State?.trim().length === 0)
                                errorArray.push("State");
                              if (tempData.Zip?.trim().length === 0)
                                errorArray.push("Zip");
                              if (tempData.Phone?.trim().length === 0)
                                errorArray.push("Phone");
                              if (tempData.Email?.trim().length === 0)
                                errorArray.push("Email");
                              setError(errorArray);

                              if (errorArray.length > 0) {
                                return;
                              }

                              await API.graphql(
                                graphqlOperation(`mutation {
                                update_Clients_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
                                  _set: 
                                    {
                                      AccountStatus: "${tempData.AccountStatus}",
                                      Name: "${tempData.Name}",
                                      PrimaryContact: "${tempData.PrimaryContact}",
                                      Address: "${tempData.Address}",
                                      City: "${tempData.City}",
                                      State: "${tempData.State}",
                                      Zip: "${tempData.Zip}",
                                      IndustryType: "${tempData.IndustryType}",
                                      Website: "${tempData.Website}",
                                      Phone: "${tempData.Phone}",
                                      Fax: "${tempData.Fax}",
                                      Email: "${tempData.Email}",
                                    })
                                    {
                                      ItemId
                                      Name
                                    }
                            }`)
                              ).then(({ data }) => {
                                setConfirmButtonMessage(true);
                                setOpen(false);
                                setTableData({ ...tempData });
                              });
                            }}
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                            font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => {
                              setOpen(false);
                              setError([]);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <ConfirmDeleteModal
          deleteItem={deleteItem}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
        />
      </Dialog>
    </Transition.Root>
  );
}
