import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { EyeIcon, EyeOffIcon, XCircleIcon } from "@heroicons/react/outline";
import Loading from "../../utils/Loading";
import { Editor } from "@tinymce/tinymce-react";
export const SendForApprovalModal = ({
    modal,
    setModal,
    selectedTimesheets,
    setSelectedTimesheets,
    tableData,
    setTableData,
    emailTemplateData,
}) => {
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [contacts, setContacts] = useState([]);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState([]);
    const [value, setValue] = useState(body ?? '');

    useEffect(() => {
        const async = async () => {
            const { data } = await API.graphql({
                query: `{
                    Admins: Users(offset: 0, limit: 10000, order_by: {DateCreated: asc}, where: {UserType: {_neq: "member"}}) {
                        ItemId
                        FirstName
                        LastName
                        Email
                    }
                    Contacts(offset: 0, limit: 10000, order_by: {DateCreated: asc}, where: {ClientId: {_eq: "${selectedTimesheets[0]?.JobOrder?.Contract?.Clients?.ItemId}"}}) {
                        ItemId
                        FirstName
                        LastName
                        Email
                    }
            }`});
            setAdmins(data.Admins.map(({ ItemId, FirstName, LastName, Email }) => ({
                value: ItemId,
                title: `${FirstName} ${LastName} <${Email}>`,
            })));
            setContacts(data.Contacts.map(({ ItemId, FirstName, LastName, Email }) => ({
                value: ItemId,
                title: `${FirstName} ${LastName} <${Email}>`,
            })));
            
        };
        if (modal) {
            async();
        }
    }, [modal, selectedTimesheets]);
    useEffect(() => {
        if (emailTemplateData && emailTemplateData.length > 0 && emailTemplateData[0].Body) {
            setBody(emailTemplateData[0].Body);
        }
    }, [emailTemplateData]);
    useEffect(() => {
        setValue(body); 
    }, [body]);
    useEffect(() => {
        if (selectedTimesheets.length > 0)
        {
            const minDate = new Date(Math.min(...selectedTimesheets.map(timesheet => new Date(timesheet?.PeriodStart))));
            const maxDate = new Date(Math.max(...selectedTimesheets.map(timesheet => new Date(timesheet?.PeriodEnd))));
            const formettedPeriodStart = minDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
            const formettedPeriodEnd = maxDate.toLocaleDateString('en-Us', { timeZone: 'UTC' });
            setSubject(`Timesheet Approval Required for Period(s): ${formettedPeriodStart} - ${formettedPeriodEnd}`);
        }
    }, [selectedTimesheets])
    const onSend = async () => {
        setLoading(true);
        
        try {              
            await API.post('auth', '/sendTimesheetsToClient', {
                body: {
                    from: from,
                    to: to,
                    subject: subject,
                    body: value,
                    timesheets: selectedTimesheets.map(({ ItemId }) => ItemId)
                }
            });
            setTableData(tableData.map(obj =>
                ({ ...obj, Status: selectedTimesheets.map(({ ItemId }) => ItemId).includes(obj.ItemId) ? 'Sent for Approval' : obj.Status })
            ));
            setSelectedTimesheets([]);
            setModal(false);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            alert(err);
        }
    };

    return (
        <Transition.Root show={modal} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    setModal(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="lg:w-[700px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-red-500"
                                        onClick={() => {
                                            setModal(false);
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start mb-6">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Send for Approval
                                        </Dialog.Title>
                                    </div>
                                </div>

                                <div className="mt-6 sm:col-span-2 flex-grow">
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            From
                                        </label>
                                        <select
                                            className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                            onChange={(e) => { setFrom(e.target.value); }}
                                            value={from}
                                        >
                                            <option className="text-gray-300" value="">
                                                Select Admin
                                            </option>
                                            {admins.map((obj, i) => (
                                                <option key={i} value={obj.value}>
                                                    {obj.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:col-span-2 flex-grow">
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            To
                                        </label>
                                        <select
                                            className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                            onChange={(e) => { setTo(e.target.value); }}
                                            value={to}
                                        >
                                            <option className="text-gray-300" value="">
                                                Select Client Contact
                                            </option>
                                            {contacts.map((obj, i) => (
                                                <option key={i} value={obj.value}>
                                                    {obj.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:col-span-2 flex-grow">
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Subject
                                        </label>
                                        <input
                                            onChange={(e) => { setSubject(e.target.value); }}
                                            value={subject}
                                            type="text"
                                            name="title"
                                            id="title"
                                            autoComplete="title"
                                            className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2 flex-grow">
                                    <div className="mt-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Body
                                        </label>
                                        <Editor                                            
                                            apiKey={"2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"}
                                            onEditorChange={(newValue, editor) => setValue(newValue)}
                                            initialValue={body}
                                            Value={value}
                                            init={{
                                                height: 450,
                                                menubar: false,
                                                plugins: [
                                                    "advlist",
                                                    "autolink",
                                                    "lists",
                                                    "link",
                                                    "image",
                                                    "charmap",
                                                    "preview",
                                                    "anchor",
                                                    "searchreplace",
                                                    "visualblocks",
                                                    "fullscreen",
                                                    "insertdatetime",
                                                    "media",
                                                    "table",
                                                    "help",
                                                    "wordcount",
                                                ],
                                                toolbar:
                                                    "undo redo | casechange blocks | bold italic backcolor | " +
                                                    "alignleft aligncenter alignright alignjustify | " +
                                                    "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                                                content_style:
                                                    "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                                                force_br_newlines: true,                                               
                                                forced_root_block: 'p',
                                                apply_source_formatting: false,
                                                indent: false
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        style={loading ? { backgroundColor: '#ccc' } : {}}
                                        disabled={loading}
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={onSend}
                                    >
                                        Send
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            setModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {loading && <Loading />}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};