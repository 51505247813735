import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const Table = ({ tableData }) => {
  const [contracts, setContracts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    API.graphql({
      query: `{
        Contracts(order_by: {Name: asc}) {
          ItemId
          ClientId
        }
      }      
    `,
    }).then(({ data }) => {
      setContracts(data.Contracts);
    });
  }, [tableData]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Company",
                      "Location",
                      "Contact",
                      "Active Contracts",
                      "Company Email",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData.map((client) => {
                    return (
                      <tr key={client.ItemId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div
                            className="font-medium teckpert-text-green cursor-pointer hover:opacity-90"
                            onClick={() =>
                              navigate("/clients/" + client.ItemId)
                            }
                          >
                            {client.Name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {client.City
                              ? client.City != "null"
                                ? client.State
                                  ? client.State != "null"
                                    ? client.City + ", " + client.State
                                    : client.State
                                  : "-"
                                : "-"
                              : "-"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">
                          {client.Contacts.length
                            ? client.Contacts[0].FirstName +
                              " " +
                              client.Contacts[0].LastName
                            : "-"}
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">
                          {client?.Contracts_aggregate?.aggregate?.count}
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">
                          <a href={`mailto:${client.Email}`}>{client.Email}</a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
