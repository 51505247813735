import { MapIcon, ClockIcon, GlobeIcon } from "@heroicons/react/outline";

const JobHeader = ({ jobsData }) => {
  return (
    <div className="lg:text-center sm:text-left lg:justify-center py-8 px-6 bg-[#2e2e2e] mb-4 rounded">
      <div className="lg:justify-center sm:justify-start">
        <div className="mb-4">
          <img
            src="/logo.png"
            width="240"
            height="60"
            className="rounded-full lg:m-auto"
          />
        </div>
        <div className="ml-5 text-4xl font-bold teckpert-text-green mb-2">
          {jobsData.Title ? jobsData.Title : "-"}
        </div>
      </div>
      <div className="p-5 text-center justify-center lg:flex sm:block lg:gap-12">
        <div className="flex gap-6">
          <ClockIcon
            className="h-5 w-5 teckpert-text-green col-span-1"
            aria-hidden="true"
          />
          <div className="text-gray-400 relative right-3">Type</div>
          <div className="text-white">
            {jobsData.Type ? jobsData.Type : "-"}
          </div>
        </div>
        <div className="flex gap-6">
          <MapIcon
            className="h-5 w-5 teckpert-text-green col-span-1"
            aria-hidden="true"
          />
          <div className="text-gray-400 relative right-3">Location</div>
          <div className="text-white">
            {jobsData.Location ? jobsData.Location : "-"}
          </div>
        </div>
        <div className="flex gap-6">
          <GlobeIcon
            className="h-5 w-5 teckpert-text-green col-span-1"
            aria-hidden="true"
          />
          <div className="text-gray-400 relative right-3">Work Type</div>
          <div className="text-white">
            {jobsData
              ? jobsData.WorkType != "null"
                ? jobsData.WorkType
                : "-"
              : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobHeader;
