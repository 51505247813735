import Table from "./Table";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import BasicSearchBar from "../../../utils/BasicSearchbar";
import SendMessage from "./SendMessage";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const MessageLog = () => {
  const [refresh, setRefresh] = useState(null);
  const [data, setData] = useState([]);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [types, setTypes] = useState([]);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    console.log(date, type);
    API.graphql({
      query: `{
      Emails(offset: 0, limit: 25, order_by: {DateCreated: desc}, where: {
        ${searchVal?.length ? `Body: {_ilike: "%${searchVal}%"}` : ''}
        ${date ? `DateCreated: {_gte: "${date.toISOString().split('T')[0]}T00:00:00", _lte: "${date.toISOString().split('T')[0]}T23:59:59"}` : ''}
        ${type?.length ? `Type: {_eq: "${type}"}` : ''}
        ItemId: {_is_null: false}
      }) {
        ItemId
        DateCreated
        DateUpdated
        From
        FromUser {
          FirstName
          LastName
          Email
        }
        To
        ToMember {
          FirstName
          LastName
          Meta
        }
        Type
        EmailTemplate {
          Type
        }
        Opportunity
        Subject
        Body
      }
      EmailTemplates {
        ItemId
        Type
      }
    }      
  `,
    }).then(({ data }) => {
      setData(data.Emails);
      setTypes(data.EmailTemplates);
    });
  }, [refresh, searchVal, date, type]);

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={() => setMessageModalOpen(true)}
            type="button"
            className="hover:opacity-90 rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
      <Table
        data={data}
        types={types}
        type={type}
        setType={setType}
        date={date}
        setDate={setDate}
      />
      <SendMessage
        open={messageModalOpen}
        setOpen={setMessageModalOpen}
        setRefresh={setRefresh}
      />
    </div>
  );
};
export default MessageLog;
