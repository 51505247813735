import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API } from "aws-amplify";
import { EyeIcon, EyeOffIcon, XCircleIcon } from "@heroicons/react/outline";
import { formatPhone } from "../../utils/FormatPhone";

const UpdateUserModal = ({
  modalOpen,
  setModalOpen,
  tempData,
  setTempData,
  tableData,
  setTableData,
}) => {
  const [originalEmail, setOriginalEmail] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!originalEmail) {
      setOriginalEmail(tempData.Email);
    }
  }, [tempData, originalEmail]);

  useEffect(() => {
    setTempData(tableData?.filter((obj, i) => obj.ItemId == tempData.ItemId));
  }, []);

  const onSave = async () => {
    let temp = tempData;
    await API.put("auth", "/adminModifyUser", {
      body: {
        email: originalEmail,
        newEmail: tempData.Email,
        firstName: tempData.FirstName,
        lastName: tempData.LastName,
        userType: tempData.UserType,
        calendlyProfileUrl: tempData.CalendlyProfileUrl,
        password: tempData.Password,
        directLine:tempData.DirectLine,
      },
    });
    setModalOpen(false);
    setTempData({
      ...{
        FirstName: temp.FirstName,
        LastName: temp.LastName,
        UserType: temp.UserType,
        Email: temp.Email,
        CalendlyProfileUrl: temp.CalendlyProfileUrl,
        DirectLine:temp.DirectLine,
      },
    });
    setTableData([
      ...tableData.filter((obj) => obj.ItemId != tempData.ItemId),
      {
        FirstName: tempData.FirstName,
        LastName: tempData.LastName,
        UserType: tempData.UserType,
        Email: tempData.Email,
        CalendlyProfileUrl: tempData.CalendlyProfileUrl,
        DirectLine: tempData.DirectLine,
      },
    ]);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start mb-6">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update User
                    </Dialog.Title>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      First Name
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.FirstName = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.FirstName}
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="first-name"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Last Name
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.LastName = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.LastName}
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="last-name"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      User Type
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.UserType = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.UserType}
                    >
                      <option className="text-gray-300" value="">
                        Select Type
                      </option>
                      <option value="Admin">Admin</option>
                      <option value="Finance">Finance</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Sales">Sales</option>
                      <option value="Recruiter">Recruiter</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Email = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Email}
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 flex-grow">
                <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700">
                Direct Line
                </label>
                <input 
                    onChange={(e)=>{
                        let temp=tempData;
                        temp.DirectLine=e.target.value
                        .replace(/\D+/g,"")
                        .slice(0,10); 
                         setTempData({ ...temp });
                    }}
                    value={formatPhone(tempData.DirectLine)}
                    type="text"
                    id="directLine"
                    name="directLine"
                    autoComplete="directLine"
                    className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                               border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                </div>
                </div>
                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Calendly Profile URL
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.CalendlyProfileUrl = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.CalendlyProfileUrl}
                      type="text"
                      name="url"
                      id="url"
                      autoComplete="url"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Password = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Password}
                      type={showPassword ? "text" : "password"}
                      name="url"
                      id="url"
                      autoComplete="url"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                    <div
                      style={{
                        marginTop: -35,
                        marginLeft: "calc(100% - 40px)",
                        cursor: "pointer",
                        zIndex: 1,
                        position: "relative",
                      }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {!showPassword ? (
                        <EyeIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <EyeOffIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default UpdateUserModal;
