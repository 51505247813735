import { useNavigate } from "react-router-dom";
import {
  MailIcon,
  MailOpenIcon,
  UsersIcon,
  ClipboardListIcon,
  GlobeAltIcon,
  ChatAltIcon,
  ShareIcon,
} from "@heroicons/react/outline";

const Table = () => {
  const navigate = useNavigate();

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-5 ">
      <div className="grid grid-cols-4 gap-2 w-full p-5">
        <button
          onClick={() => navigate("/messageslog")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <MailOpenIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Email Messages Log</div>
        </button>
        <button
          onClick={() => navigate("/resources")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <UsersIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Manage TECKpert Resources</div>
        </button>
        <button
          onClick={() => navigate("/appquestionnaire")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <ClipboardListIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Application Questionnaires</div>
        </button>
        <button
          onClick={() => navigate("/jobportals")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <GlobeAltIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Job Portals</div>
        </button>
        <button
          onClick={() => navigate("/emailtemplates")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <MailIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Email Templates</div>
        </button>
        <button
          onClick={() => navigate("/interviewguides")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <ChatAltIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Interview Guides</div>
        </button>
        <button
          onClick={() => navigate("/workflows")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <ShareIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Workflows</div>
        </button>
        <button
          onClick={() => navigate("/checklists")}
          type="button"
          className="block rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
          focus:outline-none sm:w-auto hover:border-gray-100"
        >
          <ClipboardListIcon
            className="h-24 w-24 cursor-pointer m-auto mb-5 mt-6"
            aria-hidden="true"
          />
          <div className="mb-6">Checklists</div>
        </button>
      </div>
    </div>
  );
};

export default Table;
