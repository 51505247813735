import Table from "./Table";

const Tools = () => {
  return (
    <div>
      <Table />
    </div>
  );
};
export default Tools;
