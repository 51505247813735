import { useNavigate, useLocation } from "react-router-dom";
import { formatDate } from "../../utils/FormatDate";

const Table = ({ tableData, contacts }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="mx-5 flex flex-col">
      {tableData.length > 0 ? (
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    {location.pathname.split("/")[1] === "contracts" &&
                      [
                        "Name",
                        "Client",
                        "Type",
                        "Start Date",
                        "End Date",
                        "Status",
                        "Contact",
                      ].map((header, i) => (
                        <th
                          key={i}
                          className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {header}
                        </th>
                      ))}
                    {location.pathname.split("/")[1] === "people" &&
                                          ["Name", "Client", "Type", "Start Date", "End Date", "Status"].map(
                        (header, i) => (
                          <th
                            key={i}
                            className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        )
                      )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData
                    ?.sort((a, b) => a.Name.localeCompare(b.Name))
                    ?.map((contract) => (
                      <tr key={contract.ItemId}>
                        <td
                          onClick={() =>
                            navigate("/contracts/" + contract.ItemId)
                          }
                          className="py-4 pl-6 text-sm sm:pl-6 cursor-pointer"
                        >
                          <div className="flex items-center font-medium teckpert-text-green">
                            {contract.Name ? contract.Name : "-"}
                          </div>
                        </td>
                        <td className="pl-6 py-4 text-sm text-gray-900">
                          {contract.Clients ? contract.Clients.Name : "-"}
                        </td>
                        <td className="pl-6 py-4 text-sm text-gray-500">
                          {contract.Type ? contract.Type : "-"}
                        </td>
                        <td className="pl-6 py-4 text-sm text-gray-500">
                          {contract.DateCreated
                            ? formatDate(contract.DateCreated)
                            : "-"}
                            </td>
                            <td className="pl-6 py-4 text-sm text-gray-500">
                                {contract.DateCreated
                                    ? formatDate(contract.EndDate)
                                    : "-"}
                            </td>
                        <td className="pl-6 py-4 text-sm text-gray-500">
                          {contract.Status ? contract.Status : "-"}
                        </td>
                        <td
                          className={`${
                            location.pathname.split("/")[1] === "contracts"
                              ? ""
                              : "hidden"
                          } whitespace-nowrap pl-6 py-4 text-sm text-gray-500`}
                        >
                          {contacts?.map((obj) => {
                            if (obj.ItemId === contract.PrimaryContact)
                              if (
                                (obj.FirstName != undefined) &
                                (obj.LastName != undefined)
                              )
                                return (
                                  <option key={obj.ItemId} value={obj.ItemId}>
                                    {obj.FirstName} {obj.LastName}
                                  </option>
                                );
                          })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
          No Contracts Found
        </div>
      )}
    </div>
  );
};

export default Table;
