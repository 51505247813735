import { useState, useEffect } from "react";
import UploadFileModal from "../../../../utils/UploadFileModal";
import {
  ChevronUpIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { API, Storage } from "aws-amplify";
import { formatDate } from "../../../../utils/FormatDate";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";

const Documents = ({ tableData, setTableData, setConfirmButtonMessage }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const getStorageFile = async (key, id, redirect = false) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    if (redirect === true && url !== "") {
      window.open(url, "_blank");
    }
    return { url, ItemId: id };
  };

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Documents
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setModalOpen(!modalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 text-[#6fbe47]"
            aria-hidden="true"
          />
          <UploadFileModal
            open={modalOpen}
            setOpen={setModalOpen}
            tableData={tableData}
            setTableData={setTableData}
            documentList={documentList}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
        </div>
      </div>
      {/* Table Section */}
      <div className="mx-5 flex flex-col">
        {tableData.length > 0 ? (
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                  <thead>
                    <tr>
                      {["Name", "Type", "Uploaded", "Creator", ""].map(
                        (header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableData?.map((obj) => (
                      <tr key={obj.ItemId}>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold teckpert-text-green sm:pl-6">
                          <a
                            href={"#url"}
                            onClick={async () => {
                              window.open((await getStorageFile(obj?.Key)).url);
                            }}
                          >
                            {obj.Name ? obj.Name : "-"}
                          </a>
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          {obj.Type ? obj.Type : "-"}
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          {obj.CreatorName ? obj.CreatorName : "-"}
                        </td>
                        <td className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6">
                          <XCircleIcon
                            className="h-6 w-6 cursor-pointer ml-7 inline text-gray-300 hover:text-red-500"
                            aria-hidden="true"
                            onClick={() => setDeleteModalOpen(true)}
                          />
                          <ConfirmDeleteModal
                            open={deleteModalOpen}
                            setOpen={setDeleteModalOpen}
                            deleteItem={async () => {
                              let response = await API.graphql({
                                query: `mutation {
                                delete_Documents_by_pk(ItemId: "${obj.ItemId}") {
                                  ItemId
                                  Name
                                }
                              }
                              `,
                              });
                              if (response) {
                                setTableData(
                                  tableData.filter(
                                    (document) => document.ItemId !== obj.ItemId
                                  )
                                );
                                setConfirmButtonMessage(true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
            No Documents Found
          </div>
        )}
      </div>
    </div>
  );
};
export default Documents;
