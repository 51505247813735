import { useState } from "react";
import { API } from "aws-amplify";
import {
  XCircleIcon,
  PencilIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../utils/FormatDate";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import ReactQuill from "react-quill";

const Table = ({ data, types, type, setType, date, setDate }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:col-span-3">
        <div className="mt-1 flex">
          <select
            className="mr-3 rounded-full justify-center rounded-md cursor-pointer
            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
            onChange={(e) => {
              setType(e.target.value);
            }}
            value={type}
          >
            <option className="text-gray-300" value="">
              Type
            </option>
            {types?.map(type => {
              return (
                <option value={type.ItemId}>
                  {type.Type}
                </option>
              );
            })}
          </select>
          <span className="z-10">
            <CalendarIcon
              className="relative left-7 mt-3 h-5 w-5 cursor-pointer text-gray-800"
              aria-hidden="true"
            />
          </span>
          <div>
            <DatePicker
              className="z-2 mr-3 pl-9 rounded-full justify-center rounded-md cursor-pointer
            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-800"
              selected={date}
              onChange={(date) => {
                setDate(date);
              }}
              placeholderText="mm/dd/yyyy"
              fixedHeight
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Date Sent",
                      "To",
                      "From",
                      "Type",
                      "Subject",
                      "Message",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((obj) => {
                    return (
                      <tr key={obj.ItemId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center font-medium text-[#6FBE47]">
                            {obj.DateCreated
                              ? formatDate(obj.DateCreated)
                              : "-"}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-900">
                          {obj?.ToMember
                            ? `${obj?.ToMember?.FirstName} ${obj?.ToMember?.LastName
                            } <${obj?.ToMember?.Meta &&
                            obj?.ToMember?.Meta?.Email
                            }>`
                            : "-"}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-900">
                          {obj?.FromUser
                            ? `${obj?.FromUser?.FirstName} ${obj?.FromUser?.LastName} <${obj?.FromUser?.Email}>`
                            : "-"}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-900">
                          {obj?.Type ? `${obj?.EmailTemplate?.Type}` : "-"}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-800 font-bold">
                          {obj?.Subject}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-900">
                          <ReactQuill
                            theme="bubble"
                            value={obj.Body}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
