import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import ExcelJS from 'exceljs';
import { API, Storage } from "aws-amplify";

const PayrollW2Report = ({ newBucketRegistor }) => {
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [excelData, setExcelData] = useState(null);
    const [iframeKey, setIframeKey] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const { bucketName, regionName } = newBucketRegistor;


    const handleFromDateChange = date => {
        setSelectedFromDate(new Date(date.toISOString()));

    };

    const handleToDateChange = date => {
        setSelectedToDate(new Date(date.toISOString()));
    };

    const generateReport = () => {
        if (!selectedFromDate || !selectedToDate) {
            setErrorMessage("Please select both From Date and To Date.");
            return;
        }

        if (selectedFromDate > selectedToDate) {
            setErrorMessage("From Date must be less than or equal to To Date.");
            return;
        }

        const filteredData = reportData.filter(entry => {
            const entryDate = new Date(new Date(entry.Date).toLocaleDateString('en-US', { timeZone: "utc" }));
            return (
                (!selectedFromDate || entryDate >= selectedFromDate) &&
                (!selectedToDate || entryDate <= selectedToDate)
            );
        });
        const fromDateText = selectedFromDate ? selectedFromDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : 'Start Date';
        const toDateText = selectedToDate ? selectedToDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : 'End Date';
        const dateRange = `From ${fromDateText} to ${toDateText}`;

        const workerdata = {};


        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Payroll Report');

        worksheet.addRow(["Payroll W2 Report"]).font = { bold: true, size: 16 };
        worksheet.mergeCells('A1:J1');
        worksheet.addRow([]);
        worksheet.addRow(["Filters:", dateRange]);
        worksheet.addRow([]);

        filteredData.forEach(entry => {
            if (entry.timesheet && entry.timesheet.JobOrder) {
                const workername = `${entry.timesheet.JobOrder.Member.FirstName} ${entry.timesheet.JobOrder.Member.LastName}`;

                if (!workerdata[workername]) {
                    workerdata[workername] = [];
                }

                workerdata[workername].push({
                    worker: workername,
                    contract: entry.timesheet.JobOrder.Contract.Name,
                    client: entry.timesheet.JobOrder.Contract.Clients.Name,
                    date: entry.Date,
                    day: new Date(entry.Date).toLocaleDateString('en-US', { timeZone: "utc", weekday: 'long' }),
                    hours: entry.Hours,
                    payrate: entry.timesheet.JobOrder.Rate,
                    billrate: entry.timesheet.JobOrder.BillableRate,
                    category: entry.Category,
                    description: entry.Details
                });
            }
        });

        Object.keys(workerdata).forEach(workername => {
            const workerentries = workerdata[workername];
            worksheet.addRow([workername]).font = { bold: true };
            worksheet.addRow([]);
            const columnHeaderRow = worksheet.addRow(['Worker', 'Contract', 'Client', 'Date', 'Day', 'Hours', 'Pay Rate', 'Bill Rate', 'Category', 'Description']);
            columnHeaderRow.eachCell(cell => {
                cell.font = { bold: true };
            });

            let totalHours = 0;
            let ptoHours = 0;
            let holidayHours = 0;
            let sickDayHours = 0;
            let unpaidLeaveHours = 0;
            workerentries.forEach(entry => {
                worksheet.addRow([
                    workername,
                    entry.contract,
                    entry.client,
                    entry.date,
                    entry.day,
                    entry.hours,
                    entry.payrate,
                    entry.billrate,
                    entry.category,
                    entry.description
                ]);

                totalHours += entry.hours;
                if (entry.description) {
                    const descriptionLowercase = entry.description.toLowerCase();

                    if (descriptionLowercase.includes(':pto')) {
                        ptoHours += 8;
                    }
                    if (descriptionLowercase.includes(':holiday')) {
                        holidayHours += 8;
                    }
                    if (descriptionLowercase.includes(':sick day')) {
                        sickDayHours += 8;
                    }
                    if (descriptionLowercase.includes(':unpaid leave')) {
                        unpaidLeaveHours += 8;
                    }
                }

            });
            worksheet.addRow([]);

            worksheet.addRow(['', '', '', '', '', '', '', '', 'Total Hours:', totalHours]).font = { bold: true };
            worksheet.addRow(['', '', '', '', '', '', '', '', 'PTO:', ptoHours]).font = { bold: true };
            worksheet.addRow(['', '', '', '', '', '', '', '', 'Holiday:', holidayHours]).font = { bold: true };
            worksheet.addRow(['', '', '', '', '', '', '', '', 'Sick Day:', sickDayHours]).font = { bold: true };
            worksheet.addRow(['', '', '', '', '', '', '', '', 'Unpaid Leave:', unpaidLeaveHours]).font = { bold: true };

            worksheet.addRow([]);
            worksheet.getColumn('A').width = 15;
            worksheet.getColumn('B').width = 30;
            worksheet.getColumn('C').width = 30;
            worksheet.getColumn('D').width = 15;
            worksheet.getColumn('E').width = 15;
            worksheet.getColumn('F').width = 10;
            worksheet.getColumn('G').width = 10;
            worksheet.getColumn('H').width = 10;
            worksheet.getColumn('I').width = 15;

        });

        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const uploadStorageFile = async (file) => {
                let response = await Storage.put("Payroll.xlsx", file, {
                    bucket: bucketName,
                    region: regionName,
                    contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                console.log(response);
                const timestamp = Date.now();
                setExcelData(`https://teckpert-platform-web-staging.s3.us-east-2.amazonaws.com/public/Payroll.xlsx?timestamp=${timestamp}`);

            }
            uploadStorageFile(blob);
            setIframeKey(prevKey => prevKey + 1);

        });

    };

    const downloadReport = () => {
        const timestamp = new Date().getTime();
        const url = `https://teckpert-platform-web-staging.s3.us-east-2.amazonaws.com/public/Payroll.xlsx?timestamp=${timestamp}`;

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Payroll.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    useEffect(() => {

        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const middleOfMonth = new Date(today.getFullYear(), today.getMonth(), 15);


        if (today.getDate() <= 15) {
            setSelectedFromDate(firstDayOfMonth);
            setSelectedToDate(middleOfMonth);
        } else {
            setSelectedFromDate(middleOfMonth);
            setSelectedToDate(lastDayOfMonth);
        }
    }, []);

    useEffect(() => {
        API.graphql({
            query: `{                
                Timesheets {
                    ItemId
                    JobOrderId
                    JobOrder {
                        BillableRate
                        Rate
                        ContractId
                        ItemId
                        MemberId
                        Member {
                            FirstName
                            ItemId
                            LastName
                        }
                        Contract {
                            ItemId
                            Name
                            ClientId
                            Clients {
                                ItemId
                                Name
                            }
                        }
                    }
                }
                TimesheetEntries(order_by: {Date: asc}) {
                    Date
                    Hours
                    Category
                    Details                        
                    ItemId
                    TimesheetId
                }
            }
        `,
        }).then(({ data }) => {
            const combinedData = data.TimesheetEntries.map(entry => {
                const timesheet = data.Timesheets.find(sheet => sheet.ItemId === entry.TimesheetId);
                return { ...entry, timesheet };
            });
            setReportData(combinedData);
        }).catch(error => {
            console.error('Error fetching data:', error);

        });
    }, []);

    return (
        <div>
            <div className="flex items-center ml-5 lg:mt-5 mt-20 flex-row flex-wrap  lg:flex-wrap xl:flex-wrap">
                <div className="sm:col-span-3 w-full lg:w-auto">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                        Date From
                    </label>
                    <div className="mt-1">
                        <DatePicker
                            className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                            selected={selectedFromDate}
                            onChange={handleFromDateChange}
                            dateFormat="MM-dd-yyyy"
                            fixedHeight
                        />
                    </div>
                </div>
                <div className="col-span-3 lg:ml-5 ml-0 w-full lg:w-auto">
                    <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                        Date To
                    </label>
                    <div className="mt-1">
                        <DatePicker
                            className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                            selected={selectedToDate}
                            onChange={handleToDateChange}
                            dateFormat="MM-dd-yyyy"
                            fixedHeight
                        />
                    </div>
                </div>
                <div className="sm:col-span-3 lg:ml-5 ml-0 mt-4 w-full lg:w-auto">
                    <button
                        onClick={() => generateReport()}
                        type="button"
                        className="rounded-md w-full border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm
                               focus:outline-none sm:w-auto hover:opacity-90"
                    >
                        Generate Report
                    </button>
                </div>
                <div className="sm:col-span-3 lg:ml-5 ml-0 mt-4 w-full lg:w-auto">
                    {excelData && (
                        <button
                            onClick={downloadReport}
                            type="button"
                            className="rounded-md w-full border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm
                               focus:outline-none sm:w-auto hover:opacity-90"
                        >
                            Download Report
                        </button>
                    )}
                </div>
            </div>
            <div className="mt-5 ml-5">
                {excelData && (
                    <iframe
                        style={{ border: "3px solid black", width: "100%" }}
                        key={iframeKey}
                        height="700"
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            excelData
                        )}`} title="Payroll W2 Report"
                        allowfullscreen>
                    </iframe>
                )}

            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, flexWrap: "wrap", justifyContent: "left", }}>
                {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};

export default PayrollW2Report;
