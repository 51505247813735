import { useState } from "react";
import { API } from "aws-amplify";
import {
  BriefcaseIcon,
  ClockIcon,
  GlobeAltIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import EditProfileModal from "../Modals/EditProfileModal";

const Preferences = ({
  originalData,
  profileData,
  setProfileData,
  setConfirmButtonMessage,
}) => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onSave = async () => {
    API.graphql({
      query: `mutation($meta: jsonb) {
        update_Members_by_pk(pk_columns: {ItemId: "${profileData.ItemId}"}, 
          _set: { Meta: $meta } ) 
          {
            ItemId
            Meta
          } 
      }`,
      variables: { meta: profileData.Meta },
    }).then(async ({ data }) => {
      setModalOpen(false);
      setConfirmButtonMessage(true);
    });
  };

  return (
    <div
      className={`${
        !showPreferences ? "pb-10 p-6" : "pt-6 px-6"
      } my-5 mx-8 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 `}
    >
      <EditProfileModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        originalData={originalData}
        tempData={profileData}
        setTempData={setProfileData}
        onSave={onSave}
        inputField={[
          "ScreeningStatus",
          "EmploymentStatus",
          "WeeklyAvailability",
          "LocationAvailability",
        ]}
      />
      <div className="flex mb-6">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Preferences
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <PencilIcon
            onClick={() => setModalOpen(true)}
            className="-ml-1 mr-5 h-5 w-5 text-gray-500 cursor-pointer"
            aria-hidden="true"
          />
          {!showPreferences ? (
            <ChevronUpIcon
              onClick={() => setShowPreferences(!showPreferences)}
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
            />
          ) : (
            <ChevronDownIcon
              onClick={() => setShowPreferences(!showPreferences)}
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      <div className={`${showPreferences ? "hidden" : "block"} mx-5 px-8`}>
        <div className="grid grid-cols-4">
          <div className="font-bold text-gray-800">
            <BriefcaseIcon
              className="mr-2 mb-1 mt-1 h-4 w-4 inline teckpert-text-green"
              aria-hidden="true"
            />
            Screening Status
          </div>
          <div className="font-bold text-gray-800">
            <BriefcaseIcon
              className="mr-2 mb-1 mt-1 h-4 w-4 inline teckpert-text-green"
              aria-hidden="true"
            />
            Employment Status
          </div>
          <div className="font-bold text-gray-800">
            <ClockIcon
              className="mr-2 mb-1 mt-1 h-4 w-4 inline teckpert-text-green"
              aria-hidden="true"
            />
            Weekly Availability
          </div>
          <div className="font-bold text-gray-800">
            <GlobeAltIcon className="mr-2 mb-1 mt-1 h-4 w-4 inline teckpert-text-green" />
            Work Availability
          </div>
        </div>
        <div className="grid grid-cols-4">
          <div className="text-gray-500 ml-6 capitalize">
            {profileData.Meta?.ScreeningStatus
              ? profileData.Meta.ScreeningStatus
              : "-"}
          </div>
          <div className="text-gray-500 ml-6 capitalize">
            {profileData.Meta.EmploymentStatus
              ? profileData.Meta.EmploymentStatus
              : "-"}
          </div>
          <div className="text-gray-500 ml-6 capitalize">
            {profileData.Meta.WeeklyAvailability
              ? profileData.Meta.WeeklyAvailability + " Hours"
              : "-"}
          </div>
          <div className="text-gray-500 ml-6 capitalize">
            {profileData.Meta.LocationAvailability
              ? profileData.Meta.LocationAvailability
              : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Preferences;
