import React, { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronDownIcon,
  QrcodeIcon,
  LinkIcon,
} from "@heroicons/react/outline";
import { formatDate } from "../../../utils/FormatDate";
import Loading from "../../../utils/Loading";
// Tabs
import Description from "./Tabs/Description";
import Details from "./Tabs/Details";
import Syndication from "./Tabs/Syndication";
import Files from "./Tabs/Files";
import People from "./Tabs/People";
import Profile from "./Tabs/Profile";
import AIMatches from "./Tabs/AIMatches";
import FilteredWorkFlows from "./Tabs/FilteredWorkFlows";
// Modals
import CreateOpportunitiesModal from "./Modals/CreateOpportunitiesModal";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import QrModal from "./Modals/QrModal";
import ConfirmMessage from "../../../utils/ConfirmMessage";

const OpportunityDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]); //Opportunity Data
  const [profileData, setProfileData] = useState([]); //Members Data
  const [applicantsData, setApplicantsData] = useState([]); //Applicants Data
  const [stats, setStats] = useState([]); //Opportunity Quickstats
  const [showProfile, setShowProfile] = useState(false); //Boolean that shows/hides member profile
  const [selectedMemberId, setSelectedMemberId] = useState(""); //Select which members profile to show
  const [confirmButtonMessage, setConfirmButtonMessage] = useState(false); //Shows/Hides saved confirmation message
  const [guideFilter, setGuideFilter] = useState([]); //Filters Interview Guides that correspond to the selected opportunity
  //Modals
  const [modalOpen, setModalOpen] = useState(false); //CloneOpportunitiesModal
  const [qrModalOpen, setQrModalOpen] = useState(false); //QRModal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); //ConfirmDeleteModal
  // WorkFlow Statuses
  const [newMembers, setNewMembers] = useState([]); //New
  const [reviewMembers, setReviewMembers] = useState([]); //Review Resume
  const [screenMembers, setScreenMembers] = useState([]); //Schedule Phone Screen
  const [preScreenMembers, setPreScreenMembers] = useState([]); //Prescreen Complete
  const [assessmentMembers, setAsessmentMembers] = useState([]); //Assessment Internal
  const [secInterviewMembers, setSecInterviewMembers] = useState([]); //2nd Interview
  const [requestedMembers, setRequestedMembers] = useState([]); //Info Requested
  const [submittedMembers, setSubmittedMembers] = useState([]); //Submitted To Client
  const [interviewMembers, setInterviewMembers] = useState([]); //Client Interview
  const [awaitingMembers, setAwaitingMembers] = useState([]); //Waiting for Selection
  const [backgroundCheckMembers, setBackgroundCheckMembers] = useState([]); //Reference and Background Check
  const [otherMembers, setOtherMembers] = useState([]); //Other
  const [notQualifiedMembers, setNotQualifiedMembers] = useState([]); //Not Qualified
  const [knockoutMembers, setKnockoutMembers] = useState([]); //Not Qualified - Knockout
  const [notHiredMembers, setNotHiredMembers] = useState([]); //Not Hired
  const [hiredMembers, setHiredMembers] = useState([]); //Hired
  //Dropdowns
  const [client, setClient] = useState([]);
  const [contract, setContract] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [guide, setGuide] = useState([]);
  const [user, setUser] = useState([]);
  const [workflow, setWorkflow] = useState([]);
  //Lookup table lists
  const [fileType, setFileType] = useState([]);
  const [contractStatus, setContractStatus] = useState([]);
  const [workFlowList, setWorkFlowList] = useState([]);

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  // GET/SET OPPORTUNITIES DATA
  useEffect(() => {
    API.graphql({
      query: `{
        Opportunities(where: {ItemId: {_eq: "${location.pathname.split("/")[2]
        }"}}) {
        ItemId
        TalentMatchId
        TalentMatchResponse
        ClientId
        Applicants
        Assignee
        BillRateCompensationUnit
        BillRateContract
        Compensation
        Contract
        ContractLength
        ContractTerm
        CutOffDate
        DateCreated
        DateUpdated
        Department
        Description
        DocRefNum
        Documents {
          ItemId
          Name
          Type
          DateCreated
          Key
        }
        DueDate
        HighEndRate
        Hours
        HoursPerWeek
        HoursType
        JobNum
        Length
        Location
        LowEndRate
        NonApplicants
        PayRateCompensationUnit
        QuestionnaireFormId
        InterviewGuideId
        RFQNum
        RecruiterId
        Requirements
        RequisitionNum
        Responsibilities
        SendEmail
        Specializations
        StartDate
        Status
        Support
        Syndication_Google
        Syndication_Indeed
        Syndication_LinkedIn
        Title
        TotalCandidatesToSubmit
        TotalPositionsOpen
        Type
        WorkOrder
        WorkType
        WorkflowId
        Client {
          Name
        }
      }
    }      
  `,
    }).then(({ data }) => {
      setTableData(data.Opportunities[0]);

      let tempKeyPair = {
        OpportunityId: data.Opportunities[0].ItemId,
        InterviewGuideId: data.Opportunities[0].InterviewGuideId,
      };
      setGuideFilter(tempKeyPair);

      // SET APPLICANTS AND THE CORRESPONDING MEMBER INFO AND APPLICATION QUESTIONNAIRE RESPONSES
      API.graphql({
        query: `{
          Applicants(where: {OpportunityId: {_eq: "${location.pathname.split("/")[2]
          }"}}) {  
            ItemId
            DateCreated
            DateUpdated
            OpportunityId
            MemberId
            Interested
            WorkFlowStatus
            Members {
              AccountStatus
              BackgroundCheck                
              DateCreated
              DateUpdated
              Documents
              EmploymentType
              Enroll
              FirstName
              Flagged
              ItemId
              LastName
              Meta
              Notes
              Onboarding
              PrimaryTitle
              RecruiterId
              Resume
              SSN
              ScreeningStatus
              SignCSA
              SignNDA
              Starred
              Vetted
            }
            ApplicationQuestionnaireResponses {
              DateCreated
              Responses
              QuestionnaireId
              OpportunityId
              Members {
                FirstName
                ItemId
                LastName
                Meta
              }
              Opportunities {
                ItemId
                Title
              }
              ApplicationQuestionnaire {
                ItemId
                Title
              }
            }
          }
        }      
      `,
      }).then(({ data }) => {
        console.log("data", data);
        setApplicantsData(data.Applicants);
      });
    });
  }, [location.pathname.split("/")[2], confirmButtonMessage]);

  useEffect(() => {
    // GET/SET USER CLIENT, CONTRACT, APPLICATION QUESTIONNAIRE & INTERVIEW GUIDES NAMES FOR DROPDOWNS
    // AND DOCUMENT TYPE, CONTRACT STATUS AND WORKFLOW STATUS
    API.graphql({
      query: `{
        Clients(order_by: {Name: asc}) {
          ItemId
          Name
        }
        Contracts(order_by: {Name: asc}) {
          ItemId
          Name
          ClientId
        }
        ApplicationQuestionnaire {
          ItemId
          Title
        }
        InterviewGuides {
          ItemId
          Title
        }
        Users(order_by: {FirstName: asc}, where: {UserType: {_neq: "member"}}) {
          ItemId
          FirstName
          LastName
        }
        Members {
          ItemId
          FirstName
          LastName
          Meta
        }
        Workflows {
          ItemId
          Title
          Stages
          Default
        }
        Lookup {
          ItemId
          Name   
          Options
        }
      }      
  `,
    }).then(({ data }) => {
      setClient(data.Clients);
      setContract(data.Contracts);
      setQuestionnaire(data.ApplicationQuestionnaire);
      setGuide(data.InterviewGuides);
      setWorkflow(data.Workflows);
      setUser(data.Users);
      setProfileData(data.Members);
      setFileType(
        data.Lookup.filter((obj) => obj.Name === "DocTypesOpportunity")[0]
          .Options
      );
      setContractStatus(
        data.Lookup.filter((obj) => obj.Name === "ContractStatus")[0].Options
      );
      setWorkFlowList(
        data.Lookup.filter((obj) => obj.Name === "WorkFlowStatus")[0].Options
      );
    });
  }, []);

  // SET APPLICANTS LISTS BY WORKFLOW
  useEffect(() => {
    let tempNew = applicantsData?.filter(
      (item) => item.WorkFlowStatus == "New"
    );
    let tempSubmitted = applicantsData?.filter(
      (item) => item.WorkFlowStatus == "Submitted to Client"
    );
    setNewMembers(tempNew);
    setSubmittedMembers(tempSubmitted);
    setReviewMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "Review Resume")
    );
    setPreScreenMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Prescreen Complete"
      )
    );
    setScreenMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Schedule Phone Screen"
      )
    );
    setAsessmentMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Assessment Internal"
      )
    );
    setInterviewMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Client Interview"
      )
    );
    setSecInterviewMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "2nd Interview")
    );
    setRequestedMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "Info Requested")
    );
    setAwaitingMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Waiting for Selection"
      )
    );
    setBackgroundCheckMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Reference and Background Check"
      )
    );
    setOtherMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "Other")
    );
    setNotQualifiedMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "Not Qualified")
    );
    setKnockoutMembers(
      applicantsData?.filter(
        (item) => item.WorkFlowStatus == "Not Qualified - Knockout"
      )
    );
    setNotHiredMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "Not Hired")
    );
    setHiredMembers(
      applicantsData?.filter((item) => item.WorkFlowStatus == "Hired")
    );

    setStats([
      { name: "Total", stat: applicantsData.length },
      { name: "New", stat: tempNew?.length },
      { name: "Submitted", stat: tempSubmitted?.length },
    ]);
  }, [tableData, applicantsData]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (!tableData) return <Loading />;

  return (
    <div>
      {confirmButtonMessage && <ConfirmMessage />}
      <div className="flex overflow-y-auto h-full">
        <div className="cursor-pointer absolute top-5 left-20 z-10">
          <Link className="italic text-sm ml-10" to="/opportunities">
            <ChevronLeftIcon
              className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
              aria-hidden="true"
            />
          </Link>
        </div>

        {/* First Half */}
        <div
          className={`${showProfile ? "basis-2/6" : "basis-5/12"
            } h-full bg-[#f5f5f5] p-6 bg-white rounded-lg overflow-y-auto border border-gray-200 shadow-md`}
        >
          <div>
            <div className="">
              <div className="float-right flex flex-none relative bottom-2 mr-6">
                <QrModal
                  open={qrModalOpen}
                  setOpen={setQrModalOpen}
                  value={`http://teckpert-platform-web-dev.s3-website.us-east-2.amazonaws.com/jobs/${tableData.ItemId}`}
                />
                <QrcodeIcon
                  onClick={() => setQrModalOpen(true)}
                  className="h-6 w-6 mr-2 cursor-pointer"
                  aria-hidden="true"
                />
                |
                <span className="cursor-pointer teckpert-text-green font-bold ml-2 text-sm mt-1 flex">
                  <Link
                    key={tableData.ItemId}
                    to={`/jobs/public/${tableData.ItemId}`}
                    target="_blank"
                    className="flex"
                  >
                    <LinkIcon
                      className="h-3 w-3 mr-1 mt-1"
                      aria-hidden="true"
                    />
                    View Live
                  </Link>
                </span>
              </div>

              <div className="flex lg:flex-nowrap sm:flex-wrap mt-8 block w-full">
                {/* Title */}
                <h4 className="flex-grow text-3xl font-bold teckpert-text-green ml-7 mb-4">
                  {tableData.Title}
                </h4>
                {/* Status */}
                <select
                  className="w-40 max-h-12 mr-3 rounded justify-center rounded-md border shadow-sm px-2.5 py-3 text-sm bg-white 
                  cursor-pointer text-gray-900 hover:teckpert-bg-green hover:text-white"
                  onChange={async (e) => {
                    let temp = tableData;
                    temp.Status = e.target.value;
                    setTableData({ ...temp });
                    await API.graphql(
                      graphqlOperation(`mutation {
                      update_Opportunities_by_pk(pk_columns: {ItemId: "${tableData.ItemId}"}, 
                        _set: { Status: "${tableData.Status}" }) {
                          ItemId
                          Status
                        }
                  }`)
                    ).then(({ data }) => {
                      setConfirmButtonMessage(true);
                    });
                  }}
                  value={tableData.Status}
                >
                  <option value="" className="text-gray-800">
                    Select Status
                  </option>
                  {contractStatus?.map((obj, i) => (
                    <option key={i} value={obj}>
                      {obj}
                    </option>
                  ))}
                </select>
                {/* Options Menu */}
                <Menu as="div" className="relative flex-shrink-0 mr-5">
                  <Menu.Button className="bg-white border px-2.5 py-3 shadow-sm rounded flex flex-row-reverse text-sm focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <ChevronDownIcon
                      className="h-3 w-3 cursor-pointer mt-1 ml-4"
                      aria-hidden="true"
                    />
                    <div>More</div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item key="Clone Job">
                        {({ active }) => (
                          <a
                            className={classNames(
                              active
                                ? "teckpert-bg-green text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                            onClick={() => setModalOpen(true)}
                          >
                            Clone
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item key="Delete Job">
                        {({ active }) => (
                          <a
                            className={classNames(
                              active
                                ? "teckpert-bg-green text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                            onClick={() => setDeleteModalOpen(true)}
                          >
                            Delete
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item key="Download Applicants Excel">
                        {({ active }) => (
                          <a
                            className={classNames(
                              active
                                ? "teckpert-bg-green text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            Download Applicants (Excel and Notes)
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item key="Download Applicants Zip">
                        {({ active }) => (
                          <a
                            className={classNames(
                              active
                                ? "teckpert-bg-green text-white"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            Download Applicants (Full Zip)
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <div className="px-5">
            <Description
              tableData={tableData}
              setTableData={setTableData}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <Details
              tableData={tableData}
              setTableData={setTableData}
              client={client}
              contract={contract}
              user={user}
              questionnaire={questionnaire}
              guide={guide}
              workflow={workflow}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <Syndication
              tableData={tableData}
              setTableData={setTableData}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <Files
              tableData={tableData}
              setTableData={setTableData}
              fileType={fileType}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <People
              jobsData={tableData}
              setJobsData={setTableData}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
              opportunities={tableData}
              setOpportunities={setTableData}
              profileData={profileData}
              applicantsData={applicantsData}
              setApplicantsData={setApplicantsData}
              setSelectedMemberId={setSelectedMemberId}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
          </div>
        </div>
        {/* Second Half */}
        <div
          className={`${showProfile ? "basis-4/6" : "basis-7/12"
            } h-auto bg-[#f5f5f5] pt-10 p-6 rounded-lg overflow-y-auto border border-gray-200 shadow-md`}
        >
          {!showProfile ? (
            <div>
              <div className="mx-20">
                <dl className="mt-0 grid grid-cols-1 gap-5 sm:grid-cols-3 text-center">
                  {stats.map((item, i) => (
                    <div
                      key={item.name}
                      className="px-4 py-0 rounded-lg overflow-hidden sm:p-6"
                    >
                      <dd className="mt-1 text-4xl tracking-tight font-semibold teckpert-text-green mb-3">
                        {item.stat}
                      </dd>
                      <dt className="text-lg font-medium text-gray-900 truncate">
                        {item.name}
                      </dt>
                    </div>
                  ))}
                </dl>
              </div>
              <AIMatches opportunity={tableData} applicantsData={applicantsData} />
              <div>
                {workflow
                  .filter((obj) =>
                    tableData.WorkflowId != "null" && tableData.WorkflowId
                      ? obj.ItemId == tableData.WorkflowId
                      : obj.Default == true
                  )[0]
                  ?.Stages?.map((title, key) => {
                    return (
                      <FilteredWorkFlows
                        key={key}
                        title={title.Name}
                        members={
                          title.Name === "New"
                            ? newMembers
                            : title.Name === "Review Resume"
                              ? reviewMembers
                              : title.Name === "Schedule Phone Screen"
                                ? screenMembers
                                : title.Name === "Prescreen Complete"
                                  ? preScreenMembers
                                  : title.Name === "Assessment Internal"
                                    ? assessmentMembers
                                    : title.Name === "2nd Interview"
                                      ? secInterviewMembers
                                      : title.Name === "Info Requested"
                                        ? requestedMembers
                                        : title.Name === "Submitted to Client"
                                          ? submittedMembers
                                          : title.Name === "Client Interview"
                                            ? interviewMembers
                                            : title.Name === "Waiting for Selection"
                                              ? awaitingMembers
                                              : title.Name == "Reference and Background Check"
                                                ? backgroundCheckMembers
                                                : title.Name === "Other"
                                                  ? otherMembers
                                                  : title.Name === "Not Qualified"
                                                    ? notQualifiedMembers
                                                    : title.Name === "Not Qualified - Knockout"
                                                      ? knockoutMembers
                                                      : title.Name === "Not Hired"
                                                        ? notHiredMembers
                                                        : title.Name === "Hired"
                                                          ? hiredMembers
                                                          : null
                        }
                        submittedMembers={submittedMembers}
                        formatDate={formatDate}
                        setSelectedMemberId={setSelectedMemberId}
                        setShowProfile={setShowProfile}
                        jobsData={tableData}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            <Profile
              profileData={
                applicantsData?.filter(
                  (x) => x.Members?.ItemId == selectedMemberId
                )[0]?.Members
              }
              selectedMemberId={selectedMemberId}
              setSelectedMemberId={setSelectedMemberId}
              applicantsData={applicantsData}
              setApplicantsData={setApplicantsData}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
              guideFilter={guideFilter}
              workflows={workflow
                ?.filter((obj) =>
                  tableData.WorkflowId != "null" && tableData.WorkflowId
                    ? obj.ItemId == tableData.WorkflowId
                    : obj.Default == true
                )[0]
                .Stages?.map((title, key) => title.Name)}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
          )}
          <CreateOpportunitiesModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            tableData={tableData}
            setTableData={setTableData}
            setConfirmButtonMessage={setConfirmButtonMessage}
            action={"cloning"}
          />
          <ConfirmDeleteModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            deleteItem={async () => {
              let response = await API.graphql(
                graphqlOperation(`
                  mutation {
                    delete_Opportunities_by_pk(ItemId: "${tableData.ItemId}") {
                      ItemId
                      Title
                    }
                  }
              `)
              );
              if (response) {
                navigate("/opportunities");
                setConfirmButtonMessage(true);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default OpportunityDetails;
