import { useEffect, useState } from "react";
import { useLocation  } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import Loading from "../../utils/Loading";
import MissingFieldPrompt from "../../utils/MissingFieldPrompt";
import ConfirmMessage from "../../utils/ConfirmMessage";
import { useNavigate } from 'react-router-dom';
const TimeEntry = ({ user }) => {
   
    const navigate = useNavigate();
    const location = useLocation();
    const [tempData, setTempData] = useState({
        
        DateCreated: new Date().toISOString(),
        DateUpdated: new Date().toISOString(),
        description:"",
        Hours:8,
        Minutes:0,
        selectcategory:"",
        date: new Date(),
        selectContract: "",
        joborderid: "",
        mondayDate: null,
        sundayDate: null,
        Checkeddescription:""
    });
    const linkStyle = {
        color: 'gray',
        textDecoration: 'none',
        borderBottom: '1px solid gray',
        display: 'inline-block',
       
    };
   
    const pathParts = location.pathname.split("/");
   const idIndex = pathParts.indexOf("entertime") + 1; // Assuming 'entertime' is just before the 'id' in the path
    const isIdDefined = idIndex !== 0 && pathParts[idIndex]; // Check if 'id' is found
    let id;
  if (isIdDefined) {
  // Handle the case where 'id' is not present in the URL
       id = location.pathname.split("/")[2];
       console.log("i got id ",id)
  // You might want to redirect or display an error message to the user
} 
    const [fetchedData, setFetchedData] = useState([]);
    const [error, setError] = useState([]);
    const [confirmButtonMessage, setConfirmButtonMessage] = useState(false);
    const [data, setdata] = useState([]);
    const [contractData, setContractData] = useState([]);
    
    const [category, setcategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null); 
    const googleDocsLink = 'https://docs.google.com/document/d/16DP7PIY8HhYXEDNxZjSqTNCI_vuFZqdf2ME6JL9pCAI/';
    const [errorMessage, setErrorMessage] = useState(null);
    // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
    useEffect(() => {
        const element = document.getElementById("section-top");
        if (element) {
            element.scrollIntoView({ block: "center", behavior: "smooth" });
        }
        setTimeout(() => {
            setConfirmButtonMessage(false);
        }, 5000);
    }, [confirmButtonMessage]);
    
    useEffect(() => {
        getWeekDates(tempData.date);
    }, [tempData.date]);
    useEffect(() => {
        Setcategory(tempData.selectContract);
    }, [tempData.selectContract]);
    const Setcategory = (selectContract) => { // handling a event on change of a  category dropdown
        const selectedContract = contractData.find(contract => contract.ItemId === selectContract);
        setcategory(selectedContract ? selectedContract.Categories : []);
        console.log(tempData);
    };
    const async = async () => {
        setLoading(true);

        if (user.AccessId) {
            const whereQuery = `{
                ${user.AccessId ? `MemberId: {_eq: "${user.AccessId}"},` : ''}
        ItemId: {_is_null: false}
      }`;
            const { data } = await API.graphql({
                query: `{
            JobOrders(where: ${whereQuery}) {
     MemberId
    ItemId
    Contract {
      Name
      ItemId
      Categories
    }
  }  
      }`});
           
            let temp = tempData;
            const Contract = data.JobOrders.map(jobOrder => jobOrder.Contract);//getting all contrect data 
            const filteredContract = Contract.filter(Contract => Contract !== undefined && Contract !== null);//filtering a undefined data
            console.log("data:", data);
            setdata(data);
             setcategory(filteredContract[0]?.Categories);// setting categorieos while loading a page to get defult values when page loaded
            temp.selectContract = filteredContract[0]?.ItemId;// setting Contract while loading a page to get defult values when page loaded
            const selectedjoborder = data.JobOrders.find(contract => contract.Contract != null && contract.Contract.ItemId === filteredContract[0].ItemId)
            temp.joborderid = selectedjoborder?.ItemId;
            // temp.joborderid = selectedjoborder.ItemId;
            setTempData({ ...temp })
            
            setContractData(filteredContract);
            
            setLoading(false);
        }
    }
    const fetchData = async () => {
        try {
            const { data } = await API.graphql({
                query: `
                  query MyQuery($id: String!) {
                    Timesheets(where: { TimesheetEntries: { ItemId: { _eq: $id } } }) {
                      JobOrderId                      
                      JobOrder {
                        ContractId
                      }
                      TimesheetEntries(where: { ItemId: { _eq: $id } }) {
                        ItemId
                        Category
                        Date
                        Details
                        Hours
                      }
                    }
                  }
                `,
                variables: { id },
            });


            let temp = tempData;
            
            var timeEntryData = data.Timesheets.map(x => x.JobOrder);
            var Timesheetentries = data.Timesheets.map(x => x.TimesheetEntries);
            var Timesheetentriesdata = Timesheetentries[0];
            temp.description = Timesheetentriesdata[0].Details;
            const Contract = timeEntryData[0]?.ContractId;
            const joborderid = data.Timesheets[0].JobOrderId;
            temp.joborderid = joborderid;
            console.log("Timesheetentries", Timesheetentriesdata);           
            temp.selectContract = Contract;
            const selectedContract = contractData.find(contract => contract.ItemId === Contract);
            if (selectedContract)
            setcategory(selectedContract ? selectedContract.Categories : []);
            temp.selectcategory = Timesheetentriesdata[0]?.Category;
            temp.date = new Date(new Date(Timesheetentriesdata[0]?.Date).toLocaleDateString('en-us', { timeZone: 'utc' }));
            
            var timelogged = Timesheetentriesdata[0]?.Hours;
            if (timelogged != null && typeof timelogged === "number") {
                const hours = Math.floor(timelogged); // Extract whole hours
                const minutes = Math.round((timelogged % 1) * 60); // Extract remaining minutes

                temp.Hours = hours;
                temp.Minutes = minutes;
            } else {
                // Handle the case where timelogged is not a valid number
                console.error("Invalid timelogged value:", timelogged);
                // You can choose to set 'Hours' and 'Minutes' to default values or handle it as needed
                temp.Hours = null;
                temp.Minutes = null; // or a default value
            }
            setTempData({ ...temp })

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        // Define an async function within the useEffect
        const fetchDataAndAsync = async () => {            
            await async();

            // Call fetchData only if 'id' is defined
            if (id) {
                await fetchData();
            }
        };

        // Call the fetchDataAndAsync function
        fetchDataAndAsync();
    }, [user, id]);
    

    const handleContractChange = (e) => { // handling a event on change of a  category dropdown 
        
        let temp = tempData;
        temp.selectContract = e.target.value;
        const selectedContract = contractData.find(contract => contract.ItemId === e.target.value);
        const selectedjoborder = data.JobOrders.find(contract => contract.Contract != null && contract.Contract.ItemId === e.target.value)
        temp.joborderid = selectedjoborder.ItemId;
        setTempData({ ...temp })
        setcategory(selectedContract ? selectedContract.Categories : []);
        console.log(tempData);
    };

    const getWeekDates = (date) => {

        const today = new Date(date);
        const currentDay = today.getDay(); // Use getUTCDay instead of getDay
        // Calculate the difference to Monday
        const differenceToMonday = currentDay === 0 ? 6 : currentDay - 1;
        // Calculate dates for Monday and the upcoming Sunday
        const mondayDate = new Date(today);
        mondayDate.setDate(today.getDate() - differenceToMonday); // Use setUTCDate instead of setDate
        const sundayDate = new Date(mondayDate);
        sundayDate.setDate(mondayDate.getDate() + 6); // Use setUTCDate instead of setDate
        // Format the dates
        const formattedMondayDate = `${mondayDate.getFullYear()}-${(mondayDate.getMonth() + 1).toString().padStart(2, '0')}-${mondayDate.getDate().toString().padStart(2, '0')}`;

        const formattedSundayDate = `${sundayDate.getFullYear()}-${(sundayDate.getMonth() + 1).toString().padStart(2, '0')}-${sundayDate.getDate().toString().padStart(2, '0')}`;
        // Store the dates in your data structure (tempData)
        let temp = { ...tempData };
        temp.mondayDate = formattedMondayDate;
        temp.sundayDate = formattedSundayDate;
        // Update the state or data structure with the new dates
        setTempData(temp);
    };

    const handleCheckboxChange = (checkboxId) => {
        
        if (["pto-checkbox"].includes(checkboxId)) {
            // If yes, set Hours and Minutes to 0
            let temp = { ...tempData };
            temp.Hours = 0;
            temp.Minutes = 0;
            setChecked(false);
            // Set description to the name of the selected checkbox
            temp.Checkeddescription = checkboxId === 'pto-checkbox' ? 'PTO' : "";
            
            setTempData(temp);
        } else if (["Holiday-checkbox"].includes(checkboxId)) {
            // If yes, set Hours and Minutes to 0
            let temp = { ...tempData };
            temp.Hours = 0;
            temp.Minutes = 0;
            setChecked(true);
            // Set description to the name of the selected checkbox
            temp.Checkeddescription = checkboxId === 'Holiday-checkbox' ? 'Holiday' : "";
            
            setTempData(temp);
        } else if (["UnpaidLeave-checkbox"].includes(checkboxId)) {
            // If yes, set Hours and Minutes to 0
            let temp = { ...tempData };
            temp.Hours = 0;
            temp.Minutes = 0;
            setChecked(false);
            // Set description to the name of the selected checkbox
            temp.Checkeddescription = checkboxId === 'UnpaidLeave-checkbox' ? 'Unpaid Leave' : "";
            
            setTempData(temp);
        } else if (["SickDay-checkbox"].includes(checkboxId)) {
            // If yes, set Hours and Minutes to 0
            let temp = { ...tempData };
            temp.Hours = 0;
            temp.Minutes = 0;
            setChecked(false);
            // Set description to the name of the selected checkbox
            temp.Checkeddescription = checkboxId === 'SickDay-checkbox' ? 'Sick Day' : "";
            
            setTempData(temp);
        }

        setSelectedCheckbox(checkboxId);
    };

    
    if (loading) return <Loading />
    const resetTempData = () => {
        setTempData({
            DateCreated: new Date().toISOString(),
            DateUpdated: new Date().toISOString(),
            description: "",
            Hours: 8,
            Minutes: 0,
            selectcategory: tempData.selectcategory,
            date: new Date(tempData.date.setDate(tempData.date.getDate() + 1)),
            selectContract: tempData.selectContract,
            mondayDate: null,
            sundayDate: null,
            Checkeddescription: "",
            joborderid: tempData.joborderid
        });
        //setcategory([]);
    };
    return (

        <div className="Container lg:mt-5 mt-10 lg:ml-10">
            {confirmButtonMessage && <ConfirmMessage />}
            <div className="flex flex-row flex-wrap  lg:flex-wrap xl:flex-wrap lg:w-3/5">
                <form className='w-full lg:w-1/3  ' >
                    <label htmlFor="name" >Contract</label>
                    <br />
                    <select
                        className="w-full lg:w-4/5 rounded-full justify-center rounded-md 
            border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
            text-gray-900 hover:teckpert-bg-green hover:text-white"
                        value={tempData.selectContract}
                        onChange={handleContractChange}
                    >
                        <option value="" className="text-gray-400">
                            Select Contract
                        </option>
                        {contractData && contractData.map((obj, i) => (
                            <option key={i} value={obj.ItemId}>
                                {obj.Name}
                            </option>
                        ))}
                    </select>
                </form>
                <form className='w-full lg:w-1/3  '>
                    <label  htmlFor="name">Category</label>
                    <br />
                    <select
                        className="w-full  lg:w-4/5  rounded-full justify-center rounded-md 
            border shadow-sm px-6 py-3 text-sm bg-white cursor-pointer
            text-gray-900 hover:teckpert-bg-green hover:text-white"

                        value={tempData.selectcategory}
                        onChange={(e) => {
                            let temp = tempData;
                            temp.selectcategory = e.target.value;
                            setTempData({ ...temp });
                        }}

                    >
                        <option value="" className="text-gray-400">
                            Select Category
                        </option>
                        {category && category.map((cat, i) => (
                            <option key={i} value={cat}>
                                {cat}
                            </option>
                        ))}
                    </select>
                </form>

                <div className='w-full lg:w-1/3  '>
                    <label  htmlFor="name">Date</label>
                    <br />
                    <div>
                        <DatePicker
                            className="w-full   rounded-full justify-center rounded-md 
        border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
        text-gray-900 hover:teckpert-bg-green hover:text-white"
                            label="Controlled picker"
                            selected={tempData.date}
                            calendarStartDay={1}
                            onChange={(e) => {
                                let temp = tempData;
                                temp.date = e;
                                setTempData({ ...temp });
                                getWeekDates(e);
                            }} />
                    </div>
                </div>


            </div>

            <div className="flex flex-wrap justify-start mt-5 lg:mb-5 ">
                <div className="form-group w-full lg:w-1/5  " style={{ color: '#383A44' }}>
                    <form>
                        <div className="flex flex-col  rounded-md " style={{ backgroundcolor: '#383A44' }}>

                            <label className=" ml-2 text-black">Time</label>
                            <div className="flex  ">
                                <input
                                    type="number"
                                    className="w-1/2 lg:w-auto xl:w-auto xs:w-auto md:w-auto mr-3 rounded-full justify-center rounded-md border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer text-gray-900 pl-5"
                                    id="hours"
                                    readOnly={isChecked}
                                    placeholder="Hours"
                                    min="0"
                                    max="24"
                                    value={tempData.Hours}
                                    onChange={(e) => {
                                        let temp = tempData;
                                        temp.Hours = e.target.value;
                                        setTempData({ ...temp });
                                    }}
                                />
                                <input
                                    type="number"
                                    className="w-1/2 lg:w-auto xl:w-auto xs:w-auto md:w-auto rounded-full justify-center rounded-md border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer text-gray-900"
                                    id="minutes"
                                    readOnly={isChecked}
                                    placeholder="Minutes"
                                    step="15"
                                    min="0"
                                    max="45"
                                    value={tempData.Minutes}
                                    onChange={(e) => {
                                        let temp = tempData;
                                        temp.Minutes = e.target.value;
                                        setTempData({ ...temp });
                                    }}
                                />
                            </div>
                            <div className="ml-5"><label className="mr-5">hours</label><label className="lg:ml-12 ml-24">minutes</label></div>
                        </div>
                    </form>
                </div>
                <div className="flex items-center mt-3  ">
                    <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={selectedCheckbox === 'pto-checkbox'}
                        onChange={() => handleCheckboxChange('pto-checkbox')}
                        className="w-4 h-4 mr-1 text-green-600 bg-gray-100 border-green-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="default-checkbox" className="text-sm text-gray-900 dark:text-gray-300">PTO</label>
                </div>

                <div className="flex items-center lg:ml-4  mt-3  ">
                    <input
                        id="Holiday-checkbox"
                        type="checkbox"
                        checked={selectedCheckbox === 'Holiday-checkbox'}
                        onChange={() => handleCheckboxChange('Holiday-checkbox')}
                        className="w-4 h-4 mr-1 text-green-600 bg-gray-100 border-green-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="Holiday-checkbox" className="text-sm text-gray-900 dark:text-gray-300">Holiday</label>
                </div>

                <div className="flex items-center lg:ml-4  mt-3">
                    <input
                        id="SickDay-checkbox"
                        type="checkbox"
                        checked={selectedCheckbox === 'SickDay-checkbox'}
                        onChange={() => handleCheckboxChange('SickDay-checkbox')}
                        className="w-4 h-4 mr-1 text-green-600 bg-gray-100 border-green-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="SickDay-checkbox" className="text-sm text-gray-900 dark:text-gray-300">Sick Day</label>
                </div>

                <div className="flex items-center lg:ml-4  mt-3">
                    <input
                        id="UnpaidLeave-checkbox"
                        type="checkbox"
                        checked={selectedCheckbox === 'UnpaidLeave-checkbox'}
                        onChange={() => handleCheckboxChange('UnpaidLeave-checkbox')}
                        className="w-4 h-4 mr-1  text-green-600 bg-gray-100 border-green-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="UnpaidLeave-checkbox" className="text-sm text-gray-900 dark:text-gray-300">Unpaid Leave</label>
                </div>
            </div>

             
               
            

            <div className='w-full lg:w-3/5 md:w-3/5 xl:w-3/5 mt-2 lg:mt-5'>
                <label htmlFor="name">Description:</label>
                <textarea
                    className="w-full  mt-1 rounded-full justify-center rounded-md 
                             border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
                             text-gray-900 "
                    id="name"
                    placeholder="Description"                    
                    rows={4}
                    value={tempData.description}
                    onChange={(e) => {
                        let temp = tempData;
                        temp.description = e.target.value;
                        setTempData({ ...temp });
                    }}
                ></textarea>
            </div>
            <div className="flex justify-center mt-2 ">
                <button
                    type="button"
                    className="w-full lg:w-auto lg:absolute  lg:left-[58%]   lg:rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium hover:opacity-90 text-white shadow-sm focus:outline-none"
                    onClick={async () => {
                        
                        let errorArray = [];
                        if (tempData.description?.trim().length === 0 && tempData.Checkeddescription?.trim().length === 0)
                            errorArray.push("description");
                        if (tempData.selectcategory?.trim().length === 0)
                            errorArray.push("category");
                        if (tempData.Hours === '')
                            errorArray.push("Hours");
                        if (tempData.Minutes === '')
                            errorArray.push("Minutes");
                        if (tempData.Hours === '24' && tempData.Minutes != '0')
                            errorArray.push("Time can be maximum 24 Hours");
                        if (tempData.date === null)
                            errorArray.push("date");
                        if (tempData.selectContract?.trim().length === 0)
                            errorArray.push("Contract");
                        setError(errorArray);
                        console.log("errorArray data", tempData);
                        if (errorArray.length > 0) {
                            return;
                        }
                        let temp = tempData;
                        setTempData({ ...temp });
                        await getWeekDates(temp.date);

                        console.log("Timesheets getWeekDates Finish");
                        console.log("Timesheets " + tempData?.mondayDate);
                        console.log("Timesheets " + tempData?.sundayDate);

                        const { data: { Timesheets } } = await API.graphql({
                            query: `
                                      {
                                 Timesheets(
                             where: {
                             PeriodStart: {_eq: "${tempData?.mondayDate}"},
                             JobOrderId: {_eq: "${tempData?.joborderid}"},
                             PeriodEnd: {_eq: "${tempData?.sundayDate}"}
                                }
                               ) {
                               ItemId
                               JobOrderId
                               Status
                           
                           }
                              }
                               `,
                        });

                        console.log("Timesheets data", Timesheets);
                        var existingTimesheetId = "";
                        if (Timesheets && Timesheets.length > 0) {
                            existingTimesheetId = Timesheets[0].ItemId;
                            if (Timesheets[0].Status ==="Submitted") {
                                setErrorMessage("Error: Timesheet is already submitted");
                                
                                return;
                               
                            }

                        }
                        else {
                            //Create Timesheet Process
                            console.log("New Timesheets data insert Start");


                            await API.graphql(
                                graphqlOperation(`mutation MyMutation {
                                  insert_Timesheets_one(object: {ItemId: "${uuidv4()}", JobOrderId:  "${tempData?.joborderid}", PeriodEnd: "${tempData?.sundayDate}", PeriodStart: "${tempData?.mondayDate}"}) {
                                      ItemId
                                  }
                                }`)
                            ).then(({ data }) => {
                                console.log("Inserted Timesheets data", data);
                                existingTimesheetId = data.insert_Timesheets_one.ItemId;
                            });
                        }
                        console.log("New Timesheets Entry  insert Start", id);
                      
                        //TimesheetEntry insert
                        const description = JSON.stringify(
                            tempData?.description + (tempData?.Checkeddescription ? ":" : "") + tempData?.Checkeddescription);

                        if (!id) {
                            const dateObject = tempData?.date;
                            const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
                            await API.graphql(
                                graphqlOperation(`mutation MyMutation {
                              insert_TimesheetEntries_one(object: {Category: "${tempData?.selectcategory}", Date: "${formattedDate}", Details: ${description}, Hours:  ${parseFloat((parseInt(tempData?.Hours, 10) + parseInt(tempData?.Minutes, 10) / 60).toFixed(2))}, ItemId: "${uuidv4()}", Status: "complete", TimesheetId: "${existingTimesheetId}"}) {
                                ItemId
                              }
                            }`)
                            ).then(({ data }) => {
                                console.log("Inserted Timesheets Entry data", data);
                                setConfirmButtonMessage(true);
                                resetTempData();
                                setSelectedCheckbox(null);
                                setChecked(false);
                            });
                        } else {
                            const dateObject = tempData?.date;
                            const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
                            await API.graphql(
                                graphqlOperation(`
                                    mutation MyMutation($id: String!) {
                                        update_TimesheetEntries_by_pk(
                                            pk_columns: { ItemId: $id },
                                            _set: {
                                                Category: "${tempData?.selectcategory}",
                                                Date: "${formattedDate}",
                                                Details: ${description},
                                                Hours: ${parseFloat((parseInt(tempData?.Hours, 10) + parseInt(tempData?.Minutes, 10) / 60).toFixed(2))}
                                                TimesheetId: "${existingTimesheetId}"
                                                DateUpdated:"${new Date().toISOString() }"
                                            }
                                        ) {
                                            ItemId
                                        }
                                    }
                                `, { id })
                            ).then(({ data }) => {
                                console.log("Inserted Timesheets Entry data", data);
                                 navigate(`/mytimesheets`);
                            })

                        }
                    }}
                >
                    Submit
                    </button>
               </div> 
            
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, flexWrap: "wrap", justifyContent: "left", }}>
                {error.length > 0 && MissingFieldPrompt(error)}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, flexWrap: "wrap", justifyContent: "left", }}>
                {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
            </div>
            
            <a href={googleDocsLink} target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Link To TECKpert Paid Holidays 2024 for Employees
            </a>
            

        </div>
        
    );
};

export default TimeEntry;
