import { useEffect, useState } from "react";
import { ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import { formatPhone } from "../../../../utils/FormatPhone";
import { toBase64 } from "../../../../utils/ToBase64";
import { v4 as uuidv4 } from "uuid";
import Autocomplete from "react-google-autocomplete";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";
import AppliedModal from "./AppliedModal";
import LoginModal from "../Modals/LoginModal";
import QuestionnaireForm from "./QuestionnaireForm";

const ApplicationForm = ({
  setAppliedMsg,
  jobsData,
  applicantsData,
  setApplicantsData,
  membersData,
  emailList,
  questionnaire,
  setQuestionnaire,
  responsesData,
  setResponsesData,
  newResponse,
  setNewResponse,
}) => {
  const userAttributes = JSON.parse(localStorage.getItem("attributes")) ?? {};
  const navigate = useNavigate();
  const location = useLocation();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [appliedModalOpen, setAppliedModalOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [tempLocation, setTempLocation] = useState("");
  const [tempData, setTempData] = useState({
    Email: userAttributes?.["email"] ?? "",
    FirstName: userAttributes?.["custom:firstName"] ?? "",
    LastName: userAttributes?.["custom:lastName"] ?? "",
    Phone: userAttributes?.["custom:phone"] ?? "",
    Location: userAttributes?.["custom:location"] ?? "",
    Resume: userAttributes?.["custom:resume"] ?? "",
  });
  const [error, setError] = useState([]);
  const [loginError, setLoginError] = useState(false);
  const [knockout, setKnockout] = useState(false);
  const [documentData, setDocumentData] = useState({
    Key: "",
    Folder: "",
    Name: "",
    ClientId: "",
    DateCreated: new Date(),
    CreatorName: localStorage.getItem("name"),
    Type: "",
  });

  useEffect(() => {
    setTempData({
      Email: userAttributes?.["email"] ?? "",
      FirstName: userAttributes?.["custom:firstName"] ?? "",
      LastName: userAttributes?.["custom:lastName"] ?? "",
      Phone: userAttributes?.["custom:phone"] ?? "",
      Location: userAttributes?.["custom:location"] ?? "",
      Resume: userAttributes?.["custom:resume"] ?? "",
    });
  }, [loggedIn]);

  useEffect(() => {
    setError([]);
  }, [tempData]);

  useEffect(() => {
    let temp = tempData;
    temp.Location = tempLocation;
    setTempData({ ...temp });
  }, [tempLocation]);

  useEffect(() => {
    // Check if user is logged in.
    Auth.currentAuthenticatedUser().then((user) => {
      setLoggedIn(true);
    });
  }, [location.pathname, loginModalOpen]);

  useEffect(() => {
    // Check if user already exists.
    if (tempData.Email?.trim().length > 1) {
      const async = async () => {
        const response = await API.post("public", "/checkEmail", {
          body: { email: tempData.Email },
        });
        setEmailExist(response.exists);
      };
      async();
    }

    // If user has already applied, show confirmation message and don't allow them to apply again.
    if (emailList.includes(tempData.Email)) setAppliedMsg(true);
  }, [tempData.Email, userAttributes["email"]]);

  const signUp = async (e) => {
    let errorArray = [];

    // If account already exists and you're not logged in, prompt user to login.
    if (emailExist & !loggedIn) {
      setLoginError(true);
    } else {
      setLoginError(false);
      // If user hasn't already applied
      if (!emailList.includes(tempData.Email)) {
        // Validation for all required fields
        if (tempData.Email?.trim().length === 0) errorArray.push("Email");
        if (tempData.FirstName?.trim().length === 0)
          errorArray.push("First Name");
        if (tempData.LastName?.trim().length === 0)
          errorArray.push("Last Name");
        if (tempData.Location?.trim().length === 0) errorArray.push("Location");
        if (tempData.Phone?.trim().length === 0) errorArray.push("Phone");
        if (tempData.Resume.length === 0) errorArray.push("Resume");
        questionnaire?.Questions?.map((obj, i) =>
          obj.Required && newResponse?.Responses[i]?.Answer?.trim().length === 0
            ? errorArray.push(`Question  #${i + 1}`)
            : null
        );
        setError(errorArray);
        if (errorArray.length > 0) {
          return;
        }

        let temp = tempData;
        let tempResponse = newResponse;
        let currentUser = membersData?.filter(
          (obj) => obj.Meta.Email == tempData.Email
        )[0];

        // If account doesn't exist, create one.
        if (!emailExist) {
          let response = await API.post("public", "/submitApplication", {
            body: {
              opportunityId: jobsData.ItemId,
              email: tempData.Email,
              firstName: tempData.FirstName,
              lastName: tempData.LastName,
              location: tempData.Location,
              phone: tempData.Phone,
              resumeBase64: tempData.ResumeBase64,
              resumeName: tempData.ResumeName,
              resumeContentType: tempData.ResumeContentType,
            },
          });

          if (response.itemId) {
            // On account creation
            temp.ItemId = response.itemId;
            tempResponse.MemberId = response.itemId;
          }
        } else {
          // Edit Member's Profile Info
          currentUser.Meta.Mobile = tempData.Phone;
          currentUser.Meta.Location = tempData.Location;
          currentUser.Meta.Resume = documentData.Key;
          currentUser.Meta.Email = tempData.Email;

          await API.graphql({
            query: `mutation($meta: jsonb) {
              update_Members_by_pk(pk_columns: {ItemId: "${currentUser.ItemId}"},
                _set: { Meta: $meta, FirstName: "${tempData.FirstName}", LastName: "${tempData.LastName}", Resume: "${documentData.Key}" } )
                {
                  ItemId
                  Meta
                  FirstName
                  LastName
                  Resume
                }
            }`,
            variables: { meta: currentUser.Meta },
          }).then(async ({ data }) => {});
          await API.graphql({
            query: `mutation {
              insert_Documents_one(object: {Key: "${documentData.Key}", Folder: "${documentData.Folder}", Name: "${documentData.Name}", ClientId: "${documentData.ClientId}", CreatorName: "${documentData.CreatorName}", Type: "${documentData.Type}", MemberId: "${currentUser.ItemId}"}) {
                ItemId
              }
            }`,
          });
        }

        // If user is knocked out change Workflow Status
        let tempStatus =
          newResponse?.Responses?.filter((obj, i) => obj.Knockout == true)
            .length > 0
            ? "Not Qualified - Knockout"
            : "New";

        // Apply to Opportunity
        API.post("public", "/graphql", {
          body: {
            query: `mutation {
            insert_Applicants_one (
              object:
                {
                  ItemId: "${uuidv4()}",
                  MemberId: "${
                    currentUser ? currentUser.ItemId : tempResponse.MemberId
                  }",
                  OpportunityId: "${newResponse.OpportunityId}",
                  Interested: "Yes",
                  WorkFlowStatus: "${tempStatus}",
                }
              )
              {
                ItemId
                MemberId
                OpportunityId
                WorkFlowStatus
              }
          }`,
          },
        }).then(({ data }) => {
          let tempData = applicantsData;
          tempData = [
            {
              ItemId: data.insert_Applicants_one.ItemId,
              MemberId: currentUser
                ? currentUser.ItemId
                : tempResponse.MemberId,
              OpportunityId: newResponse.OpportunityId,
              Interested: "Yes",
              WorkFlowStatus: knockout ? "Not Qualified - Knockout" : "New",
            },
            ...applicantsData,
          ];
          setApplicantsData(tempData);
        });

        //Submit Questionnaire Responses
        API.post("public", "/graphql", {
          body: {
            query: `mutation {
              insert_ApplicationQuestionnaireResponses_one(
                object: 
                {
                  ItemId: "${uuidv4()}",
                  OpportunityId: "${newResponse.OpportunityId}", 
                  MemberId: "${
                    currentUser ? currentUser.ItemId : tempResponse.MemberId
                  }", 
                  QuestionnaireId: "${questionnaire.ItemId}",
                  DateCreated: "${newResponse.DateCreated}",
                  Responses: [${newResponse.Responses?.map(
                    (obj) =>
                      `{
                        Question: "${obj.Question}",
                        AnswerType: "${obj.AnswerType}",
                        Answer: "${obj.Answer}",
                        Knockout: ${obj.Knockout},
                      }`
                  ).join(`,`)}]
                })
                {
                  ItemId
                  QuestionnaireId
                  Responses
                  MemberId
                }         
            }`,
          },
        }).then(({ data }) => {
          // If successful, show confirmation message.
          setAppliedModalOpen(true);
          setAppliedMsg(true);
        });
      }
    }
  };

  return (
    <div className="lg:mb-8 sm:mb-0 lg:mt-5 sm:mt-0 lg:mx-8 sm:mx-5 pb-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />
      {/* {console.log("userAttributes", userAttributes)} */}
      <AppliedModal
        open={appliedModalOpen}
        setOpen={setAppliedModalOpen}
        name={tempData.FirstName}
      />

      <div className="text-2xl text-gray-800 font-bold mb-9">
        Apply for this position
      </div>
      <div>
        {loggedIn ? (
          <div className="flex text-sm justify-end mr-4">
            <ExclamationCircleIcon
              className="h-5 w-5 teckpert-text-green mr-1"
              aria-hidden="true"
            />
            You are logged in as
            <span className="text-blue-600 ml-1">
              {userAttributes["email"]}
            </span>
            . Not you?&nbsp;
            <span
              onClick={() => {
                localStorage.removeItem("name");
                Auth.signOut();
                navigate("/login");
              }}
              className="teckpert-text-green cursor-pointer underline font-bold"
            >
              Logout
            </span>
          </div>
        ) : emailExist ? (
          <div className={`flex font-bold text-sm justify-end mr-4`}>
            <ExclamationCircleIcon
              className="h-5 w-5 teckpert-text-green mr-1"
              aria-hidden="true"
            />
            This email address is already registered.&nbsp;
            <span
              onClick={() => setLoginModalOpen(true)}
              className={`
                ${
                  loginError
                    ? "border border-solid border-red-500 px-2 rounded"
                    : ""
                } teckpert-text-green cursor-pointer`}
            >
              Log in
            </span>
          </div>
        ) : emailList.includes(tempData.Email) ? (
          <div className="flex font-bold text-sm justify-end mr-4">
            <ExclamationCircleIcon
              className="h-5 w-5 teckpert-text-green mr-1"
              aria-hidden="true"
            />
            You have already applied to this position.
          </div>
        ) : null}
        <div className="sm:col-span-2 flex-grow">
          <div className="mt-2 grid grid-cols-5">
            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              onBlur={async () => {
                if (tempData.Email.trim().length > 1) {
                  const response = await API.post("public", "/checkEmail", {
                    body: { email: tempData.Email },
                  });
                  setEmailExist(response.exists);
                }
              }}
              onChange={(e) => {
                let temp = tempData;
                temp.Email = e.target.value;
                setTempData({ ...temp });
              }}
              value={tempData.Email}
              type="text"
              name="email"
              id="email"
              autoComplete="email"
              className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
            />
          </div>
        </div>
        <div>
          <div className="sm:col-span-2 flex-grow">
            <div className="mt-2 grid grid-cols-5">
              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                onChange={(e) => {
                  let temp = tempData;
                  temp.FirstName = e.target.value;
                  setTempData({ ...temp });
                }}
                value={tempData.FirstName}
                type="text"
                name="name"
                id="name"
                autoComplete="first-name"
                className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
              />
            </div>
          </div>

          <div className="sm:col-span-2 flex-grow">
            <div className="mt-2 grid grid-cols-5">
              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                onChange={(e) => {
                  let temp = tempData;
                  temp.LastName = e.target.value;
                  setTempData({ ...temp });
                }}
                value={tempData.LastName}
                type="text"
                name="name"
                id="name"
                autoComplete="last-name"
                className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
              />
            </div>
          </div>

          <div className="sm:col-span-2 flex-grow">
            <div className="mt-2 grid grid-cols-5">
              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                Location
              </label>
              <Autocomplete
                className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                text-gray-900 hover:teckpert-bg-green hover:text-white"
                apiKey={"AIzaSyAVDbuhk4LVrRFHVjyB4Zk5bHs3QgWiX_Y"}
                onPlaceSelected={async (place) => {
                  setTempLocation(place.formatted_address);
                }}
                defaultValue={tempData.Location}
                placeholder=""
              />
            </div>
          </div>

          <div className="sm:col-span-2 flex-grow">
            <div className="mt-2 grid grid-cols-5">
              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                onChange={(e) => {
                  let temp = tempData;
                  temp.Phone = e.target.value.replace(/\D+/g, "").slice(0, 10);
                  setTempData({ ...temp });
                }}
                value={formatPhone(tempData.Phone)}
                type="text"
                name="phone"
                id="phone"
                autoComplete="phone"
                className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
              />
            </div>
          </div>

          <div className="sm:col-span-2 flex-grow">
            <div className="mt-2 grid grid-cols-5">
              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                Resume
              </label>
              {userAttributes?.["custom:resume"]
                ? `Resume already uploaded`
                : ``}
              <input
                onChange={async (e) => {
                  setTempData({
                    ...tempData,
                    Resume: "true",
                    ResumeBase64: await toBase64(e.target.files[0]),
                    ResumeName: e.target.files[0].name,
                    ResumeContentType: e.target.files[0].type,
                  });
                  setDocumentData({
                    Key: e.target.files[0].name,
                    Folder: "testing",
                    Name: e.target.files[0].name,
                    MemberId: location.pathname.split("/")[2],
                    DateCreated: new Date().toISOString(),
                    CreatorName: localStorage.getItem("name"),
                    Type: "Resume",
                  });
                }}
                type="file"
                name="file"
                id="file"
                autoComplete="file"
                className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
              />
            </div>
          </div>
        </div>
        {newResponse?.Responses && (
          <QuestionnaireForm
            jobsData={jobsData}
            newResponse={newResponse}
            setNewResponse={setNewResponse}
            questionnaire={questionnaire}
            setQuestionnaire={setQuestionnaire}
            responsesData={responsesData}
            setResponsesData={setResponsesData}
            knockout={knockout}
            setKnockout={setKnockout}
          />
        )}
        {error.length > 0 && MissingFieldPrompt(error)}
        {loginError && !loggedIn && (
          <div className="rounded-md bg-red-50 p-4 mt-10">
            <div className="flex ">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 text-sm font-medium text-red-800">
                Please log in to continue.
              </div>
            </div>
          </div>
        )}

        <div className="mt-5 flex justify-end">
          <button
            onClick={signUp}
            type="button"
            className={`${
              emailList.includes(tempData.Email)
                ? "opacity-50"
                : "opacity-100 hover:opacity-90"
            } mt-3 rounded-md float-right teckpert-bg-green px-4 py-3 text-base font-medium text-white shadow-sm 
            sm:mt-0 sm:w-auto sm:text-sm`}
          >
            Submit Application
          </button>
        </div>
      </div>
    </div>
  );
};
export default ApplicationForm;
