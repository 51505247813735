import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Table from "./Table";
import Timesheets from "../Timesheets/Timesheets";
import AddInvoiceModal from "./AddInvoiceModal";
import Loading from "../../utils/Loading";

const Invoice = () => {
  const itemsPerPage = 25;
  const [tableData, setTableData] = useState([]);
  const [memberTotal, setMemberTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [addClientOpen, setAddClientOpen] = useState("");
  const [openModal, setOpenModal] = useState(false);
   const [loading, setLoading] = useState(true);
  const [quickbookCompanies, setQuickBookCompanies] = useState([]);
  const [selectedTimesheets, setSelectedTimesheets] = useState([]);

  useEffect(() => {
    API.graphql({
      query: `{
      Invoices(offset: ${(page - 1) * itemsPerPage
        }, limit: ${itemsPerPage}, order_by: {DateCreated: desc}) {
        ItemId
        DateCreated
        DateUpdated
        Workers
        PMDescription
        PMFee
        PMPercentage
        CTODescription
        CTOFee
        CTOPercentage
        ContractId
        Amount
        Status
        QuickbooksId
        QuickbooksLink
        QuickbooksCompanyId
        Contract {
          ItemId
          Name
          Clients {
            ItemId
            Name
          }
        }
      }
    }      
  `,
    }).then(({ data }) => {
        setTableData(data.Invoices);
        setLoading(false);
      // setMemberTotal(responseJson.RecordsTotal);
      // setTempTable(data.Opportunities);
      // setMemberTotal(data.Opportunities_aggregate.aggregate.count);
      // setAllOpportunitiesCalc(1);
      // setMyOpportunitiesCalc(2);
    });
  }, [page]);

  // useEffect(() => {
  //   API.get("auth", "/quickbookcompanies/dropdown").then((responseJson) => {
  //     setQuickBookCompanies(responseJson.Output);
  //   });
  // }, []);

  // useEffect(() => {
  //   API.get(
  //     "auth",
  //     `/invoices?ItemsPerPage=${itemsPerPage}&&Page=${page - 1}`
  //   ).then((responseJson) => {
  //     setTableData(responseJson.Output);
  //     setMemberTotal(responseJson.RecordsTotal);
  //   });
  // }, [page]);
    if (loading) return <Loading />
  return (
    <div>
      <div className="flex justify-end mx-4">
        <div
          className={`${addClientOpen === "show-timesheet-table" && "w-full "
            } mt-4 sm:mt-0 sm:ml-16 flex justify-between`}
        >
          {addClientOpen === "show-timesheet-table" && (
            <button
              type="button"
              className="mr-5 rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
              onClick={() => setAddClientOpen("")}
            >
              Go Back
            </button>
          )}
          <button
            type="button"
            className="mr-5 rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
            onClick={() => {
              if (addClientOpen === "")
                setAddClientOpen("show-timesheet-table");
              else if (addClientOpen === "show-timesheet-table")
                setOpenModal(true);
            }}
          >
            Create
          </button>
        </div>
      </div>
      {addClientOpen === "" ? (
        <Table
          tableData={tableData}
          setTableData={setTableData}
          page={page}
          setPage={setPage}
          memberTotal={memberTotal}
          itemsPerPage={itemsPerPage}
        />
      ) : (
        <Timesheets
          show={false}
          selectedTimesheets={selectedTimesheets}
          setSelectedTimesheets={setSelectedTimesheets}
        />
      )}
      <AddInvoiceModal
        open={openModal}
        setOpen={setOpenModal}
        selectedTimesheets={selectedTimesheets}
      />
    </div>
  );
};
export default Invoice;
