import { ChevronLeftIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const BackButton = ({ link }) => {
  return (
    <div className="cursor-pointer absolute top-5 left-20 z-10">
      <Link className="italic text-sm ml-10" to={link}>
        <ChevronLeftIcon
          className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
          aria-hidden="true"
        />
      </Link>
    </div>
  );
};
export default BackButton;
