/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function UpdateInterviewModal({
  open,
  setOpen,
  tableData,
  setTableData,
  selectedJob,
  setSelectedJob,
  setEditQuestionsModalOpen,
}) {
  const [error, setError] = useState([]);

  useEffect(() => {
    setError([]);
  }, [selectedJob]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setSelectedJob([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] sm:w-[500px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Interview Guide
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-4">
                      <div className="mt-2 pb-6">
                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2">
                            <label className="my-auto block text-sm font-medium text-gray-700">
                              Title
                            </label>
                            <input
                              onChange={(e) => {
                                let temp = selectedJob;
                                temp.Title = e.target.value;
                                setSelectedJob({ ...temp });
                              }}
                              value={selectedJob.Title}
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>

                          <div className="w-full grid grid-cols-2 gap-4 my-3">
                            <div className="">
                              <label className="block text-sm font-medium text-gray-700">
                                Type
                              </label>
                              <select
                                onChange={(e) => {
                                  let temp = selectedJob;
                                  temp.Type = e.target.value;
                                  setSelectedJob({ ...temp });
                                }}
                                value={selectedJob.Type}
                                className="h-[50px] w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                              >
                                <option className="text-gray-300" value="">
                                  Select
                                </option>
                                <option value="In-Person">In-Person</option>
                                <option value="Online Video">
                                  Online Video
                                </option>
                                <option value="Phone">Phone</option>
                              </select>
                            </div>
                            <div className="">
                              <label className="block text-sm font-medium text-gray-700">
                                Duration
                              </label>
                              <select
                                onChange={(e) => {
                                  let temp = selectedJob;
                                  temp.Duration = e.target.value;
                                  setSelectedJob({ ...temp });
                                }}
                                value={selectedJob.Duration}
                                className="h-[50px] w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                              >
                                <option className="text-gray-300" value="">
                                  Select
                                </option>
                                <option value="15">15 min</option>
                                <option value="30">30 min</option>
                                <option value="1">1 hr</option>
                              </select>
                            </div>
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <textarea
                              onChange={(e) => {
                                let temp = selectedJob;
                                temp.Description = e.target.value;
                                setSelectedJob({ ...temp });
                              }}
                              value={selectedJob.Description}
                              type="text"
                              name="description"
                              className="h-52 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                              rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                              id="description"
                              autoComplete="description"
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Intruction Notes
                            </label>
                            <textarea
                              onChange={(e) => {
                                let temp = selectedJob;
                                temp.Notes = e.target.value;
                                setSelectedJob({ ...temp });
                              }}
                              value={selectedJob.Notes}
                              type="text"
                              name="notes"
                              className="h-52 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                              rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                              id="notes"
                              autoComplete="notes"
                            />
                          </div>
                        </div>
                      </div>

                      {error.length > 0 && MissingFieldPrompt(error)}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                          font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                          onClick={async () => {
                            let errorArray = [];
                            if (selectedJob.Title?.trim().length === 0)
                              errorArray.push("Title");
                            if (selectedJob.Description?.trim().length === 0)
                              errorArray.push("Description");
                            // if (selectedJob.Type?.trim().length === 0)
                            //   errorArray.push("Type");
                            // if (selectedJob.Duration?.trim().length === 0)
                            //   errorArray.push("Duration");
                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }
                              const Description = JSON.stringify(selectedJob?.Description);
                              if (selectedJob.ItemId)
                                 
                              await API.graphql(
                                graphqlOperation(`mutation {
                                  update_InterviewGuides_by_pk(pk_columns: {ItemId: "${selectedJob.ItemId}"}, 
                                    _set: 
                                      {
                                        CreatedBy: "${selectedJob.CreatedBy}", 
                                        Title: "${selectedJob.Title}",
                                        Type: "${selectedJob.Type}",
                                        Description: ${Description}
                                        Duration: "${selectedJob.Duration}",
                                      })
                                      {
                                        ItemId
                                        Title
                                      }
                              }`)
                              ).then(({ data }) => {
                                setOpen(false);
                                setError([]);
                                setEditQuestionsModalOpen(true);
                                setTableData([
                                  selectedJob,
                                  ...tableData.filter(
                                    (obj) => obj.ItemId !== selectedJob.ItemId
                                  ),
                                ]);
                              });
                          }}
                        >
                          Next
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                          font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
