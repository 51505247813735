export const SearchBarLogic = (data, setTempTable, searchVal) => {
  setTempTable(
    data.filter(
      (person) =>
        person.FirstName?.toLowerCase().startsWith(searchVal.toLowerCase()) ||
        person.LastName?.toLowerCase().startsWith(searchVal.toLowerCase()) ||
        person.Title?.toLowerCase().startsWith(searchVal.toLowerCase())
    )
  );
};
