import React, { useState, useEffect } from "react";
import HideColumnsModal from "../../utils/HideColumnsModal";
import AdvancedFiltersModal from "../../utils/AdvancedFiltersModal";
import { MinusCircleIcon, DocumentTextIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { formatDate } from "../../utils/FormatDate";
import { Storage } from "aws-amplify";
import NoteView from "../../utils/NoteView";

const Table = React.memo((props) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [recruiter, setRecruiter] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [dateRegistered, setDateRegistered] = useState("");
  const [recruitersList, setRecruitersList] = useState([]);
  const [sysEmplmntList, setSysEmplmntList] = useState([]);
  const [employmentPref, setEmploymentPrefList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columnsModal, setColumnsModal] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [rows, setRows] = useState({});
  const [dragOver, setDragOver] = useState("");
  const [cols, setCols] = useState([
    "Role",
    "Status",
    "Availability",
    "Location",
    "Recent Education",
    "Date Registered",
    "Vetted",
    "Links",
  ]);

  const navigate = useNavigate();

  // GET "LINKS" COLUMN FILES
  const getStorageFile = async (key) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    window.open(url, "_blank");
  };

  useEffect(() => {
    setHiddenColumns(props.userData?.HiddenColumns);
  }, [props.userData?.HiddenColumns]);

  // SET TABLE DATA ROWS
  useEffect(() => {
    setRows(props.tempTable);
  }, [props.tempTable, props.tableData]);

  //SET ADVANCED FILTERS DROPDOWNS
  useEffect(() => {
    props.opportunitiesData.map((obj) => obj.Name);
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
          ItemId
          FirstName
          LastName
        }
      }      
    `,
    }).then(({ data }) => {
      setRecruitersList(
        data.Users.map((obj) => obj.FirstName + " " + obj.LastName)
      );
    });
    // setRecruitersList(
    //   props.tableData
    //     ?.map((person) => person?.RecruiterId)
    //     .filter((val, id, array) => array.indexOf(val) == id)
    // );

    // Lookup
    setSysEmplmntList(
      ["Full-Time", "Part-Time", "1099", "Freelance", "Not Working"].map(
        (item) => item
      )
    );
    // Lookup CurrentEmploymentStatus
    setEmploymentPrefList(
      ["OnSite", "Remote", "Hybrid", "Does not Matter"].map((item) => item)
    );
  }, [props.tableData, props.opportunitiesData]);

  // SELECT ALL CHECKBOXES
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(props.tableData?.map((person) => person.ItemId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // SELECT SINGLE CHECKBOX
  const handleClick = (e) => {
    const { name, checked } = e.target;
    setIsCheck([...isCheck, name]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== name));
    }
  };

  return (
    <div className="px-4 sm:px-7 lg:px-0">
      {props.tableData?.Notes?.length > 0 && (
        <NoteView
          profileData={profileData}
          setNotesModalOpen={setNotesModalOpen}
          notesModalOpen={notesModalOpen}
        />
      )}
      {/* Filter buttons */}
      <div className="sm:flex sm:overflow-x-auto sm:items-center justify-between">
        {/* Small button filter list */}
        <div className="flex">
          {props.smFilterButtons?.map((obj, i) => (
            <div className="lg:basis-1/7 flex flex-wrap mr-2" key={i}>
              <div className="lg:mr-2 md:mr-2">
                <button
                  key={i}
                  value={obj?.name}
                  onClick={async (e) => {
                    let temp = props.userData;

                    console.log(props?.filterSearch);
                    let tempSearch = props?.filterSearch.includes(
                      e.target.value
                    )
                      ? props?.filterSearch.filter(
                          (obj) => obj !== e.target.value
                        )
                      : [...props?.filterSearch, obj.name];

                    props?.setFilterSearch(tempSearch);

                    temp.TableFilters.people = [...tempSearch];

                    await API.graphql(
                      graphqlOperation(`mutation {
                        update_Users_by_pk(pk_columns: {ItemId: "${
                          props.userData.ItemId
                        }"}, 
                          _set: 
                            {
                              TableFilters: {
                                people: [${temp.TableFilters.people
                          .map((obj) => `"${obj}"`)
                                  .join(",")}],
                                opportunities: [${props.userData.TableFilters.opportunities
                          .map((obj) => `"${obj}"`)
                                  .join(",")}],
                                contracts: [${props.userData.TableFilters.contracts
                          .map((obj) => `"${obj}"`)
                                  .join(",")}]
                              }    
                            }
                          )       
                          {
                            ItemId
                            TableFilters
                          }            
                    }`)
                    ).then(({ data }) => {
                      console.log("data", data);
                    });
                  }}
                  className={`${
                    props?.filterSearch.includes(obj.name)
                      ? "teckpert-bg-green text-white"
                      : "bg-white text-gray-900"
                  } w-[80px] mr-3 rounded-full justify-center rounded-md 
                    border shadow-sm px-2.5 py-3 text-sm
                    hover:border-[#6fbe47] hover:border-solid`}
                  data-status={obj.dataStatus}
                >
                  {obj.name}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Dropdown button filter list */}
        <div>
          {[
            { title: "Status", key: "Status" },
            { title: "Job Title", key: "JobTitle" },
            { title: "Location", key: "Location" },
            { title: "Opportunity", key: "Opportunity" },
          ].map((obj) => (
            <select
              onChange={async (e) => {
                let temp = props.userData;

                // let tempSearch = props?.filterSearch.includes(
                //   e.target.value
                // )
                console.log(obj.key, e.target.value);
                props?.setDropdownSearch({
                  ...props.dropdownSearch,
                  [obj.key]: e.target.value,
                });

                // props?.setFilterSearch(tempSearch);
                // temp.TableFilters.people = [...tempSearch];

                // await API.graphql(
                //   graphqlOperation(`mutation {
                //     update_Users_by_pk(pk_columns: {ItemId: "${
                //       props.userData.ItemId
                //     }"},
                //       _set:
                //         {
                //           TableFilters: {
                //             people: [${temp.TableFilters.people
                //               .map((obj) => `${obj}`)
                //               .join(",")}],
                //             opportunities: [${props.userData.TableFilters.opportunities
                //               .map((obj) => `${obj}`)
                //               .join(",")}],
                //             contracts: [${props.userData.TableFilters.contracts
                //               .map((obj) => `${obj}`)
                //               .join(",")}]
                //           }
                //         }
                //       )
                //       {
                //         ItemId
                //         TableFilters
                //       }
                // }`)
                // ).then(({ data }) => {
                //   console.log("data", data);
                // });
              }}
              className={`${
                props?.filterSearch.includes(obj.name)
                  ? "teckpert-bg-green text-white"
                  : "bg-white text-gray-900"
              } w-[130px] mr-3 rounded-full justify-center rounded-md 
                  border shadow-sm px-2.5 py-3 text-sm
                  hover:border-[#6fbe47] hover:border-solid cursor-pointer`}
            >
              <option value="">{obj.title}</option>
              {props?.comboBoxLabel[obj.key]?.data?.map((obj) => (
                <option value={obj}>{obj}</option>
              ))}
            </select>
          ))}
        </div>

        <div className="basis-1/7">
          <button
            onClick={() => setColumnsModal(true)}
            className="min-w-[100px] mr-3 rounded-full justify-center rounded-md 
            border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900  hover:border-[#6fbe47] hover:border-solid"
          >
            ||| &nbsp;Columns
          </button>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
            {rows.length ? (
              <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <div className="form-check ml-5">
                          <input
                            id="bordered-checkbox"
                            type="checkbox"
                            name="bordered-checkbox"
                            className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          ></input>
                        </div>
                      </th>
                      <th className="flex-none w-[30px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Name
                      </th>
                      {cols
                        .sort(
                          (a, b) =>
                            hiddenColumns?.people?.findIndex(
                              (x) => x.name === a
                            ) -
                            hiddenColumns?.people?.findIndex(
                              (x) => x.name === b
                            )
                        )
                        .map((col) => {
                          let index = hiddenColumns?.people?.findIndex(
                            (x) => x.name === col
                          );
                          return (
                            <th
                              className={`${
                                hiddenColumns?.people &&
                                hiddenColumns?.people[index]?.hidden
                                  ? "hidden"
                                  : "default"
                              } px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer`}
                              id={col}
                              key={col}
                              draggable
                              dragOver={col === dragOver}
                            >
                              {col === "Date Registered" ? (
                                <div>
                                  <div className="block">Date</div>
                                  <div className="block">Registered</div>
                                </div>
                              ) : col === "Recent Education" ? (
                                <div>
                                  <div className="block">Recent</div>
                                  <div className="block">Education</div>
                                </div>
                              ) : (
                                col
                              )}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className={`divide-y divide-gray-200 bg-white`}>
                    {rows
                      .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                      .map((row, i) => {
                        return (
                          <tr key={row.id}>
                            <td>
                              <div className="form-check mr-6">
                                <input
                                  key={i}
                                  type="checkbox"
                                  name={row.ItemId}
                                  id={i}
                                  onChange={handleClick}
                                  className="ml-8 w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                                  checked={isCheck.includes(row.ItemId)}
                                />
                              </div>
                            </td>
                            <td className="text-gray-400 w-[30px] flex-none">
                              <MinusCircleIcon
                                className={`${
                                  row.Starred
                                    ? "bg-[#36b37e] text-[#36b37e]"
                                    : row.Flagged
                                    ? "bg-[#ff5630] text-[#ff5630]"
                                    : row.ScreeningStatus?.toLowerCase() ===
                                      "approved"
                                    ? "bg-[#ffc400] text-[#ffc400]"
                                    : row.ScreeningStatus?.toLowerCase() ===
                                      "on contract"
                                    ? "bg-[#4c9aff] text-[#4c9aff]"
                                    : "bg-gray-400"
                                }  rounded-full h-3 w-3`}
                                aria-hidden="true"
                              />
                            </td>
                            <td
                              className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6`}
                            >
                              <div className="flex">
                                <div
                                  className="font-medium teckpert-text-green cursor-pointer"
                                  onClick={() =>
                                    navigate("/people/" + row.ItemId)
                                  }
                                >
                                  {row.FirstName} {row.LastName}
                                </div>
                                {row.Notes && (
                                  <DocumentTextIcon
                                    className="h-5 w-5 ml-4 font-medium teckpert-text-green cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      setProfileData(row);
                                      setNotesModalOpen(true);
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                            {Object.entries(row).map(([k, v], idx) =>
                              cols[idx] === "Links" ? (
                                <td
                                  className={`${
                                    hiddenColumns?.people &&
                                    hiddenColumns?.people[
                                      hiddenColumns?.people?.findIndex(
                                        (x) => x.name === "Links"
                                      )
                                    ]?.hidden
                                      ? "hidden"
                                      : "default"
                                  } whitespace-nowrap px-3 py-4 text-sm text-gray-900`}
                                >
                                  {["LinkedIn", "Portfolio", "Resume"].map(
                                    (obj, i) => (
                                      <>
                                        {obj === "Resume" &&
                                        row?.Resume?.includes("pdf") ? (
                                          <a
                                            className={`${
                                              row?.Resume
                                                ? "text-gray-800 hover:teckpert-text-green"
                                                : "text-gray-300"
                                            } cursor-pointer`}
                                            onClick={() =>
                                              getStorageFile(row?.Resume)
                                            }
                                          >
                                            {obj}
                                          </a>
                                        ) : (
                                          <a
                                            key={i}
                                            className={`${
                                                row?.Meta && row.Meta[obj]
                                                ? "text-gray-800 hover:teckpert-text-green"
                                                : "text-gray-300"
                                                              } cursor-pointer`}
                                                          href={row?.Meta && row.Meta[obj]}
                                            target="_blank"
                                            rel="noopener"
                                          >
                                            {obj}
                                          </a>
                                        )}

                                        <br />
                                      </>
                                    )
                                  )}
                                </td>
                              ) : cols[idx] === "Recent Education" ? (
                                <td
                                  className={`${
                                    hiddenColumns?.people &&
                                    hiddenColumns?.people[
                                      hiddenColumns?.people?.findIndex(
                                        (x) => x.name === "Recent Education"
                                      )
                                    ]?.hidden
                                      ? "hidden"
                                      : "default"
                                  }  w-13 px-3 py-4 text-sm text-gray-900`}
                                >
                                  {row.Meta?.Education
                                    ? row.Meta?.Education[0]?.School != undefined
                                      ? row.Meta?.Education[0]?.School
                                      : ""
                                    : "-"}
                                  <br />
                                  {row.Meta?.Education
                                    ? row.Meta?.Education[0]?.Degree
                                      ? row.Meta?.Education[0]?.Degree +
                                        " " +
                                        row.Meta?.Education[0]?.End
                                      : "-"
                                    : " "}
                                </td>
                              ) : cols.includes(cols[idx]) ? (
                                <td
                                  className={`${
                                    hiddenColumns?.people &&
                                    hiddenColumns?.people[
                                      hiddenColumns?.people?.findIndex(
                                        (x) => x.name === cols[idx]
                                      )
                                    ]?.hidden
                                      ? "hidden"
                                      : "default"
                                  } whitespace-nowrap px-3 py-4 text-sm text-gray-900 capitalize`}
                                >
                                  {cols[idx] === "Role"
                                    ? row.Meta?.PrimaryRole?.label
                                    : cols[idx] === "Status"
                                    ? row.AccountStatus
                                    : cols[idx] === "Availability"
                                    ? row.Meta?.LocationAvailability
                                    : cols[idx] === "Location"
                                    ? row.Meta?.Location
                                    : cols[idx] === "Date Registered"
                                    ? row.DateCreated
                                      ? formatDate(row.DateCreated)
                                      : "-"
                                    : cols[idx] === "Vetted"
                                    ? row.Vetted === "0000-00-00"
                                      ? "-"
                                      : formatDate(row.Vetted)
                                    : row[cols[idx]]}
                                </td>
                              ) : null
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex justify-center items-center align-middle">
                <div className="my-auto inline-block w-50 h-20 rounded-full pt-10">
                  <span className="font-bold text-gray-800 text-lg mt-50">
                    No Results
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {props.tableData?.length > 25 && (
        <div className="flex justify-center mt-5 pb-8">
          <nav aria-label="Page navigation">
            <ul className="flex list-style-none">
              <li
                className="page-item"
                //decrement pagination by one when clicking arrow
                onClick={() => {
                  if (props.page === 1) return;
                  props.setPage(props.page - 1);
                }}
              >
                <a
                  className="page-link relative block py-1.5 px-3 rounded-full border-0 
                bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 focus:shadow-none"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {props.memberTotal &&
                props.itemsPerPage &&
                [
                  ...Array(Math.ceil(props.memberTotal / props.itemsPerPage)),
                ]?.map((_, page) => {
                  //only displays pagination within a certain range
                  if (props.page - page + 1 >= 0 && props.page < page + 6)
                    return (
                      <li
                        className="page-item"
                        onClick={() => props.setPage(page + 1)}
                        key={page + 1}
                      >
                        <a
                          className={`rounded-full page-link relative block py-1.5 px-3 bg-transparent outline-none transition-all duration-300 rounded 
                        text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${
                          page + 1 === props.page && "font-bold"
                        }`}
                          // border-solid border-2 border-[#6FBE47]
                          href="#"
                        >
                          {page + 1}
                        </a>
                      </li>
                    );
                })}
              <li
                className="page-item"
                onClick={() => {
                  //increment pagination by one when clicking arrow
                  if (
                    props.page ===
                    Math.ceil(props.memberTotal / props.itemsPerPage)
                  )
                    return;
                  props.setPage(props.page + 1);
                }}
              >
                <a
                  aria-label="Next"
                  className="page-link relative block py-1.5 px-3 rounded-full border-0 bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}

      <AdvancedFiltersModal
        recruitersList={recruitersList}
        setRecruitersList={setRecruitersList}
        sysEmplmntList={sysEmplmntList}
        setSysEmplmntList={setSysEmplmntList}
        employmentPref={employmentPref}
        setEmploymentPrefList={setEmploymentPrefList}
        setColumnsModal={setColumnsModal}
        setRecruiter={setRecruiter}
        setEmploymentStatus={setEmploymentStatus}
        setEmploymentType={setEmploymentType}
        setDateRegistered={setDateRegistered}
        setTempTable={props.setTempTable}
        tempTable={props.tempTable}
        tableData={props.tableData}
        filtersModal={props.filtersModal}
        setFiltersModal={props.setFiltersModal}
        advFilterSearch={props.advFilterSearch}
        setAdvFilterSearch={props.setAdvFilterSearch}
        isSelected={props.isSelected}
      />
      {hiddenColumns?.people && (
        <HideColumnsModal
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          setColumnsModal={setColumnsModal}
          columnsModal={columnsModal}
          userData={props.userData}
          setUserData={props.setUserData}
        />
      )}
    </div>
  );
});

export default Table;
