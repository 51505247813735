import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";

const CreateNoteModal = ({
  profileData,
  setProfileData,
  open,
  setOpen,
  url,
  setConfirmButtonMessage,
  newData,
  setNewData,
  title,
  datePicker,
  logDate,
  setLogDate,
}) => {
  const [validDate, setValidDate] = useState(true);
  let tempText = {};
  if (title === "Create a Note") {
    tempText = newData.Note;
  }
  if (title === "Log a Call") {
    tempText = newData.CallLog;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${
                  title === "Create a Note"
                    ? "lg:h-[510px]"
                    : title === "Log a Call"
                    ? "lg:h-[550px]"
                    : "lg:h-[510px]"
                } lg:w-[1000px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6`}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="block text-sm font-medium text-gray-700 pb-5">
                    {title}
                  </div>
                  {datePicker ? (
                    <div>
                      <DatePicker
                        className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                        selected={logDate || new Date()}
                        onChange={(date) => {
                          let temp = logDate;
                          if (date > new Date()) {
                            setValidDate(false);
                            return;
                          } else {
                            setValidDate(true);
                            temp = date;
                            setLogDate(temp);
                          }
                        }}
                        fixedHeight
                      />
                      <span
                        className={`${
                          logDate > new Date() || !validDate ? "" : "hidden"
                        } text-xs text-red-500`}
                      >
                        Cannot set date in the future.
                      </span>
                    </div>
                  ) : null}
                  <Editor
                    apiKey={"2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"}
                    onEditorChange={(value) => {
                      tempText = value;
                    }}
                    initialValue={newData?.Note || newData?.CallLog}
                    init={{
                      height: 350,
                      menubar: false,
                      plugins: [
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                      content_style:
                        "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                    }}
                  />
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    type="button"
                    className="mt-3 mr-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                    font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                    font-medium text-white shadow-sm teckpert-bg-green hover:opacity-90 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let tempData = profileData;
                      let temp = url.substring(1);
                      temp = temp.charAt(0).toUpperCase() + url.slice(2);
                      if (logDate > new Date()) {
                        return;
                      } else {
                        if (title === "Create a Note") {
                          tempData.Notes = [
                            {
                              Creator: localStorage.getItem("name"),
                              DateCreated: new Date().toISOString(),
                              Note: tempText,
                              ItemId: uuidv4(),
                            },
                            ...(Array.isArray(profileData.Notes)
                              ? profileData.Notes
                              : []),
                          ];
                          await API.graphql({
                            query: `mutation ($notes: jsonb) {
                              update_${temp}_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
                                _set: 
                                  {
                                    Notes: $notes
                                  })
                                  {
                                    ItemId
                                    Notes
                                  }
                              }`,
                            variables: {
                              notes: tempData.Notes,
                            },
                          }).then(({ data }) => {
                            setOpen(false);
                            setConfirmButtonMessage(true);
                            setNewData({
                              ItemId: data.update_Members_by_pk.ItemId,
                            });
                          });
                        }

                        if (title === "Log a Call") {
                          tempData.CallLogs = [
                            {
                              Creator: localStorage.getItem("name"),
                              DateCreated: logDate,
                              CallLog: tempText,
                              ItemId: uuidv4(),
                            },
                            ...(Array.isArray(profileData.CallLogs)
                              ? profileData.CallLogs
                              : []),
                          ];

                          await API.graphql({
                            query: `mutation ($logs: jsonb) {
                              update_${temp}_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
                                _set: 
                                  {
                                    CallLogs: $logs
                                  })
                                  {
                                    ItemId
                                    CallLogs
                                  }
                              }`,
                            variables: {
                              logs: tempData.CallLogs,
                            },
                          }).then(({ data }) => {
                            setOpen(false);
                            setConfirmButtonMessage(true);
                          });
                        }
                      }
                    }}
                  >
                    Create
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNoteModal;
