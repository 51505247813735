export default function listOfTimezones() {
  return [
    {
      value: "(GMT-12:00) International Date Line West",
    },
    {
      value: "(GMT-11:00) Midway Island, Samoa",
    },
    {
      value: "(GMT-10:00) Hawaii",
    },
    {
      value: "(GMT-09:00) Alaska",
    },
    {
      value: "(GMT-08:00) Pacific Time (US and Canada); Tijuana",
    },
    {
      value: "(GMT-07:00) Mountain Time (US and Canada)",
    },
    {
      value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    },
    {
      value: "(GMT-07:00) Arizona",
    },
    {
      value: "(GMT-06:00) Central Time (US and Canada)",
    },
    {
      value: "(GMT-06:00) Saskatchewan",
    },
    {
      value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    },
    {
      value: "(GMT-06:00) Central America",
    },
    {
      value: "(GMT-05:00) Eastern Time (US and Canada)",
    },
    {
      value: "(GMT-05:00) Indiana (East)",
    },
    {
      value: "(GMT-05:00) Bogota, Lima, Quito",
    },
    {
      value: "(GMT-04:00) Atlantic Time (Canada)",
    },
    {
      value: "(GMT-04:00) Caracas, La Paz",
    },
    {
      value: "(GMT-04:00) Santiago",
    },
    {
      value: "(GMT-03:30) Newfoundland and Labrador",
    },
    {
      value: "(GMT-03:00) Brasilia",
    },
    {
      value: "(GMT-03:00) Buenos Aires, Georgetown",
    },
    {
      value: "(GMT-03:00) Greenland",
    },
    {
      value: "(GMT-02:00) Mid-Atlantic",
    },
    {
      value: "(GMT-01:00) Azores",
    },
    {
      value: "(GMT-01:00) Cape Verde Islands",
    },
    {
      value: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    },
    {
      value: "(GMT) Casablanca, Monrovia",
    },
    {
      value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    },
    {
      value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    },
    {
      value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    },
    {
      value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    },
    {
      value: "(GMT+01:00) West Central Africa",
    },
    {
      value: "(GMT+02:00) Bucharest",
    },
    {
      value: "(GMT+02:00) Cairo",
    },
    {
      value: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    },
    {
      value: "(GMT+02:00) Athens, Istanbul, Minsk",
    },
    {
      value: "(GMT+02:00) Jerusalem",
    },
    {
      value: "(GMT+02:00) Harare, Pretoria",
    },
    {
      value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    },
    {
      value: "(GMT+03:00) Kuwait, Riyadh",
    },
    {
      value: "(GMT+03:00) Nairobi",
    },
    {
      value: "(GMT+03:00) Baghdad",
    },
    {
      value: "(GMT+03:30) Tehran",
    },
    {
      value: "(GMT+04:00) Abu Dhabi, Muscat",
    },
    {
      value: "(GMT+04:00) Baku, Tbilisi, Yerevan",
    },
    {
      value: "(GMT+04:30) Kabul",
    },
    {
      value: "(GMT+05:00) Ekaterinburg",
    },
    {
      value: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    },
    {
      value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
    {
      value: "(GMT+05:45) Kathmandu",
    },
    {
      value: "(GMT+06:00) Astana, Dhaka",
    },
    {
      value: "(GMT+06:00) Sri Jayawardenepura",
    },
    {
      value: "(GMT+06:00) Almaty, Novosibirsk",
    },
    {
      value: "(GMT+06:30) Yangon Rangoon",
    },
    {
      value: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    },
    {
      value: "(GMT+07:00) Krasnoyarsk",
    },
    {
      value: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
    },
    {
      value: "(GMT+08:00) Kuala Lumpur, Singapore",
    },

    {
      value: "(GMT+08:00) Taipei",
    },
    {
      value: "(GMT+08:00) Perth",
    },
    {
      value: "(GMT+08:00) Irkutsk, Ulaanbaatar",
    },
    {
      value: "(GMT+09:00) Seoul",
    },
    {
      value: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    },
    {
      value: "(GMT+09:00) Yakutsk",
    },
    {
      value: "(GMT+09:30) Darwin",
    },
    {
      value: "(GMT+09:30) Adelaide",
    },
    {
      value: "(GMT+10:00) Canberra, Melbourne, Sydney",
    },
    {
      value: "(GMT+10:00) Brisbane",
    },
    {
      value: "(GMT+10:00) Hobart",
    },
    {
      value: "(GMT+10:00) Vladivostok",
    },
    {
      value: "(GMT+10:00) Guam, Port Moresby",
    },
    {
      value: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    },
    {
      value: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    },
    {
      value: "(GMT+12:00) Auckland, Wellington",
    },
    {
      value: "(GMT+13:00) Nuku'alofa",
    },
  ];
}
