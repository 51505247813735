import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  XCircleIcon,
  PencilIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/outline";
import { formatDate } from "../../../utils/FormatDate";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";

const Table = ({
  selectedJob,
  setSelectedJob,
  jobsData,
  tableData,
  setTableData,
}) => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Title",
                      "Date Created",
                      "Created By",
                      "Used By",
                      "",
                      "",
                      "",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData?.map((obj) => (
                    <tr key={obj.ItemId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center font-medium teckpert-text-green">
                          {obj.Title ? obj.Title : "-"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.CreatedBy ? obj.CreatedBy : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        Used by&nbsp;
                        {
                          jobsData?.filter((x) => x.WorkflowId === obj.ItemId)
                            .length
                        }
                        &nbsp;jobs
                      </td>
                      <td className="w-[100px] cursor-pointer whitespace-nowrap py-2 text-sm">
                        {/* Clone Workflow */}
                        <button
                          onClick={async () => {
                                      let temp = obj;
                                      const Description = JSON.stringify(temp?.Description);
                            await API.graphql(
                              graphqlOperation(`mutation {
                                insert_Workflows_one(
                                  object: 
                                  {
                                    ItemId: "${uuidv4()}",
                                    CreatedBy: "${temp.CreatedBy}",
                                    DateCreated: "${new Date().toISOString()}",
                                    Title: "Copy of ${temp.Title}", 
                                    Description: ${Description},
                                    Default: "${temp.Default}" 
                                    Stages: [${temp.Stages?.map(
                                      (obj) =>
                                        `{
                                          Name: "${obj.Name}",
                                          Order: ${obj.Order},
                                          Actions: [
                                            ${obj.Actions?.map(
                                              (item) => `{
                                                TemplateId: "${item.TemplateId}",
                                                Type: "${item.Type}"
                                            }`
                                            ).join(`,`)}
                                        ]
                                        }`
                                    ).join(`,`)}] 
                                  })
                                  {
                                    ItemId
                                    Title
                                  }         
                            }`)
                            ).then(({ data }) => {
                              setTableData([
                                ...tableData,
                                {
                                  ItemId: data.insert_Workflows_one.ItemId,
                                  Title: `Copy of ${temp.Title}`,
                                  Description: temp.Description,
                                  DateCreated: new Date().toISOString(),
                                  CreatedBy: temp.CreatedBy,
                                  Stages: temp.Stages,
                                },
                              ]);
                            });
                          }}
                          type="button"
                          className="mr-8 mt-2 items-center justify-center rounded-md border border-transparent teckpert-bg-green 
                          px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto hover:border-white"
                        >
                          Clone
                          <DocumentDuplicateIcon
                            className="h-6 w-6 inline cursor-pointer mx-3"
                            aria-hidden="true"
                          />
                        </button>
                      </td>
                      <td className="w-[60px] py-4 text-sm">
                        <PencilIcon
                          onClick={() => navigate("/workflows/" + obj.ItemId)}
                          className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                          aria-hidden="true"
                        />
                      </td>
                      <td className="w-[60px] py-4 text-sm">
                        <XCircleIcon
                          value={obj.ItemId}
                          onClick={() => {
                            setSelectedJob(obj);
                            setDeleteModalOpen(true);
                          }}
                          className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                          aria-hidden="true"
                        />
                        <ConfirmDeleteModal
                          open={deleteModalOpen}
                          setOpen={setDeleteModalOpen}
                          deleteItem={async () => {
                            if (selectedJob.ItemId) {
                              let response = await API.graphql(
                                graphqlOperation(`
                                mutation {
                                    delete_Workflows_by_pk(ItemId: "${selectedJob.ItemId}") {
                                      ItemId
                                      Title
                                    }
                                  }
                              `)
                              );
                              if (response) {
                                setTableData(
                                  tableData.filter(
                                    (item) => item.ItemId !== selectedJob.ItemId
                                  )
                                );
                                setSelectedJob([]);
                              }
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
