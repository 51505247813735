/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function CreateChecklistModal({
  open,
  setOpen,
  tableData,
  setTableData,
  tempData,
  setTempData,
  setSelectedJob,
    setConfirmButtonMessage,
    memberData,
    contractData,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState([]);

  useEffect(() => {
    setError([]);
  }, [tempData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] sm:w-[500px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Checklist Information
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-4">
                      <div className="mt-2 pb-6">
                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2">
                            <label className="my-auto block text-sm font-medium text-gray-700">
                              Title
                            </label>
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Title = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Title}
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900"
                            />
                          </div>

                          <div className="sm:col-span-2 flex-grow">
                            <div className="mt-2">
                              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Assigned
                              </label>
                              <select
                                className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                onChange={(e) => {
                                  let temp = tempData;
                                  temp.Assign = e.target.value;
                                  setTempData({ ...temp });
                                }}
                                value={tempData.Assign}
                              >
                                <option className="text-gray-300" value="">
                                  Select
                                </option>
                                <option value="People">People</option>
                                 <option value="Contract">Contract</option>
                              </select>
                                </div>
                           {tempData.Assign === "People" && (
                            <div className="mt-2">
                                <select
                                    className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                                    border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                    text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                                    onChange={(e) => {
                                        let temp = tempData;                                       
                                        temp.MemberId = e.target.value;
                                        setTempData({ ...temp });
                                        }}
                                   value={tempData.MemberId}
                                >
                                  <option className="text-gray-300" value="">
                                        Select
                                    </option>
                                   {memberData
                                        ?.sort((a, b) =>
                                            a.FirstName.localeCompare(b.FirstName)
                                        )
                                        .map((obj) => (
                                            <option key={obj.ItemId} value={obj.ItemId}>
                                                {obj.FirstName} {obj.LastName}
                                            </option>
                                           
                                        ))}
                                </select>
                            </div>
                          )}
                           {tempData.Assign === "Contract" && (
                            <div className="mt-2">
                                <select
                                    className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                                    border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                    text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                                    onChange={(e) => {
                                        let temp = tempData;                                       
                                        temp.MemberId = e.target.value;
                                        setTempData({ ...temp });
                                        }}
                                   value={tempData.MemberId}
                                >
                                  <option className="text-gray-300" value="">
                                        Select
                                    </option>
                                   {contractData
                                       ?.sort((a,b)=>(a.Name.localeCompare(b.Name ) ))?.map((obj) => (                                                                               
                                            <option key={obj.ItemId} value={obj.ItemId}>
                                                {obj.Name} 
                                            </option>                                           
                                        ))}
                                </select>
                            </div>
                            )}
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <textarea
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Description = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Description}
                              type="text"
                              name="description"
                              className="h-72 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                              rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                              id="message"
                              autoComplete="description"
                            />
                          </div>
                        </div>
                      </div>

                      {error.length > 0 && MissingFieldPrompt(error)}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                          font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                          onClick={async () => {
                            let errorArray = [];
                            if (tempData.Title?.trim().length === 0)
                              errorArray.push("Title");
                            if (tempData.Description?.trim().length === 0)
                              errorArray.push("Description");
                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }

                            let temp = tempData;
                            await API.graphql(
                              graphqlOperation(`mutation {
                                update_Checklists_by_pk(pk_columns: {ItemId: "${
                                  location.pathname.split("/")[2]
                                }"}, 
                                   _set: { 
                                    Title: "${tempData.Title}", 
                                    Description: ${JSON.stringify(tempData?.Description) },
                                  })
                                  {
                                    ItemId
                                    Title
                                    Items
                                  }
                                }`)
                            ).then(async ({ data }) => {
                                if (data.update_Checklists_by_pk == null) {
                                    const Description = JSON.stringify(tempData?.Description);
                                await API.graphql(
                                  graphqlOperation(`mutation {
                                    insert_Checklists_one(
                                      object:
                                      {
                                        ItemId: "${
                                          location.pathname.split("/")[2]
                                        }",
                                        Title: "${tempData.Title}",
                                        Description: ${Description},
                                        CreatedBy: "${tempData.CreatedBy}",
                                        Assign:"${tempData.Assign}"
                                        MemberId:"${tempData.MemberId}"
                                        Items: []
                                      })
                                      {
                                        ItemId
                                        Title
                                      }
                                }`)
                                ).then(({ data }) => {
                                  temp.ItemId =
                                    data.insert_Checklists_one.ItemId;
                                });
                              }
                              setOpen(false);
                              setConfirmButtonMessage(true);
                            });
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 
                          shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError([]);
                            setTempData({
                              Title: tableData.Title,
                              Description: tableData.Description,
                              Assign: tableData.Assign,
                              DateCreated: tableData.DateCreated,
                              CreatedBy: tableData.CreatedBy,
                              Items: tempData.Items,
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
