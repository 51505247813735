import React from 'react';
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { XCircleIcon } from "@heroicons/react/outline";

const ApprovalTimesheetModal = ({ isOpen, onClose, onApprove, contact }) => {    
    const [name, setName] = useState('');
    const [error, setError] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
  
    const handleInputChange = (e) => {
        setName(e.target.value);
    };
    useEffect(() => {
        setIsChecked(false);
        if (contact) {
            setName(`${contact.FirstName} ${contact.LastName}`);
        }
    }, [contact, isOpen]); 
    const handleSubmit = () => {
        if (isChecked) {
            onApprove(name); 
            onClose()
        }
    };

    if (!isOpen) return null;
        
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    onClose();
                    
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="lg:w-[400px] sm:w-full relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl 
          transition-all sm:my-8 sm:p-6"
                            >
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-red-500"
                                        onClick={() => {
                                            onClose(); 
                                            setError([]);
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <form className="space-y-8 divide-y divide-gray-200">                                    
                                            <div className="sm:col-span-3">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Approve By
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) => setName(e.target.value)}
                                                        value={name}
                                                        className="w-full mt-1 rounded-md shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900 border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                                                    />
                                                </div>
                                                <div className="mt-3 flex items-start mt-2">
                                                    <input
                                                        id="confirm-checkbox"
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={() => setIsChecked(!isChecked)}
                                                        className="w-4 h-4 mr-2 mt-1 text-green-600 bg-gray-100 border-green-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label  className="text-sm text-gray-900 dark:text-gray-300">I here by confirm that I have reviewed and approved the hours worked as per the submitted timesheet for specified period</label>
                                        </div>
                                        <div className="flex justify-end mt-2 ">
                                        <button
                                                type="button"                                                
                                                onClick={handleSubmit} 
                                                disabled={!isChecked} 
                                                className={`w-full lg:w-auto justify-end lg:rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium ${!isChecked ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90 text-white shadow-sm focus:outline-none'}`}
                                            >Approve</button>
                                        </div>
                                            </div>
                                        
                                    
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ApprovalTimesheetModal;

