/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function CompleteInterviewModal({
  open,
  setOpen,
  openConfirmation,
  tempData,
  setTempData,
}) {
  const [error, setError] = useState([]);

  useEffect(() => {
    setError([]);
  }, [tempData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] sm:w-[500px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Complete Interview
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div className="pt-4">
                      <div className="mt-2 border-gray-200 border border-solid rounded p-5">
                        <label className="block text-sm font-medium text-gray-700 mb-4">
                          1. How would you rate the candidates performance in
                          this interview?
                        </label>
                        <div className="flex mb-2">
                          <button
                            onClick={(e) => {
                              let temp = tempData;
                              temp.Rating = "Passed";
                              setTempData({ ...temp });
                            }}
                            value={tempData?.Rating}
                            className={`${
                              tempData?.Rating !== "Failed"
                                ? "hover:opacity-90"
                                : "opacity-40"
                            } teckpert-bg-green flex-grow justify-center rounded-md border border-transparent  py-2.5 m-auto
                            text-base font-medium text-white shadow-sm sm:ml-3 sm:text-sm hover:opacity-90`}
                            type="button"
                          >
                            Passed the interview
                          </button>
                          <button
                            onClick={(e) => {
                              let temp = tempData;
                              temp.Rating = "Failed";
                              setTempData({ ...temp });
                            }}
                            className={`${
                              tempData?.Rating !== "Passed"
                                ? "hover:opacity-90"
                                : "opacity-40"
                            } bg-red-500 flex-grow justify-center rounded-md border border-transparent py-2.5 m-auto
                            text-base font-medium text-white shadow-sm sm:ml-3 sm:text-sm`}
                            type="button"
                          >
                            Failed the interview
                          </button>
                        </div>
                      </div>

                      <div className="mt-2 border-gray-200 border border-solid rounded p-5">
                        <label className="block text-sm font-medium text-gray-700 mb-4">
                          2. Share some post-interview feedback with the team
                          (optional).
                        </label>
                        <textarea
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Notes = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData?.Notes}
                          placeholder="Use @ to notify a team member. Use @team to notify everyone in the hiring team."
                          type="text"
                          name="description"
                          className="h-48 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                          rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                          id="message"
                          autoComplete="description"
                        />
                      </div>

                      <div className="mt-2 border-gray-200 border border-solid rounded p-5">
                        <label className="block text-sm font-medium text-gray-700 mb-4">
                          3. To ensure accurate reporting, confirm when this
                          interview took place.
                        </label>
                        <div className="w-full grid grid-cols-2 gap-4 my-3">
                          <div className="sm:col-span-3 flex-grow">
                            <div className="mt-2" style={{ zIndex: 2000 }}>
                              <DatePicker
                                className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                                selected={
                                  tempData?.DateCompleted
                                    ? new Date(tempData.DateCompleted)
                                    : new Date()
                                }
                                onChange={(date) => {
                                  let temp = tempData;
                                  temp.DateCompleted = date;
                                  setTempData({ ...temp });
                                }}
                                fixedHeight
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-8 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                          font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                          onClick={async () => {
                            let temp = tempData;
                            if (!temp.DateCompleted)
                              temp.DateCompleted = new Date().toISOString();

                            await API.graphql(
                              graphqlOperation(`mutation {
                                insert_InterviewGuidesResponses_one(
                                  object: 
                                  {
                                    ItemId: "${uuidv4()}",
                                    DateCompleted: "${temp.DateCompleted}",
                                    Rating: "${tempData.Rating}",
                                    Notes: "${tempData.Notes}",
                                    MemberId: "${tempData.MemberId}",
                                    InterviewGuideId: "${
                                      tempData.InterviewGuideId
                                    }",
                                    Responses: [${tempData.Responses.map(
                                      (obj) =>
                                        `{
                                          Question: "${obj.Question}",
                                          Rating: "${obj.Rating}",
                                          Note: "${obj.Note}",
                                        }`
                                    ).join(`,`)}]
                                  })
                                  {
                                    ItemId
                                    Responses
                                  }         
                            }`)
                            ).then(({ data }) => {
                              setOpen(false);
                              openConfirmation(true);
                            });
                          }}
                        >
                          Complete Interview
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 
                          shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError([]);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
