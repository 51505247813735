import { useEffect, useState } from "react";
import Table from "../../../Timesheets/Table";
import { API } from "aws-amplify";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";

const Timesheet = ({ timesheets, setTimesheets }) => {
  const [showContent, setShowContent] = useState(false);

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Timesheet
          </h4>
        </div>
        <div
          onClick={() => setShowContent(!showContent)}
          className="flex flex-none mr-5"
        >
          {!showContent ? (
            <ChevronUpIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
            />
          ) : (
            <ChevronDownIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {/* Table Section */}
      <div className={`${showContent ? "hidden" : "block"}`}>
        <Table tableData={timesheets} />
      </div>
    </div>
  );
};
export default Timesheet;
