import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
// Dashboard
import Table from "./Table";
import BasicSearchBar from "../../../utils/BasicSearchbar";
// Modals
import CreatePortalModal from "./Modals/CreatePortalModal";
import SearchModal from "./Modals/SearchModal";
import HideColumnsModal from "./Modals/HideColumnsModal";

const JobPortals = () => {
  const [tableData, setTableData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [tempOutput, setTempOutput] = useState([]);
  // const [hiddenColumns, setHiddenColumns] = useState([]);
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [tempOpportunitiesData, setTempOpportunitiesData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [columnsModalOpen, setColumnsModalOpen] = useState(false);
  const [opportunityField, setOpportunityField] = useState("");
  const [selectedJob, setSelectedJob] = useState([]);
  const [criteriaFilter, setCriteriaFilter] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [client, setClient] = useState([]);
  const [contract, setContract] = useState([]);
  const [newData, setNewData] = useState({
    Name: "",
    Creator: localStorage.getItem("name"),
    DateCreated: new Date().toISOString(),
    DateUpdated: new Date().toISOString(),
    Status: "",
    Logo: "",
    Url: "",
    Criteria: {
      Location: "",
      Client: "",
      Contract: "",
    },
    Headline: "",
    Description: "",
    Opportunities: [],
    HiddenColumns: [
      {
        name: "Type",
        hidden: false,
        order: 0,
      },
      {
        name: "Location",
        hidden: false,
        order: 1,
      },
      {
        name: "Client",
        hidden: false,
        order: 2,
      },
      {
        name: "Contract",
        hidden: false,
        order: 3,
      },
      {
        name: "Pay Rate",
        hidden: false,
        order: 4,
      },
      {
        name: "Work Type",
        hidden: false,
        order: 5,
      },
    ],
  });

  // GET JOB PORTALS DATA
  useEffect(() => {
    API.graphql({
      query: `{
      JobPortals {
        ItemId
        DateCreated
        DateUpdated
        Creator
        Status
        Headline
        Description
        Url
        Criteria
        Logo
        Name
        Opportunities
        HiddenColumns    
      }
      Clients(order_by: {Name: asc}) {
        ItemId
        Name
      }
      Contracts(order_by: {Name: asc}) {
        ItemId
        Name
        ClientId
      }
    }      
  `,
    }).then(({ data }) => {
      setTableData(data.JobPortals);
      setClient(data.Clients);
      setContract(data.Contracts);
    });
  }, []);

  useEffect(() => {
    setTempData(tableData);
  }, [tableData]);

  // GET OPPORTUNITIES, CLIENTS AND CONTRACTS DATA FOR CRITERIA DROPDOWNS
  useEffect(() => {
    API.graphql({
      query: `{
      Opportunities(offset: 0, limit: 1000 order_by: {DateCreated: asc}) {
        ItemId
        ClientId
        Contract
        Applicants
        Assignee
        BillRateCompensationUnit
        BillRateContract
        Compensation
        Department
        DueDate
        HighEndRate
        JobNum
        Length
        Location
        LowEndRate
        PayRateCompensationUnit
        QuestionnaireFormId
        RecruiterId
        Status
        Support
        Title
        Type
        WorkOrder
        WorkType
        Client {
          Name
        }
        Contracts {
          Name
        }
        ApplicationQuestionnaire {
          Title
        }
      }
    }      
  `,
    }).then(({ data }) => {
      setOpportunitiesData(data.Opportunities);
      setLocationList(
        data.Opportunities.map((obj) => obj.Location).filter(
          (val, id, array) => array.indexOf(val) == id
        )
      );
      // APPLY CRITERIA FILTER
      if (criteriaFilter) {
        if (opportunityField === "Location") {
          setTempOpportunitiesData(
            data.Opportunities.filter((obj) =>
              obj.Location.startsWith(criteriaFilter)
            )
          );
        } else if (opportunityField === "Contract") {
          setTempOpportunitiesData(
            data.Opportunities.filter((obj) =>
              obj.Contract?.startsWith(criteriaFilter)
            )
          );
        } else if (opportunityField === "Client") {
          setTempOpportunitiesData(
            data.Opportunities.filter((obj) =>
              obj.ClientId?.startsWith(criteriaFilter)
            )
          );
        }
      }
      if (opportunityField === "Manual" || opportunityField === "") {
        setTempOpportunitiesData(data.Opportunities);
      }
    });
  }, [criteriaFilter, createModalOpen, opportunityField]);

  // APPLY SEARCHBAR FILTER
  useEffect(() => {
    setTempData(
      tableData.filter((obj) =>
        obj.Name?.toLowerCase().startsWith(searchVal.toLowerCase())
      )
    );
  }, [searchVal]);

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={() => setCreateModalOpen(!createModalOpen)}
            type="button"
            className="hover:opacity-90 rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
      <Table
        tableData={tableData}
        setTableData={setTableData}
        tempData={tempData}
        setSearchVal={setSearchVal}
        setSearchModalOpen={setSearchModalOpen}
        opportunityField={opportunityField}
        setOpportunityField={setOpportunityField}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        criteriaFilter={criteriaFilter}
        setCriteriaFilter={setCriteriaFilter}
        client={client}
        contract={contract}
        locationList={locationList}
        jobsData={opportunitiesData}
      />
      <CreatePortalModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        setSearchModalOpen={setSearchModalOpen}
        opportunityField={opportunityField}
        setOpportunityField={setOpportunityField}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        jobsData={tempOpportunitiesData}
        tableData={tableData}
        setTableData={setTableData}
        tempData={newData}
        setTempData={setNewData}
        criteriaFilter={criteriaFilter}
        setCriteriaFilter={setCriteriaFilter}
        client={client}
        contract={contract}
        locationList={locationList}
        setTempOutput={setTempOutput}
        tempOutput={tempOutput}
      />
      {opportunitiesData.length > 0 && (
        <SearchModal
          open={searchModalOpen}
          setOpen={setSearchModalOpen}
          tempData={tempData}
          newData={newData}
          jobsData={tempOpportunitiesData}
          setJobsData={setTempOpportunitiesData}
          jobPortalsData={tableData}
          setJobPortalsData={setTableData}
          client={client}
          contract={contract}
          opportunityField={opportunityField}
          criteriaFilter={criteriaFilter}
          setCreateModalOpen={setCreateModalOpen}
          setEditModalOpen={setEditModalOpen}
          setColumnsModalOpen={setColumnsModalOpen}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          setTempOutput={setTempOutput}
          tempOutput={tempOutput}
        />
      )}
      {selectedJob.HiddenColumns && (
        <HideColumnsModal
          open={columnsModalOpen}
          setOpen={setColumnsModalOpen}
          userData={tableData}
          setUserData={setTableData}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          setSearchModalOpen={setSearchModalOpen}
        />
      )}
    </div>
  );
};
export default JobPortals;
