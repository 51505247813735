/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function CreatePortalModal({
  open,
  setOpen,
  setSearchModalOpen,
  tableData,
  setTableData,
  tempData,
  setTempData,
  selectedJob,
  setSelectedJob,
  opportunityField,
  setOpportunityField,
  criteriaFilter,
  setCriteriaFilter,
  client,
  contract,
  locationList,
  jobsData,
}) {
  const [error, setError] = useState([]);

  useEffect(() => {
    setError([]);
  }, [tempData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                      setTempData({});
                      setOpportunityField("");
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Job Portal
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-8">
                      <div className="mt-2 pb-6">
                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2 grid grid-cols-5">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                              Status
                            </label>
                            <select
                              className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Status = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Status}
                            >
                              <option className="text-gray-300" value="">
                                Select
                              </option>
                              <option value="Live">Live</option>
                              <option value="Draft">Draft</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                          </div>
                        </div>

                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2 grid grid-cols-5">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Name = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Name}
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2 grid grid-cols-5">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                              Upload Logo
                            </label>
                            <input
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                setTempData({
                                  ...tempData,
                                  Logo: file.name,
                                });

                                try {
                                  let response = await Storage.put(
                                    file.name,
                                    file,
                                    {
                                      contentType: file.name.includes(".pdf")
                                        ? "application/pdf"
                                        : "image/png",
                                    }
                                  );
                                  let temp = tempData;
                                  temp.Logo = response.key;
                                  setTempData({ ...temp });
                                  return response;
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              type="file"
                              name="file"
                              id="file"
                              autoComplete="file"
                              className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2 grid grid-cols-5">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                              Headline
                            </label>
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Headline = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Headline}
                              type="text"
                              name="headline"
                              id="headline"
                              autoComplete="headline"
                              className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <textarea
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Description = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Description}
                              type="text"
                              name="description"
                              className="h-52 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                              rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                              id="description"
                              autoComplete="description"
                            />
                          </div>
                        </div>

                        <div className="font-bold text-lg mt-6 mb-4 text-gray-700">
                          Criteria
                        </div>

                        <div className="sm:col-span-2 flex-grow">
                          <div className="mt-2 grid grid-cols-5">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                              Opportunity Field
                            </label>
                            <select
                              className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                              onChange={(e) => {
                                setOpportunityField(e.target.value);
                              }}
                              value={opportunityField}
                            >
                              <option className="text-gray-300" value="">
                                Select
                              </option>
                              <option value="Client">Client</option>
                              <option value="Contract">Contract</option>
                              <option value="Location">Location</option>
                              <option value="Manual">Manual</option>
                            </select>
                          </div>
                        </div>

                        {(opportunityField === "Client") & open ||
                        (opportunityField === "Contract") & open ? (
                          <div className="sm:col-span-2 flex-grow">
                            <div className="mt-2 grid grid-cols-5">
                              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Client
                              </label>
                              <select
                                className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                onChange={(e) => {
                                  let temp = tempData;
                                  temp.Criteria.Client = e.target.value;
                                  if (opportunityField === "Client")
                                    setCriteriaFilter(e.target.value);
                                  setTempData({ ...temp });
                                }}
                                value={tempData.Criteria.Client}
                              >
                                <option className="text-gray-300" value="">
                                  Select
                                </option>
                                {client
                                  ?.sort((a, b) => a.Name.localeCompare(b.Name))
                                  ?.map((obj) => (
                                    <option key={obj.ItemId} value={obj.ItemId}>
                                      {obj.Name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        ) : null}

                        {(opportunityField === "Contract") & open ? (
                          <div className="sm:col-span-2 flex-grow">
                            <div className="mt-2 grid grid-cols-5">
                              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Contract
                              </label>
                              <select
                                className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                onChange={(e) => {
                                  let temp = tempData;
                                  temp.Criteria.Contract = e.target.value;
                                  setCriteriaFilter(e.target.value);
                                  setTempData({ ...temp });
                                }}
                                value={tempData.Criteria.Contract}
                              >
                                <option className="text-gray-300" value="">
                                  Select
                                </option>
                                {contract
                                  ?.sort((a, b) => a.Name.localeCompare(b.Name))
                                  .map((obj) => {
                                    if (
                                      obj.ClientId === tempData.Criteria.Client
                                    )
                                      return (
                                        <option
                                          key={obj.ItemId}
                                          value={obj.ItemId}
                                        >
                                          {obj.Name}
                                        </option>
                                      );
                                  })}
                              </select>
                            </div>
                          </div>
                        ) : null}

                        {(opportunityField === "Location") & open ? (
                          <div className="sm:col-span-2 flex-grow">
                            <div className="mt-2 grid grid-cols-5">
                              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Location
                              </label>
                              <select
                                className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                onChange={(e) => {
                                  let temp = tempData;
                                  temp.Criteria.Location = e.target.value;
                                  setCriteriaFilter(e.target.value);
                                  setTempData({ ...temp });
                                }}
                                value={tempData.Criteria.Location}
                              >
                                <option className="text-gray-300" value="">
                                  Select
                                </option>
                                {locationList?.map((obj, i) => (
                                  <option key={i} value={obj}>
                                    {obj}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : null}

                        {opportunityField === "Manual" ? (
                          <div className="sm:col-span-2 flex-grow">
                            <div className="mt-2 grid grid-cols-5">
                              <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Opportunities in Database
                              </label>
                              <input
                                value={`${jobsData.length} opportunities found`}
                                type="text"
                                className="col-span-4 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                                border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                text-gray-900 hover:teckpert-bg-green hover:text-white"
                              />
                            </div>
                          </div>
                        ) : null}

                        {error.length > 0 && MissingFieldPrompt(error)}
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                            font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                            onClick={async () => {
                              let errorArray = [];
                              if (tempData.Status?.trim().length === 0)
                                errorArray.push("Status");
                              if (tempData.Name?.trim().length === 0)
                                errorArray.push("Name");
                              if (tempData.Headline?.trim().length === 0)
                                errorArray.push("Headline");
                              if (tempData.Description?.trim().length === 0)
                                errorArray.push("Description");
                              if (opportunityField.trim().length === 0)
                                errorArray.push("Opportunity Field");

                              if (opportunityField === "Client") {
                                if (
                                  tempData.Criteria.Client?.trim().length ===
                                    0 ||
                                  !tempData.Criteria.Client
                                )
                                  errorArray.push("Client");
                              }
                              if (opportunityField === "Contract") {
                                if (
                                  tempData.Criteria?.Client?.trim().length ===
                                    0 ||
                                  !tempData.Criteria.Client
                                )
                                  errorArray.push("Client");
                                if (
                                  tempData.Criteria?.Contract?.trim().length ===
                                    0 ||
                                  !tempData.Criteria.Contract
                                )
                                  errorArray.push("Contract");
                              }
                              if (opportunityField === "Location") {
                                if (
                                  tempData.Criteria?.Location?.trim().length ===
                                    0 ||
                                  !tempData.Criteria.Location
                                )
                                  errorArray.push("Location");
                              }
                              setError(errorArray);

                              if (errorArray.length > 0) {
                                return;
                              }

                              let temp = tempData;
                              temp.Url = tempData.Name.toLowerCase().replace(
                                /\s+/g,
                                "-"
                              );

                              await API.graphql(
                                graphqlOperation(`mutation {
                                  insert_JobPortals_one(
                                    object: 
                                    {
                                      ItemId: "${uuidv4()}",
                                      Creator: "${tempData.Creator}", 
                                      Status: "${tempData.Status}", 
                                      Headline: "${tempData.Headline}", 
                                      Description: ${JSON.stringify(tempData?.Description) }, 
                                      Url: "${tempData.Url}",
                                      Logo: "${tempData.Logo}",
                                      Name: "${tempData.Name}",
                                      Criteria: {
                                        Location: "${
                                          tempData.Criteria?.Location
                                        }",
                                        Client: "${tempData.Criteria?.Client}",
                                        Contract: "${
                                          tempData.Criteria?.Contract
                                        }"
                                      },   
                                      HiddenColumns: [${tempData.HiddenColumns.map(
                                        (obj) =>
                                          `{
                                              name: "${obj.name}",
                                              hidden: ${obj.hidden},
                                              order: ${obj.order}
                                           }`
                                      ).join(",")}
                                      ],
                                      Opportunities: [${tempData.Opportunities.map(
                                        (obj) => `"${obj}"`
                                      ).join(",")}],                     
                                    })
                                    {
                                      ItemId
                                      Name
                                      Criteria
                                      HiddenColumns
                                    }         
                              }`)
                              ).then(({ data }) => {
                                console.log("test", data);
                                temp.ItemId = data.insert_JobPortals_one.ItemId;
                                setSelectedJob(temp);
                                setOpen(false);
                                setSearchModalOpen(true);
                                setTableData([...tableData, tempData]);
                              });
                            }}
                          >
                            Create
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 
                            py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => {
                              setTempData({
                                Name: "",
                                Creator: localStorage.getItem("name"),
                                DateCreated: new Date().toISOString(),
                                DateUpdated: new Date().toISOString(),
                                Status: "",
                                Logo: "",
                                Url: "",
                                Criteria: {
                                  Location: "",
                                  Client: "",
                                  Contract: "",
                                },
                                Headline: "",
                                Description: "",
                                Opportunities: [],
                              });
                              setOpen(false);
                              setError([]);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
