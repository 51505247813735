import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Certifications from "./Tabs/Certifications";
import Contracts from "./Tabs/Contracts";
import Documents from "./Tabs/Documents";
import Education from "./Tabs/Education";
import Notes from "./Tabs/Notes";
import Opportunities from "./Tabs/Opportunities";
import PDFViewer from "./Tabs/PDFViewer";
import Preferences from "./Tabs/Preferences";
import References from "./Tabs/References";
import Specialization from "./Tabs/Specialization";
import Interviews from "./Tabs/Interviews";
import Timesheet from "./Tabs/Timesheet";
import Loading from "../../../utils/Loading";
import Logs from "./Tabs/Logs";
import ConfirmMessage from "../../../utils/ConfirmMessage";
import TabsMenu from "./TabsMenu";
import BackButton from "../../../utils/BackButton";

const Profile = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("tab0");
  const [profileData, setProfileData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [interviewGuides, setInterviewGuides] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState({});
  const [newResponses, setNewResponses] = useState({
    ItemId: "",
    MemberId: location.pathname.split("/")[2],
    Assigned: "People",
    DateUpdated: new Date().toISOString(),
    ChecklistId: "",
    Responses: [],
  });
  const [checklistResponses, setChecklistResponses] = useState([]);
  const [guideFilter, setGuideFilter] = useState([]);
  const [interviewGuideResponses, setInterviewGuideResponses] = useState([]);
  const [responsesData, setResponsesData] = useState([]);
  const [applicantData, setApplicantData] = useState([]);
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [opportunitiesApplied, setOpportunitiesApplied] = useState(new Map());
  const [documentData, setDocumentData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [resumeUrl, setResumeUrl] = useState([]);
  const [timesheets, setTimesheets] = useState([]);
  const [confirmButtonMessage, setConfirmButtonMessage] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [adminsData, setAdminsData] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [emailsData, setEmailsData] = useState([]);
  const [msgModalOpen, setMsgModalOpen] = useState(false);

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  // FUNCTION TO GET DOCUMENTS
  const getStorageFile = async (key, id) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });

    return { url, ItemId: id };
  };

  // GET/SET MEMBER DATA AND RESUME FILE TO RENDER PROFILE, AND OPPORTUNITES DATA  // GET/SET INTERVIEW GUIDES DATA
  useEffect(() => {
    API.graphql({
      query: `{
        Members(where: {ItemId: {_eq: "${location.pathname.split("/")[2]}"}}) {
          ItemId
          DateCreated
          DateUpdated
          Meta
          FirstName
          LastName
          PrimaryTitle
          ScreeningStatus
          AccountStatus
          Documents
          Notes
          CallLogs
          Starred
          Courses
          Onboarding
          SSN
          BackgroundCheck
          SignNDA
          NDALink
          SignCSA
          Enroll
          Vetted
          Flagged
          EmploymentType
          RecruiterId
          Resume
        }
      }   
      `,
    }).then(({ data }) => {
        setOriginalData(data.Members[0]);
      
    });
  }, [location.pathname.split("/")[2]]);

  useEffect(() => {
    API.graphql({
      query: `{
      Members_by_pk(ItemId: "${location.pathname.split("/")[2]}") {
        ItemId
        DateCreated
        DateUpdated
        Meta
        FirstName
        LastName
        PrimaryTitle
        ScreeningStatus
        AccountStatus
        Documents
        Notes
        CallLogs
        Starred
        Courses
        Onboarding
        SSN
        BackgroundCheck
        SignNDA
        NDALink
        SignCSA
        Enroll
        Vetted
        Flagged
        EmploymentType
        RecruiterId
        Resume
      }
      Opportunities {
        ItemId
        ClientId
        Contract
        Applicants
        BillRateCompensationUnit
        BillRateContract
        HighEndRate
        Location
        LowEndRate
        PayRateCompensationUnit
        Status
        Title
        Type
        WorkType
        InterviewGuideId
        Client {
          Name
        }
        Contracts {
          Name
        }
      }
    }      
  `,
    }).then(async ({ data: { Members_by_pk: Member, Opportunities } }) => {
       
      setProfileData(Member);
        setOpportunitiesData(Opportunities);
       
      setResumeUrl(
        (await getStorageFile(Member?.Resume ?? Member?.Meta?.Resume)).url
      );

      API.graphql({
        query: `{
          Applicants(where: {MemberId: {_eq: "${
            location.pathname.split("/")[2]
          }"}}) {  
            ItemId
            DateCreated
            DateUpdated
            OpportunityId
            Opportunities {
              ItemId
              Title
              Workflows {
                ItemId
                Stages
              }
            }
            MemberId
            Interested
            WorkFlowStatus
            InterviewGuidesResponses {
              Responses
              InterviewGuides {
                Title
                Questions
              }
            }
          }
        }      
      `,
      }).then(({ data }) => {
          setApplicantData(data.Applicants);
         
        //create hashmap to store data. hash map is more effecient in this case.
        let map = new Map();
          data.Applicants.map((obj) => map.set(obj.OpportunityId, obj));
          
        setOpportunitiesApplied(map);

        let tempAppliedIds = data.Applicants.map((obj) => obj.OpportunityId);
        let tempKeyPair = Opportunities.filter((r) =>
          tempAppliedIds.includes(r.ItemId)
        )
          .filter((i) => i.InterviewGuideId != null)
          .map((x) => ({
            OpportunityId: x.ItemId,
            InterviewGuideId: x.InterviewGuideId,
          }));
        setGuideFilter(tempKeyPair);
      });
    });
  }, [location.pathname.split("/")[2], confirmButtonMessage]);

  // GET/SET INTERVIEW GUIDES DATA
  useEffect(() => {
    API.graphql({
      query: `{
        InterviewGuides {
          ItemId
          DateCreated
          DateUpdated
          Title
          Duration
          Questions
          Notes
          Description
          CreatedBy
        }
      }      
      `,
    }).then(({ data }) => {
      let tempGuideFilter = guideFilter.map((x) => x.InterviewGuideId);
      setInterviewGuides(
        data.InterviewGuides.filter((item) =>
          tempGuideFilter.includes(item.ItemId)
        )
      );
    });
  }, [location.pathname, opportunitiesData, guideFilter]);

  useEffect(() => {
    API.graphql({
      query: `{
        InterviewGuidesResponses {
          ItemId
          DateCreated
          DateUpdated
          DateCompleted
          InterviewGuideId
          Notes
          Rating
          MemberId
          Responses
        }
      }      
    `,
    }).then(({ data }) => {
      setResponsesData(data.InterviewGuidesResponses);
      setInterviewGuideResponses(
        data.InterviewGuidesResponses.filter(
          (obj) => obj.MemberId === location.pathname.split("/")[2]
        )[0]
      );
    });
  }, [opportunitiesApplied]);

  // GET CHECKLISTS DATA
  useEffect(() => {
    API.graphql({
      query: `{
        Checklists(where: { Assign: { _neq: "Contract" } }) {
          ItemId
          DateCreated
          Title
          Items
          Assign
          Description
          CreatedBy
          Default
          MemberId
        }
        ChecklistsResponses(order_by: {DateUpdated: desc}, where: {MemberId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          MemberId
          Assigned
          DateUpdated
          Responses
          ChecklistId
          Checklists {
            ItemId
            Title
          }
          Members {
            ItemId
          }
        }
      }      
      `,
    }).then(({ data }) => {
        setChecklists(data.Checklists.filter((obj) => obj.Items.length > 0));
       
        setChecklistResponses(data.ChecklistsResponses);
        

      // get all member's checklists responses
      let tempExistingResponses = data.ChecklistsResponses?.filter(
        (obj) => obj.MemberId == location.pathname.split("/")[2]
      );

      // get default checklist
      let defaultList = data.Checklists.filter(
        (obj, i) => obj.Default == true
      )[0];

      let tempNewResponse = newResponses;

      if (defaultList && tempExistingResponses.length < 1) {
        // if there is a default checklist and no existing checklists responses
        let tempObj = defaultList.Items.map((obj, i) => ({
          Name: obj.Name,
          Checked: false,
        }));
        tempNewResponse.ChecklistId = defaultList.ItemId;
        tempNewResponse.Responses = tempObj;
      } else if (tempExistingResponses.length > 0) {
        // if there are existing responses
        console.log("tempExistingResponses", tempExistingResponses);
        tempNewResponse.ItemId = tempExistingResponses[0].ItemId;
        tempNewResponse.ChecklistId = tempExistingResponses[0].ChecklistId;
        tempNewResponse.Responses = tempExistingResponses[0].Responses;
      }
      setNewResponses({ ...tempNewResponse });
    });
  }, []);

  useEffect(() => {
    setNewResponses({ ...selectedChecklist });
  }, [selectedChecklist]);

  useEffect(() => {
    const async = async () => {
      const { data } = await API.graphql({
        query: `{
          Documents(where: {MemberId: {_eq: "${
            location.pathname.split("/")[2]
          }"}}) {
            ItemId
            Name
            Type
            DateCreated
            Key
          }
      }`,
      });
      setDocumentData(data.Documents);
    };
    async();
  }, [location.pathname]);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(order_by: {FirstName: asc}, where: {UserType: {_neq: "member"}}) {
          ItemId
          DateCreated
          DateUpdated
          FirstName
          LastName
          UserType
          Email
      }
    }      
  `,
    }).then(({ data }) => {
        setAdmins(data.Users);
       
      let temp = [];
      let adminsData = {};
      data.Users?.map((item) => {
        temp.push({
          title: `${item.FirstName} ${item.LastName} (${item.Email})`,
          value: item.ItemId,
          firstName: item.FirstName,
          lastName: item.LastName,
          type: item.UserType,
        });
        adminsData[item.ItemId] = item.FirstName + " " + item.LastName;
      });
      setAdminList(temp);
      setAdminsData({ admins: temp, adminsData: adminsData });
    });
  }, []);

  if (profileData?.length === 0) return <Loading />;

  return (
    <React.Fragment>
      <BackButton link={"/people"} />
      {confirmButtonMessage && <ConfirmMessage />}
      <Header
        originalData={originalData}
        profileData={profileData}
        setProfileData={setProfileData}
        opportunitiesData={opportunitiesData}
        opportunitiesApplied={opportunitiesApplied}
        applicantData={applicantData}
        setApplicantData={setApplicantData}
        setConfirmButtonMessage={setConfirmButtonMessage}
        resumeUrl={resumeUrl}
        admins={admins}
        setMsgModalOpen={setMsgModalOpen}
        msgModalOpen={msgModalOpen}
        checklists={checklists}
        selectedChecklist={selectedChecklist}
        setSelectedChecklist={setSelectedChecklist}
        checklistResponses={checklistResponses}
        newResponses={newResponses}
        setNewResponses={setNewResponses}
      />
      <div className="bg-white">
        <TabsMenu activeTab={activeTab} setActiveTab={setActiveTab} />
              {/* Resume Tab */}
        <div className={activeTab === "tab0" ? "block" : "hidden"}>
          <PDFViewer
            profileData={profileData}
            setProfileData={setProfileData}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
        </div>
              {/* Interviews Tab */}
        <div className={activeTab === "tab1" ? "block" : "hidden"}>
          {interviewGuides ? (
            <Interviews
              tableData={interviewGuides}
              responsesData={responsesData.filter(
                (x) => x.MemberId === location.pathname.split("/")[2]
              )}
              profileData={profileData}
              setProfileData={setProfileData}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
          ) : null}
        </div>
              {/* Profile Tab */}
        <div className={activeTab === "tab2" ? "block" : "hidden"}>
          <Preferences
            originalData={originalData}
            profileData={profileData}
            setProfileData={setProfileData}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
          <References
            originalData={originalData}
            profileData={profileData}
            setProfileData={setProfileData}
            url={"/members"}
            adminList={adminList}
            adminsData={adminsData}
            emailsData={emailsData}
            setEmailsData={setEmailsData}
            setMsgModalOpen={setMsgModalOpen}
            msgModalOpen={msgModalOpen}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
          <Education
            originalData={originalData}
            profileData={profileData}
            setProfileData={setProfileData}
            url={"/members"}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
          <Specialization
            originalData={originalData}
            profileData={profileData}
            setProfileData={setProfileData}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
          <Certifications
            originalData={originalData}
            profileData={profileData}
            setProfileData={setProfileData}
            url={"/members"}
            confirmButtonMessage={confirmButtonMessage}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
        </div>
              {/* Opportunities Tab */}
        <div className={activeTab === "tab3" ? "block" : "hidden"}>
          <Opportunities
            profileData={profileData}
            applicantData={applicantData}
            activeTab={activeTab}
            adminList={adminList}
          />
        </div>
              {/* Contracts Tab */}
        <div className={activeTab === "tab4" ? "block" : "hidden"}>
          <Contracts
            contractData={contractData}
            setContractData={setContractData}
            activeTab={activeTab}
          />
          <Timesheet timesheets={timesheets} setTimesheets={setTimesheets} />
        </div>
              {/* Documents Tab */}
        <div className={activeTab === "tab5" ? "block" : "hidden"}>
          <Documents
            tableData={documentData}
            setTableData={setDocumentData}
            documentUrl={documentUrl}
            setConfirmButtonMessage={setConfirmButtonMessage}
          />
        </div>
              {/* Notes Tab */}
        <div className={activeTab === "tab6" ? "block" : "hidden"}>
          <div className="mt-4 p-3">
            <div className="flex">
              <div className="flex-grow">
                <Notes
                  originalData={originalData}
                  profileData={profileData}
                  setProfileData={setProfileData}
                  url={"/members"}
                  setConfirmButtonMessage={setConfirmButtonMessage}
                />
              </div>
              <div className="flex-grow">
                <Logs
                  originalData={originalData}
                  profileData={profileData}
                  setProfileData={setProfileData}
                  url={"/members"}
                  setConfirmButtonMessage={setConfirmButtonMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Profile;
