import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/FormatDate";

const Contracts = ({ tableData }) => {
  const navigate = useNavigate();

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border shadow-md hover:bg-gray-100 ">
      <div className="flex justify-between">
        <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
          Contracts
        </h4>
      </div>
      {/* Table Section */}
      <div className="mx-5 flex flex-col  p-3">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Status
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Uploaded
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Created By
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData?.map((obj) => {
                    return (
                      <tr key={obj.ItemId}>
                        <td
                          onClick={() => navigate("/contracts/" + obj.ItemId)}
                          className="whitespace-nowrap pl-5 py-4 text-sm teckpert-text-green font-bold cursor-pointer hover:opacity-80"
                        >
                          {obj.Name ? obj.Name : "-"}
                        </td>
                        <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                          {obj.Status ? obj.Status : "-"}
                        </td>
                        <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900">
                          {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                        </td>
                        <td className="whitespace-nowrap pl-5 py-4 text-sm text-gray-900 ">
                          {obj.CreatorName ? obj.CreatorName : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contracts;
