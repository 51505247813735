/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import MissingFieldPrompt from "../../../utils/MissingFieldPrompt";
import { Editor } from "@tinymce/tinymce-react";

export default function SendMessage({
  open,
  setOpen,
  setRefresh,
  defaultTo,
  setConfirmButtonMessage,
  defaultTemplate,
}) {
  const location = useLocation();
  const [error, setError] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [members, setMembers] = useState([]);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(defaultTo);
  const [template, setTemplate] = useState(null);
  const [opportunity, setOpportunity] = useState(null);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [initialValue, setInitialValue] = useState("");

  useEffect(() => {
    const async = async () => {
      const { data } = await API.graphql({
        query: `{
        Admins: Users(offset: 0, limit: 10000, order_by: {DateCreated: asc}, where: {UserType: {_neq: "member"}}) {
          ItemId
          FirstName
          LastName
          Email
        }
        EmailTemplates(offset: 0, limit: 10000, order_by: {DateCreated: asc}) {
          ItemId
          Type
          Subject
          Body
        }
        Opportunities(offset: 0, limit: 10000, order_by: {DateCreated: asc}) {
          ItemId
          Title
        }
        Members(offset: 0, limit: 10000, order_by: {DateCreated: asc}) {
          ItemId
          FirstName
          LastName
          Meta
        }
      }      
    `,
      });
      setAdmins(
        data.Admins.map(({ ItemId, FirstName, LastName, Email }) => ({
          value: ItemId,
          title: `${FirstName} ${LastName} <${Email}>`,
        }))
      );
      setEmailTemplates(data.EmailTemplates);
      setOpportunities(data.Opportunities);
      setMembers(data.Members);
      // setTemplate(
      //   data.EmailTemplates.length > 0 ? data.EmailTemplates[0].ItemId : null
      // );
    };
    async();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("workflows")) setTemplate(defaultTemplate);
  }, [location.pathname, defaultTemplate]);

  const onSave = async () => {
    let errorArray = [];
    if (from?.trim().length === 0 || from === null) errorArray.push("From");
    if (to?.trim().length === 0) errorArray.push("To");
    if (template?.trim().length === 0) errorArray.push("Template");
    if (subject?.trim().length === 0) errorArray.push("Subject");
    if (body?.trim().length === 0) errorArray.push("Body");

    setError(errorArray);

    if (errorArray.length > 0) {
      return;
    }

    const response = await API.post("auth", "/sendEmail", {
      body: { from, to, template, opportunity, subject, body },
    });
    await API.graphql({
      query: `mutation($subject: String, $body: String) {
        insert_Emails_one(
          object: 
          {
            From: "${from}",
            To: "${to}",
            Type: "${template}",
            Opportunity: "${opportunity}",
            Subject: $subject,
            Body: $body
          })
          {
            ItemId
            From
          }         
    }`,
      variables: {
        subject: response.subject,
        body: response.body,
      },
    });
    setRefresh(new Date().toISOString());
    setOpen(false);
    setTemplate("");
    setSubject("");
    setBody("");
    setOpportunity("");
    setInitialValue("");
    setTo("");
    setFrom("");
  };

  useEffect(() => {
    const response = emailTemplates?.find(({ ItemId }) => ItemId === template);
    if (response) {
      setSubject(response.Subject);
      setBody(response.Body);
      setInitialValue(response.Body);
    }
  }, [emailTemplates, template]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[1000px] h-[800px] relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 hover:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {!location.pathname.includes("workflows") &&
                        "Send Message"}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-6 sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      From
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        setFrom(e.target.value);
                      }}
                      value={from}
                    >
                      <option className="text-gray-300" value="">
                        Select Recruiter
                      </option>
                      {admins
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .map((obj, i) => (
                          <option key={i} value={obj.value}>
                            {obj.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      To
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        setTo(e.target.value);
                      }}
                      value={to}
                    >
                      <option className="text-gray-300" value="">
                        Select User
                      </option>
                      {members
                        ?.sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                        .map((obj) => (
                          <option
                            className="text-white"
                            value={obj.ItemId}
                            key={obj.ItemId}
                          >
                            {`${obj?.FirstName} ${obj?.LastName} <${obj?.Meta?.Email}>`}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {!location.pathname.includes("workflows") && (
                  <div className="sm:col-span-2">
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Template
                      </label>
                      <select
                        className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                        onChange={(e) => {
                          setTemplate(e.target.value);
                        }}
                        value={template}
                      >
                        <option className="text-gray-300" value="">
                          Select Template
                        </option>
                        {emailTemplates?.map((obj, i) => (
                          <option key={i} value={obj.ItemId}>
                            {obj.Type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {!location.pathname.includes("workflows") && (
                  <div>
                    <div className="sm:col-span-2">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Opportunity
                        </label>
                        <select
                          className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                          border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                          text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                          onChange={(e) => {
                            setOpportunity(e.target.value);
                          }}
                          value={opportunity}
                        >
                          <option className="text-gray-300" value="">
                            Select Opportunity
                          </option>
                          {opportunities
                            ?.sort((a, b) => a.Title.localeCompare(b.Title))
                            .map((obj, i) => (
                              <option key={i} value={obj.ItemId}>
                                {obj.Title}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-2 flex-grow">
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Subject
                        </label>
                        <input
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                          value={subject}
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Body
                    </label>
                    <Editor
                      apiKey={
                        "2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"
                      }
                      onEditorChange={(value) => {
                        setBody(value);
                      }}
                      value={body}
                      initialValue={initialValue}
                      init={{
                        height: 450,
                        menubar: false,
                        plugins: [
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | casechange blocks | bold italic backcolor | " +
                          "alignleft aligncenter alignright alignjustify | " +
                          "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        content_style:
                          "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                      }}
                    />
                  </div>
                </div>
                {error.length > 0 && MissingFieldPrompt(error)}

                <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {!location.pathname.includes("workflows") ? (
                    <div>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                        teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={onSave}
                      >
                        Send
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                        bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                        focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false);
                          setError([]);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => {}}
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                      teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Send
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
