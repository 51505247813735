import { useState, useEffect } from "react";
import QuickStats from "./QuickStats";
import Table from "./Table";
import SearchBar from "../SearchBar";
import { API } from "aws-amplify";
import Loading from "../../utils/Loading";

const People = ({ userData, setUserData }) => {
  const itemsPerPage = 25;
  const [tableFilters, setTableFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tempTable, setTempTable] = useState(tableData);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [filtersModal, setFiltersModal] = useState(false);
  const [filterSearch, setFilterSearch] = useState([]);
  const [dropdownSearch, setDropdownSearch] = useState({
    Status: null,
    JobTitle: null,
    Location: null,
    Opportunity: null,
  });
  const [advFilterSearch, setAdvFilterSearch] = useState([]);
  const [btnFilter, setBtnFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [filterName, setFilterName] = useState([]);
  const [comboFilter, setComboFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [filterPage, setFilterPage] = useState(1);
  const [memberTotal, setMemberTotal] = useState("");
  const [filterMemberTotal, setFilterMemberTotal] = useState(1);
  const [userStatus, setUserStatus] = useState([]);
  const [quickStats, setQuickStats] = useState({});
    const [comboBoxLabel, setComboBoxLabel] = useState({
    Status: {
      name: "Status",
      data: [],
      search: "",
    },
    JobTitle: {
      name: "JobTitle",
      data: [],
      search: "",
    },
    Location: {
      name: "Location",
      data: [],
      search: "",
    },
    Opportunity: {
      name: "Opportunity",
      data: [],
      search: "",
    },
  });
  const smFilterButtons = [
    { name: "Resume", dataStatus: "hasResume" },
    { name: "LinkedIn", dataStatus: "hasLinkedin" },
    { name: "Portfolio", dataStatus: "hasPortfolio" },
    { name: "ShortList", dataStatus: "hasShortList" },
  ];

  // GET MEMBERS TABLE DATA PAGINATED
  useEffect(() => {
    setLoading(true);
    const sharedWhere = `{
      ${searchVal ? `FirstName: {_ilike: "${searchVal}%"},` : ""}
      ${
        filterSearch.includes("ShortList")
          ? `AccountStatus: {_eq:"Short Listed"},`
          : ""
      }
      ${
        filterSearch.includes("Photo") ||
        filterSearch.includes("Portfolio") ||
        filterSearch.includes("LinkedIn") ||
        filterSearch.includes("Resume")
          ? `Meta: {_has_keys_all: [${filterSearch
              .filter((obj) => obj !== "ShortList")
              .map((obj) => `"${obj}"`)
              .join(",")}]},`
          : ""
      }
      ${
        dropdownSearch.Status
          ? `Applicants: {WorkFlowStatus: {_eq: "${dropdownSearch.Status}"}},`
          : ""
      }
      ${
        dropdownSearch.JobTitle
          ? `Meta: {PrimaryRole: {label: {_eq: "${dropdownSearch.JobTitle}"}}},`
          : ""
      }
      ${
        dropdownSearch.Location
          ? `Meta.Location: {_eq: "${dropdownSearch.Location}"},`
          : ""
      }
      ${
        dropdownSearch.Opportunity
          ? `Applicants: {Opportunities: {Title: {_eq: "${dropdownSearch.Opportunity}"}}},`
          : ""
      }
      ItemId: {_is_null: false}
    }`;
    API.graphql({
      query: `{
        Members(offset: ${
          (page - 1) * itemsPerPage
        }, limit: ${itemsPerPage}, order_by: {DateCreated: desc}, where: ${sharedWhere}) {
          AccountStatus
          BackgroundCheck
          CSALink
          CompletedProfile
          Courses
          DateCreated
          DateUpdated
          Documents
          EmploymentType
          Enroll
          EnrollLink
          FirstName
          Flagged
          ItemId
          JobOrderLink
          LastName
          Meta
          NDALink
          NoShow
          Notes
          Onboarding
          PrimaryTitle
          Overtime
          RecruiterId
          ReferEmail
          Resume
          ReviewedApp
          SSN
          ScreeningStatus
          SignCSA
          SignJobOrder
          SignNDA
          Starred
          Textract
          Vetted
          WonContract
        }
        Applicants {
          ItemId
          MemberId
          WorkFlowStatus
          OpportunityId
          Opportunities {
            Title
          }
        }
        Members_aggregate(where: ${sharedWhere}) {
          aggregate {
            count
          }
        }
      }      
    `,
    }).then(({ data }) => {
      setTableData(data.Members);
      setTempTable(data.Members);
      setMemberTotal(data.Members_aggregate.aggregate.count);
      setLoading(false);
    });
      
    console.log(comboBoxLabel);
  }, [page, searchVal, filterSearch, btnFilter, dropdownSearch]);

  // GET DATA FOR DROPDOWNS AND QUICKSTATS
  useEffect(() => {
    API.graphql({
      query: `{
      Opportunities(order_by: {Title: asc}) {
        ItemId
        Name: Title
      }
    }
    `,
    }).then(({ data }) => {
      setOpportunitiesData(data.Opportunities);
    });

    API.graphql({
      query: `{
      Lookup {
        ItemId
        Name   
        Options
      }
    }      
  `,
    }).then(({ data }) => {
      setUserStatus(
        data.Lookup.filter((obj) => obj.Name === "WorkFlowStatus")[0].Options
      );
    });
  }, []);

  useEffect(() => {
    setFilterSearch(userData.TableFilters?.people || []);
  }, [userData]);

    
  useEffect(() => {
      let tempComboBoxLabel = comboBoxLabel;
      
    comboBoxLabel["Status"].data = userStatus
      ?.map((x) => x)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    comboBoxLabel["JobTitle"].data = tableData
      // ?.sort((a, b) =>
      //   a.Meta?.PrimaryRole?.label.localeCompare(b.Meta?.PrimaryRole?.label)
      // )
      ?.map((x) => x.Meta?.PrimaryRole?.label)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    comboBoxLabel["Location"].data = tableData
      ?.sort((a, b) => a.Meta?.Location?.localeCompare(b.Meta?.Location))
      ?.map((x) => x.Meta?.Location)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    comboBoxLabel["Opportunity"].data = opportunitiesData
      ?.map((x) => x.Name)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    setComboBoxLabel(tempComboBoxLabel);
  }, [tableData, btnFilter, opportunitiesData]);

  if (loading) return <Loading />;

  return (
    <div className="p-6">
      <QuickStats />
      <SearchBar
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        setFilterSearch={setFilterSearch}
        advFilterSearch={advFilterSearch}
        setAdvFilterSearch={setAdvFilterSearch}
        setFiltersModal={setFiltersModal}
      />
      <Table
        tableData={tableData}
        tempTable={tempTable}
        setTempTable={setTempTable}
        setFiltersModal={setFiltersModal}
        filtersModal={filtersModal}
        filteredTableData={filteredTableData}
        searchVal={btnFilter}
        setSearchVal={setBtnFilter}
        filterSearch={filterSearch}
        dropdownSearch={dropdownSearch}
        setDropdownSearch={setDropdownSearch}
        setFilterSearch={setFilterSearch}
        advFilterSearch={advFilterSearch}
        setAdvFilterSearch={setAdvFilterSearch}
        comboFilter={comboFilter}
        setComboFilter={setComboFilter}
        opportunitiesData={opportunitiesData}
        setOpportunitiesData={setOpportunitiesData}
        page={page}
        setPage={setPage}
        memberTotal={memberTotal}
        itemsPerPage={itemsPerPage}
        userStatus={userStatus}
        userData={userData}
        setUserData={setUserData}
        filterMemberTotal={filterMemberTotal}
        setFilterPage={setFilterPage}
        setActiveFilter={setActiveFilter}
        comboBoxLabel={comboBoxLabel}
        setComboBoxLabel={setComboBoxLabel}
        smFilterButtons={smFilterButtons}
        filterName={filterName}
        setFilterName={setFilterName}
      />
    </div>
  );
};
export default People;
