/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

const UploadResumeModal = ({
  open,
  setOpen,
  tableData,
  setTableData,
  setConfirmButtonMessage,
}) => {
  const location = useLocation();
  const [documentData, setDocumentData] = useState({
    Key: "",
    Folder: "",
    Name: "",
    ClientId: "",
    DateCreated: new Date(),
    CreatorName: localStorage.getItem("name"),
    Type: "",
  });
  const [error, setError] = useState([]);

  async function onChange(e) {
    const file = e.target.files[0];
    
    setDocumentData({
      Key: file.name,
      Folder: "testing",
      Name: file.name,
      ClientId: location.pathname.split("/")[2],
      MemberId: location.pathname.split("/")[2],
      DateCreated: new Date().toISOString(),
      CreatorName: localStorage.getItem("name"),
      Type: "Resume",
    });

    try {
      let response = await Storage.put(file.name, file, {
        contentType: file.name.includes(".pdf")
          ? "application/pdf"
          : "image/png",
      });
        setTableData({
            ...tableData,
            Resume: file.name,
        });
      return response;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setError([]);
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setError([]);
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Upload Resume
                  </label>
                  <div className="m-5">
                    <input
                      onChange={onChange}
                      type="file"
                      name="resume"
                      id="resume"
                    />
                  </div>
                </div>
                {error.length > 0 && MissingFieldPrompt(error)}

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                    font-medium text-white shadow-sm hover:opacity-80 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let errorArray = [];
                      if (tableData.Resume.trim().length === 0)
                        errorArray.push("Upload Resume");
                      if (
                        tableData.Resume.substring(
                          tableData.Resume.length - 4
                        ) !== ".pdf"
                      )
                        errorArray.push("Resume must be a PDF");

                      setError(errorArray);

                      if (errorArray.length > 0) {
                        return;
                      }

                      const {
                        data: {
                          Members_by_pk: { Meta: meta },
                        },
                      } = await API.graphql({
                        query: `{ Members_by_pk(ItemId: "${tableData.ItemId}") { Meta } }`,
                      });
                      meta.Resume = tableData.Resume;

                      await API.graphql({
                        query: `mutation ($meta: jsonb) {
                          update_Members_by_pk(pk_columns: {ItemId: "${tableData.ItemId}"}, _set: {Resume: "${tableData.Resume}", Meta: $meta}) {
                            ItemId
                          }
                        }`,
                        variables: { meta: meta },
                      });

                      let temp = tableData;
                      setTableData(temp);

                      await API.graphql({
                        query: `mutation {
                          insert_Documents_one(object: {Key: "${documentData.Key}", Folder: "${documentData.Folder}", Name: "${documentData.Name}", ClientId: "${documentData.ClientId}", CreatorName: "${documentData.CreatorName}", Type: "${documentData.Type}", MemberId: "${tableData.ItemId}"}) {
                            ItemId
                          }
                        }`,
                      });

                      let tempDocument = tableData;
                      setTableData(tempDocument);
                      setOpen(false);
                      setConfirmButtonMessage(true);
                    }}
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UploadResumeModal;
