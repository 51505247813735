import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../utils/Loading";



const LoginFields = ({ setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = (e) => {
    setError("");
    e.preventDefault();
    //error handling
    if (email.trim().length === 0 || password.trim().length === 0) {
      setError("Email and password cannot be blank");
      return;
      }
      setLoading(true)//put login as a true when user  is authticated
    //Logs in upon user input
    Auth.signIn({
      username: email,
      password: password,
    })  
      .then(() => {
        //set user to signed in
        // this._validAuthStates = ["signedIn"];
          
        //upon authentication, retrieve the logged in user's name and profile picture
        Auth.currentAuthenticatedUser().then((user) => {
          let attributes = user.attributes;
          // let accessId = attributes["custom:accessId"];
          localStorage.setItem("userType", attributes["custom:userType"]);
          localStorage.setItem(
            "name",
            attributes["custom:firstName"] + " " + attributes["custom:lastName"]
          );
          localStorage.setItem("location", attributes["custom:location"]);
          localStorage.setItem("phone", attributes["custom:phone"]);
          localStorage.setItem("resume", attributes["custom:resume"]);
          localStorage.setItem("attributes", JSON.stringify(attributes));
            
          // if (["admin", "client"].includes(attributes["custom:userType"])) {
          //   API.get("auth", "/clients?ItemId=" + accessId).then((responseJson) => {
          //     let loggedInUser = responseJson.Output[0];
          //   });
          // }
          if (!location.pathname.includes("jobs")) navigate("/");
            setOpen(false);
            
        });
          setLoading(false);
      })
      .catch((err) => {
          setLoading(false)//put login as a true when user  is authticated
        console.log(err.message);
        if (err.message === "User is not confirmed.") {
          setError(
            "You must confirm your email by clicking the link you received before logging in"
          );
        } else {
          setError("Email or password is incorrect");
        }
      });
  };

  useEffect(() => {
    if (location.search.startsWith("?email")) {
      setEmail(decodeURI(location.search.replace("?email=", "")));
    }
  }, [location.search]);
   if (loading) return <Loading />
  return (
    <div>
      {/* Login Header */}
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-5/6">
        <div>
          <h2 className="mt-1 text-center text-3xl tracking-tight font-bold teckpert-text-green">
            {/* text-neutral-100 */}
            Sign in to your account
          </h2>
        </div>

        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              {(location.search.startsWith("?confirm") ||
                location.search.startsWith("?email")) && (
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        {location.search.startsWith("?confirm") &&
                          `Success! Confirm your email before logging in`}
                        {location.search.startsWith("?email") &&
                          `Email confirmed! You may now log in`}
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <div>{error}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                  onFocus={() => setError("")}
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    onClick={() => navigate("/forgot-password")}
                    className="font-medium teckpert-text-green cursor-pointer"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  onClick={(e) => handleLogin(e)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white teckpert-bg-green focus:outline-none"
                >
                  Log in
                </button>
              </div>
              {error.length > 0 && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Your login attempt has failed
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <div>{error}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-sm">
                <a
                  onClick={() => navigate("/signup")}
                  className="font-medium teckpert-text-green cursor-pointer"
                >
                  Sign up
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginFields;
