import React, { useEffect, useState, Fragment, useRef } from "react";
import Modal from "react-modal";
import { MenuIcon, XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";

Modal.setAppElement("#root");

export default function HideColumnsModal({
  open,
  setOpen,
  userData,
  setUserData,
  selectedJob,
  setSelectedJob,
  setSearchModalOpen,
}) {
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    originalOrderList: [],
    updatedOrder: [],
    updatedOrderList: [],
  };

  const [list, setList] = useState([]);
  const [listNames, setListNames] = useState([]);
  const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);

  useEffect(() => {
    let temp = [];
    let tempListNames = [];
    if (selectedJob) {
      selectedJob.HiddenColumns.map((obj, i) => {
        temp.push(obj.order);
        tempListNames.push(obj.name);
      });
      setList(temp);
      setListNames(tempListNames);
    }
  }, [selectedJob.HiddenColumns]);

  // onDragStart fires when an element
  // starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list,
      originalOrderList: listNames,
    });

    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData("text/html", "");
  };

  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = (event) => {
    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;
    let newListNames = dragAndDrop.originalOrderList;

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedFromName = selectedJob.HiddenColumns[draggedFrom].name;

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position);
    const draggedToName = selectedJob.HiddenColumns[draggedTo].name;

    const itemDragged = newList[draggedFrom];
    const itemDraggedName = newListNames[draggedFromName];

    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    const remainingItemsNames = newListNames.filter(
      (item, index) => index !== draggedFromName
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    newListNames = [
      ...remainingItemsNames.slice(0, draggedToName),
      itemDragged,
      ...remainingItemsNames.slice(draggedToName),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        updatedOrderList: newListNames,
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop = (event) => {
    let newTempList = selectedJob.HiddenColumns;

    setList(dragAndDrop.updatedOrder);
    newTempList = newTempList.map(
      (obj, i) => newTempList[dragAndDrop.updatedOrder[i]]
    );

    newTempList.map((obj, i) => (obj.order = i));

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  if (!selectedJob.HiddenColumns) return <div></div>;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`lg:w-[700px] max-h-100 relative transform overflow-y-auto overflow-x-hidden rounded-lg bg-white px-4 pt-5 pb-4 
                text-left shadow-xl transition-all sm:my-8 sm:p-6`}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Display Columns:
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-7">
                  {/* Disabled column */}
                  <div className="opacity-60 grid grid-cols-7 shadow-sm border border-solid border-[#f5f5f5] mb-1 py-3 px-5">
                    <div className="col-span-6">
                      <MenuIcon
                        className="h-5 w-5 inline mr-7 mb-1"
                        aria-hidden="true"
                      />
                      Job Title
                    </div>
                    <div className="flex">
                      <label className="inline-flex relative items-center mr-5">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={true}
                          readOnly
                        />
                        <div
                          className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                          peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                          after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                          after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
                        ></div>
                      </label>
                    </div>
                  </div>
                  {/* Columns */}
                  {console.log("HiddenColumns", selectedJob.HiddenColumns)}
                  {selectedJob.HiddenColumns.map((obj, i) => {
                    return (
                      <div
                        key={i}
                        data-position={i}
                        draggable
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        onDragLeave={onDragLeave}
                        className={`${
                          dragAndDrop && dragAndDrop.draggedTo === Number(i)
                            ? "bg-white"
                            : ""
                        } grid grid-cols-7 cursor-pointer shadow-sm border border-solid border-[#f5f5f5] mb-1 py-3 px-5`}
                      >
                        <div className="col-span-6">
                          <MenuIcon
                            className="h-5 w-5 cursor-pointer inline mr-7 mb-1"
                            aria-hidden="true"
                          />
                          {obj.name}
                        </div>
                        <div className="flex">
                          <label className="inline-flex relative items-center mr-5 cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              checked={!selectedJob.HiddenColumns[i].hidden}
                              readOnly
                            />
                            <div
                              onClick={() => {
                                let temp = selectedJob;
                                temp.HiddenColumns[i].hidden =
                                  !temp.HiddenColumns[i].hidden;
                                setSelectedJob({ ...temp });
                              }}
                              className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                              peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                              after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                              after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
                            ></div>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-6 justify-end flex ml-8">
                  <button
                    onClick={() => {
                      setOpen(false);
                      setSearchModalOpen(true);
                    }}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium 
                    text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Back
                  </button>
                  <button
                    onClick={async () => {
                      await API.graphql(
                        graphqlOperation(`mutation {
                          update_JobPortals_by_pk(pk_columns: {ItemId: "${
                            selectedJob.ItemId
                          }"}, 
                            _set: 
                              {
                                HiddenColumns: [${selectedJob.HiddenColumns.map(
                                  (obj) =>
                                    `{
                                    name: "${obj.name}",
                                    hidden: ${obj.hidden},
                                    order: ${obj.order},
                                  }`
                                ).join(",")}]    
                              }
                            )       
                            {
                              ItemId
                              HiddenColumns
                            }            
                      }`)
                      ).then(({ data }) => {
                        console.log("data Hidden Columns", data);
                        setOpen(false);
                      });
                    }}
                    className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                    font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
