/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../../utils/FormatDate";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function UpdateContractModal({
  open,
  setOpen,
  tableData,
  setTableData,
  setConfirmButtonMessage,
  users,
  clients,
  contacts,
}) {
  const location = useLocation();
  const [tempData, setTempData] = useState({});
  const [error, setError] = useState([]);

  useEffect(() => {
    setTempData({
      Name: tableData.Name,
      ClientId: tableData.ClientId,
      Status: tableData.Status,
      PrimaryContact: tableData.PrimaryContact,
      PM: tableData.PM,
      PMFee: tableData.PMFee,
      CTO: tableData.CTO,
      CTOFee: tableData.CTOFee,
      StartDate: tableData.StartDate,
      EndDate: tableData.EndDate,
      Hours: tableData.Hours,
      Type: tableData.Type,
      DefaultTo: tableData.DefaultTo,
      DefaultFrom: tableData.DefaultFrom,
      Details: tableData.Details,
      ItemId: tableData.ItemId,
    });
  }, [tableData]);
   
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] h-[800px] relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Contract
                    </Dialog.Title>
                  </div>
                </div>
                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-4">
                      <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Name = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Name}
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Status
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            value={tempData.Status}
                            onChange={(e) => {
                              let temp = tempData;
                              temp.Status = e.target.value;
                              setTempData({ ...temp });
                            }}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option value="Draft">Draft</option>
                            <option value="Building">Building</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Paused">Paused</option>
                            <option value="Ended">Ended</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Client
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                                temp.ClientId = e.target.value;
                                temp.PrimaryContact = "";
                                temp.DefaultTo = "";
                              setTempData({ ...temp });
                            }}
                            value={tempData.ClientId}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {clients
                              ?.sort((a, b) => a.Name.localeCompare(b.Name))
                              .map((obj) => (
                                <option key={obj.ItemId} value={obj.ItemId}>
                                  {obj.Name}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Client Supervisor
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.PrimaryContact = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.PrimaryContact}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {contacts
                              ?.sort((a, b) =>
                                a.FirstName.localeCompare(b.FirstName)
                              )
                              ?.map((obj) => {
                                if (tempData.ClientId == obj.ClientId)
                                  return (
                                    <option key={obj.ItemId} value={obj.ItemId}>
                                      {obj.FirstName} {obj.LastName}
                                    </option>
                                  );
                              })}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            CTO
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.CTO = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.CTO}
                          >
                            <option value="" >
                              Select
                            </option>
                            {users?.map((obj) => (
                              <option key={obj.ItemId} value={obj.ItemId}>
                                {obj.FirstName} {obj.LastName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            CTO Fee
                          </label>
                          <span className="inline absolute px-2 mt-3.5 ml-10">
                            %
                          </span>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.CTOFee = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.CTOFee}
                              type="text"
                              name="fee"
                              id="fee"
                              autoComplete="fee"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Project Manager
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.PM = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.PM}
                          >
                            <option value="" >
                              Select
                            </option>
                            {users?.map((obj) => (
                              <option key={obj.ItemId} value={obj.ItemId}>
                                {obj.FirstName} {obj.LastName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Project Manager Fee
                          </label>
                          <span className="inline absolute px-2 mt-3.5 ml-10">
                            %
                          </span>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.PMFee = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.PMFee}
                              type="text"
                              name="fee"
                              id="fee"
                              autoComplete="fee"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Start Date
                          </label>
                          <div className="mt-1" style={{ zIndex: 2000 }}>
                            <DatePicker
                              className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                              selected={new Date(tempData.StartDate)}
                              onChange={(date) => {
                                let temp = tempData;
                                temp.StartDate = date;
                                setTempData({ ...temp });
                              }}
                              fixedHeight
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            End Date
                          </label>
                          <div className="mt-1">
                            <DatePicker
                              className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                              selected={new Date(tempData.EndDate)}
                              onChange={(date) => {
                                let temp = tempData;
                                temp.EndDate = date;
                                setTempData({ ...temp });
                              }}
                              fixedHeight
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Hours
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Hours = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Hours}
                              type="text"
                              name="hours"
                              id="hours"
                              autoComplete="hours"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Type
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.Type = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.Type}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="Fixed Project">Fixed Project</option>
                            <option value="Augmentation">Augmentation</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Default To
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.DefaultTo = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.DefaultTo}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {contacts?.map((obj) => {
                              if (tempData.ClientId == obj.ClientId)
                                return (
                                  <option key={obj.ItemId} value={obj.ItemId}>
                                    {obj.FirstName} {obj.LastName}
                                  </option>
                                );
                            })}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Default From
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.DefaultFrom = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.DefaultFrom}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {users?.map((obj) => (
                              <option key={obj.ItemId} value={obj.ItemId}>
                                {obj.FirstName} {obj.LastName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mt-3">
                        <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                          Description
                        </label>
                        <textarea
                          className="z-2 mt-1 w-full mr-3 rounded-full justify-center rounded-md
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                          rows={4}
                          name="comment"
                          id="comment"
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Details = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Details}
                        />
                      </div>
                      {error.length > 0 && MissingFieldPrompt(error)}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                          teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={async () => {
                              let errorArray = [];
                           
                            if (tempData.CTOFee < 0) errorArray.push("CTO Fee");
                            if (tempData.ClientId?.trim().length === 0)
                              errorArray.push("Client");
                            if (tempData.DefaultFrom?.trim().length === 0)
                              errorArray.push("Default From");
                            if (tempData.Hours==null)
                              errorArray.push("Hours");
                            if (tempData.Name?.trim().length === 0)
                              errorArray.push("Name");
                            if (tempData.PMFee < 0) errorArray.push("PM Fee");
                            if (tempData.PrimaryContact?.trim().length === 0)
                              errorArray.push("Client Supervisor");
                            if (tempData.Status?.trim().length === 0)
                              errorArray.push("Status");
                            if (tempData.Type?.trim().length === 0)
                                  errorArray.push("Type");

                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }

                            let updatedTempData = tempData;

                            if (isNaN(tempData.CTOFee))
                              updatedTempData.CTOFee = parseFloat(
                                tempData.CTOFee
                              );
                            if (isNaN(tempData.PMFee))
                              updatedTempData.PMFee = parseFloat(
                                tempData.PMFee
                                  );
                             
                            if (tempData.StartDate)
                              updatedTempData.StartDate = formatDate(
                                new Date(updatedTempData.StartDate)
                              );
                            else
                              updatedTempData.StartDate = formatDate(
                                new Date()
                              );

                            if (tempData.EndDate)
                              updatedTempData.EndDate = formatDate(
                                new Date(updatedTempData.EndDate)
                              );
                            else
                              updatedTempData.EndDate = formatDate(new Date());
                              const Details = JSON.stringify(tempData?.Details); 
                            await API.graphql(
                              graphqlOperation(`mutation {
                                update_Contracts_by_pk(pk_columns: {ItemId: "${
                                  location.pathname.split("/")[2]
                                }"}, 
                                  _set: 
                                    {
                                      ClientId: "${tempData.ClientId}",
                                      Status: "${tempData.Status}",
                                      Details: ${Details},
                                      Name: "${tempData.Name}",
                                      PrimaryContact: "${
                                        tempData.PrimaryContact
                                      }",
                                      Type: "${tempData.Type}",
                                      PM: "${tempData.PM}",
                                      CTO: "${tempData.CTO}",
                                      DefaultFrom: "${tempData.DefaultFrom}",
                                      DefaultTo: "${tempData.DefaultTo}",
                                      CreatorName: "${tempData.CreatorName}",
                                      Hours: "${tempData.Hours}",
                                      PMFee: ${tempData.PMFee},
                                      CTOFee: ${tempData.CTOFee},
                                      StartDate: "${updatedTempData.StartDate}",
                                      EndDate: "${updatedTempData.EndDate}"
                                    })
                                    {
                                      ItemId
                                      Name
                                    }
                            }`)
                            ).then(({ data }) => {
                              setOpen(false);
                              setConfirmButtonMessage(true);
                              setTableData([
                                {
                                  Status: updatedTempData.Status,
                                  StartDate: updatedTempData.StartDate,
                                  EndDate: updatedTempData.EndDate,
                                  Hours: updatedTempData.Hours,
                                  DefaultTo: updatedTempData.DefaultTo,
                                  DefaultFrom: updatedTempData.DefaultFrom,
                                },
                                ...tableData,
                              ]);
                            });
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 
                          text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError([]);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
