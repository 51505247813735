import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API, Storage } from "aws-amplify";
// import ComboBox from "../../../../utils/ComboBox";
import ComboBox from "./ComboBox";
import JobPortalHeader from "./JobPortalHeader";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

const JobPortalPage = () => {
  const [comboBoxLabel, setComboBoxLabel] = useState({
    location: {
      name: "Location",
      dataStatus: "hasLocation",
      data: [],
      search: "",
    },
    worktype: {
      name: "Work Type",
      dataStatus: "isWorkType",
      data: [],
      search: "",
    },
  });

  const [tempData, setTempData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [img, setImg] = useState("");
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [portalOpportunities, setPortalOpportunities] = useState([]);
  const [criteriaFilter, setCriteriaFilter] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [opportunityField, setOpportunityField] = useState("");
  const [client, setClient] = useState([]);
  const [contract, setContract] = useState([]);
  const [rows, setRows] = useState({});
  const [dragOver, setDragOver] = useState("");
  const [cols, setCols] = useState([]);

  const location = useLocation();

  // GET JOB PORTALS DATA
  useEffect(() => {
    API.graphql({
      query: `{
        JobPortals(where: {Url: {_eq: "${location.pathname.split("/")[3]}"}}) {
          ItemId
          DateCreated
          DateUpdated
          Creator
          Status
          Headline
          Description
          Url
          Criteria
          Logo
          Name
          Opportunities
          HiddenColumns    
        }
      }      
    `,
    }).then(({ data }) => {
      setTempData(data.JobPortals[0]);
      setCols(data.JobPortals[0].HiddenColumns.map((obj, i) => obj.name));
      setPortalOpportunities(data.JobPortals[0].Opportunities);
      // GET JOB PORTAL LOGO
      const getStorageFile = async (key, redirect = false) => {
        const url = await Storage.get(key, {
          level: "public",
          expires: 60,
        });
        if (redirect === true && url !== "") {
          window.open(url, "_blank");
        }
        return url;
      };
      getStorageFile(data.JobPortals[0].Logo).then((url) => {
        setImg(url);
      });
    });
  }, []);

  // GET OPPORTUNITIES, CLIENTS AND CONTRACTS DATA FOR CRITERIA DROPDOWNS
  useEffect(() => {
    API.graphql({
      query: `{
        Opportunities {
          ItemId
          ClientId
          Contract
          Applicants
          BillRateCompensationUnit
          BillRateContract
          HighEndRate
          Location
          LowEndRate
          PayRateCompensationUnit
          Status
          Title
          Type
          WorkType
          Client {
            Name
          }
          Contracts {
            Name
          }
        }
      }      
    `,
    }).then(({ data }) => {
      setOpportunitiesData(data.Opportunities);
      setFilteredData(
        data.Opportunities.filter((x) =>
          tempData.Opportunities?.includes(x.ItemId)
        )
      );
    });

    API.graphql({
      query: `{
        Clients(order_by: {Name: asc}) {
          ItemId
          Name   
        }
        Contracts(order_by: {Name: asc}) {
          ItemId
          Name
          ClientId 
        }
      }      
    `,
    }).then(({ data }) => {
      setClient(data.Clients);
      setContract(data.Contracts);
    });
  }, [location.pathname, tempData.Opportunities]);

  // SET COMBOBOX DROPDOWNS
  useEffect(() => {
    let tempComboBoxLabel = comboBoxLabel;
    if (opportunitiesData) {
      comboBoxLabel["location"].data = opportunitiesData
        ?.map((x) => x.Location)
        .filter((val, id, array) => array.indexOf(val) == id)
        .filter((item) => (item != "") & (item != null));
      comboBoxLabel["worktype"].data = opportunitiesData
        ?.map((x) => x.WorkType)
        .filter((val, id, array) => array.indexOf(val) == id)
        .filter((item) => (item != "") & (item != null));
      setComboBoxLabel(tempComboBoxLabel);
    }
  }, [opportunitiesData, searchVal]);

  // APPLY COMBOBOX FILTERS
  useEffect(() => {
    if (opportunitiesData) {
      if (comboBoxLabel["location"].search)
        setFilteredData(
          opportunitiesData.filter((obj) =>
            obj.Location?.toLowerCase().startsWith(
              comboBoxLabel["location"].search?.toLowerCase()
            )
          )
        );
      if (comboBoxLabel["worktype"].search)
        setFilteredData(
          opportunitiesData.filter((obj) =>
            obj.WorkType?.toLowerCase().startsWith(
              comboBoxLabel["worktype"].search?.toLowerCase()
            )
          )
        );
    }
  }, [comboBoxLabel, searchVal]);

  // SET TABLE DATA ROWS
  useEffect(() => {
    setRows(filteredData);
  }, [filteredData]);

  if (tempData.length === 0)
    return (
      <div className="flex justify-center items-center h-screen align-middle">
        <div
          className="my-auto spinner-border animate-spin inline-block w-20 h-20 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden font-bold text-white">..</span>
        </div>
      </div>
    );

  return (
    <div className="mb-12 px-4 sm:px-6 lg:px-8">
      {tempData && <JobPortalHeader img={img} tempData={tempData} />}
      <div className="justify-center text-center py-4">
        <div className="text-3xl font-bold mb-7">Open Opportunities</div>
        <div className="flex">
          {/* Dropdown button filter list */}
          <div className="flex flex-grow text-center justify-center relative mb-4">
            {Object.keys(comboBoxLabel)?.map((obj, i) => (
              <div className="relative left-5">
                <div className="mr-4 justify-center text-center">
                  <ComboBox
                    comboBoxLabel={comboBoxLabel}
                    setComboBoxLabel={setComboBoxLabel}
                    data={comboBoxLabel[obj].data}
                    name={comboBoxLabel[obj].name}
                    searchVals={comboBoxLabel[obj].search}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    i={i}
                    unfilteredData={opportunitiesData}
                    setFilteredData={setFilteredData}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Job Title
                    </th>
                    {cols?.map((col, idx) => {
                      return (
                        <th
                          className={`${
                            tempData?.HiddenColumns[
                              tempData?.HiddenColumns.findIndex(
                                (x) => x.name === cols[idx]
                              )
                            ]?.hidden
                              ? "hidden"
                              : "default"
                          } px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer`}
                          id={col}
                          key={col}
                          draggable
                          dragOver={col === dragOver}
                        >
                          {col}
                        </th>
                      );
                    })}
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                  </tr>
                </thead>
                <tbody className={`divide-y divide-gray-200 bg-white`}>
                  {rows.map((row, i) => {
                    return (
                      <tr key={row.ItemId}>
                        <td
                          className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6`}
                        >
                          <div className="flex">
                            <div className="font-medium text-[#6FBE47] cursor-pointer">
                              {row.Title}
                            </div>
                          </div>
                        </td>
                        {Object.entries(row).map(([k, v], idx) => {
                          let clientIndex = client.findIndex(
                            (x) => x.ItemId === row.ClientId
                          );
                          let contractIndex = contract.findIndex(
                            (x) => x.ItemId === row.Contract
                          );
                          return cols.includes(cols[idx]) ? (
                            <td
                              className={`${
                                tempData.HiddenColumns[
                                  tempData.HiddenColumns.findIndex(
                                    (x) => x.name === cols[idx]
                                  )
                                ]?.hidden
                                  ? "hidden"
                                  : "default"
                              } capitalize w-13 px-3 py-4 text-sm text-gray-900`}
                              key={v}
                              dragOver={cols[idx] === dragOver}
                            >
                              {cols[idx] === "Type"
                                ? row.Type
                                : cols[idx] === "Work Type"
                                ? row.WorkType
                                : cols[idx] === "Pay Rate"
                                ? row.HighEndRate & row.PayRateCompensationUnit
                                  ? `$${
                                      row.HighEndRate -
                                      row.PayRateCompensationUnit
                                    }`
                                  : row.HighEndRate
                                  ? `$${row.HighEndRate}`
                                  : "-"
                                : cols[idx] === "Client"
                                ? client[clientIndex]?.Name
                                  ? client[clientIndex].Name
                                  : "-"
                                : cols[idx] === "Contract"
                                ? contract[contractIndex]?.Name
                                  ? contract[contractIndex].Name
                                  : "-"
                                : row[cols[idx]]}
                            </td>
                          ) : null;
                        })}
                        <td className="flex-none py-4 text-sm">
                          <Link
                            key={row.ItemId}
                            to={`/jobs/public/${row.ItemId}`}
                            target="_blank"
                          >
                            <button
                              type="button"
                              className="mr-8 mt-2 items-center justify-center rounded-md border border-transparent teckpert-bg-green 
                              px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto hover:border-white"
                            >
                              Apply Online
                              <ArrowCircleRightIcon
                                className="h-6 w-6 inline cursor-pointer mx-3"
                                aria-hidden="true"
                              />
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPortalPage;
