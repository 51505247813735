import { useEffect, useState } from "react";
import { API, Storage } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, CheckCircleIcon } from "@heroicons/react/outline";
import ClientInfo from "./ClientInfo";
import Notes from "../../People/Profile/Tabs/Notes";
import Documents from "./Documents";
import Contracts from "./Contracts";
// import History from "./History";
import Loading from "../../../utils/Loading";

const ClientDetail = () => {
  const location = useLocation();
  const [clientData, setClientData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [confirmButtonMessage, setConfirmButtonMessage] = useState(false);

  const getStorageFile = async (key, id, redirect = false) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    if (redirect === true && url !== "") {
      window.open(url, "_blank");
    }
    return { url, ItemId: id };
  };

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  useEffect(() => {
    API.graphql({
      query: `{
        Clients(where: {ItemId: {_eq: "${location.pathname.split("/")[2]}"}}) {
          ItemId
          DateCreated
          DateUpdated
          AccountStatus
          Name
          PrimaryContact
          Address
          City
          State
          Zip
          IndustryType
          Website
          Phone
          Fax
          Email
          Documents
          Notes
          Categories    
        }
      }      
    `,
    }).then(({ data }) => {
      setClientData(data.Clients[0]);
    });

    API.graphql({
      query: `{
        Documents(where: {ClientId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          DateUpdated
          Folder
          Type
          Key
          Link
          Name
          ClientId
          MemberId
          JobOrderId
          ContractId
          CreatorId
          CreatorName   
        }
      }      
    `,
    }).then(({ data }) => {
      setDocumentData(data.Documents);
      Promise.all(
        data.Documents.map((obj) => getStorageFile(obj.Key, obj.ItemId))
      ).then((url) => setDocumentUrl(url));
    });

    API.graphql({
      query: `{
        Contracts(where: {ClientId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          DateUpdated
          Name
          ClientId
          Documents
          Notes
          StartDate
          EndDate
          Hours
          Status
          PrimaryContact
          PM
          CTO
          Details
          Categories
          Type
          People
          PMFee
          CTOFee
          DefaultTo
          DefaultFrom
          CreatorName    
        }
      }      
    `,
    }).then(({ data }) => {
      setContractData(data.Contracts);
    });
  }, [location.pathname.split("/")[2]]);

  if (clientData.length === 0) return <Loading />;
  return (
    <div>
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/clients">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      {confirmButtonMessage && (
        <div
          id="section-top"
          className="bg-[#D3EBC7] border-t-4 border-[#6fbe47] rounded-b teckpert-text-green px-4 py-3 shadow-md mb-2"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <CheckCircleIcon
                className="h-6 w-6 cursor-pointer mr-3"
                aria-hidden="true"
              />
            </div>
            <div>
              <p className="font-bold">Your changes have been saved.</p>
            </div>
          </div>
        </div>
      )}
      <div className="mx-auto overflow-hidden lg:mb-2 lg:mx-10">
        {/* Client Information and Contacts */}
        <ClientInfo
          tableData={clientData}
          setTableData={setClientData}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <Notes
          profileData={clientData}
          setProfileData={setClientData}
          url={"/clients"}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <Documents
          tableData={documentData}
          setTableData={setDocumentData}
          documentUrl={documentUrl}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <Contracts
          tableData={contractData}
          setTableData={setContractData}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
      </div>
    </div>
  );
};

export default ClientDetail;
