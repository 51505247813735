import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
// Modals
import AddPeopleModal from "../Modals/AddPeopleModal";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";

const People = ({
  setShowProfile,
  profileData,
  jobsData,
  setJobsData,
  applicantsData,
  setApplicantsData,
  setSelectedMemberId,
  setConfirmButtonMessage,
}) => {
  const [showTab, setShowTab] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState({});

  return (
    <div
      className={`${
        !showTab ? "bg-white" : "bg-[#f5f5f5]"
      } p-6 rounded-lg mt-1 border border-gray-200 shadow-md `}
    >
      {/* Header Section */}
      <div className="flex">
        <div className="grow">
          <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">People</h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setAddModalOpen(!addModalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
          {!showTab ? (
            <ChevronDownIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
              onClick={() => setShowTab(!showTab)}
            />
          ) : (
            <ChevronUpIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
              onClick={() => setShowTab(!showTab)}
            />
          )}
        </div>
      </div>
      {/* Table Section */}
      {showTab ? (
        <div className="mt-5 w-full">
          <div>
            <div className="block py-2 align-middle md:px-4 lg:px-4">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {/* {applicantsData > 0 ? ( */}
                <table className="w-full divide-y divide-gray-300 overflow-x-auto">
                  <thead>
                    <tr>
                      {["Name", "Location", "Status", ""].map((column, i) => (
                        <th
                          key={i}
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {column}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {applicantsData
                      ?.sort((a, b) =>
                        a.Members?.FirstName?.localeCompare(b.Members.FirstName)
                      )
                      ?.filter((item) => item.OpportunityId == jobsData.ItemId)
                      .map((person) => {
                        return (
                          <tr key={person.ItemId}>
                            <td
                              value={person.ItemId}
                              className="px-3 py-4 text-sm teckpert-text-green font-bold pl-5 cursor-pointer"
                              onClick={() => {
                                setShowProfile(true);
                                setSelectedMemberId(person.MemberId);
                              }}
                            >
                              {person.Members?.FirstName
                                ? person.Members.FirstName
                                : "-"}
                              &nbsp;
                              {person.Members?.LastName
                                ? person.Members.LastName
                                : ""}
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-900 pl-5">
                              {person.Members?.Meta?.Location
                                ? person.Members.Meta.Location
                                : "-"}
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-900 pl-5">
                              {person?.WorkFlowStatus
                                ? person.WorkFlowStatus
                                : "-"}
                            </td>
                            <td className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                              <XCircleIcon
                                className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                                aria-hidden="true"
                                onClick={() => {
                                  setSelectedApplicant(person);
                                  setDeleteModalOpen(!deleteModalOpen);
                                }}
                              />
                              <ConfirmDeleteModal
                                open={deleteModalOpen}
                                setOpen={setDeleteModalOpen}
                                deleteItem={async () => {
                                  let response = await API.graphql(
                                    graphqlOperation(`
                                        mutation {
                                          delete_Applicants_by_pk(ItemId: "${selectedApplicant.ItemId}") {
                                            ItemId
                                            MemberId
                                          }
                                        }
                                      `)
                                  );
                                  if (response) {
                                    setApplicantsData(
                                      applicantsData.filter(
                                        (applicant) =>
                                          applicant.ItemId !==
                                          selectedApplicant.ItemId
                                      )
                                    );
                                    setConfirmButtonMessage(true);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* ) : (
                  <div className="py-5 justify-center text-center mb-8 font-bold text-gray-300 text-lg">
                    No People Added
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <AddPeopleModal
        jobsData={jobsData}
        setJobsData={setJobsData}
        profileData={profileData}
        applicantsData={applicantsData}
        setApplicantsData={setApplicantsData}
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        setShowProfile={setShowProfile}
        setSelectedMemberId={setSelectedMemberId}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
    </div>
  );
};
export default People;
