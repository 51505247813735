import { useState } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { formatPhone } from "../../../../utils/FormatPhone";
import ReviewResponsesModal from "../Modals/ReviewResponsesModal";

const FilteredWorkFlows = ({
  members,
  formatDate,
  title,
  setSelectedMemberId,
  setShowProfile,
  jobsData,
}) => {
  const [showTab, setShowTab] = useState(false);
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      {members?.length > 0 ? (
        <div
          className={`${
            !showTab ? "bg-white" : "bg-[#f5f5f5]"
          } p-6 rounded-lg mt-1 border border-gray-200 shadow-md `}
        >
          {/* Header Section */}
          <div className="flex">
            <div className="grow">
              <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">
                {title}
                {members.length > 0 ? ` (${members.length})` : null}
              </h4>
            </div>
            <div
              className="flex flex-none mr-5"
              onClick={() => setShowTab(!showTab)}
            >
              {!showTab ? (
                <ChevronDownIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              ) : (
                <ChevronUpIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
          {/* Table Section */}
          {showTab ? (
            <div className="mx-5 flex flex-col mt-5">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full max-w-full w-auto overflow-x-auto divide-y divide-gray-300">
                      <thead>
                        <tr>
                          {[
                            "Name",
                            "Status",
                            "Availability",
                            "Phone Number",
                            "Date Registered",
                          ].map((header, i) => (
                            <th
                              key={i}
                              className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {members?.map((person) => {
                          let knockout =
                            person.ApplicationQuestionnaireResponses?.Responses.filter(
                              (item) => item.Knockout !== false
                            ).length > 0;

                          return (
                            <tr key={person.ItemId}>
                              <td className="flex pl-6 whitespace-nowrap px-3 py-4 text-sm teckpert-text-green font-bold cursor-pointer">
                                <span
                                  onClick={() => {
                                    setShowProfile(true);
                                    setSelectedMemberId(person.MemberId);
                                  }}
                                >
                                  {person.Members?.FirstName
                                    ? person.Members.FirstName
                                    : "-"}
                                  &nbsp;
                                  {person.Members?.LastName
                                    ? person.Members.LastName
                                    : ""}
                                </span>
                                {person.ApplicationQuestionnaireResponses && (
                                  <QuestionMarkCircleIcon
                                    onClick={() => {
                                      setSelected(
                                        person.ApplicationQuestionnaireResponses
                                      );
                                      setModalOpen(true);
                                    }}
                                    className={`${
                                      !knockout
                                        ? "text-gray-300"
                                        : "text-red-500"
                                    } h-5 w-5 ml-1`}
                                    aria-hidden="true"
                                  />
                                )}
                                <ReviewResponsesModal
                                  open={modalOpen}
                                  setOpen={setModalOpen}
                                  questionnaire={selected}
                                />
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 capitalize">
                                {person.Members?.Meta?.EmploymentStatus
                                  ? person.Members.Meta.EmploymentStatus
                                  : "-"}
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 capitalize">
                                {person.Members?.Meta?.LocationAvailability
                                  ? person.Members.Meta.LocationAvailability
                                  : "-"}
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {person.Members?.Meta?.Mobile
                                  ? formatPhone(person.Members?.Meta?.Mobile)
                                  : "-"}
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {person.Members?.DateCreated
                                  ? formatDate(person.Members.DateCreated)
                                  : "-"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : null}
    </div>
  );
};
export default FilteredWorkFlows;
