import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { SearchBarLogic } from "../../../../utils/SearchBarLogic";
import {
  PlusCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";

const AddPeopleModal = ({
  tableData,
  profileData,
  applicantsData,
  setApplicantsData,
  modalOpen,
  setModalOpen,
  setShowProfile,
  setSelectedMemberId,
  setConfirmButtonMessage,
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(profileData);
  }, [profileData]);

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] h-[800px] relative transform overflow-x-hidden overflow-y-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add People to Opportunity
                    </Dialog.Title>
                  </div>
                </div>
                <div>
                  <div className="mx-5 flex flex-col mt-5">
                    <div className="sm:-mx-6 lg:-mx-8">
                      <div className="w-[91%] mx-4">
                        <input
                          onChange={(e) => {
                            setSearchVal(e.target.value);
                            SearchBarLogic(
                              profileData,
                              setFilteredData,
                              e.target.value
                            );
                          }}
                          value={searchVal}
                          type="search"
                          className="w-full mx-6 form-control relative flex-auto block px-7 py-3 ml-3 mb-1
                          text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding transition 
                          ease-in-out m-0 focus:text-gray-700 focus:outline-none rounded-full"
                          placeholder="Search by Name"
                          aria-label="Search"
                          aria-describedby="button-addon3"
                        />
                      </div>

                      <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="mb-4 h-[600px] overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                            <thead>
                              <tr>
                                {["Name", "Location", "Status", ""].map(
                                  (header, i) => (
                                    <th
                                      key={i}
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      {header}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {filteredData &&
                                filteredData.map((person) => {
                                  let index = applicantsData.findIndex(
                                    (x) => x.MemberId === person.ItemId
                                  );
                                  return (
                                    <tr
                                      className={`${
                                        index === -1
                                          ? "bg-white"
                                          : "bg-gray-100"
                                      }`}
                                      key={person.ItemId}
                                    >
                                      <td
                                        className="whitespace-nowrap px-3 py-4 text-sm teckpert-text-green font-bold pl-5 cursor-pointer"
                                        onClick={() => {
                                          setModalOpen(false);
                                          setShowProfile(true);
                                          setSelectedMemberId(person.ItemId);
                                        }}
                                      >
                                        {person.FirstName
                                          ? person.FirstName
                                          : "-"}
                                        &nbsp;
                                        {person.LastName ? person.LastName : ""}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 pl-5">
                                        {person.Meta?.Location
                                          ? person.Meta.Location
                                          : "-"}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 pl-5">
                                        {applicantsData[index]?.WorkFlowStatus
                                          ? applicantsData[index].WorkFlowStatus
                                          : "-"}
                                      </td>
                                      <td>
                                        {index === -1 ? (
                                          <PlusCircleIcon
                                            value={person.ItemId}
                                            className="h-6 w-6 cursor-pointer mr-3 teckpert-text-green"
                                            aria-hidden="true"
                                            onClick={async () => {
                                              await API.graphql(
                                                graphqlOperation(`mutation {
                                                  insert_Applicants_one(
                                                    object: 
                                                      {
                                                        ItemId: "${uuidv4()}",
                                                        MemberId: "${
                                                          person.ItemId
                                                        }",
                                                        OpportunityId: "${
                                                          tableData.ItemId
                                                        }",
                                                        Interested: "Yes",
                                                        WorkFlowStatus: "New",
                                                      }, on_conflict: {constraint: Applicants_pkey}
                                                    )       
                                                    {
                                                      ItemId
                                                      MemberId
                                                    }            
                                              }`)
                                              ).then(({ data }) => {
                                                let tempData = applicantsData;
                                                tempData = [
                                                  {
                                                    ItemId: data.ItemId,
                                                    MemberId: person.ItemId,
                                                    OpportunityId:
                                                      tableData.ItemId,
                                                    Interested: "Yes",
                                                    WorkFlowStatus: "New",
                                                  },
                                                  ...applicantsData,
                                                ];

                                                setApplicantsData(tempData);
                                                setConfirmButtonMessage(true);
                                              });
                                            }}
                                          />
                                        ) : (
                                          <CheckCircleIcon
                                            className="h-6 w-6 cursor-not-allowed mr-3 teckpert-bg-green text-white rounded-full"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default AddPeopleModal;
