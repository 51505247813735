import { useState } from "react";
import { API } from "aws-amplify";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  XCircleIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";
import EditEducationModal from "../Modals/EditEducationModal";
import AddProfile from "../../../../utils/AddProfile";

const Education = ({
  originalData,
  profileData,
  setProfileData,
  setConfirmButtonMessage,
}) => {
  const [showEducation, setShowEducation] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [selected, setSelected] = useState(0);

  const [tempData, setTempData] = useState({
    ItemId: profileData.ItemId,
    Meta: profileData.Meta,
  });

  const [newEducation, setNewEducation] = useState({
    Start: "",
    End: "",
    Degree: "",
    School: "",
  });

  const onSave = async () => {
    let temp = tempData;
    if (!temp.Meta.Education) temp.Meta.Education = [];
    API.graphql({
      query: `mutation($meta: jsonb) {
        update_Members_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, _set: { Meta: $meta } )       
        {
          ItemId
          Meta
        }     
      }`,
      variables: { meta: temp.Meta },
    }).then(async ({ data }) => {
      setProfileData({ ...tempData });
      setEditModalOpen(false);
      setConfirmButtonMessage(true);
    });
  };

  const onSubmit = async () => {
    let temp = tempData;
    if (!temp.Meta.Education) {
      temp.Meta.Education = [];
    }
    temp.Meta.Education.push(newEducation);
    if (!temp.Meta.Education) temp.Meta.Education = [];
    API.graphql({
      query: `mutation($meta: jsonb) {
        update_Members_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, _set: { Meta: $meta } )       
          {
            ItemId
            Meta
          }   
      }`,
      variables: { meta: temp.Meta },
    }).then(async ({ data }) => {
      setTempData({ ...tempData, newEducation });
      setProfileData({ ...tempData, newEducation });
      setNewEducation({
        Start: "",
        End: "",
        Degree: "",
        School: "",
      });
      setAddModalOpen(false);
      setConfirmButtonMessage(true);
    });
  };

  return (
    <div className="my-5 mx-8 p-6  bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 ">
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Education
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <PlusCircleIcon
            onClick={() => setAddModalOpen(!addModalOpen)}
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
          <div
            onClick={() => setShowEducation(!showEducation)}
            className="flex flex-none mr-5"
          >
            {!showEducation ? (
              <ChevronUpIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 cursor-pointer mt-1"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
      {/* Table Section */}
      <div
        className={`${showEducation ? "hidden" : "block"} mx-5 flex flex-col`}
      >
        {profileData.Meta.Education?.length > 0 ? (
          Array.isArray(profileData.Meta.Education) ? (
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                    <thead className="bg-gray-100">
                      <tr>
                        {[
                          "Start Date",
                          "End Date",
                          "Degree",
                          "School",
                          "",
                          "",
                          "",
                        ].map((header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {profileData.Meta.Education?.map((obj, i) => (
                        <tr key={i}>
                          <td className="whitespace-nowrap pl-6 px-3 py-4 text-sm text-gray-900">
                            {obj ? obj.Start : "N/A"}
                          </td>
                          <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                            {obj ? obj.End : "N/A"}
                          </td>
                          <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                            {obj ? obj.Degree : "N/A"}
                          </td>
                          <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                            {obj ? obj.School : "N/A"}
                          </td>
                          <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900"></td>
                          <td className="flex-none pl-6 pt-1 text-sm text-gray-900 cursor-pointer">
                            <PencilIcon
                              onClick={() => {
                                setId(i);
                                setEditModalOpen(true);
                              }}
                              className="-ml-1 h-5 w-5 text-gray-300 hover:teckpert-text-green cursor-pointer"
                              aria-hidden="true"
                            />
                          </td>
                          <td className="flex-none px-3 py-4 text-sm text-gray-900 cursor-pointer">
                            <XCircleIcon
                              className="h-6 w-6 cursor-pointer mt-1 text-gray-300 hover:text-red-500 mr-3"
                              aria-hidden="true"
                              onClick={() => {
                                setSelected(obj);
                                setDeleteModalOpen(true);
                              }}
                            />
                            <ConfirmDeleteModal
                              open={deleteModalOpen}
                              setOpen={setDeleteModalOpen}
                              deleteItem={async () => {
                                let temp = tempData;
                                temp.Meta.Education =
                                  temp.Meta.Education.filter(
                                    (item) => item.Degree !== selected.Degree
                                  );
                                API.graphql({
                                  query: `mutation($meta: jsonb) {
                                  update_Members_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, _set: { Meta: $meta } )       
                                  {
                                    ItemId
                                    Meta
                                  }            
                                }`,
                                  variables: { meta: temp.Meta },
                                }).then(async ({ data }) => {
                                  setDeleteModalOpen(false);
                                  setProfileData({ ...temp });
                                  setConfirmButtonMessage(true);
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
              No Education Found
            </div>
          )
        ) : (
          <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
            No Education Found
          </div>
        )}
      </div>
      {tempData.Meta.Education?.length > 0 ? (
        <EditEducationModal
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          originalData={originalData}
          tempData={tempData}
          setTempData={setTempData}
          onSave={onSave}
          inputField={["Start", "End", "Degree", "School"]}
          id={id}
        />
      ) : null}

      <AddProfile
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        profileData={profileData}
        setProfileData={setProfileData}
        tempData={tempData}
        setTempData={setTempData}
        onSave={onSubmit}
        newData={newEducation}
        setNewData={setNewEducation}
        inputField={["Start", "End", "Degree", "School"]}
      />
    </div>
  );
};
export default Education;
