/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ToggleButton from "../../../utils/ToggleButton";
import MissingFieldPrompt from "../../../utils/MissingFieldPrompt";
import { formatPhone } from "../../../utils/FormatPhone";

export default function AddContactModal({
  open,
  setOpen,
  contact,
  setContact,
  setRefresh,
  setConfirmButtonMessage,
}) {
  const location = useLocation();
  const defaultTempData = {
    FirstName: "",
    LastName: "",
    Title: "",
    Phone: "",
    Fax: "",
    Email: "",
    ProfilePicture: "",
    ClientId: location.pathname.split("/")[2],
    PrimaryContact: false,
    Mobile:"",
    OfficePhoneExtension:"",
  };
  const [tempData, setTempData] = useState(defaultTempData);
  const [file, setFile] = useState(null);
  const [error, setError] = useState([]);

  const onChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="lg:w-[700px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl 
              transition-all sm:my-8 sm:p-6"
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setError(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Contact
                    </Dialog.Title>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-8">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            First Name
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.FirstName = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.FirstName}
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Last Name
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.LastName = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.LastName}
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Title = e.target.value;
                                setTempData({ ...temp });
                                                          }}
                               maxLength={43}
                              value={tempData.Title}
                              type="text"
                              name="title"
                              id="title"
                              
                              autoComplete="title"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                           Office 
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Phone = e.target.value
                                  .replace(/\D+/g, "")
                                  .slice(0, 10);
                                setTempData({ ...temp });
                              }}
                              value={formatPhone(tempData.Phone)}
                              type="text"
                              name="phone"
                              id="phone"
                              autoComplete="phone"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                              />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Ext.
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={(e) => {
                                        let temp = tempData;
                                        temp.Phone = e.target.value
                                            .replace(/\D+/g, "")
                                            .slice(0, 8);
                                        setTempData({ ...temp });
                                    }}
                                    value={tempData.Phone}
                                    type="text"
                                    name="extension"
                                    id="extension"
                                    autoComplete="extension"
                                    className="w-24 rounded-full justify-center rounded-md placeholder:text-gray-900
                                                border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                                    placeholder="Ext."
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                                Mobile
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={(e) => {
                                    let temp = tempData;
                                    temp.Mobile = e.target.value
                                            .replace(/\D+/g, "")
                                            .slice(0, 10);
                                        setTempData({ ...temp });
                                    }}
                                    value={formatPhone(tempData.Mobile)}
                                    type="text"
                                    name="fax"
                                    id="fax"
                                    autoComplete="fax"
                                    className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                                                border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                             Fax
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Fax = e.target.value
                                  .replace(/\D+/g, "")
                                  .slice(0, 10);
                                setTempData({ ...temp });
                              }}
                              value={formatPhone(tempData.Fax)}
                              type="text"
                              name="fax"
                              id="fax"
                              autoComplete="fax"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Email = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Email}
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-3 mt-7">
                        <label className="block text-sm font-medium text-gray-700">
                          Upload Profile Picture
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={onChange}
                            type="file"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 mt-7">
                        <span
                          onClick={() => {
                            let temp = tempData;
                            if (temp.PrimaryContact)
                              temp.PrimaryContact = false;
                            else temp.PrimaryContact = true;

                            setTempData({ ...temp });
                          }}
                        >
                          <ToggleButton enabled={tempData.PrimaryContact} />
                        </span>

                        <span className="relative bottom-1 pl-2 font-medium text-gray-700">
                          Primary Contact
                        </span>
                      </div>
                      {error.length > 0 && MissingFieldPrompt(error)}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green 
                          px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                          onClick={async () => {
                            let errorArray = [];
                            if (tempData.FirstName?.trim().length === 0)
                              errorArray.push("First Name");
                            if (tempData.LastName?.trim().length === 0)
                              errorArray.push("Last Name");
                            if (tempData.Title?.trim().length === 0)
                              errorArray.push("Title");
                            if (tempData.Phone?.trim().length === 0)
                              errorArray.push("Phone");
                            if (tempData.Email?.trim().length === 0)
                              errorArray.push("Email Address");

                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }

                            const profilePicture = uuidv4();
                            try {
                              await Storage.put(profilePicture, file, {
                                contentType: file.type,
                              });
                            } catch (error) {
                              console.log(error);
                            }

                            await API.graphql(
                              graphqlOperation(`mutation {
                                insert_Contacts_one(
                                  object: 
                                  {
                                    FirstName: "${tempData.FirstName}", 
                                    LastName: "${tempData.LastName}", 
                                    Title: "${tempData.Title}", 
                                    Phone: "${tempData.Phone}", 
                                    Fax: "${tempData.Fax}", 
                                    Email: "${tempData.Email}",
                                    ItemId: "${uuidv4()}",
                                    ClientId: "${tempData.ClientId}",
                                    Mobile:"${tempData.Mobile}",
                                    OfficePhoneExtension: ${tempData.OfficePhoneExtension ? `"${tempData.OfficePhoneExtension}"` : null },                                                 
                                    PrimaryContact: ${tempData.PrimaryContact},
                                    ProfilePicture: ${
                                      file ? `"${profilePicture}"` : "null"
                                    }
                                  })
                                  {
                                    ItemId
                                  }         
                            }`)
                            );
                            let tempContact = contact;
                            tempContact.push(tempData);
                            setContact(tempContact);
                            setTempData(defaultTempData);
                            setFile(null);
                            setOpen(false);
                            setRefresh(new Date().toISOString());
                            setConfirmButtonMessage(true);
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 
                          text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError([]);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
