import { useState } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const resetPassword = (email) => {
    Auth.forgotPassword(email)
      .then((data) => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setError("There is no account associated with that email address");
      });
  };

  const handleLogin = (e) => {
    setError("");
    e.preventDefault();
    //error handling
    if (email.trim().length === 0) {
      setError("Email and password cannot be blank");
      return;
    }

    resetPassword(email);
  };

  return (
    <div>
      {/* Login Header */}
      <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-5/6'>
        <div>
          <h2 className='mt-1 text-center text-3xl tracking-tight font-bold teckpert-text-green'>
            {/* text-neutral-100 */}
            Reset Password
          </h2>
        </div>

        <div className='mt-5 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' action='#' method='POST'>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                  onFocus={() => setError("")}>
                  Email address
                </label>
                <div className='mt-1'>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                  />
                  <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  onClick={(e) => handleLogin(e)}
                  className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white teckpert-bg-green focus:outline-none focus:ring-2 focus:ring-offset-2'>
                  Send reset instruction
                </button>
              </div>
              {error.length > 0 && (
                <div className='rounded-md bg-red-50 p-4'>
                  <div className='flex'>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-red-800'>
                        Failed to send instructions to your email.
                      </h3>
                      <div className='mt-2 text-sm text-red-700'>
                        <div>{error}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
