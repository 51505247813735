import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function TemplateModal({
  open,
  setOpen,
  tempData,
  setTempData,
  stages,
  i,
  templates,
  setConfirmButtonMessage,
  newAction,
  setNewAction,
  actionType,
  actionId,
  mode,
  defaultTemplate,
}) {
  // const location = useLocation();
  const [error, setError] = useState([]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setError([]);
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Workflow Templates
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                    Select Template
                  </label>
                  <select
                    className="col-span-4 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                    border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                    text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                    onChange={(e) => {
                      // console.log("mode", mode);
                      if (mode == "Edit") {
                        let temp = tempData;
                        temp.Stages[0].Actions[actionId].TemplateId =
                          e.target.value;
                        setTempData({ ...temp });
                        setNewAction(e.target.value);
                        // console.log(
                        //   "onChange",
                        //   temp.Stages[0].Actions[actionId]
                        // );
                      } else if (mode == "Add") setNewAction(e.target.value);
                    }}
                    value={
                      mode == "Edit"
                        ? tempData.Stages[0]?.Actions[actionId].TemplateId
                        : newAction
                    }
                  >
                    <option className="text-gray-300" value="">
                      Select
                    </option>
                    {templates.map((obj) => {
                      return (
                        <option key={obj.ItemId} value={obj.ItemId}>
                          {obj.Type}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {error.length > 0 && MissingFieldPrompt(error)}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90 
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let errorArray = [];
                      if (newAction?.trim().length === 0)
                        errorArray.push("Template");
                      setError(errorArray);

                      if (errorArray.length > 0) {
                        return;
                      }

                      let temp = tempData;

                      if (mode == "Add") {
                        temp.Stages[i].Actions.push({
                          TemplateId: newAction,
                          Type: "Email",
                        });
                      } else if (mode == "Edit") {
                      }
                      await API.graphql(
                        graphqlOperation(`mutation {
                            update_Workflows_by_pk(pk_columns: {ItemId: "${
                              temp.ItemId
                            }"},
                                 _set: { Stages: [${temp.Stages?.map(
                                   (obj) =>
                                     `{
                                        Name: "${obj.Name}",
                                        Order: ${obj.Order},
                                        Actions: [
                                          ${obj.Actions?.map(
                                            (item) => `{
                                              TemplateId: "${item.TemplateId}",
                                              Type: "${item.Type}"
                                          }`
                                          ).join(`,`)}
                                      ]
                                    }`
                                 ).join(`,`)}]
                                })
                                {
                                    ItemId
                                    Stages
                                }
                            }`)
                      ).then(({ data }) => {
                        console.log("response", data);
                        setOpen(false);
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
