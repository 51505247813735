/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import listOfStates from "../../../../utils/dropdownOptions/listOfStates";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function AddWorkerModal({
  open,
  setOpen,
  tableData,
  setTableData,
  member,
   setConfirmButtonMessage,
   contractData
}) {
  const location = useLocation();
  const [tempData, setTempData] = useState({
    ContractId: location.pathname.split("/")[2],
    MemberId: "",
      Title: "",
      StartDate: contractData.StartDate ? new Date(contractData.StartDate) : new Date(),
      EndDate: contractData.EndDate ? new Date(contractData.EndDate) : Date(),
    Hours: 0,
    BillableRate: 0,
    Rate: 0,
    TimeApproval: "",
    Remote: "",
    Description: "",
    LocationId: "",
    Type: "",
    AutoSend: 0,
    Details: "",
    Archived: 0,
    JobCode:"",
    PurchaseOrder:"",
    FundingSource:"",
  });
    const [error, setError] = useState([]);
    console.log("contractData", contractData)
  const [locationData, setLocationData] = useState({
    Address: "",
    City: "",
    State: "",
    Zip: "",
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] h-[700px] relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Worker to Contract
                    </Dialog.Title>
                  </div>
                </div>
                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-2">
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3 mt-1">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.MemberId = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.MemberId}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            {member
                              ?.sort((a, b) =>
                                a.FirstName.localeCompare(b.FirstName)
                              )
                              .map((obj) => (
                                <option key={obj.ItemId} value={obj.ItemId}>
                                  {obj.FirstName} {obj.LastName}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Title = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Title}
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Start Date
                          </label>
                          <div className="mt-1" style={{ zIndex: 2000 }}>
                            <DatePicker
                              className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                              selected={tempData.StartDate}
                              onChange={(date) => {
                                let temp = tempData;
                                temp.StartDate = date;
                                setTempData({ ...temp });
                              }}
                              fixedHeight
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            End Date
                          </label>
                          <div className="mt-1">
                            <DatePicker
                              className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                              selected={tempData.EndDate}
                              onChange={(date) => {
                                let temp = tempData;
                                temp.EndDate = date;
                                setTempData({ ...temp });
                              }}
                              fixedHeight
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Type
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.Type = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.Type}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option value="Hours per month">
                              Hours per month
                            </option>
                            <option value="Fixed Project">Fixed Project</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Hours per month
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Hours = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Hours}
                              type="text"
                              name="hours"
                              id="hours"
                              autoComplete="hours"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Billable Rate
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.BillableRate = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.BillableRate}
                              type="text"
                              name="rate"
                              id="rate"
                              autoComplete="rate"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Worker Rate
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Rate = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.Rate}
                              type="text"
                              name="rate"
                              id="rate"
                              autoComplete="rate"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Time Approval
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.TimeApproval = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.TimeApproval}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Work Type
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.Remote = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.Remote}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option value="Remote">Remote</option>
                            <option value="On Site">On Site</option>
                            <option value="Hybrid">Hybrid</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Auto Send
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer hover:placeholder:text-white
                            border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900 hover:teckpert-bg-green hover:text-white"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.AutoSend = e.target.value;
                              setTempData({ ...temp });
                            }}
                            value={tempData.AutoSend}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Purchase Order
                          </label>
                          <div className="">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.PurchaseOrder = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.PurchaseOrder}
                              type="text"
                              name="purchaseOrder"
                              id="purchaseOrder"
                              autoComplete="purchaseOrder"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex mt-5">
                       <div className="sm:col-span-3 w-1/2">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Job Code
                          </label>
                          <div className="mt-1 mr-2">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.JobCode = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.JobCode}
                              type="text"
                              name="jobCode"
                              id="jobCode"
                              autoComplete="jobCode"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                         <div className="sm:col-span-2 w-1/2 ml-2">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Funding Source
                          </label>
                          <div className="mt-1 ">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.FundingSource = e.target.value;
                                setTempData({ ...temp });
                              }}
                              value={tempData.FundingSource}
                              type="text"
                              name="fundingSource"
                              id="fundingSource"
                              autoComplete="fundingSource"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>
                        </div>

                      <div className="mt-3">
                        <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                          Role Description
                        </label>
                        <textarea
                          rows={4}
                          name="comment"
                          id="comment"
                          className="w-full mt-1 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Description = e.target.value;
                            setTempData({ ...temp });
                          }}
                          value={tempData.Description}
                        />
                      </div>

                      {error.length > 0 && MissingFieldPrompt(error)}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 
                          text-base font-medium text-white shadow-sm hover:opacity-90 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={async () => {
                            let errorArray = [];
                            if (tempData.MemberId?.trim().length === 0)
                              errorArray.push("Worker Name");
                            if (tempData.Title?.trim().length === 0)
                              errorArray.push("Title");
                            if (tempData.Hours?.length === 0)
                              errorArray.push("Hours per month");
                            if (tempData.BillableRate?.length === 0)
                              errorArray.push("Billable Rate");
                            if (tempData.TimeApproval?.trim().length === 0)
                              errorArray.push("Time Approval");
                            if (tempData.Remote?.trim().length === 0)
                              errorArray.push("Work Type");
                            if (tempData.Description?.trim().length === 0)
                              errorArray.push("Description");
                            if (tempData.Type?.trim().length === 0)
                              errorArray.push("Type");
                            if (tempData.AutoSend?.length === 0)
                              errorArray.push("Auto Send");

                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }
                              const Description = JSON.stringify(tempData?.Description);
                            await API.graphql(
                              graphqlOperation(`mutation {
                                insert_JobOrders_one(
                                  object: 
                                  {
                                    ItemId: "${uuidv4()}",
                                    StartDate: "${tempData.StartDate.toISOString()}",
                                    EndDate: "${tempData.EndDate.toISOString()}",
                                    ContractId: "${tempData.ContractId}",
                                    MemberId: "${tempData.MemberId}",
                                    LocationId: "${tempData.LocationId}",
                                    Title: "${tempData.Title}",
                                    Hours: ${tempData.Hours},
                                    Rate: ${tempData.Rate},
                                    BillableRate: ${tempData.BillableRate},
                                    TimeApproval: "${tempData.TimeApproval}",
                                    Remote: "${tempData.Remote}",
                                    Description: ${Description},
                                    Archived: ${tempData.Archived},
                                    Type: "${tempData.Type}",
                                    AutoSend: ${tempData.AutoSend},
                                    FundingSource:"${tempData.FundingSource}",
                                    PurchaseOrder:"${tempData.PurchaseOrder}",
                                    JobCode:"${tempData.JobCode}",
                                  })
                                  {
                                    ItemId
                                    ContractId
                                  }         
                            }`)
                            ).then(({ data }) => {
                              setOpen(false);
                              setConfirmButtonMessage(true)
                            });

                            let temp = tableData;
                            let updatedTempData = tempData;
                            updatedTempData.StartDate = new Date(
                              updatedTempData.StartDate
                            )
                              .toISOString()
                              .split("T")[0];
                            updatedTempData.EndDate = new Date(
                              updatedTempData.EndDate
                            )
                              .toISOString()
                              .split("T")[0];
                            temp = [updatedTempData, ...temp];
                            setTableData(temp);

                            setTempData({
                              ContractId: location.pathname.split("/")[2],
                              MemberId: "",
                              Title: "",
                              StartDate: new Date(),
                              EndDate: new Date(),
                              Hours: "",
                              BillableRate: "",
                              Rate: "",
                              TimeApproval: "",
                              Remote: "",
                              Description: "",
                              LocationId: "",
                              Type: "",
                              AutoSend: "",
                              JobCode: "",
                              PurchaseOrder: "",
                              FundingSource: "",
                            });

                            setLocationData({
                              Address: "",
                              City: "",
                              State: "",
                              Zip: "",
                            });
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 
                          text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError([]);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
