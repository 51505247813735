import React from "react";

export const TabsMenu = ({ activeTab, setActiveTab }) => {
  return (
    <ul className="flex justify-evenly pt-7 text-xl font-bold mx-20">
      <li
        className={`${
          activeTab === "tab0" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab0")}>
          <span>Resume</span>
        </a>
      </li>
      <li
        className={`${
          activeTab === "tab1" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab1")}>
          <span>Interviews</span>
        </a>
      </li>
      <li
        className={`${
          activeTab === "tab2" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab2")}>
          <span>Profile</span>
        </a>
      </li>
      <li
        className={`${
          activeTab === "tab3" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab3")}>
          <span>Opportunities</span>
        </a>
      </li>
      <li
        className={`${
          activeTab === "tab4" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab4")}>
          <span>Contracts</span>
        </a>
      </li>
      <li
        className={`${
          activeTab === "tab5" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab5")}>
          <span>Documents</span>
        </a>
      </li>
      <li
        className={`${
          activeTab === "tab6" ? "underline" : "none"
        } hover:underline mr-8 cursor-pointer decoration-solid decoration-[#6FBE47] decoration-4 decoration-offset-8`}
      >
        <a onClick={() => setActiveTab("tab6")}>
          <span>Notes</span>
        </a>
      </li>
    </ul>
  );
};

export default TabsMenu;
