import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import Table from "../../../Contracts/Table";

const Contracts = ({ contractData, setContractData, activeTab }) => {
  const location = useLocation();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    API.graphql({
      query: `{
      JobOrders(where: {MemberId: {_eq: "${
        location.pathname.split("/")[2]
      }"}}) {  
        ItemId
        DateCreated
        DateUpdated
        ContractId
        MemberId
        LocationId
        Title
        StartDate
        EndDate
        Hours
        Rate
        BillableRate
        TimeApproval
        Remote
        Description
        Archived
        Type
        AutoSend  
      }
    }      
  `,
    }).then(({ data: JobOrders }) => {
      API.graphql({
        query: `{
          Contracts(order_by: {Name: asc}) {
            ItemId
            DateCreated
            DateUpdated
            Name
            ClientId
            Documents
            Notes
            StartDate
            EndDate
            Hours
            Status
            PrimaryContact
            PM
            CTO
            Details
            Categories
            Type
            Clients {
              Name
            }
            Members {
              FirstName
              LastName
            }
          }
        }
      `,
      }).then(({ data }) => {
        let temp = JobOrders.JobOrders.map((obj) => obj.ContractId);
        setContractData(
          data.Contracts.filter((obj) => temp.includes(obj.ItemId))
        );
      });
    });
  }, [location.pathname.split("/")[2], activeTab]);

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Contracts
          </h4>
        </div>
        <div
          onClick={() => setShowContent(!showContent)}
          className="flex flex-none mr-5"
        >
          {!showContent ? (
            <ChevronUpIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
            />
          ) : (
            <ChevronDownIcon
              className="h-5 w-5 cursor-pointer mt-1"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {/* Table Section */}
      <div className={`${showContent ? "hidden" : "block"}`}>
        <Table tableData={contractData} />
      </div>
    </div>
  );
};
export default Contracts;
