import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  XCircleIcon,
  ArrowCircleRightIcon,
  EyeIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import ReviewInterviewModal from "../Modals/ReviewInterviewModal";

const Interviews = ({ tableData, interviewGuides, responsesData }) => {
  const [selected, setSelected] = useState([]);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const location = useLocation();

  if (!tableData) return <div></div>;

  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      {/* Header Section */}
      <div className="flex shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-5 relative top-1 py-3">
        <div className="grow">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Interview Guides
          </h4>
        </div>
      </div>
      {/* Table Section */}
      <div className="mx-5 flex flex-col">
        {tableData.length > 0 ? (
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                  <thead>
                    <tr>
                      {["Title", "Duration", "Result", "", ""].map(
                        (header, i) => (
                          <th
                            key={i}
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableData?.map((obj, i) => (
                      <tr key={obj.ItemId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center font-medium teckpert-text-green">
                            {obj.Title ? obj.Title : "-"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                          <div className="col-span-2 flex-grow align-middle m-auto">
                            {obj.Duration
                              ? obj.Duration !== 1
                                ? obj.Duration + " min"
                                : obj.Duration + " hr"
                              : "-"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                          {responsesData[
                            responsesData.findIndex(
                              (x) => x.InterviewGuideId === obj.ItemId
                            )
                          ]?.Rating === "Passed" ? (
                            <div className="flex teckpert-text-green">
                              <CheckCircleIcon
                                className="h-5 w-5 mx-1"
                                aria-hidden="true"
                              />
                              {
                                responsesData[
                                  responsesData.findIndex(
                                    (x) => x.InterviewGuideId === obj.ItemId
                                  )
                                ]?.Rating
                              }
                            </div>
                          ) : responsesData[
                              responsesData.findIndex(
                                (x) => x.InterviewGuideId === obj.ItemId
                              )
                            ]?.Rating ? (
                            <div className="flex text-red-500">
                              <XCircleIcon
                                className="h-5 w-5 mx-1"
                                aria-hidden="true"
                              />
                              {
                                responsesData[
                                  responsesData.findIndex(
                                    (x) => x.InterviewGuideId === obj.ItemId
                                  )
                                ]?.Rating
                              }
                            </div>
                          ) : (
                            <div className="text-gray-800 font-normal">
                              No Rating
                            </div>
                          )}
                        </td>
                        <td className="py-2 text-sm text-right">
                          <button
                            onClick={() => {
                              setSelected(obj);
                              setReviewModalOpen(true);
                            }}
                            type="button"
                            className={`${
                              !responsesData[
                                responsesData.findIndex(
                                  (x) => x.InterviewGuideId === obj.ItemId
                                )
                              ]
                                ? "bg-gray-300 opacity-70 cursor-not-allowed"
                                : "bg-blue-500 hover:opacity-90 cursor-pointer"
                            }
                                mr-8 mt-2 items-center justify-center rounded-md border border-transparent  
                                px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto`}
                          >
                            View
                            <EyeIcon
                              className="h-6 w-6 inline cursor-pointer mx-3"
                              aria-hidden="true"
                            />
                          </button>
                          {selected &&
                          responsesData[
                            responsesData.findIndex(
                              (x) => x.InterviewGuideId === selected.ItemId
                            )
                          ] ? (
                            <ReviewInterviewModal
                              open={reviewModalOpen}
                              setOpen={setReviewModalOpen}
                              tableData={tableData}
                              selectedJob={
                                responsesData[
                                  responsesData.findIndex(
                                    (x) =>
                                      x.InterviewGuideId === selected.ItemId
                                  )
                                ]
                              }
                              interviewGuides={interviewGuides}
                            />
                          ) : null}
                        </td>
                        <td className="py-4 text-sm text-right w-[170px]">
                          <button
                            type="button"
                            className={`${
                              !responsesData[
                                responsesData.findIndex(
                                  (x) => x.InterviewGuideId === obj.ItemId
                                )
                              ]
                                ? "hover:opacity-90 teckpert-bg-green"
                                : "bg-gray-300 opacity-70"
                            } mr-8 mt-2 items-center justify-center 
                              rounded-md border border-transparent px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto`}
                          >
                            {!responsesData[
                              responsesData.findIndex(
                                (x) => x.InterviewGuideId === obj.ItemId
                              )
                            ] ? (
                              <Link
                                to={"/interviewguides/" + obj.ItemId}
                                state={location.pathname.split("/")[2]}
                              >
                                Launch
                                <ArrowCircleRightIcon
                                  className="h-6 w-6 inline cursor-pointer mx-3"
                                  aria-hidden="true"
                                />
                              </Link>
                            ) : (
                              <div className="flex cursor-not-allowed">
                                Launch
                                <ArrowCircleRightIcon
                                  className="h-6 w-6 inline mx-3"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
            No Interviews Found
          </div>
        )}
      </div>
    </div>
  );
};
export default Interviews;
