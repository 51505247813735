/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import {
  PlusIcon,
  MapIcon,
  PencilIcon,
  PhoneIcon,
  MailIcon,
  PrinterIcon,
} from "@heroicons/react/solid";
import ClientList from "./ClientList";
import CreateContactModal from "./CreateContactModal";
import UpdateClientModal from "./UpdateClientModal";
import { formatPhone } from "../../../utils/FormatPhone";
import Loading from "../../../utils/Loading";

const ClientInfo = ({ tableData, setTableData, setConfirmButtonMessage }) => {
  const location = useLocation();
  const [modalOpenAddContact, setModalOpenAddContact] = useState(false);
  const [modalOpenUpdateClient, setModalOpenUpdateClient] = useState(false);
  const [contact, setContact] = useState([]);
  const [img, setImg] = useState([]);
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    const async = async () => {
      const { data } = await API.graphql({
        query: `{
          Contacts(order_by: {DateCreated: desc}, where: { ClientId: {_eq: "${
            location.pathname.split("/")[2]
          }"}}) {
            ItemId
            DateCreated
            DateUpdated
            ClientId
            Title
            FirstName
            LastName
            Phone
            Fax
            Email           
            ProfilePicture
            PrimaryContact
            Mobile
            OfficePhoneExtension
        }
      }      
    `,
      });
      setContact(
        await Promise.all(
          data.Contacts.map(async (person) => ({
            ...person,
            ProfilePictureUrl:
              person.ProfilePicture &&
              (await Storage.get(person.ProfilePicture)),
          }))
        )
      );
    };
    async();
  }, [location.pathname, refresh]);

  if (!tableData) return <Loading />;
  return (
    <div>
      <CreateContactModal
        open={modalOpenAddContact}
        setOpen={setModalOpenAddContact}
        contact={contact}
        setContact={setContact}
        setRefresh={setRefresh}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
      <UpdateClientModal
        open={modalOpenUpdateClient}
        setOpen={setModalOpenUpdateClient}
        tableData={tableData}
        setTableData={setTableData}
        setRefresh={setRefresh}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
      <div className="lg:flex lg:items-center lg:justify-between p-12 rounded">
        <div className="min-w-0 flex-1">
          <h2 className="text-3xl mb-1 font-bold leading-7 teckpert-text-green sm:truncate sm:text-3xl sm:tracking-tight">
            {tableData.Name}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <MapIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {tableData.State ? tableData?.City + ", " + tableData.State : "-"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <MailIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {tableData.Email ? tableData.Email : "-"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <PhoneIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {tableData.Phone ? formatPhone(tableData.Phone) : "-"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <PrinterIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {tableData.Fax ? formatPhone(tableData.Fax) : "-"}
            </div>
          </div>
        </div>

        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="block">
            <button
              onClick={() => setModalOpenUpdateClient(!modalOpenUpdateClient)}
              type="button"
              className="inline-flex items-center rounded-md border teckpert-bg-green px-4 py-2 text-sm font-medium text-white
              shadow-sm hover:opacity-90 focus:outline-none"
            >
              <PencilIcon
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              Update Client
            </button>
          </span>

          <span className="ml-3 block">
            <button
              onClick={() => setModalOpenAddContact(!modalOpenAddContact)}
              type="button"
              className="inline-flex items-center rounded-md border teckpert-bg-green px-4 py-2 text-sm font-medium text-white
              shadow-sm hover:opacity-90 focus:outline-none"
            >
              <PlusIcon
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              Add Contact
            </button>
          </span>
        </div>
      </div>
      {contact ? (
        <ClientList
          contact={contact}
          setContact={setContact}
          img={img}
          setImg={setImg}
          setModalOpenAddContact={setModalOpenAddContact}
          setRefresh={setRefresh}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
      ) : null}
    </div>
  );
};

export default ClientInfo;
