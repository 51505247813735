import { useState, useEffect } from "react";
import { ClipboardIcon, ClipboardCheckIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import { Editor } from "@tinymce/tinymce-react";

let timeout;
const CreateEmailTemplate = () => {
  const navigate = useNavigate();
  const [recentlyCopied, setRecentlyCopied] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [tempData, setTempData] = useState({
    DateCreated: new Date().toISOString(),
    DateUpdated: new Date().toISOString(),
    From: "",
    To: "",
    Subject: "",
    Body: "",
    Type: "",
    Name: "",
  });

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setRecentlyCopied(null);
    }, 2000);
  }, [recentlyCopied]);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
        ItemId
        DateCreated
        DateUpdated
        FirstName
        LastName
        UserType
        Email
        DirectLine
      }
    }      
  `,
    }).then(({ data }) => {
      setUsersData(data.Users);
    });
  }, []);

  let tempText = "";
  return (
    <div className="my-1 mx-4 p-12 bg-white rounded-lg border border-gray-200 shadow-md">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/emailtemplates">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      {/* Email Section */}
      <div className="sm:col-span-2 flex-grow">
        <div className="mt-2 grid grid-cols-7">
          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
            Template Name
          </label>
          <input
            className="col-span-6 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
            border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
            onChange={(e) => {
              let temp = tempData;
              temp.Name = e.target.value;
              setTempData({ ...temp });
            }}
            value={tempData.Name}
          />
        </div>
          </div>
          <div className="sm:col-span-2 flex-grow">
              <div className="mt-2 grid grid-cols-7 ">
                  <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">Template Type</label>
                  <select className="col-span-6 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md
                                     border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white text-gray-900
                                     hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                          let temp = tempData;
                          temp.Type = e.target.value;
                          setTempData({ ...temp })
                      }}
                      value={tempData.Type}
                  >
                      <option className="text-gray-300" value="">
                      Select Template Type
                      </option>
                      <option className="">
                      User Template
                      </option>
                      <option className="">
                          System Templates
                      </option>
                  </select>
              </div>
          </div>
      <div className="sm:col-span-2 flex-grow">
        <div className="mt-2 grid grid-cols-7">
          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
            Default From
          </label>
          <select
            className="col-span-6 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
            text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
            onChange={(e) => {
              let temp = tempData;
              temp.From = e.target.value;
              setTempData({ ...temp });
            }}
            value={tempData.From}
          >
            <option className="text-gray-300" value="">
              Select From
            </option>
            {usersData.map((obj) => (
              <option
                key={obj.ItemId}
                value={obj.ItemId}
              >{`${obj.FirstName} ${obj.LastName} <${obj.Email}>`}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="sm:col-span-2 flex-grow">
        <div className="mt-2 grid grid-cols-7">
          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
            Subject
          </label>
          <input
            className="col-span-6 w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
            border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
            onChange={(e) => {
              let temp = tempData;
              temp.Subject = e.target.value;
              setTempData({ ...temp });
            }}
            value={tempData.Subject}
          />
        </div>
      </div>

      <div className="mt-8 mb-20">
        <Editor          
          apiKey={"2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"}
          onEditorChange={(value) => {
            tempText = value;
          }}
          initialValue={tempData.Body}
          init={{
            height: 450,
            menubar: false,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | casechange blocks | bold italic backcolor | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
            content_style:
                  "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
             force_br_newlines: true,
             force_p_newlines: false,
              indent: false
          }}
        />
      </div>

      <div className="mb-10">
        <div className="text-lg font-bold">Custom Fields</div>
        <div className="my-2 flex flex-wrap gap-2">
          {[
            "opportunity_name",
            "opportunity_url",
            "member_firstname",
            "member_lastname",
            "member_email",
            "recruiter_firstname",
            "recruiter_lastname",
            "recruiter_email",
            "recruiter_calendlyurl",
            "recruiter_fullname",
            "recruiter_directline",
          ].map((obj) => (
            <div
              onClick={() => {
                navigator.clipboard.writeText(`%%${obj}%%`);
                setRecentlyCopied(obj);
              }}
              className="border border-gray-400 rounded p-2 hover:teckpert-bg-green"
              style={{
                cursor: "pointer",
                width: 260,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: recentlyCopied === obj ? "#6fbe47" : "",
              }}
            >
              {`%%${obj}%%`}
              {recentlyCopied === obj ? (
                <ClipboardCheckIcon
                  className="h-6 w-6 inline cursor-pointer mx-3"
                  aria-hidden="true"
                />
              ) : (
                <ClipboardIcon
                  className="h-6 w-6 inline cursor-pointer mx-3"
                  aria-hidden="true"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
          teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
          onClick={async () => {
            console.log(tempText);
            await API.graphql(
              graphqlOperation(`mutation {
                  insert_EmailTemplates_one(
                    object: 
                    {
                      ItemId: "${uuidv4()}",    
                      DateCreated: "${tempData.DateCreated}",
                      DateUpdated: "${tempData.DateUpdated}",
                      From: "${tempData.From}",
                      To: "${tempData.To}",
                      Subject: "${tempData.Subject}",
                      Body: ${JSON.stringify(tempText)},           
                      Type: "${tempData.Type}", 
                      Name: "${tempData.Name}",  
                    })
                    {
                      ItemId
                      Subject
                      Body
                    }         
              }`)
            ).then(({ data }) => {
              console.log("data", data);
              navigate("/emailtemplates");
            });
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
          bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
          focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
          // onClick={() => {}}
        >
          Add Attachment
        </button>
      </div>
    </div>
  );
};
export default CreateEmailTemplate;
