import { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import UpdateCategoriesModal from "../Modals/UpdateCategoriesModal";

const Categories = ({ tableData, setTableData, setConfirmButtonMessage }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <UpdateCategoriesModal
        open={modalOpen}
        setOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
      <div className="my-5 mx-8 p-6 bg-white rounded-lg border shadow-md hover:bg-gray-100 ">
        <div className="flex justify-between">
          <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
            Categories
          </h4>
          <div
            onClick={() => setModalOpen(!modalOpen)}
            className="flex flex-none mr-5"
          >
            <PlusCircleIcon
              className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="flex p-3">
          {tableData && Array.isArray(tableData.Categories) ? (
            tableData.Categories?.map((obj, i) => (
              <span key={i} className="mx-3 bg-gray-200 p-3 rounded-lg">
                {obj}
              </span>
            ))
          ) : (
            <div className="m-auto p-4 align-middle justify-center text-gray-300 font-bold">
              No Categories Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Categories;
