/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import ConfirmDeleteModal from "../../../../utils/ConfirmDeleteModal";
import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";

const UpdateNotesModal = ({
  open,
  setOpen,
  profileData,
  setProfileData,
  noteId,
  selectedNote,
  setSelectedNote,
  url,
  setConfirmButtonMessage,
  title,
  datePicker,
  logDate,
  setLogDate,
}) => {
  const [input, setInput] = useState("");
  const [validDate, setValidDate] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  let tempText = profileData.Notes.filter((obj) => obj.ItemId === noteId)[0];

  useEffect(() => {
    if (profileData.Notes.length > 0) {
      if (title?.includes("Note")) setInput(selectedNote?.Note);
      else if (title?.includes("Log")) setInput(selectedNote?.CallLog);
    }
  }, [open]);

  const deleteItem = async () => {
    let temp = profileData;
    let tempUrl = url.substring(1);
    tempUrl = tempUrl.charAt(0).toUpperCase() + url.slice(2);

    if (title.includes("Note") || !title) {
      temp.Notes = profileData.Notes?.filter(
        (obj) => obj.ItemId != selectedNote.ItemId
      );
      await API.graphql(
        graphqlOperation(`mutation {
          update_${tempUrl}_by_pk(pk_columns: {ItemId: "${
          profileData.ItemId
        }"}, 
            _set: 
              {
                Notes: [${temp.Notes.map(
                  (obj) =>
                    `{
                    Note: ${JSON.stringify(obj.Note)},
                    Creator: "${obj.Creator}",
                    DateCreated: "${obj.DateCreated}",
                    ItemId: "${obj.ItemId}"
                  }`
                ).join(",")}]
              })
              {
                ItemId
                Notes
              }
      }`)
      ).then(({ data }) => {
        setProfileData({ ...temp });
        setDeleteModalOpen(false);
        setOpen(false);
        setConfirmButtonMessage(true);
        setSelectedNote({});
      });
    }
    if (title.includes("Log")) {
      temp.CallLogs = profileData.CallLogs?.filter(
        (obj) => obj.ItemId != selectedNote.ItemId
      );
      await API.graphql(
        graphqlOperation(`mutation {
          update_${tempUrl}_by_pk(pk_columns: {ItemId: "${
          profileData.ItemId
        }"}, 
            _set: 
              {
                CallLogs: [${temp.CallLogs.map(
                  (obj) =>
                    `{
                    CallLog: ${JSON.stringify(obj.CallLog)},
                    Creator: "${obj.Creator}",
                    DateCreated: "${obj.DateCreated}",
                    ItemId: "${obj.ItemId}"
                  }`
                ).join(",")}]
              })
              {
                ItemId
                CallLogs
              }
      }`)
      ).then(({ data }) => {
        setProfileData({ ...temp });
        setDeleteModalOpen(false);
        setOpen(false);
        setConfirmButtonMessage(true);
        setSelectedNote({});
      });
    }
  };

  if (profileData.Notes.length == 0) return <div></div>;

  return (
    <>
      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteItem={deleteItem}
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`${
                    title.includes("Note")
                      ? "lg:h-[510px]"
                      : title.includes("Log")
                      ? "lg:h-[570px]"
                      : "lg:h-[510px]"
                  } lg:w-[1000px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6`}
                >
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {title}
                    </label>
                    {datePicker ? (
                      <DatePicker
                        className="mt-3 mb-1 z-2 w-[95%] ml-6 rounded-full justify-center rounded-md cursor-pointer
                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                        text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                        selected={new Date(logDate)}
                        onChange={(date) => {
                          let temp = logDate;
                          if (date > new Date()) {
                            setValidDate(false);
                            return;
                          } else {
                            setValidDate(true);
                            temp = date;
                            setLogDate(temp);
                          }
                        }}
                        fixedHeight
                      />
                    ) : null}
                    <span
                      className={`${
                        logDate > new Date() || !validDate ? "" : "hidden"
                      } text-xs text-red-500`}
                    >
                      Cannot set date in future.
                    </span>
                    <div className="m-5 h-60 md:h-48">
                      <Editor
                        apiKey={
                          "2cj6h0jykxie6wcz710ekrqhf9brlujzbuap1f2scxuv77e6"
                        }
                        onEditorChange={(value) => {
                          tempText = value;
                          console.log("Text", tempText);
                        }}
                        initialValue={input}
                        init={{
                          height: 350,
                          menubar: false,
                          plugins: [
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | casechange blocks | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                          content_style:
                            "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                        }}
                      />
                    </div>
                  </div>

                  <div className="lg:mt-48 sm:mt-56 sm:flex justify-between">
                    <button
                      type="button"
                      className="mt-3 bg-red-500 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 
                      text-base font-medium text-white shadow-sm sm:mt-0 sm:w-auto sm:text-sm hover:opacity-90"
                      onClick={() => setDeleteModalOpen(!deleteModalOpen)}
                    >
                      Delete
                    </button>
                    <div>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                        font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm hover:opacity-90"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 
                        text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                        onClick={async () => {
                          let tempUrl = url.substring(1);
                          tempUrl =
                            tempUrl.charAt(0).toUpperCase() + url.slice(2);

                          if (title?.includes("Note")) {
                            let temp = {
                              ItemId: profileData.ItemId,
                              Notes: profileData.Notes.map((obj) => {
                                if (obj.ItemId === selectedNote.ItemId)
                                  obj.Note = tempText;
                                return obj;
                              }),
                            };
                            await API.graphql(
                              graphqlOperation(`mutation {
                              update_${tempUrl}_by_pk(pk_columns: {ItemId: "${
                                profileData.ItemId
                              }"}, 
                                _set: 
                                  {
                                    Notes: [${temp.Notes.map(
                                      (obj) =>
                                        `{
                                        Note: ${JSON.stringify(obj.Note)},
                                        Creator: "${obj.Creator}",
                                        DateCreated: "${obj.DateCreated}",
                                        ItemId: "${obj.ItemId}"
                                      }`
                                    ).join(",")}]
                                  })
                                  {
                                    ItemId
                                    Notes
                                  }
                            }`)
                            ).then(({ data }) => {
                              setOpen(false);
                              setConfirmButtonMessage(true);
                              setSelectedNote({});
                            });
                          }

                          if (title?.includes("Log")) {
                            let temp = {
                              ItemId: profileData.ItemId,
                              CallLogs: profileData.CallLogs.map((obj) => {
                                if (obj.ItemId === selectedNote.ItemId) {
                                  obj.CallLog = tempText;
                                  obj.DateCreated = logDate;
                                }
                                return obj;
                              }),
                            };
                            await API.graphql(
                              graphqlOperation(`mutation {
                              update_${tempUrl}_by_pk(pk_columns: {ItemId: "${
                                profileData.ItemId
                              }"}, 
                                _set: 
                                    {
                                      CallLogs: [${temp.CallLogs.map(
                                        (obj) =>
                                          `{
                                              CallLog: ${JSON.stringify(
                                                obj.CallLog
                                              )},
                                              Creator: "${obj.Creator}",
                                              DateCreated: "${obj.DateCreated}",
                                              ItemId: "${obj.ItemId}"
                                          }`
                                      ).join(
                                        ","
                                      )}]                                    
                                    })
                                    {
                                      ItemId
                                      CallLogs
                                    }
                              }`)
                            ).then(({ data }) => {
                              setOpen(false);
                              setConfirmButtonMessage(true);
                              setSelectedNote({});
                            });
                          }
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UpdateNotesModal;
