import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { XCircleIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";
import MissingFieldPrompt from "../../../utils/MissingFieldPrompt";

const CreateResourceModal = ({
  modalOpen,
  setModalOpen,
  tableData,
  setTableData,
  setTempTableData,
}) => {
  const [tempData, setTempData] = useState({
    Name: "",
    Type: "",
    Link: "",
  });
  const [error, setError] = useState([]);

  const onSave = async () => {
    let errorArray = [];
    if (tempData.Name?.trim().length === 0) errorArray.push("Name");
    if (tempData.Type?.trim().length === 0) errorArray.push("Type");
    if (tempData.Link?.trim().length === 0) errorArray.push("Link");

    setError(errorArray);

    if (errorArray.length > 0) {
      return;
    }

    // API.post("auth", "/documents?", { body: tempOutput }).then(
    //   (responseJson) => {
    //     //add id to tempData
    //     let temp = tempData;
    //     temp.ItemId = responseJson.Output.ItemId;
    //     setTableData([temp, ...tableData]);
    //     setTempTableData([temp, ...tableData]);
    //     return responseJson;
    //   }
    // );

    await API.graphql(
      graphqlOperation(`mutation {
        insert_Documents_one(
          object: 
          {
            ItemId: "${uuidv4()}",
            ContractId: "${tempData.ContractId}"
            ClientId: "${tempData.ClientId}",
            MemberId: "${tempData.MemberId}",
            JobOrderId: "${tempData.JobOrderId}",
            CreatorId: "${tempData.CreatorId}",
            CreatorName: "${tempData.CreatorName}",
            Folder: "TECKpert",
            Type: "${tempData.Type}",
            Key: "${tempData.Key}",
            Link: "${tempData.Link}",
            Name: "${tempData.Name}"
          })
          {
            ItemId
            ContractId
            Name
          }         
    }`)
    ).then(({ data }) => {
      setTableData([tempData, ...tableData]);
      setTempTableData([tempData, ...tableData]);
    });

    setModalOpen(false);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setModalOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[500px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setModalOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start mb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Resource
                    </Dialog.Title>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Name = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Name}
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Type
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Type = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Type}
                    >
                      <option className="text-gray-300" value="">
                        Select Type
                      </option>
                      <option value="Onboarding">Onboarding</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Link
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Link = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Link}
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white"
                    />
                  </div>
                </div>
                {error.length > 0 && MissingFieldPrompt(error)}

                <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent 
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setModalOpen(false);
                      setError([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CreateResourceModal;
