/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XCircleIcon,
  PlusCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";
import { SearchBarLogic } from "../../../../utils/SearchBarLogic";

export default function SearchModal({
  open,
  setOpen,
  setCreateModalOpen,
  setColumnsModalOpen,
  tableData,
  setTableData,
  client,
  contract,
  opportunityField,
  criteriaFilter,
  jobsData,
  setJobsData,
  jobPortalsData,
  tempData,
  newData,
  setEditModalOpen,
  selectedJob,
  setSelectedJob,
  setJobPortalsData,
}) {
  const [searchVal, setSearchVal] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(jobsData);
  }, [jobsData, open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] lg:min-h-[800px] lg:max-h-[800px] relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setSelectedJob([]);
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Opportunities found
                    </Dialog.Title>
                  </div>
                </div>

                <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="text-lg font-bold my-4">
                    {selectedJob ? selectedJob.Name : ""}
                  </div>
                  <p className="mb-4">
                    Criteria: {opportunityField}&nbsp;
                    <span className="font-bold">
                      {opportunityField === "Location"
                        ? `[${criteriaFilter}]`
                        : opportunityField === "Client"
                        ? `[${
                            client?.filter(
                              (obj) => obj.ItemId === criteriaFilter
                            )[0]?.Name
                          }]`
                        : opportunityField === "Contract"
                        ? `[${
                            contract?.filter(
                              (obj) => obj.ItemId === criteriaFilter
                            )[0]?.Name
                          }]`
                        : ""}
                    </span>
                  </p>
                  <input
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                      SearchBarLogic(jobsData, setFilteredData, e.target.value);
                    }}
                    value={searchVal}
                    type="search"
                    className="w-full form-control relative flex-auto block px-7 py-3 mb-2
                    text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding transition 
                    ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
                    focus:outline-none rounded-full"
                    placeholder="Search by Title"
                    aria-label="Search"
                    aria-describedby="button-addon3"
                  />
                  {filteredData.length === 0 ? (
                    <div className="flex justify-center items-center h-100 align-middle py-10">
                      No results
                    </div>
                  ) : (
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[500px] overflow-y-scroll">
                      <table className="w-full divide-y divide-gray-300 overflow-y-scroll">
                        <thead>
                          <tr>
                            {["Title", "Location", ""].map((header, i) => (
                              <th
                                key={i}
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {filteredData.map((obj, i) => {
                            return (
                              <tr
                                className={`${
                                  !selectedJob.Opportunities?.includes(
                                    obj.ItemId
                                  )
                                    ? "bg-white"
                                    : "bg-gray-100"
                                } max-h-[40px]`}
                                key={obj.ItemId}
                              >
                                <td className="px-3 py-4 text-sm teckpert-text-green font-bold pl-5">
                                  {obj.Title}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-900 pl-5">
                                  {obj.Location}
                                </td>
                                <td>
                                  {opportunityField === "Manual" ? (
                                    !selectedJob.Opportunities?.includes(
                                      obj.ItemId
                                    ) ? (
                                      <PlusCircleIcon
                                        onClick={async () => {
                                          let temp = selectedJob;
                                          temp.Opportunities = [
                                            obj.ItemId,
                                            ...selectedJob.Opportunities,
                                          ];

                                          await API.graphql(
                                            graphqlOperation(`mutation {
                                              update_JobPortals_by_pk(pk_columns: {ItemId: "${
                                                selectedJob.ItemId
                                              }"}, 
                                                _set: 
                                                  {
                                                    Opportunities: [${temp.Opportunities.map(
                                                      (obj) => `"${obj}"`
                                                    ).join(",")}], 
                                                  })
                                                  {
                                                    ItemId
                                                    Opportunities
                                                  }
                                            }`)
                                          ).then(({ data }) => {
                                            setSelectedJob({ ...temp });
                                          });
                                        }}
                                        className="h-6 w-6 cursor-pointer mr-3 teckpert-text-green"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <MinusCircleIcon
                                        onClick={async () => {
                                          let temp = selectedJob;
                                          let tempId = filteredData[i].ItemId;
                                          temp.Opportunities =
                                            temp.Opportunities.filter(
                                              (item) => item !== tempId
                                            );

                                          await API.graphql(
                                            graphqlOperation(`mutation {
                                                update_JobPortals_by_pk(pk_columns: {ItemId: "${
                                                  selectedJob.ItemId
                                                }"}, 
                                                  _set: 
                                                    {
                                                      Opportunities: [${temp.Opportunities.map(
                                                        (obj) => `"${obj}"`
                                                      ).join(",")}], 
                                                    })
                                                    {
                                                      ItemId
                                                      Opportunities
                                                    }
                                              }`)
                                          ).then(({ data }) => {
                                            setSelectedJob({ ...temp });
                                          });
                                        }}
                                        className="h-6 w-6 cursor-pointer mr-3 text-red-500"
                                        aria-hidden="true"
                                      />
                                    )
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="mt-8 sm:mt-4 sm:flex sm:flex-row-reverse absolute bottom-8 right-8">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green 
                    px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                    onClick={async () => {
                      let temp = selectedJob;
                      if (opportunityField !== "Manual") {
                        jobsData.map((obj) => {
                          temp.Opportunities = [
                            obj.ItemId,
                            ...(selectedJob.Opportunities || []),
                          ];
                        });

                        if (selectedJob.ItemId)
                          await API.graphql(
                            graphqlOperation(`mutation {
                              update_JobPortals_by_pk(pk_columns: {ItemId: "${
                                selectedJob.ItemId
                              }"},
                                _set:
                                  {
                                    Opportunities: [${temp.Opportunities.map(
                                      (obj) => `"${obj}"`
                                    ).join(",")}],
                                  })
                                  {
                                    ItemId
                                    Opportunities
                                  }
                            }`)
                          ).then(({ data }) => {
                            console.log("more testing", data);
                            setColumnsModalOpen(true);
                            setOpen(false);
                          });
                      }
                      setJobPortalsData([
                        ...jobPortalsData.filter(
                          (obj) => obj.ItemId != selectedJob.ItemId
                        ),
                        selectedJob,
                      ]);
                      setColumnsModalOpen(true);
                      setOpen(false);
                    }}
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                    font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpen(false);
                      setEditModalOpen(true);
                    }}
                  >
                    Back
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
