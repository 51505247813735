/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

const UploadFileModal = ({
  open,
  setOpen,
  tableData,
  setTableData,
  setConfirmButtonMessage,
}) => {
  const location = useLocation();
  const [data, setData] = useState({
    Key: "",
    Folder: "",
    Name: "",
    ContractId: "",
    DateCreated: new Date().toISOString(),
    CreatorName: localStorage.getItem("name"),
    Type: "",
  });
  const [error, setError] = useState([]);
  const [fileType, setFileType] = useState([]);

  useEffect(() => {
    API.graphql({
      query: `{
      Lookup {
        ItemId
        Name   
        Options
      }
    }      
  `,
    }).then(({ data }) => {
      setFileType(
        data.Lookup.filter((obj) => obj.Name === "DocTypesClientContract")[0]
          .Options
      );
    });
  }, []);

  async function onChange(e) {
    const file = e.target.files[0];
    setData({
      Key: file.name,
      Folder: "testing",
      Name: file.name,
      ContractId: location.pathname.split("/")[2],
      DateCreated: new Date().toISOString(),
      CreatorName: localStorage.getItem("name"),
      Type: "",
    });

    try {
      let response = await Storage.put(file.name, file, {
        contentType: file.name.includes(".pdf")
          ? "application/pdf"
          : "image/png",
      });
      return response;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setError([]);
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setError([]);
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Upload Profile Picture
                  </label>
                  <div className="m-5">
                    <input
                      onChange={onChange}
                      type="file"
                      name="last-name"
                      id="last-name"
                    />
                  </div>
                </div>
                <div>
                  <select
                    className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                    border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                    text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                    onChange={(e) => {
                      let temp = data;
                      temp.Type = e.target.value;
                      setData({ ...temp });
                    }}
                  >
                    <option value="">Choose Type</option>
                    {fileType.map((obj, i) => (
                      <option key={i} value={obj}>
                        {obj}
                      </option>
                    ))}
                  </select>
                </div>
                {error.length > 0 && MissingFieldPrompt(error)}

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 
                    py-2 text-base font-medium text-white shadow-sm hover:opacity-90 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      let errorArray = [];
                      if (data.Type?.trim().length === 0)
                        errorArray.push("Type");
                      if (data.Name?.trim().length === 0)
                        errorArray.push("File Upload");

                      setError(errorArray);

                      if (errorArray.length > 0) {
                        return;
                      }
                      // API.post("auth", `/documents`, { body: data }).then(
                      //   (responseJson) => {
                      //     return responseJson;
                      //   }
                      // );

                      await API.graphql(
                        graphqlOperation(`mutation {
                          insert_Documents_one(
                            object: 
                            {
                              ItemId: "${uuidv4()}",
                              ContractId: "${data.ContractId}"
                              ClientId: "${data.ClientId}",
                              MemberId: "${data.MemberId}",
                              JobOrderId: "${data.JobOrderId}",
                              CreatorId: "${data.CreatorId}",
                              CreatorName: "${data.CreatorName}",
                              Folder: "${data.Folder}",
                              Type: "${data.Type}",
                              Key: "${data.Key}",
                              Link: "${data.Link}",
                              Name: "${data.Name}"

                            })
                            {
                              ItemId
                              ContractId
                              Name
                            }         
                      }`)
                      ).then(({ data }) => {
                        setConfirmButtonMessage(true);
                        setOpen(false);
                      });
                      let temp = tableData;
                      temp.unshift(data);
                      setTableData(temp);
                    }}
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setError([]);
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UploadFileModal;
