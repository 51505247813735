import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Table from "./Table";
import BasicSearchBar from "../../utils/BasicSearchbar";
import CreateClientModal from "./CreateClientModal";
import Loading from "../../utils/Loading";

const Client = () => {
  const [tableData, setTableData] = useState([]);
  // const [contracts, setContracts] = useState([]);
  const [tempTableData, setTempTableData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    const async = async () => {
      const { data } = await API.graphql({
        query: `{
      Clients(order_by: {DateCreated: desc}) {
        ItemId
        DateCreated
        DateUpdated
        AccountStatus
        Name
        Address
        City
        State
        Zip
        IndustryType
        Website
        Phone
        Fax
        Email
        Documents
        Notes
        Categories    
        Contacts(where: {PrimaryContact: {_eq: true}}) {
          FirstName
          LastName
        }
        Contracts_aggregate(where: {Status: {_eq: "In Progress"}}) {
          aggregate {
            count
          }
        }
      }
    }      
  `,
      });
      setTableData(data.Clients);
      setTempTableData(data.Clients);
      setLoading(false);
    }
    async();
  }, [refresh]);

  useEffect(() => {
    setTempTableData(
      tableData.filter((client) =>
        client.Name?.toLowerCase().startsWith(searchVal.toLowerCase())
      )
    );
  }, [searchVal]);

  if (loading) return <Loading />

  return (
    <div>
      <div className="flex justify-end mx-6">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            type="button"
            onClick={() => setModalOpen(true)}
            className="float-right mr-6 inline-flex items-center justify-center rounded-md border 
            border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium hover:opacity-90
            text-white shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <CreateClientModal
        open={modalOpen}
        setOpen={setModalOpen}
        setRefresh={setRefresh}
      />
      <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
      <Table tableData={tempTableData} />
    </div>
  );
};
export default Client;
