import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";

const Syndication = ({ tableData, setTableData, setConfirmButtonMessage }) => {
  const [showTab, setShowTab] = useState(false);

  const onSave = async () => {
    await API.graphql(
      graphqlOperation(`mutation {
        update_Opportunities_by_pk(pk_columns: {ItemId: "${tableData.ItemId}"}, _set: { 
          Syndication_Google: ${tableData.Syndication_Google},
          Syndication_Indeed: ${tableData.Syndication_Indeed},
          Syndication_LinkedIn: ${tableData.Syndication_LinkedIn}
        }) {
          ItemId
          Syndication_Google
          Syndication_Indeed
          Syndication_LinkedIn
        }
    }`)
    ).then(({ data }) => {
      setConfirmButtonMessage(true);
    });
  };

  return (
    <div
      className={`${!showTab ? "bg-white" : "bg-[#f5f5f5]"} p-6 rounded-lg mt-1
      border border-gray-200 shadow-md `}
    >
      {/* Header Section */}
      <div className="flex">
        <div className="grow">
          <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">
            Syndication
          </h4>
        </div>
        <div className="flex flex-none mr-5">
          <div className="flex flex-none">
            <div onClick={() => setShowTab(!showTab)}>
              {showTab ? (
                <ChevronUpIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Content Section */}
      <div
        className={`${
          showTab ? "block" : "hidden"
        } m-5 p-4 justify-center mt-6 text-sm bg-white rounded-lg border border-gray-200`}
      >
        <div className="m-auto justify-center text-center">
          <div className="flex gap-4 m-2 w-full space-around m-auto justify-center">
            <div className="font-medium w-[60px]">LinkedIn</div>
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                onChange={() => {
                  let temp = tableData;
                  temp.Syndication_LinkedIn =
                    temp.Syndication_LinkedIn == 0 ||
                    temp.Syndication_LinkedIn == null
                      ? 1
                      : 0;
                  setTableData({ ...temp });
                  onSave();
                }}
                type="checkbox"
                className="sr-only peer"
                checked={tableData.Syndication_LinkedIn ? 1 : 0}
                readOnly
              />
              <div
                className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
              ></div>
            </label>
          </div>
          <div className="flex gap-4 m-2 w-full space-around m-auto justify-center">
            <div className="font-medium w-[60px]">Indeed</div>
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                onChange={() => {
                  let temp = tableData;
                  temp.Syndication_Indeed =
                    temp.Syndication_Indeed == 0 ||
                    temp.Syndication_Indeed == "null"
                      ? 1
                      : 0;
                  setTableData({ ...temp });
                  onSave();
                }}
                type="checkbox"
                className="sr-only peer"
                checked={tableData.Syndication_Indeed ? 1 : 0}
                readOnly
              />
              <div
                className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
              ></div>
            </label>
          </div>
          <div className="flex gap-4 m-2 w-full space-around m-auto justify-center">
            <div className="font-medium w-[60px]">Google</div>
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                onChange={() => {
                  let temp = tableData;
                  temp.Syndication_Google =
                    temp.Syndication_Google == 0 ||
                    temp.Syndication_Google == "null"
                      ? 1
                      : 0;
                  setTableData({ ...temp });
                  onSave();
                }}
                type="checkbox"
                className="sr-only peer"
                checked={tableData.Syndication_Google ? 1 : 0}
                readOnly
              />
              <div
                className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-[#6fbe47]  
                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#6fbe47]"
              ></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Syndication;
