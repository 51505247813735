import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { XCircleIcon } from "@heroicons/react/outline";

const UpdateResourceModal = ({
  modalOpen,
  setModalOpen,
  tempData,
  setTempData,
}) => {
  const onSave = async () => {
    await API.graphql(
      graphqlOperation(`mutation {
      update_Documents_by_pk(pk_columns: {ItemId: "${tempData.ItemId}"}, 
        _set: 
          {
            ContractId: "${tempData.ContractId}"
            ClientId: "${tempData.ClientId}",
            MemberId: "${tempData.MemberId}",
            JobOrderId: "${tempData.JobOrderId}",
            CreatorId: "${tempData.CreatorId}",
            CreatorName: "${tempData.CreatorName}",
            Folder: "TECKpert",
            Type: "${tempData.Type}",
            Key: "${tempData.Key}",
            Link: "${tempData.Link}",
            Name: "${tempData.Name}"
          })
          {
            ItemId
            Name
          }
  }`)
    ).then(({ data }) => {
      setModalOpen(false);
    });
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[500px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start mb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Resource
                    </Dialog.Title>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Name = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Name}
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="title"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Type
                    </label>
                    <select
                      className="placeholder:text-gray-300 w-full mr-3 rounded-full justify-center rounded-md 
                      border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                      text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Type = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Type}
                    >
                      <option className="text-gray-300" value="">
                        Select Type
                      </option>
                      <option value="Onboarding">Onboarding</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 flex-grow">
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Link
                    </label>
                    <input
                      onChange={(e) => {
                        let temp = tempData;
                        temp.Link = e.target.value;
                        setTempData({ ...temp });
                      }}
                      value={tempData.Link}
                      type="text"
                      name="link"
                      id="link"
                      autoComplete="link"
                      className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                    />
                  </div>
                </div>

                <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent hover:opacity-90
                    teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300
                    bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default UpdateResourceModal;
