import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Modal from "react-modal";
import { LightningBoltIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/outline";
import { AILoader } from "./AILoader";
import ReactTooltip from "react-tooltip";
const AIMatches = ({
  applicantsData,
  opportunity,
}) => {
  const [showTab, setShowTab] = useState(false);
  const [matches, setMatches] = useState([]);

  const [allUsers, setAllUsers] = useState(0);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const async = async () => {
      const { data: { data: { aggregate: { count } } } } = await API.graphql({
        query: `{
          data: Members_aggregate {
            aggregate {
              count
            }
          }
        }`
      });
      setAllUsers(count);
    }
    async();
  }, []);

  useEffect(() => {
    if (opportunity?.TalentMatchResponse) {
      setMatches(JSON.parse(opportunity.TalentMatchResponse.matched_resumes));
    }
  }, [opportunity])

  const runMatch = async () => {
    setLoading('Processing job opportunity...');
    const { id: jobId } = (await (await fetch('https://api.quantumrecruit.io/auth/processJob?demo=teckpert', {
      method: 'POST',
      body: opportunity?.Description
    })).json());

    setLoading('Matching candidates...');
    const response = (await (await fetch('https://api.quantumrecruit.io/auth/processMatch?demo=teckpert', {
      method: 'POST',
      body: JSON.stringify({ id: jobId })
    })).json());
    await API.graphql({
      query: `mutation($response: jsonb) {
          update_Opportunities_by_pk(pk_columns: {ItemId: "${opportunity.ItemId}"}, _set: { 
            TalentMatchResponse: $response
          }) {
            ItemId
            Syndication_Google
            Syndication_Indeed
            Syndication_LinkedIn
          }
      }`,
      variables: {
        response: response
      }
    });
    setLoading(false);
    setModal(false);
    setMatches(JSON.parse(response?.matched_resumes));
    setShowTab(true);
  }

  return (
    <>
      <div>
        <div
          className={`${!showTab ? "bg-white" : "bg-[#f5f5f5]"
            } p-6 rounded - lg mt - 1 border border - gray - 200 shadow - md`}
        >
          {/* Header Section */}
          <div className="flex">
            <div className="grow">
              <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">
                AI Matches
              </h4>
            </div>
            <div className="grow">
              <span style={{ marginRight: 5, padding: '4px 8px', }}>RUN MATCH</span>
              <button onClick={() => { setModal('job'); }} style={{ marginRight: 10, padding: '4px 8px', border: '1px solid black' }}>JOB ({applicantsData?.length})</button>
              <button onClick={() => { setModal('db'); }} style={{ backgroundColor: '#6fbe47', padding: '4px 8px', color: '#fff', }}>DB ({allUsers})</button>
            </div>
            <div
              className="flex flex-none mr-5"
              onClick={() => setShowTab(!showTab)}
            >
              {!showTab ? (
                <LightningBoltIcon
                  className="h-7 w-7 cursor-pointer mt-1 text-yellow-300"
                  aria-hidden="true"
                />
              ) : (
                <LightningBoltIcon
                  className="h-7 w-7 cursor-pointer mt-1 text-yellow-300"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
          {/* Table Section */}
          {showTab ? (
            <div className="mx-5 flex flex-col mt-5">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full max-w-full w-auto overflow-x-auto divide-y divide-gray-300">
                      <thead>
                        <tr>
                          {[
                            "Name",
                            "Status",
                            "Match Score",
                            "Skills Match",
                            "Education Match",
                            "Experience Match",
                          ].map((header, i) => (
                            <th
                              key={i}
                              className={`${header === "Name" ? "text-left" : "text-center"
                                } py - 3.5 text - sm font - semibold text - gray - 900 sm: pl - 6`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {matches.map((match, i) => {
                          return (
                            <tr key={i}>
                              <td className="flex pl-6 whitespace-nowrap px-3 py-4 text-sm teckpert-text-green font-bold cursor-pointer">
                                {match?.name}
                                <div style={{ color: '#6fbe47', border: '1px solid #6fbe47', borderRadius: '100%', marginLeft: 5, width: 20, height: 20, textAlign: 'center', lineHeight: 1.2 }} data-tip data-for={match?.id}>?</div>
                                <ReactTooltip id={match?.id} place="top" effect="solid">
                                  <div style={{ width: 300, whiteSpace: 'break-spaces' }}>
                                    {match?.pros}
                                  </div>
                                </ReactTooltip>
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 capitalize text-center">
                                {match.Status}
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 capitalize text-center justify-center">
                                <div style={{ backgroundColor: '#6fbe47', padding: '2px 10px', borderRadius: 10, color: '#fff' }} >{match?.overall_match}</div>
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-center">
                                {match?.skills_match}
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-center">
                                {match?.education_match}
                              </td>
                              <td className="pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-center">
                                {match?.experience_match}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

      </div>

      <Modal isOpen={modal} style={{
        content: {
          zIndex: 10,
          height: "50%",
          overflowY: "auto",
          width: "500px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}>
        <div>
          <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-red-500"
              onClick={() => setModal(false)}
            >
              <span className="sr-only">Close</span>
              <XCircleIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <h2 className="font-bold mb-5">Run AI Match on {modal === 'job' ? 'job applicants' : 'all resumes'}</h2>
          <p>
            This AI Match will take {modal === 'job' ? applicantsData.length : allUsers} credits to process.
          </p>
          <p>
            This will take a few minutes to complete.
          </p>
          <p>
            Click "Continue" to proceed.
          </p>
          <button onClick={runMatch} style={{ marginTop: 25, padding: '5px 20px', backgroundColor: '#6fbe47' }}>Continue</button>
          {loading && <AILoader loading={loading} />}
        </div>
      </Modal>
    </>
  );
};
export default AIMatches;
