import { useState } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { Combobox } from "@headlessui/react";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ComboBox({
  comboBoxLabel,
  name,
  data,
  searchVal,
  searchVals,
  setSearchVal,
  setComboBoxLabel,
}) {
  const [query, setQuery] = useState("");
  const location = useLocation();

  const filteredData =
    query === ""
      ? data
      : data.filter((data) => {
          return data?.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div>
      <Combobox
        as="div"
        value={searchVals}
        onChange={(value) => {
          let temp = comboBoxLabel;
          if (location.pathname.includes("/jobportals")) {
            if (name === "Location") temp["location"].search = value;
            if (name === "Work Type") temp["worktype"].search = value;
          }
          if (location.pathname.includes("/people")) {
            if (name === "Location") temp["location"].search = value;
            if (name === "Status") temp["status"].search = value;
            if (name === "Job Title") temp["jobTitle"].search = value;
            if (name === "Opportunity") temp["opportunity"].search = value;
          }
          if (location.pathname.includes("/opportunities")) {
            if (name === "Assignee") temp["assignee"].search = value;
            if (name === "Work Type") temp["workType"].search = value;
            if (name === "Location") temp["location"].search = value;
            if (name === "Client") temp["client"].search = value;
          }
          setComboBoxLabel(temp);
          setSearchVal(value);
        }}
      >
        <Combobox.Button>
          <div className="relative mt-1 absolute">
            <Combobox.Input
              className="w-auto rounded-full justify-center rounded-md 
              border shadow-sm px-2.5 py-3 text-sm placeholder:text-gray-900 hover:placeholder:text-white
              hover:teckpert-bg-green hover:text-white cursor-pointer text-gray-900"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(item) => item}
              placeholder={name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-900"
                aria-hidden="true"
              />
            </div>
            {filteredData.length > 0 && (
              <Combobox.Options
                className="fixed z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg 
                ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {filteredData.map((obj, i) => (
                  <Combobox.Option
                    key={i}
                    value={obj}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-pointer select-none py-2 pl-3 pr-9 capitalize w-[250px] text-left overflow-x-hidden",
                        active ? "bg-[#6FBE47] text-white" : "text-gray-900"
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            "block truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {obj}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              "absolute inset-y-0 left-0 flex items-center pl-1.5",
                              active ? "text-white" : "text-[#6FBE47]"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox.Button>
      </Combobox>
      {searchVal !== "" && (
        <div className="relative right-7 bottom-9 cursor-pointer">
          <div
            onClick={() => {
              let temp = comboBoxLabel;

              if (location.pathname.includes("/people")) {
                temp["location"].search = "";
                temp["status"].search = "";
                temp["jobTitle"].search = "";
                temp["opportunity"].search = "";
              }
              if (location.pathname.includes("/opportunities")) {
                temp["assignee"].search = "";
                temp["workType"].search = "";
                temp["location"].search = "";
                temp["client"].search = "";
              }
              if (location.pathname.includes("/jobportals")) {
                temp["worktype"].search = "";
                temp["location"].search = "";
              }
              setComboBoxLabel(temp);
            }}
            className="w-8 text-gray-400 hover:text-red-400 float-right"
          >
            x
          </div>
        </div>
      )}
    </div>
  );
}
