import { useState } from "react";
import TableDetails from "../../../Timesheets/TableDetails";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const History = ({ timesheetData }) => {
  const [modalOpen, setModalOpen] = useState("");
  const [tableDataSingle, setTableDataSingle] = useState("");
  return (
    <div className="my-5 mx-8 p-6 bg-white rounded-lg border shadow-md hover:bg-gray-100">
      <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">
        Timesheet History
      </h4>
      {/* Table Section */}
      <div className="mx-5 flex flex-col  p-10">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8 overflow-x-auto">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                <thead className="bg-gray-100">
                  <tr>
                    {["Worker", "Period", "Hours", "Status", "Details"].map(
                      (obj, i) => (
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          {obj}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {timesheetData.map((obj) => (
                    <tr key={obj.ItemId}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Link to={`/people/${obj?.JobOrder?.Member?.ItemId}`}>
                          <div className="font-medium text-[#6FBE47]">
                            {obj?.JobOrder?.Member?.FirstName}&nbsp;
                            {obj?.JobOrder?.Member?.LastName}
                          </div>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {new Date(obj?.PeriodStart).toLocaleDateString(
                          "en-US",
                            { day: "numeric", month: "short", timeZone: "utc" }
                        )}
                        -
                        {new Date(obj?.PeriodEnd).toLocaleDateString("en-US", {
                            day: "numeric",month: "short",timeZone: "utc"
                        })}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {obj?.Hours?.aggregate?.sum?.Hours?.toFixed(2)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {obj.Status}
                      </td>
                      <td
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                        onClick={() => {
                          setTableDataSingle(obj);
                          setModalOpen(!modalOpen);
                        }}
                      >
                        <DocumentTextIcon className="w-7 h-7 teckpert-text-green cursor-pointer" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <TableDetails
          open={modalOpen}
          setOpen={setModalOpen}
          tableData={tableDataSingle}
        />
      )}
    </div>
  );
};

export default History;
