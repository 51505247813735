import React from "react";
import logo from "../../img/logo192.png";

export default function Loading() {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1,
        top: "calc(50% - 50px)",
        left: "calc(50% - 50px)",
        backgroundColor: "#00000033",
        borderRadius: 10,
        padding: 20,
      }}
    >
      <img
        alt="logo"
        src={logo}
        className="mx-auto p-3 bg-zinc-700"
        style={{
          borderRadius: 70,
          width: 70,
          height: 70,
          marginBottom: -75,
          marginTop: 10,
        }}
      />
      <div>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
