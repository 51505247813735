import logo from "../../img/teckpert-logo.png";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../utils/Loading";

const Set = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const emailParam = urlParams.get("username")?.replaceAll(' ', '+');
        const codeParam = urlParams.get("code");
        setEmail(emailParam);
        setCode(codeParam);
    }, [location]);

    const handleSignup = async (e) => {
        setError("");
        debugger;
        e.preventDefault();
        //error handling
        if (password.trim().length === 0) {
            setError("Your password cannot be blank");
            return;
        }
        else if (password !== confirmPassword) {
            setError("Your passwords do not match");
        }
        else if (password.trim().length < 6) {
            setError("Your password must be at least 6 characters long");
        }
        setLoading(true)//put login as a true when user  is authticated
        //username: email,
        //    password: password,
       // const user = await Auth.signIn(email, code);
        //const user = await Auth.signIn({
        //    username: email,
        //    code: code,
        //});
        //const data = await Auth.forgotPasswordSubmit(email, code, password);
        //console.log(data);
        try {
            const data = await Auth.forgotPasswordSubmit(email, code, confirmPassword);
            console.log(data);
            if (data === "SUCCESS") {
                const user = await Auth.signIn(email, confirmPassword);
                console.log(user);
                const payload = user.signInUserSession.idToken.payload;
                localStorage.setItem("userType", payload["custom:userType"]);
                localStorage.setItem("name", `${payload["custom:firstName"]} ${payload["custom:lastName"]}`);
                localStorage.setItem("location", payload["custom:location"]);
                localStorage.setItem("phone", payload["custom:phone"]);
                localStorage.setItem("resume", payload["custom:resume"]);
                localStorage.setItem("user.attributes", JSON.stringify(payload));
                navigate('/');
            }
        } catch (err) {
            setLoading(false)//put login as a true when user  is authticated
            console.log(err);
            setError("Error" + err);
        }
       // console.log(user);
      //  await Auth.completeNewPassword(user, password);
      
      
    };
    if (loading) return <Loading />
    return (
        <div className="h-screen">
            <div className="p-3 bg-zinc-700 h-1/5 max-h-24 w-full relative top-0">
                <img src={logo} className="mx-auto h-full py-3" />
            </div>
            <div className="h-[73%]">
                <div>
                    {/* Login Header */}
                    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-5/6">
                        <div>
                            <h2 className="mt-1 text-center text-3xl tracking-tight font-bold teckpert-text-green">
                                Set your new password
                            </h2>
                        </div>

                        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
                            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                <form className="space-y-6" action="#" method="POST">
                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                                autoComplete="current-confirm"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            onClick={(e) => handleSignup(e)}
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white teckpert-bg-green focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        >
                                            Set password and log in
                                        </button>
                                    </div>
                                    {error.length > 0 && (
                                        <div className="rounded-md bg-red-50 p-4">
                                            <div className="flex">
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-medium text-red-800">
                                                        Your set password attempt has failed
                                                    </h3>
                                                    <div className="mt-2 text-sm text-red-700">
                                                        <div>{error}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-5 bg-zinc-700 h-1/6 max-h-60 w-full relative bottom-0">
                <div className="text-2xl text-neutral-100 text-center p-3">
                    Need to speak with someone?
                </div>
                <div className="text-lg text-neutral-100 text-center teckpert-text-green">
                    Get in touch with us
                </div>
            </div>
        </div>
    );
};

export default Set;
