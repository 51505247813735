import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { formatDate } from "../../../../utils/FormatDate";
import UpdateOpportunitiesModal from "../Modals/UpdateOpportunitiesModal";

const Details = ({
  tableData,
  setTableData,
  client,
  contract,
  user,
  questionnaire,
  guide,
  workflow,
  setConfirmButtonMessage,
}) => {
  const [tempData, setTempData] = useState([]);
  const [showTab, setShowTab] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setTempData(tableData);
  }, [tableData]);

  return (
    <div
      className={`${!showTab ? "bg-white" : "bg-[#f5f5f5]"} p-6 rounded-lg mt-1
      border border-gray-200 shadow-md`}
    >
      {/* Header Section */}
      <div className="flex">
        <div className="grow">
          <h4 className="text-lg mt-0 font-bold text-gray-800 ml-5">Details</h4>
        </div>
        <div className="flex flex-none mr-5">
          <div className="flex flex-none">
            <PencilIcon
              onClick={() => setModalOpen(!modalOpen)}
              className="h-5 w-5 cursor-pointer mr-3 text-gray-500"
              aria-hidden="true"
            />
            <UpdateOpportunitiesModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              tableData={tableData}
              setTableData={setTableData}
              tempData={tempData}
              setTempData={setTempData}
              client={client}
              contract={contract}
              questionnaire={questionnaire}
              guide={guide}
              workflow={workflow}
              user={user}
              setConfirmButtonMessage={setConfirmButtonMessage}
            />
            <div onClick={() => setShowTab(!showTab)}>
              {showTab ? (
                <ChevronUpIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="h-5 w-5 cursor-pointer mt-1"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Content Section */}
      <div>
        {showTab ? (
          <div>
            <div className="p-4 justify-center mt-6 text-sm bg-white rounded-lg mt-2 border border-gray-200">
              <div className="px-3">
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Location</div>
                  <div className="py-1">
                    {tableData.Location ? tableData.Location : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Type</div>
                  <div className="py-1">
                    {tableData.Type ? tableData.Type : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Client</div>
                  <div className="py-1">
                    {client[
                      client.findIndex((x) => x.ItemId === tableData.ClientId)
                    ]?.Name
                      ? client[
                          client.findIndex(
                            (x) => x.ItemId === tableData.ClientId
                          )
                        ]?.Name
                      : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Contract</div>
                  <div className="py-1">
                    {contract[
                      contract.findIndex((x) => x.ItemId === tableData.Contract)
                    ]?.Name
                      ? contract[
                          contract.findIndex(
                            (x) => x.ItemId === tableData.Contract
                          )
                        ]?.Name
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Department
                  </div>
                  <div className="py-1">
                    {tableData.Department && tableData.Department != null
                      ? tableData.Department
                      : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Recruiter</div>
                  <div className="py-1">
                    {user[user.findIndex((x) => x.ItemId === tableData.Support)]
                      ?.FirstName != undefined
                      ? user[
                          user.findIndex((x) => x.ItemId === tableData.Support)
                        ]?.FirstName
                      : "-"}
                    &nbsp;
                    {user[user.findIndex((x) => x.ItemId === tableData.Support)]
                      ?.LastName != undefined
                      ? user[
                          user.findIndex((x) => x.ItemId === tableData.Support)
                        ]?.LastName
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 justify-center mt-6 text-sm bg-white rounded-lg mt-2 border border-gray-200">
              <div className="px-3">
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Requisition #
                  </div>
                  <div className="py-1">
                    {tableData.RequisitionNum ? tableData.RequisitionNum : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    RFQ, Job Number or Work Order
                  </div>
                  <div className="py-1">
                    {tableData.RFQNum
                      ? tableData.RFQNum
                      : tableData.JobNum
                      ? tableData.JobNum
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Doc or Ref #
                  </div>
                  <div className="py-1">
                    {tableData.DocRefNum ? tableData.DocRefNum : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Pay Rate Up To
                  </div>
                  <div className="py-1">
                    ${tableData.HighEndRate ? tableData.HighEndRate : "0"}
                    &nbsp;/&nbsp;
                    {tableData.PayRateCompensationUnit &&
                    tableData.PayRateCompensationUnit != null
                      ? tableData.PayRateCompensationUnit
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Low End Rate Starting
                  </div>
                  <div className="py-1">
                    ${tableData.LowEndRate ? tableData.LowEndRate : "0"}
                    &nbsp;/&nbsp;
                    {tableData.PayRateCompensationUnit &&
                    tableData.PayRateCompensationUnit != null
                      ? tableData.PayRateCompensationUnit
                      : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Bill Rate for Contract
                  </div>
                  <div className="py-1">
                    $
                    {tableData.BillRateContract &&
                    tableData.BillRateContract != null
                      ? tableData.BillRateContract
                      : "0"}
                    &nbsp;/&nbsp;
                    {tableData.BillRateCompensationUnit &&
                    tableData.BillRateCompensationUnit != null
                      ? tableData.BillRateCompensationUnit
                      : "-"}
                  </div>
                </div>

                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Hours per Week
                  </div>
                  <div className="py-1">
                    {tableData.HoursPerWeek ? tableData.HoursPerWeek : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Contract Length
                  </div>
                  <div className="py-1">
                    {tableData.ContractLength
                      ? tableData.ContractLength + " " + tableData.ContractTerm
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Total Positions Open
                  </div>
                  <div className="py-1">
                    {tableData.TotalPositionsOpen
                      ? tableData.TotalPositionsOpen
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Total Candidates to Submit
                  </div>
                  <div className="py-1">
                    {tableData.TotalCandidatesToSubmit
                      ? tableData.TotalCandidatesToSubmit
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Recruiting Cut Off Date
                  </div>
                  <div className="py-1">
                    {tableData.CutOffDate
                      ? formatDate(tableData.CutOffDate)
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Due Date</div>
                  <div className="py-1">
                    {tableData.DueDate ? formatDate(tableData.DueDate) : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Support Role
                  </div>
                  <div className="py-1">
                    {user[user.findIndex((x) => x.ItemId === tableData.Support)]
                      ?.FirstName != undefined
                      ? user[
                          user.findIndex((x) => x.ItemId === tableData.Support)
                        ]?.FirstName
                      : "-"}
                    &nbsp;
                    {user[user.findIndex((x) => x.ItemId === tableData.Support)]
                      ?.LastName != undefined
                      ? user[
                          user.findIndex((x) => x.ItemId === tableData.Support)
                        ]?.LastName
                      : ""}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Work Type</div>
                  <div className="py-1">
                    {tableData.WorkType && tableData.WorkType != null
                      ? tableData.WorkType
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Questionnaire Form
                  </div>
                  <div className="py-1">
                    {questionnaire[
                      questionnaire.findIndex(
                        (x) => x.ItemId === tableData.QuestionnaireFormId
                      )
                    ]?.Title
                      ? questionnaire[
                          questionnaire.findIndex(
                            (x) => x.ItemId === tableData.QuestionnaireFormId
                          )
                        ].Title
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">
                    Interview Guide
                  </div>
                  <div className="py-1">
                    {guide[
                      guide.findIndex(
                        (x) => x.ItemId === tableData.InterviewGuideId
                      )
                    ]?.Title
                      ? guide[
                          guide.findIndex(
                            (x) => x.ItemId === tableData.InterviewGuideId
                          )
                        ].Title
                      : "-"}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-2">
                  <div className="text-gray-400 py-1 text-right">Workflow</div>
                  <div className="py-1">
                    {workflow[
                      workflow.findIndex(
                        (x) => x.ItemId === tableData.WorkflowId
                      )
                    ]?.Title
                      ? workflow[
                          workflow.findIndex(
                            (x) => x.ItemId === tableData.WorkflowId
                          )
                        ].Title
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default Details;
