import { useEffect, useState } from "react";
import classes from "./AILoader.module.css";

export const AILoader = ({ loading }) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        setTimeout(() => { setSeconds(seconds + 1) }, 1000);
    }, [seconds])
    return (
        <div className={classes.container}>
            <div className={classes.modal}>
                <div className={classes.loader}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className={classes.message}>{loading}</div>
                <div className={classes.message}> ({seconds} sec)</div>
            </div>
        </div>
    );
}