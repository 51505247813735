/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MissingFieldPrompt from "../../utils/MissingFieldPrompt";
import { formatDate } from "../../utils/FormatDate";

export default function CreateContractModal({
  open,
  setOpen,
  tableData,
    setTableData,
    updateTableData,
    setUpdateTableData,
}) {
  const [tempData, setTempData] = useState({
    ClientId: "",
    PrimaryContact: "",
    Status: "",
    Details: "",
    Name: "",
    StartDate: new Date(),
    EndDate: new Date(),
    Hours: "",
    Type: "",
  });
  const [client, setClient] = useState([]);
  const [contact, setContact] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    API.graphql({
      query: `{
      Clients(offset: 0, limit: 25, order_by: {DateCreated: asc}) {
        ItemId
        Name   
      }
    }      
  `,
    }).then(({ data }) => {
      setClient(data.Clients);
    });

    API.graphql({
      query: `{
        Contacts(offset: 0, limit: 25, order_by: {DateCreated: asc}) {
          ItemId
          ClientId
          FirstName
          LastName 
        }
      }      
    `,
    }).then(({ data }) => {
      setContact(data.Contacts);
    });
  }, []);

    const setUpdateDataLoading = async () => {
        await setUpdateTableData(true)
    }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="lg:w-[700px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl 
              transition-all sm:my-8 sm:p-6"
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Contract
                    </Dialog.Title>
                  </div>
                </div>
                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-8">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) => {
                                let temp = tempData;
                                temp.Name = e.target.value;
                                 setTempData({ ...temp });
                                }}
                               value={tempData.Name}
                               maxLength={41}
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Status
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.Status = e.target.value;
                              setTempData({ ...temp });
                            }}
                          >
                            <option className="text-gray-300" value="">
                              Select
                            </option>
                            <option value="Draft">Draft</option>
                            <option value="Building">Building</option>
                            <option value="In Progress">In progress</option>
                            <option value="Paused">Paused</option>
                            <option value="Ended">Ended</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Client Name
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.ClientId = e.target.value;
                              setTempData({ ...temp });
                            }}
                          >
                            <option className="text-gray-300" value="">
                              Select
                            </option>
                            {client
                              ?.sort((a, b) => a.Name.localeCompare(b.Name))
                              .map((obj) => (
                                <option key={obj.ItemId} value={obj.ItemId}>
                                  {obj.Name}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Type
                          </label>
                          <select
                            className="z-2 w-full mr-3 rounded-full justify-center rounded-md cursor-pointer
                            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                            text-gray-900 hover:teckpert-bg-green hover:text-white placeholder:text-gray-300"
                            onChange={(e) => {
                              let temp = tempData;
                              temp.Type = e.target.value;
                              setTempData({ ...temp });
                            }}
                          >
                            <option className="text-gray-300" value="">
                              Select
                            </option>
                            <option value="Fixed Project">Fixed Project</option>
                            <option value="Augmentation">Augmentation</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            Start Date
                          </label>
                          <div style={{ zIndex: 2000 }}>
                            <DatePicker
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white"
                              selected={tempData.StartDate}
                              onChange={(date) => {
                                let temp = tempData;
                                temp.StartDate = date;
                                setTempData({ ...temp });
                              }}
                              fixedHeight
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                            End Date
                          </label>
                          <div>
                            <DatePicker
                              className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                              border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                              text-gray-900 hover:teckpert-bg-green hover:text-white"
                              selected={tempData.EndDate}
                              onChange={(date) => {
                                let temp = tempData;
                                temp.EndDate = date;
                                setTempData({ ...temp });
                              }}
                              fixedHeight
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="col-span-1 my-auto block text-sm font-medium text-gray-700">
                          Details
                        </label>
                        <textarea
                          onChange={(e) => {
                            let temp = tempData;
                            temp.Details = e.target.value;
                            setTempData({ ...temp });
                          }}
                          rows={8}
                          name="comment"
                          id="comment"
                          className="mt-1 w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                          border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                          defaultValue={""}
                        />
                      </div>
                      {error.length > 0 && MissingFieldPrompt(error)}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={async () => {
                            let errorArray = [];
                            if (tempData.Name?.trim().length === 0)
                              errorArray.push("Contract Name");
                            if (tempData.Status?.trim().length === 0)
                              errorArray.push("Status");
                            if (tempData.ClientId?.trim().length === 0)
                              errorArray.push("Client");
                            if (tempData.Details?.trim().length === 0)
                              errorArray.push("Details");
                            if (tempData.Type?.trim().length === 0)
                              errorArray.push("Type");

                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }
                            const detailsString = JSON.stringify(tempData.Details);
                            await API.graphql({
                              query: `mutation {
                                insert_Contracts_one(
                                  object: 
                                  {
                                    ClientId: "${tempData.ClientId}",
                                    Status: "${tempData.Status}",
                                    Details: ${detailsString},
                                    Name: "${tempData.Name}",
                                    EndDate: "${formatDate(tempData.EndDate)}",
                                    StartDate:"${formatDate(tempData.StartDate)}",
                                    PrimaryContact: "${tempData.PrimaryContact}",
                                    CreatorName: "${tempData.CreatorName}",
                                    Type: "${tempData.Type}"
                                  })
                                  {
                                    ItemId
                                    Name
                                  }         
                            }`,
                            }).then(({ data }) => {
                              setTableData([...tableData, tempData]);
                            });

                            setTempData({
                              ClientId: "",
                              Status: "",
                              Details: "",
                              Name: "",
                              StartDate: new Date(),
                              EndDate: new Date(),
                              Hours: "",
                              Type: "",
                              CreatorName: localStorage.getItem("name"),
                            });
                              setUpdateDataLoading();
                              setOpen(false);
                               
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border 
                          border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 
                          shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 
                          focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setError([]);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
