import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import StatsDisplay from "./StatsDisplay";
import Table from "./Table";
import BasicSearchBar from "../../utils/BasicSearchbar";
import Loading from "../../utils/Loading";

const Opportunities = ({ userData, setUserData }) => {
  const itemsPerPage = 25;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tempTable, setTempTable] = useState(tableData);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterSearch, setFilterSearch] = useState([]);
  const [dropdownSearch, setDropdownSearch] = useState({
    Location: null,
    Recruiter: null,
    Client: null,
  });
  const [searchVal, setSearchVal] = useState("");
  const [client, setClient] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [memberTotal, setMemberTotal] = useState(1);
  const [allOpportunitiesCalc, setAllOpportunitiesCalc] = useState([]);
  const [myOpportunitiesCalc, setMyOpportunitiesCalc] = useState([]);
  const [comboBoxLabel, setComboBoxLabel] = useState({
    Recruiter: {
      name: "Recruiter",
      data: [],
      search: "",
    },
    Location: {
      name: "Location",
      data: [],
      search: "",
    },
    Client: {
      name: "Client",
      data: [],
      search: "",
    },
  });

  // SET COMBOBOX FILTERS
  useEffect(() => {
    let tempComboBoxLabel = comboBoxLabel;
    comboBoxLabel["Recruiter"].data = users
      .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
      ?.map((x) => ({ name: x.FirstName + " " + x.LastName, id: x.ItemId }));
    comboBoxLabel["Location"].data = tableData
      .sort((a, b) => a.Location.localeCompare(b.Location))
      ?.map((x) => x.Location)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    comboBoxLabel["Client"].data = client
      .sort((a, b) => a.Name.localeCompare(b.Name))
      ?.map((x) => x.Name)
      .filter((val, id, array) => array.indexOf(val) == id)
      .filter((item) => (item != "") & (item != null));
    setComboBoxLabel(tempComboBoxLabel);
  }, [tableData, client, users]);

  useEffect(() => {
    // In order to Sort By Total or New Applicants, use the following query:
    //  Opportunities(offset: 0, limit: 5, order_by: {NewApplicants_aggregate: {count: desc}}) {
    setLoading(true);
    const sharedWhere = `{
      ${searchVal ? `Title: {_ilike: "${searchVal}%"},` : ""}
      ${
        filterSearch?.length
          ? `Status: {_in: [${filterSearch
              .map((obj) => `"${obj}"`)
              .join(",")}]}`
          : ""
      }
      ${
        dropdownSearch.Location
          ? `Location: {_eq: "${dropdownSearch.Location}"},`
          : ""
      }
      ${
        dropdownSearch.Recruiter
          ? `Support: {_eq: "${dropdownSearch.Recruiter}"}`
          : ""
      }
      ${
        dropdownSearch.Client
          ? `Client: {Name: {_eq: "${dropdownSearch.Client}"}},`
          : ""
      }
      ItemId: {_is_null: false}
    }`;
    API.graphql({
      query: `{
      Opportunities(offset: ${
        (page - 1) * itemsPerPage
      }, limit: ${itemsPerPage}, order_by: {DateCreated: asc}, where: ${sharedWhere}) {
        ItemId
        ClientId
        Applicants
        Assignee
        BillRateCompensationUnit
        BillRateContract
        Compensation
        Contract
        ContractLength
        ContractTerm
        CutOffDate
        DateCreated
        DateUpdated
        Department
        Description
        DocRefNum
        DueDate
        HighEndRate
        Hours
        HoursPerWeek
        HoursType
        JobNum
        Length
        Location
        LowEndRate
        NonApplicants
        PayRateCompensationUnit
        QuestionnaireFormId
        InterviewGuideId
        RFQNum
        RecruiterId
        Requirements
        RequisitionNum
        Responsibilities
        SendEmail
        Specializations
        StartDate
        Status
        Support
        Syndication_Google
        Syndication_Indeed
        Syndication_LinkedIn
        Title
        TotalCandidatesToSubmit
        TotalPositionsOpen
        Type
        WorkOrder
        WorkType
        Client {
          Name
        }
        Contracts {
          Name
        } 
        ApplicationQuestionnaire {
          Title
        }
        Documents {
          ItemId
          Name
          Type
          DateCreated
          Key
        }
        TotalApplicants_aggregate {
          aggregate {
            count
          }
        }
        NewApplicants_aggregate {
          aggregate {
            count
          }
        }
        ActiveApplicants: TotalApplicants_aggregate(where: {WorkFlowStatus: {_nin: ["New", "Not Qualified - Knockout", "Not Qualified", "Not Hired"]}}) {
          aggregate {
            count
          }
        }
      }
      Opportunities_aggregate(where: ${sharedWhere}) {
        aggregate {
          count
        }
      }
    }      
  `,
    }).then(({ data }) => {
      setTableData(data.Opportunities);
      setTempTable(data.Opportunities);
      setMemberTotal(data.Opportunities_aggregate.aggregate.count);
      setAllOpportunitiesCalc(1);
      setMyOpportunitiesCalc(2);
      setLoading(false);
    });
    // console.log("filterSearch", filterSearch)
    // console.log("sharedWhere", sharedWhere);
    // console.log("opportunities", tableData);
  }, [page, location.pathname, searchVal, filterSearch, dropdownSearch]);

  useEffect(() => {
    setFilterSearch(userData.TableFilters?.opportunities);
  }, [userData]);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
          ItemId
          FirstName
          LastName
        }
        Clients(order_by: {Name: asc}) {
          ItemId
          Name
        }
      }      
    `,
    }).then(({ data }) => {
      setUsers(data.Users); //Setting 'Users' for Recruiter dropdown filter
      setClient(data.Clients); //Setting 'Clients' for Client dropdown filter
    });
  }, []);

  return (
    <div>
      {/* Stats and Modal to create Opportunities */}
      <StatsDisplay
        tableData={tableData}
        setTableData={setTableData}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        allOpportunitiesCalc={allOpportunitiesCalc}
        myOpportunitiesCalc={myOpportunitiesCalc}
        client={client}
      />
      {/* Search Bar */}
      <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
      {/* Paginated table with 'Dropdown Filters' */}
      <Table
        userData={userData}
        setUserData={setUserData}
        tableData={tableData}
        tempTable={tempTable}
        setTempTable={setTempTable}
        filterSearch={filterSearch}
        setFilterSearch={setFilterSearch}
        dropdownSearch={dropdownSearch}
        setDropdownSearch={setDropdownSearch}
        comboBoxLabel={comboBoxLabel}
        setComboBoxLabel={setComboBoxLabel}
        client={client}
        users={users}
        page={page}
        setPage={setPage}
        memberTotal={memberTotal}
        itemsPerPage={itemsPerPage}
      />
      {loading ? <Loading /> : null}
    </div>
  );
};
export default Opportunities;
