import { useState } from "react";
import { API } from "aws-amplify";
import { XCircleIcon, PencilIcon } from "@heroicons/react/outline";
import UpdateUserModal from "./UpdateUserModal";
import ConfirmDeleteModal from "../../utils/ConfirmDeleteModal";

const Table = ({
  tableData,
  setTableData,
  tempData,
  setTempData,
  page,
  setPage,
  itemsPerPage,
  memberTotal,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [deleteUser, setDeleteUser] = useState([]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {["Name", "User Type", "Email", "", ""].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tempData
                    ?.sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                    ?.map((person) => (
                      <tr key={person.ItemId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center font-medium teckpert-text-green">
                            {person.FirstName + " " + person.LastName}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pl-4 pr-3 py-4 text-sm text-gray-900 capitalize">
                          {person.UserType ? person.UserType : "-"}
                        </td>
                        <td className="whitespace-nowrap pl-4 pr-3 py-4 text-sm text-gray-900">
                          {person.Email ? person.Email : "-"}
                        </td>
                        <td className="flex-none w-[50px] py-4 text-sm">
                          <PencilIcon
                            onClick={() => {
                              setEditModalOpen(!editModalOpen);
                              setSelectedUser(person);
                            }}
                            className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                            aria-hidden="true"
                          />
                          {selectedUser && (
                            <UpdateUserModal
                              modalOpen={editModalOpen}
                              setModalOpen={setEditModalOpen}
                              tempData={selectedUser}
                              setTempData={setSelectedUser}
                              tableData={tempData}
                              setTableData={setTempData}
                              originalData={tableData}
                              setOriginalData={setTableData}
                            />
                          )}
                        </td>
                        <td className="flex-none w-[50px] py-4 text-sm">
                          <XCircleIcon
                            onClick={() => {
                              setDeleteModalOpen(!deleteModalOpen);
                              setDeleteUser(person);
                            }}
                            className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                            aria-hidden="true"
                          />
                          <ConfirmDeleteModal
                            open={deleteModalOpen}
                            setOpen={setDeleteModalOpen}
                            deleteItem={async () => {
                              if (deleteUser) {
                                const response = await API.del(
                                  "auth",
                                  "/adminModifyUser",
                                  {
                                    queryStringParameters: {
                                      email: deleteUser.Email,
                                    },
                                  }
                                );
                                if (response) {
                                  let updateTable = tableData.filter(
                                    (obj) => obj.ItemId !== deleteUser.ItemId
                                  );
                                  setTableData(updateTable);
                                  setTempData(updateTable);
                                }
                              }
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-5 pb-8">
        <nav aria-label="Page navigation">
          <ul className="flex list-style-none">
            <li
              className="page-item"
              //decrement pagination by one when clicking arrow
              onClick={() => {
                if (page === 1) return;
                setPage(page - 1);
              }}
            >
              <a
                className="page-link relative block py-1.5 px-3 rounded-full border-0 bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 focus:shadow-none"
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {memberTotal &&
              itemsPerPage &&
              [...Array(Math.ceil(memberTotal / itemsPerPage))]?.map(
                (_, tempPage) => {
                  //only displays pagination within a certain range
                  if (page - tempPage + 1 >= 0 && page < tempPage + 6)
                    return (
                      <li
                        className="page-item"
                        onClick={() => setPage(tempPage + 1)}
                        key={tempPage + 1}
                      >
                        <a
                          className={`rounded page-link relative block py-1.5 px-3 bg-transparent outline-none transition-all duration-300 rounded 
                          text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${
                            tempPage + 1 === page &&
                            "font-bold border-solid border-2 border-[#6FBE47]"
                          }`}
                          href="#"
                        >
                          {tempPage + 1}
                        </a>
                      </li>
                    );
                }
              )}
            <li
              className="page-item"
              onClick={() => {
                //increment pagination by one when clicking arrow
                if (page === Math.ceil(memberTotal / itemsPerPage)) return;
                setPage(page + 1);
              }}
            >
              <a
                className="page-link relative block py-1.5 px-3 rounded-full border-0 bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Table;
