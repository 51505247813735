import { formatDate } from "../../utils/FormatDate";
import { XCircleIcon } from "@heroicons/react/outline";
import { API } from "aws-amplify";

import ConfirmDeleteModal from "../../utils/ConfirmDeleteModal";
import { useState } from "react";

const Table = ({
  tableData,
  setTableData,
  page,
  setPage,
  memberTotal,
  itemsPerPage,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");

  const deleteItem = (id) => {
    API.del("auth", `/invoices?ItemId=${id}`).then((responseJson) => {
      setTableData(tableData.filter((obj) => obj.ItemId !== id));
    });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteItem={() => deleteItem(deleteItemId)}
      />
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Invoice #
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Client
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Contract
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Amount
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData.map((invoice) => (
                    <tr key={invoice.ItemId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <td>
                          <div className="form-check flex">
                            <input
                              className="form-check-input appearance-none h-4 w-4 border 
                              border-gray-300 rounded-sm bg-white checked:bg-[#6FBE47]
                              checked:border-[#6FBE47] focus:outline-none transition 
                              duration-200 mt-1 align-top bg-no-repeat bg-center 
                              bg-contain ml-4 cursor-pointer"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <a
                              className="font-medium text-[#6FBE47] pl-5"
                              href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.QuickbooksLink}`}
                              target="_blank"
                            >
                              {invoice.QuickbooksLink}
                            </a>
                          </div>
                        </td>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {invoice?.Contract?.Clients?.Name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {invoice?.Contract?.Name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDate(invoice.DateCreated)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(invoice.Amount)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {invoice.Status}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            setDeleteItemId(invoice.ItemId);
                            setDeleteModalOpen(true);
                          }}
                        >
                          <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-5 pb-8">
        <nav aria-label="Page navigation">
          <ul className="flex list-style-none">
            <li
              className="page-item"
              //decrement pagination by one when clicking arrow
              onClick={() => {
                if (page === 1) return;
                setPage(page - 1);
              }}
            >
              <a
                className="page-link relative block py-1.5 px-3 rounded-full border-0 
                bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 focus:shadow-none"
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {memberTotal &&
              itemsPerPage &&
              [...Array(Math.ceil(memberTotal / itemsPerPage))]?.map(
                (_, pageNumber) => {
                  //only displays pagination within a certain range
                  if (page - pageNumber + 1 >= 0 && page < pageNumber + 6)
                    return (
                      <li
                        className="page-item"
                        onClick={() => setPage(pageNumber + 1)}
                        key={pageNumber + 1}
                      >
                        <a
                          className={`rounded-full page-link relative block py-1.5 px-3
                          bg-transparent outline-none transition-all duration-300 rounded 
                          text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${
                            pageNumber + 1 === page && "font-bold"
                          }`}
                          // border-solid border-2 border-[#6FBE47]
                          href="#"
                        >
                          {pageNumber + 1}
                        </a>
                      </li>
                    );
                }
              )}
            <li
              className="page-item"
              onClick={() => {
                //increment pagination by one when clicking arrow
                if (page === Math.ceil(memberTotal / itemsPerPage)) return;
                setPage(page + 1);
              }}
            >
              <a
                className="page-link relative block py-1.5 px-3 rounded-full border-0 
                bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Table;
