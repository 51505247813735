/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import {
  XCircleIcon,
  FlagIcon,
  MenuIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import MissingFieldPrompt from "../../../../utils/MissingFieldPrompt";

export default function EditQuestionsModal({
  open,
  setOpen,
  questionsData,
  setQuestionsData,
  selectedJob,
  setSelectedJob,
  editModalOpen,
  setEditModalOpen,
}) {
  const location = useLocation();
  const dragItemQs = useRef();
  const dragOverItemQs = useRef();
  const dragItemOps = useRef();
  const dragOverItemOps = useRef();
  const menuDragItem = useRef();

  const [error, setError] = useState([]);
  const [hidden, setHidden] = useState("");

  useEffect(() => {
    setError([]);
  }, [selectedJob]);

  useEffect(() => {
    const handleClick = (event) => {};

    if (menuDragItem.current) {
      const element = menuDragItem.current;

      element.addEventListener("click", handleClick);

      return () => {
        element.removeEventListener("click", handleClick);
      };
    }
  }, [menuDragItem.current]);

  const dragStartQs = (e, position) => {
    dragItemQs.current = position;

    menuDragItem.current.style.backgroundColor = "#e5e7eb";
    menuDragItem.current.style.color = "gray";
    menuDragItem.current.style.border = "1px #00008B";
    menuDragItem.current.style.borderStyle = "dashed";
    menuDragItem.current.style.borderRadius = "6px";
  };

  const dragEnterQs = (e, position) => {
    dragOverItemQs.current = position;
  };

  const dropQs = async (e) => {
    menuDragItem.current.style.backgroundColor = "white";
    menuDragItem.current.style.color = "gray";
    menuDragItem.current.style.border = "none";

    const copyListItems = selectedJob.Questions.map((obj) => obj.Order);
    const dragItemContent = copyListItems[dragItemQs.current];
    copyListItems.splice(dragItemQs.current, 1);
    copyListItems.splice(dragOverItemQs.current, 0, dragItemContent);
    dragItemQs.current = null;
    dragOverItemQs.current = null;

    let newTempList = selectedJob;
    selectedJob.Questions.map((obj, i) => (obj.Order = copyListItems[i]));
    if (newTempList.ItemId)
      await API.graphql(
        graphqlOperation(`mutation {
          update_ApplicationQuestionnaire_by_pk(pk_columns: {ItemId: "${
            newTempList.ItemId
          }"}, 
            _set: 
              {
                Questions: [${newTempList.Questions?.map(
                  (obj) =>
                    `{
                      Question: "${obj.Question}",
                      Required: ${obj.Required},
                      AnswerType: "${obj.AnswerType}",
                      Order: ${obj.Order},
                      AnswerOptions: [
                        ${obj.AnswerOptions?.map(
                          (item) => `{
                            Option: "${item.Option}",
                            Knockout: ${item.Knockout},
                            Order: ${item.Order},
                          }`
                        ).join(`,`)}
                      ]}`
                ).join(`,`)}]   
              })
              {
                ItemId
                Questions
              }
      }`)
      ).then(({ data }) => {
        setSelectedJob({ ...newTempList });
      });
  };

  const dragStartOps = (e, position) => {
    dragItemOps.current = position;
    menuDragItem.current.style.backgroundColor = "#e5e7eb";
    menuDragItem.current.style.color = "gray";
    menuDragItem.current.style.border = "1px #00008B";
    menuDragItem.current.style.borderStyle = "dashed";
    menuDragItem.current.style.borderRadius = "6px";
  };

  const dragEnterOps = (e, position) => {
    dragOverItemOps.current = position;
  };

  const dropOps = async (e, option, question) => {
    menuDragItem.current.style.backgroundColor = "white";
    menuDragItem.current.style.color = "gray";
    menuDragItem.current.style.border = "none";

    const copyListItems = question.AnswerOptions.map((obj, i) => obj.Order);
    const dragItemContent = copyListItems[dragItemOps.current];
    copyListItems.splice(dragItemOps.current, 1);
    copyListItems.splice(dragOverItemOps.current, 0, dragItemContent);
    dragItemOps.current = null;
    dragOverItemOps.current = null;

    let newTempList = selectedJob;
    question.AnswerOptions.map((obj, i) => (obj.Order = copyListItems[i]));
    if (newTempList.ItemId)
      await API.graphql(
        graphqlOperation(`mutation {
        update_ApplicationQuestionnaire_by_pk(pk_columns: {ItemId: "${
          newTempList.ItemId
        }"}, 
          _set: 
            {
              Questions: [${newTempList.Questions?.map(
                (obj) =>
                  `{
                    Question: "${obj.Question}",
                    Required: ${obj.Required},
                    AnswerType: "${obj.AnswerType}",
                    Order: ${obj.Order},
                    AnswerOptions: [
                      ${obj.AnswerOptions?.map(
                        (item) => `{
                          Option: "${item.Option}",
                          Knockout: ${item.Knockout},
                          Order: ${item.Order},
                        }`
                      ).join(`,`)}
                    ]}`
              ).join(`,`)}]
            })
            {
              ItemId
              Questions
            }
      }`)
      ).then(({ data }) => {
        setSelectedJob({ ...newTempList });
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setError([]);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-x-hidden lg:w-[700px] sm:w-[500px] min-h-[500px] max-h-[850px] relative transform overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setError([]);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900 mb-1"
                    >
                      {selectedJob.Title}
                    </Dialog.Title>
                    <div>{selectedJob.Description}</div>
                  </div>
                </div>

                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="">
                    <div className="pt-2">
                      <div className="mt-2 pb-2 min-h-[350px]">
                        {selectedJob.Questions?.sort(
                          (a, b) => a.Order - b.Order
                        ).map((obj, i) => {
                          return (
                            <div className="flex">
                              <div
                                className={`${
                                  i === hidden
                                    ? "teckpert-border-green"
                                    : selectedJob.Questions[i].Question === ""
                                    ? "border-gray-500"
                                    : "border-gray-200"
                                } border border-solid rounded pt-4 p-3 mb-2 w-[95%]`}
                                onDragStart={(e) => dragStartQs(e, i)}
                                onDragEnter={(e) => dragEnterQs(e, i)}
                                onDragEnd={dropQs}
                                draggable
                                key={i}
                              >
                                <div className="flex mb-2">
                                  <label className="flex-grow text-lg font-medium text-gray-700">
                                    {obj.Question
                                      ? obj.Question
                                      : "Enter question below"}
                                  </label>
                                  <div className="text-xs text-red-500 px-2 mt-1">
                                    {obj.Required ? "required" : ""}
                                  </div>
                                  <div className="text-xs text-red-500 px-2 mt-1">
                                    {obj.AnswerOptions?.filter(
                                      (obj) => obj.Knockout == true
                                    ).length > 0
                                      ? "knockout"
                                      : ""}
                                  </div>
                                  <div className="flex float-right">
                                    {i === hidden ? (
                                      <ChevronUpIcon
                                        onClick={() => {
                                          let temp = hidden;
                                          temp = i;
                                          if (temp === hidden) setHidden("");
                                          else setHidden(temp);
                                        }}
                                        className="cursor-pointer h-5 w-5 text-gray-500 mx-1 mt-1"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        onClick={() => {
                                          let temp = hidden;
                                          temp = i;
                                          setHidden(temp);
                                        }}
                                        className="cursor-pointer h-5 w-5 text-gray-500 mx-1 mt-1"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <MenuIcon
                                      className="cursor-pointer h-5 w-5 text-gray-500 mx-1 mt-1"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                                <div
                                  className={`${
                                    i !== hidden ? "hidden" : "block"
                                  } sm:col-span-2 flex-grow pt-5`}
                                >
                                  <div>
                                    <input
                                      onChange={(e) => {
                                        let temp = selectedJob;
                                        temp.Questions[i].Question =
                                          e.target.value;
                                        setSelectedJob({ ...temp });
                                      }}
                                      value={obj.Question}
                                      type="text"
                                      name="question"
                                      className="w-full mb-2 mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                                      border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                                      id="question"
                                      autoComplete="question"
                                    />
                                  </div>

                                  {/* Grid 1 */}
                                  <div className="w-full grid grid-cols-9 gap-5 my-3">
                                    <div className="col-span-2">
                                      <label className="min-w-[90px] block text-sm font-medium text-gray-700">
                                        Answer Type
                                      </label>
                                    </div>
                                    <div className="col-span-7">
                                      <select
                                        className="h-[50px] min-w-[50%] placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                        border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                        text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                        onChange={(e) => {
                                          let temp = selectedJob;
                                          temp.Questions[i].AnswerType =
                                            e.target.value;
                                          setSelectedJob({ ...temp });
                                        }}
                                        value={obj.AnswerType}
                                      >
                                        <option
                                          className="text-gray-300"
                                          value=""
                                        >
                                          Select
                                        </option>
                                        <option value="Single Lane">
                                          Single Lane
                                        </option>
                                        <option value="Paragraph">
                                          Paragraph
                                        </option>
                                        <option value="Number">Number</option>
                                        <option value="Single Option">
                                          Single Option
                                        </option>
                                        <option value="Multiple Options">
                                          Multiple Options
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  {/* Grid 2 */}
                                  <div
                                    className={`${
                                      obj.AnswerType?.includes("Option")
                                        ? ""
                                        : "hidden"
                                    }  w-full grid grid-cols-9 gap-5 my-3`}
                                  >
                                    <div className="col-span-2">
                                      <label className="min-w-[90px] block text-sm font-medium text-gray-700">
                                        Answer Options
                                      </label>
                                    </div>
                                    <div className="col-span-6">
                                      {obj.AnswerOptions?.sort(
                                        (a, b) => a.Order - b.Order
                                      ).map((option, index) => (
                                        <div>
                                          <div
                                            className="flex mb-1"
                                            ref={menuDragItem}
                                            onDragStart={(e) =>
                                              dragStartOps(e, index)
                                            }
                                            onDragEnter={(e) =>
                                              dragEnterOps(e, index)
                                            }
                                            onDragEnd={(e) =>
                                              dropOps(e, obj.AnswerOptions, obj)
                                            }
                                            draggable
                                            key={i}
                                            id="draggable-menu"
                                          >
                                            <input
                                              onChange={(e) => {
                                                if (
                                                  obj.AnswerType.includes(
                                                    "Option"
                                                  )
                                                ) {
                                                  let temp = selectedJob;
                                                  temp.Questions[
                                                    i
                                                  ].AnswerOptions[
                                                    index
                                                  ].Option = e.target.value;
                                                  setSelectedJob({ ...temp });
                                                }
                                              }}
                                              onKeyPress={(e) => {
                                                let temp = selectedJob;
                                                // if (!obj.AnswerOptions)
                                                if (e.key === "Enter") {
                                                  if (
                                                    (obj.AnswerOptions[
                                                      obj.AnswerOptions.length -
                                                        1
                                                    ].Option !==
                                                      "") &
                                                    obj.AnswerType.includes(
                                                      "options"
                                                    )
                                                  ) {
                                                    temp.Questions[
                                                      i
                                                    ].AnswerOptions.push({
                                                      Order:
                                                        temp.Questions[i]
                                                          .AnswerOptions.length,
                                                      Option: "",
                                                      Knockout: false,
                                                    });
                                                    setSelectedJob({ ...temp });
                                                  }
                                                }
                                              }}
                                              value={option.Option}
                                              type="text"
                                              name="option"
                                              id="option"
                                              autoComplete="option"
                                              className="min-w-[100%] mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
                                              border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900"
                                            />
                                            <button
                                              type="button"
                                              onClick={(e) => {
                                                let temp = selectedJob;
                                                if (
                                                  obj.AnswerType.includes(
                                                    "Option"
                                                  )
                                                ) {
                                                  temp.Questions[
                                                    i
                                                  ].AnswerOptions[
                                                    index
                                                  ].Knockout = !option.Knockout;

                                                  if (option.Knockout)
                                                    temp.Questions[
                                                      i
                                                    ].Required = true;

                                                  setSelectedJob({ ...temp });
                                                }
                                              }}
                                              value={option.Knockout}
                                            >
                                              <FlagIcon
                                                className={`${
                                                  option.Knockout
                                                    ? "text-red-500"
                                                    : "text-gray-300"
                                                } h-6 w-6 m-auto cursor-pointer relative right-20`}
                                                aria-hidden="true"
                                              />
                                            </button>
                                            <button type="button">
                                              <MenuIcon
                                                className="h-5 w-5 text-gray-500 relative right-16"
                                                aria-hidden="true"
                                              />
                                            </button>
                                            <div className="mt-4">
                                              <TrashIcon
                                                onClick={() => {
                                                  let temp = selectedJob;
                                                  temp.Questions[
                                                    i
                                                  ].AnswerOptions =
                                                    obj.AnswerOptions.filter(
                                                      (item) => item !== option
                                                    );
                                                  console.log("options", temp);
                                                  setSelectedJob({ ...temp });
                                                }}
                                                className="h-5 w-5 text-gray-300 cursor-pointer relative right-10 hover:text-red-500"
                                                aria-hidden="true"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                      <div>
                                        <button
                                          className="mb-2 ml-3 text-sm"
                                          type="button"
                                          onClick={(e) => {
                                            let temp = selectedJob;
                                            if (
                                              (obj.AnswerOptions[
                                                obj.AnswerOptions.length - 1
                                              ]?.Option !==
                                                "") &
                                              obj.AnswerType.includes("Option")
                                            ) {
                                              temp.Questions[
                                                i
                                              ].AnswerOptions.push({
                                                Option: "",
                                                Knockout: false,
                                                Order:
                                                  temp.Questions[i]
                                                    .AnswerOptions.length,
                                              });
                                              setSelectedJob({
                                                ...temp,
                                              });
                                            }
                                          }}
                                        >
                                          <span className="font-medium teckpert-text-green relative top-0.5">
                                            Add Answer
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {selectedJob.Questions[i].Question === "" &&
                                    error.length > 0 &&
                                    MissingFieldPrompt(error)}

                                  <div className="mt-5 mb-2 sm:mt-4 grid grid-cols-8">
                                    <div className="col-span-6 flex">
                                      <input
                                        id="bordered-checkbox"
                                        type="checkbox"
                                        name="bordered-checkbox"
                                        className="mr-1 w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                                        onChange={(e) => {
                                          let temp = selectedJob;
                                          if (
                                            temp.Questions[
                                              i
                                            ].AnswerOptions.filter(
                                              (obj) => obj.Knockout
                                            ).length > 0
                                          )
                                            return;
                                          temp.Questions[i].Required =
                                            !obj.Required;
                                          setSelectedJob({ ...temp });
                                        }}
                                        checked={obj.Required}
                                      />
                                      <label className="block text-sm font-medium text-gray-700">
                                        Make it required
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-1 rounded justify-center align-middle m-auto ml-2">
                                <TrashIcon
                                  onClick={() => {
                                    let temp = selectedJob;
                                    temp.Questions = temp.Questions.filter(
                                      (item) =>
                                        item.Question !==
                                        temp.Questions[i].Question
                                    );
                                    setSelectedJob({ ...temp });
                                  }}
                                  className="h-5 w-5 text-gray-300 cursor-pointer hover:text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          );
                        })}

                        {selectedJob.Questions &&
                          selectedJob.Questions[
                            selectedJob.Questions?.length - 1
                          ]?.Question === "" &&
                          error.length > 0 &&
                          MissingFieldPrompt([
                            "You must configure the current question before adding a new one.",
                          ])}

                        <div className="mt-2">
                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              onClick={() => {
                                let temp = selectedJob;
                                if (!selectedJob.Questions)
                                  temp.Questions = {
                                    Questions: [
                                      {
                                        Question: "",
                                        Required: false,
                                        AnswerType: "",
                                        Order: 0,
                                        AnswerOptions: [
                                          {
                                            Option: "",
                                            Knockout: false,
                                            Order: 0,
                                          },
                                        ],
                                      },
                                    ],
                                  };
                                let errorArray = [];
                                if (
                                  temp.Questions[
                                    temp.Questions.length - 1
                                  ]?.Question?.trim().length === 0
                                )
                                  errorArray.push("Question");
                                if (
                                  temp.Questions[
                                    temp.Questions.length - 1
                                  ]?.AnswerType?.trim().length === 0
                                )
                                  errorArray.push("Type");
                                setError(errorArray);

                                if (errorArray.length > 0) {
                                  return;
                                }

                                if (
                                  (temp.Questions[temp.Questions.length - 1]
                                    ?.Question !==
                                    "") &
                                  (temp.Questions[temp.Questions.length - 1]
                                    ?.AnswerType !==
                                    "")
                                ) {
                                  temp.Questions.push({
                                    Question: "",
                                    Required: false,
                                    AnswerType: "",
                                    Order: temp.Questions.length || 0,
                                    AnswerOptions: [
                                      {
                                        Option: "",
                                        Knockout: false,
                                        Order: 0,
                                      },
                                    ],
                                  });
                                  setSelectedJob({ ...temp });
                                }
                              }}
                              className="mt-3 flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base
                              font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                            >
                              <PlusCircleIcon
                                className={`mt-1 mr-2 h-7 w-7 m-auto teckpert-text-green cursor-pointer`}
                                aria-hidden="true"
                              />
                              <span className="mt-2">Add Question</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          onClick={async () => {
                            let errorArray = [];

                            if (
                              selectedJob.Questions[
                                selectedJob.Questions.length - 1
                              ].Question?.trim().length === 0
                            )
                              errorArray.push("Question");
                            if (
                              selectedJob.Questions[
                                selectedJob.Questions.length - 1
                              ].AnswerType?.trim().length === 0
                            )
                              errorArray.push("Type");
                            setError(errorArray);

                            if (errorArray.length > 0) {
                              return;
                            }

                            let temp = selectedJob;

                            await API.graphql(
                              graphqlOperation(`mutation {
                                  update_ApplicationQuestionnaire_by_pk(pk_columns: {ItemId: "${
                                    temp.ItemId
                                  }"}, 
                                     _set: 
                                      {
                                        Questions: [${temp.Questions?.map(
                                          (obj) =>
                                            `{
                                              Question: "${obj.Question}",
                                              Required: ${obj.Required},
                                              AnswerType: "${obj.AnswerType}",
                                              Order: ${obj.Order},
                                              AnswerOptions: [
                                                ${obj.AnswerOptions?.map(
                                                  (item) => `{
                                                    Option: "${item.Option}",
                                                    Knockout: ${item.Knockout},
                                                    Order: ${item.Order},
                                                  }`
                                                ).join(`,`)}
                                              ]}`
                                        ).join(
                                          `,`
                                        )}]                                      
                                      })
                                      {
                                        ItemId
                                        Questions
                                      }
                                    }`)
                            ).then(({ data }) => {
                              setOpen(false);
                              setError([]);
                            });
                          }}
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 
                          py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setEditModalOpen(true)}
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                          font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
