import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { XCircleIcon } from "@heroicons/react/outline";

const EditWorkFlowModal = ({
  opportunitiesData,
  profileData,
  applicantData,
  applicantsData,
  modalOpen,
  setModalOpen,
  defaultWorkFlow,
  setConfirmButtonMessage,
  setApplicantData,
  setRefresh,
}) => {
  const [filteredData, setFilteredData] = useState("");
  const [defaultStages, setDefaultStages] = useState([]);

  useEffect(() => {
    setDefaultStages(defaultWorkFlow.Stages?.map((obj) => obj.Name));
  }, [defaultWorkFlow?.Stages, applicantData]);

  useEffect(() => {
    setFilteredData(opportunitiesData);
  }, [opportunitiesData]);

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[700px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => setModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Workflow Status
                    </Dialog.Title>
                  </div>
                </div>

                <div>
                  <div className="mx-5 flex flex-col mt-5">
                    <div className="sm:-mx-6 lg:-mx-8">
                      <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                            <thead>
                              <tr>
                                {["Title", "Location", "Workflow Status"].map(
                                  (header, i) => (
                                    <th
                                      key={i}
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      {header}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {opportunitiesData?.map((opportunity, i) => {
                                let index = applicantData?.findIndex(
                                  (x) => opportunity.ItemId === x.OpportunityId
                                );
                                return (
                                  <tr
                                    className="bg-white"
                                    key={opportunity.ItemId}
                                  >
                                    <td className="whitespace-nowrap px-3 py-4 text-sm teckpert-text-green font-bold pl-5 cursor-pointer">
                                      {opportunity.Title
                                        ? opportunity.Title
                                        : "-"}
                                      &nbsp;
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 pl-5">
                                      {opportunity.Location
                                        ? opportunity.Location
                                        : "-"}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 pl-5">
                                      <select
                                        className="max-h-60 w-48 mr-3 rounded-full justify-center rounded-md 
                                        border shadow-sm px-2.5 py-3 text-sm bg-white inline cursor-pointer
                                        text-gray-900 hover:teckpert-bg-green hover:text-white"
                                        onChange={async (e) => {
                                          let temp = applicantData[index];
                                          temp.WorkFlowStatus = e.target.value;
                                          setApplicantData([
                                            ...applicantData,
                                            temp,
                                          ]);

                                          if (
                                            applicantData[index].OpportunityId
                                          )
                                            await API.graphql(
                                              graphqlOperation(`mutation {
                                              update_Applicants_by_pk(pk_columns: {ItemId: "${applicantData[index].ItemId}"},
                                                _set:
                                                  {
                                                    WorkFlowStatus: "${temp.WorkFlowStatus}",
                                                  })
                                                  {
                                                    ItemId
                                                    WorkFlowStatus
                                                  }
                                          }`)
                                            ).then(({ data }) => {});
                                          setRefresh(new Date().toISOString());
                                          setConfirmButtonMessage(true);
                                        }}
                                        value={
                                          applicantData[index].WorkFlowStatus
                                        }
                                      >
                                        {!applicantData[index]
                                          .WorkFlowStatus ? (
                                          <option
                                            value=""
                                            className="text-gray-400"
                                          >
                                            Select Workflow Status
                                          </option>
                                        ) : (
                                          <option
                                            value=""
                                            className="text-gray-400"
                                          >
                                            {
                                              applicantData[index]
                                                .WorkFlowStatus
                                            }
                                          </option>
                                        )}
                                        {applicantData[index]?.Opportunities
                                          ?.Workflows &&
                                          applicantData[
                                            index
                                          ].Opportunities?.Workflows?.Stages?.map(
                                            (obj) => (
                                              <option key={i} value={obj.Name}>
                                                {obj.Name}
                                              </option>
                                            )
                                          )}
                                        {applicantData[index]?.Opportunities
                                          ?.Workflows == undefined &&
                                          defaultStages.map((obj) => (
                                            <option key={i} value={obj}>
                                              {obj}
                                            </option>
                                          ))}
                                      </select>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default EditWorkFlowModal;
