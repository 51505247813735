import Modal from "react-modal";
import { formatDate } from "../utils/FormatDate";
import { XCircleIcon } from "@heroicons/react/outline";

const customStyles = {
  content: {
    zIndex: 10,
    height: "50%",
    overflowY: "auto",
    width: "500px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const NoteView = ({ profileData, setNotesModalOpen, notesModalOpen }) => {
  if (!profileData) return;
  return (
    <Modal isOpen={notesModalOpen} style={customStyles}>
      <div>
        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-red-500"
            onClick={() => setNotesModalOpen(false)}
          >
            <span className="sr-only">Close</span>
            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <h2 className="font-bold mb-5">Latest Note</h2>
        <div className="shadow-sm">
          <div
            className="py-4 px-3"
            dangerouslySetInnerHTML={{
              __html: profileData?.Notes[0]?.Note,
            }}
          />
          <div className="float-right text-gray-400 pb-3">
            {profileData?.Notes[0]?.DateUpdated
              ? formatDate(profileData?.Notes[0]?.DateUpdated)
              : "-"}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NoteView;
