import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import Table from "./Table";
import Loading from "../../../utils/Loading";
import { v4 as uuidv4 } from "uuid";

const Workflows = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const [tempData, setTempData] = useState({
    ItemId: uuidv4(),
    Title: "",
    Description: "",
    Default: false,
    DateCreated: new Date().toISOString(),
    CreatedBy: localStorage.getItem("name"),
    Stages: [
      {
        Name: "",
        Actions: [],
        Order: 0,
      },
    ],
  });

  // GET WORKFLOW DATA
  useEffect(() => {
    API.graphql({
      query: `{
        Workflows {
          ItemId
          DateCreated
          Title
          Stages
          Description
          CreatedBy
          Default
        }
        Opportunities {
          ItemId
          Title
          WorkflowId
        }
      }      
    `,
    }).then(({ data }) => {
      setTableData(data.Workflows);
      setJobsData(data.Opportunities);
    });
  }, []);

  if (tableData.length === 0) return <Loading />;

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6 mb-4">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={async () => {
              navigate("/workflows/" + tempData.ItemId);
            }}
            type="button"
            className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white hover:opacity-90
            shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <Table
        tableData={tableData}
        setTableData={setTableData}
        jobsData={jobsData}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
      />
    </div>
  );
};
export default Workflows;
