import { Link, useLocation } from "react-router-dom";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { formatDate } from "../../utils/FormatDate";
import TableDetails from "./TableDetails";
import { API, graphqlOperation } from "aws-amplify";
const Table = ({
  contractData,
    tableData,
    userdata,
  setTableData,
  selectStatus,
  setSelectStatus,
  selectContract,
  setSelectContract,
  selectDuration,
  setSelectDuration,
  page,
  setPage,
  itemsTotal,
  itemsPerPage,

}) => {
  const location = useLocation();
  const [status, setStatus] = useState("");
  const [modalOpen, setModalOpen] = useState("");
  const [tableDataSingle, setTableDataSingle] = useState("");
  const downloadToExcel = () => {
    //header shown in excel
    let content = [
      ["Worker", "Contract", "Client", "Period", "Hours", "Status", "Payroll"],
    ];
    tableData.map((obj) => {
      if (obj.checked && obj.EmploymentType.startsWith(status))
        content.push([
          obj.ItemId,
          obj.ContractName,
          obj.ClientName,
          obj.Period,
          obj.Hours,
          obj.Status,
          obj.PayrollId,
        ]);
    });

   };
     console.log("tabledata",tableData);
  //figure out what day of week it is and then determine how many days is it from monday this week
  const daysFromMonday = () => {
    const date = new Date();
    let dayOfWeek = date.getDay();
    //if saturday or sunday, set monday to the monday next week
    if (dayOfWeek === 0) return -1;
    if (dayOfWeek === 6) return -2;
    return 1 - dayOfWeek;
  };

  return (
    <div className="">
      {!location.pathname.includes("/people/") ? (
       <div>
                  
                  <div className="flex flex-row flex-wrap sm:flex-no-wrap lg:flex-wrap xl:flex-wrap lg:w-3/5 ml-1 lg:ml-2">
                      <form className="w-full lg:w-1/3" >
              <select
                 className="w-full lg:w-4/5 rounded-full justify-center rounded-md border shadow-sm px-4 py-3 
                text-sm bg-white cursor-pointer text-gray-900 hover:teckpert-bg-green hover:text-white"
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.target.value)}
              >
                <option value="" className="text-gray-400">
                  Select Status
                </option>
                <option value="Pending">Pending</option>
                <option value="Sent for Approval">Sent for Approval</option>
                <option value="Approved">Approved</option>
                <option value="Payroll in Progress">Payroll in Progress</option>
                <option value="Paid">Paid</option>
              </select>
              <br />
            </form>
            <form className="w-full lg:w-1/3 mt-2 lg:mt-0" >
              <select
                  className="w-full lg:w-4/5  rounded-full justify-center rounded-md 
                border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
                text-gray-900 hover:teckpert-bg-green hover:text-white"
                value={selectContract}
                onChange={(e) => setSelectContract(e.target.value)}
              >
                <option value="" className="text-gray-400">
                  Select Contract
                </option>
                {contractData.map((obj, i) => (
                  <option key={i} value={obj.ItemId}>
                    {obj.Name}
                  </option>
                ))}
              </select>
              <br />
            </form>
                      <form className="w-full lg:w-1/3 mt-2 lg:mt-0">
              <select
                className="w-full lg:w-4/5 rounded-full justify-center rounded-md 
                border shadow-sm px-4 py-3 text-sm bg-white cursor-pointer
                text-gray-900 hover:teckpert-bg-green hover:text-white"
                value={selectDuration}
                onChange={(e) => setSelectDuration(e.target.value)}
              >
                <option value="" className="text-gray-400">
                  Select Duration
                </option>
                <option value="A Week Ago">A Week Ago</option>
                <option value="Two Weeks Ago">Two Weeks Ago</option>
                <option value="A Month Ago">A Month Ago</option>
                <option value="Two Months Ago">Two Months Ago</option>
                <option value="Three Months Ago">Three Months Ago</option>
              </select>
              <br />
            </form>
          </div>
          
        </div>
      ) : (
        <div></div>
      )}
      {tableData.length > 0 ? (
        <div className="mx-5 flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto">
                <table className="min-w-full overflow-x-auto divide-y divide-gray-300">
                  <thead>
                    <tr>
                     {[
                      
                        "Contract",
                        "Client",
                        "Period",
                        "Hours",
                         "Status",
                         "Action",
                        "Payroll",
                        "Details",
                      ].map((obj) => (
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {obj}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableData?.sort((a,b)=>b.PeriodStart.localeCompare(a.PeriodStart)).map((timesheet, i) => (
                      console.log("Singletimesheetdata",timesheet),
                      <tr key={timesheet?.ItemId}>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {timesheet?.JobOrder?.Contract?.Name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {timesheet?.JobOrder?.Contract?.Clients?.Name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(timesheet?.PeriodStart).toLocaleDateString(
                            "en-US",
                              { day: "numeric", month: "short", timeZone: "utc" }
                          )}{" "}
                          -{" "}
                                {new Date(timesheet?.PeriodEnd).toLocaleDateString(
                                    "en-US",
                                    { day: "numeric", month: "short", timeZone:"utc" }
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {timesheet?.Hours?.aggregate?.sum?.Hours?.toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {timesheet?.Status}
                                
                            </td>
                            <td className="whitespace-nowrap   text-sm text-gray-900">
                                {timesheet?.Status == "Pending" &&
                                    <button type="button"
                                        className="teckpert-text-green  font-semibold pl-5"
                                    onClick={async () => {
                                        
                                        console.log("timesheet?.ItemId", timesheet?.ItemId)
                                        const currentDate = new Date();
                                        const timestampString = currentDate.toISOString();

                                        await API.graphql(
                                            graphqlOperation(`
          mutation UpdateTimesheet($itemId: String!, $newStatus: String!, $newDateUpdated: timestamp!) {
            update_Timesheets_by_pk(
              pk_columns: { ItemId: $itemId },
              _set: { Status: $newStatus, DateUpdated: $newDateUpdated }
            ) {
              ItemId
            }
          }
        `, {
                                                itemId: timesheet?.ItemId,
                                                newStatus: "Submitted",
                                                newDateUpdated: timestampString
                                            })
                                        );
                                        window.location.reload();
                                    }}

                                     

                                          >
                                        Submit
                                    </button>}
                            </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          -{" "}
                        </td>
                        <td
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                          onClick={() => {
                            setTableDataSingle(timesheet);
                            setModalOpen(!modalOpen);
                          }}
                        >
                          {modalOpen && (
                            <TableDetails
                                        open={modalOpen}
                                        setOpen={setModalOpen}
                                        tableData={tableDataSingle}
                                        userdata={userdata}
                            />
                          )}
                          <DocumentTextIcon className="w-7 h-7 teckpert-text-green cursor-pointer" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-20 pt-10 justify-center text-center w-full mb-8 font-bold text-gray-300 text-lg">
          No Timesheets Found
        </div>
      )}
      {tableData.length > 25 ? (
        <div className="flex justify-center mt-5 pb-8">
          <nav aria-label="Page navigation">
            <ul className="flex list-style-none">
              <li
                className="page-item"
                //decrement pagination by one when clicking arrow
                onClick={() => {
                  if (page === 1) return;
                  setPage(page - 1);
                }}
              >
                <a
                  className="page-link relative block py-1.5 px-3 rounded-full border-0 
                  bg-transparent outline-none transition-all duration-300 rounded 
                  text-gray-800 hover:text-gray-800 focus:shadow-none"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {itemsTotal &&
                itemsPerPage &&
                [...Array(Math.ceil(itemsTotal / itemsPerPage))]?.map(
                  (_, pageNumber) => {
                    //only displays pagination within a certain range
                    if (page - pageNumber + 1 >= 0 && page < pageNumber + 6)
                      return (
                        <li
                          className="page-item"
                          onClick={() => setPage(pageNumber + 1)}
                          key={pageNumber + 1}
                        >
                          <a
                            className={`rounded-full page-link relative block py-1.5 px-3
                          bg-transparent outline-none transition-all duration-300 rounded 
                          text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none ${
                            pageNumber + 1 === page && "font-bold"
                          }`}
                            // border-solid border-2 border-[#6FBE47]
                            href="#"
                          >
                            {pageNumber + 1}
                          </a>
                        </li>
                      );
                  }
                )}
              <li
                className="page-item"
                onClick={() => {
                  //increment pagination by one when clicking arrow
                  if (page === Math.ceil(itemsTotal / itemsPerPage)) return;
                  setPage(page + 1);
                }}
              >
                <a
                  className="page-link relative block py-1.5 px-3 rounded-full border-0 
                bg-transparent outline-none transition-all duration-300 rounded 
                text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Table;
