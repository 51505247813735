import { useEffect, useState } from "react";
import { API, Storage } from "aws-amplify";
import { useLocation, Link } from "react-router-dom";
import { ChevronLeftIcon, CheckCircleIcon } from "@heroicons/react/outline";
import ContractInfo from "./ContractInfo";
import Notes from "../../People/Profile/Tabs/Notes";
import Documents from "./Tabs/Documents";
import History from "./Tabs/History";
import Workers from "./Tabs/Workers";
import Categories from "./Tabs/Categories";
import Loading from "../../../utils/Loading";

const ContractDetail = () => {
  const location = useLocation();
  const [contractData, setContractData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [workerData, setWorkerData] = useState([]);
  const [timesheetData, setTimeSheetData] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
    const [confirmButtonMessage, setConfirmButtonMessage] = useState(false);
   
    const [checklists, setChecklists] = useState([]);
    const [selectedChecklist, setSelectedChecklist] = useState({});
    const [newResponses, setNewResponses] = useState({
        ItemId: "",
        MemberId: location.pathname.split("/")[2],
        Assigned: "Contract",
        DateUpdated: new Date().toISOString(),
        ChecklistId: "",
        Responses: [],
    });
    const [checklistResponses, setChecklistResponses] = useState([]);

  const getStorageFile = async (key, id, redirect = false) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    if (redirect === true && url !== "") {
      window.open(url, "_blank");
    }
    return { url, ItemId: id };
  };

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  useEffect(() => {
    // GET/SET CONTRACT DATA
    API.graphql({
      query: `{
        Contracts(where: {ItemId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          DateUpdated
          Name
          ClientId
          Documents
          Notes
          StartDate
          EndDate
          Hours
          Status
          PrimaryContact
          PM
          CTO
          Details
          Categories
          Type
          People
          PMFee
          CTOFee
          DefaultTo
          DefaultFrom
          CreatorName   
          Clients {
            Name
          } 
        }
      }      
    `,
    }).then(({ data }) => {
      setContractData(data.Contracts[0]);
      if (data.Contracts[0].Documents)
        Promise.all(
          data.Contracts[0].Documents.map((obj) =>
            getStorageFile(obj.Name, obj.ItemId)
          )
        ).then((url) => setDocumentUrl(url));
    });

    // GET/SET WORKERS DATA
    API.graphql({
      query: `{
        JobOrders(where: {ContractId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {  
          ItemId
          DateCreated
          DateUpdated
          ContractId
          MemberId
          LocationId
          Title
          StartDate
          EndDate
          Hours
          Rate
          BillableRate
          TimeApproval
          Remote
          Description
          Archived
          Type
          AutoSend
          JobCode
          PurchaseOrder
          FundingSource
        }
      }      
    `,
    }).then(({ data }) => {
        const dataArray = data.JobOrders;

        function calculateddiffbetweendays(data) {
            
            
            var startday = new Date();
            var Enddate = new Date(data.EndDate);
            // To calculate the time difference of two dates 
            var Difference_In_Time = Enddate.getTime() - startday.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            data.Difference_In_Days = Math.trunc(Difference_In_Days);
            if (Difference_In_Days <= 60 && Difference_In_Days >= 0) {
                data.Tooltipdata = "Contract ends in the next " + data.Difference_In_Days +" days " ;
            }
           }
        dataArray.forEach(calculateddiffbetweendays);



        setWorkerData(dataArray);
        console.log("Job order", dataArray);
    });

    // GET/SET DOCUMENT DATA
    API.graphql({
      query: `{
        Documents(where: {ContractId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {  
          ItemId
          DateCreated
          DateUpdated
          ContractId
          ClientId
          MemberId
          JobOrderId
          CreatorId
          CreatorName
          Folder
          Type
          Key
          Link
          Name
        }
      }      
    `,
    }).then(({ data }) => {
      setDocumentData(data.Documents);
    });

    API.graphql({
      query: `{
        Timesheets(where: {JobOrder: {ContractId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}}) {
          ItemId
          JobOrder {
              BillableRate
              Rate
            Member {
              ItemId
              FirstName
              LastName
              
            }
          }
          PeriodStart
          PeriodEnd
          Status
          Hours: TimesheetEntries_aggregate {
            aggregate {
              sum {
                Hours
              }
            }
          }
          TimesheetEntries(order_by: {Date: asc}) {
            Date
            Hours
            Category
            Details
            Status
          }
        }
      }`,
    }).then(({ data }) => {
      setTimeSheetData(data.Timesheets);
    });
  }, [confirmButtonMessage]);

    // GET CHECKLISTS DATA
    useEffect(() => {
        API.graphql({
            query: `{
        Checklists(where: { Assign: { _eq: "Contract" } }) {
          ItemId
          DateCreated
          Title
          Items
          Assign
          Description
          CreatedBy
          Default
          MemberId
        }
        ChecklistsResponses(order_by: {DateUpdated: desc}, where: {MemberId: {_eq: "${location.pathname.split("/")[2]
                }"}}) {
          ItemId
          MemberId
          Assigned
          DateUpdated
          Responses
          ChecklistId
          Checklists {
            ItemId
            Title
          }
          Members {
            ItemId
          }
        }
      }      
      `,
        }).then(({ data }) => {
            setChecklists(data.Checklists.filter((obj) => obj.Items.length > 0));

            setChecklistResponses(data.ChecklistsResponses);


            // get all member's checklists responses
            let tempExistingResponses = data.ChecklistsResponses?.filter(
                (obj) => obj.MemberId == location.pathname.split("/")[2]
            );

            // get default checklist
            let defaultList = data.Checklists.filter(
                (obj, i) => obj.Default == true
            )[0];

            let tempNewResponse = newResponses;

            if (defaultList && tempExistingResponses.length < 1) {
                // if there is a default checklist and no existing checklists responses
                let tempObj = defaultList.Items.map((obj, i) => ({
                    Name: obj.Name,
                    Checked: false,
                }));
                tempNewResponse.ChecklistId = defaultList.ItemId;
                tempNewResponse.Responses = tempObj;
            } else if (tempExistingResponses.length > 0) {
                // if there are existing responses
                console.log("tempExistingResponses", tempExistingResponses);
                tempNewResponse.ItemId = tempExistingResponses[0].ItemId;
                tempNewResponse.ChecklistId = tempExistingResponses[0].ChecklistId;
                tempNewResponse.Responses = tempExistingResponses[0].Responses;
            }
            setNewResponses({ ...tempNewResponse });
        });
    }, []);
    useEffect(() => {
        setNewResponses({ ...selectedChecklist });
    }, [selectedChecklist]);

    if (Object.keys(contractData).length === 0) return <Loading />;


    
  return (
    <div className="h-auto bg-neutral-100">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/contracts">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
          </div>
     
      {confirmButtonMessage && (
        <div
          id="section-top"
          className="bg-[#D3EBC7] border-t-4 border-[#6fbe47] rounded-b teckpert-text-green px-4 py-3 shadow-md mb-2"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <CheckCircleIcon
                className="h-6 w-6 cursor-pointer mr-3"
                aria-hidden="true"
              />
            </div>
            <div>
              <p className="font-bold">Your changes have been saved.</p>
            </div>
          </div>
        </div>
      )}
      <div className="lg:mb-2 lg:mx-10">
        <ContractInfo
          tableData={contractData}
          setTableData={setContractData}
          setConfirmButtonMessage={setConfirmButtonMessage}
          checklists={checklists}
          selectedChecklist={selectedChecklist}
          setSelectedChecklist={setSelectedChecklist}
          checklistResponses={checklistResponses}
          newResponses={newResponses}
        setNewResponses={setNewResponses}
       
        />
        <Notes
          profileData={contractData}
          setProfileData={setContractData}
          url={"/contracts"}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <Categories
          tableData={contractData}
          setTableData={setContractData}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <Workers
          contractData={contractData}
          tableData={workerData}
          setTableData={setWorkerData}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <Documents
          tableData={documentData}
          setTableData={setDocumentData}
          documentUrl={documentUrl}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <History timesheetData={timesheetData} />
      </div>
    </div>
  );
};

export default ContractDetail;
