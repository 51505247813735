import {
  MapIcon,
  CurrencyDollarIcon,
  PhoneIcon,
  InboxIcon,
  LinkIcon,
  UserCircleIcon,
  DocumentIcon,
  UserAddIcon,
  SearchIcon,
  DocumentTextIcon,
  PrinterIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { formatPhone } from "../../../utils/FormatPhone";
import EditProfile from "./Modals/EditProfileModal";
import EditWorkFlow from "./Modals/EditWorkFlowModal";
import CreateNoteModal from "./Modals/CreateNoteModal";
import NoteView from "../../../utils/NoteView";
import SendMessage from "../../Tools/Messages/SendMessage";

const Info = ({
  originalData,
  profileData,
  setProfileData,
  url,
  opportunitiesData,
  opportunitiesApplied,
  applicantData,
  setApplicantData,
  setConfirmButtonMessage,
  resumeUrl,
  admins,
  msgModalOpen,
  setMsgModalOpen,
  applicantsData,
  setApplicantsData,
  workflows,
  defaultWorkFlow,
}) => {
  const [refresh, setRefresh] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userStatusList, serUserStatusList] = useState([]);
  const [workFlowList, setWorkFlowList] = useState([]);
  const [workFlowStatus, setWorkFlowStatus] = useState("");
  const [logDate, setLogDate] = useState(new Date());
  const [newLog, setNewLog] = useState({
    Creator: localStorage.getItem("name"),
    DateCreated: logDate,
    CallLog: "",
    ItemId: "",
  });
  const location = useLocation();

  const [tempStatusData, setTempStatusData] = useState({
    ItemId: profileData?.ItemId,
    EmploymentType: profileData?.EmploymentType,
    RecruiterId: profileData?.RecruiterId,
    AccountStatus: profileData?.AccountStatus,
  });

  useEffect(() => {
    const async = async () => {
      const { data } = await API.graphql({
        query: `{
        Lookup {
          ItemId
          Name
          Options
        }
        Applicants(where: {MemberId: {_eq: "${profileData?.ItemId}"}}) {
          WorkFlowStatus
        }
      }           
    `,
      });
      const tempWorkFlowStatusList = data.Lookup.filter(
        (obj) => obj.Name === "WorkFlowStatus"
      )[0].Options;
      const tempUserStatusList = data.Lookup.filter(
        (obj) => obj.Name === "UserStatus"
      )[0].Options;

      serUserStatusList(tempUserStatusList);
      setWorkFlowList(tempWorkFlowStatusList);

      let maximumStatusIndex = 0;
      for (const { WorkFlowStatus } of data.Applicants) {
        const currentIndex = tempWorkFlowStatusList.indexOf(WorkFlowStatus);
        if (currentIndex > maximumStatusIndex) {
          maximumStatusIndex = currentIndex;
        }
      }
      setWorkFlowStatus(tempWorkFlowStatusList[maximumStatusIndex]);
    };
    async();
  }, [refresh]);

  const onSave = async () => {
    let temp = profileData;
    if (!temp.Meta.References) {
      temp.Meta.References = [];
    }
    if (!temp.Meta.Education) temp.Meta.Education = [];
    if (!temp.Meta.Specializations) temp.Meta.Specializations = [];
    if (!temp.Meta.VerifiedCertifications)
      temp.Meta.VerifiedCertifications = [];
    if (!temp.Meta.UnverifiedCertifications)
      temp.Meta.UnverifiedCertifications = [];

    await API.graphql({
      query: `mutation($meta: jsonb) {
        update_Members_by_pk(pk_columns: {ItemId: "${profileData.ItemId}"}, 
          _set: { Meta: $meta } )
          {
            ItemId
            Meta
          } 
      }`,
      variables: { meta: profileData?.Meta },
    }).then(async ({ data }) => {
      setModalOpen(false);
      setConfirmButtonMessage(true);
    });
  };

  return (
    <div className="h-full px-6 pb-6 pt-8 rounded-lg border border-gray-200 shadow-md">
      <ul className="w-full grid grid-cols-2 gap-4 px-5 py-5 mt-5">
        <div>
          <li className="grid grid-cols-9 pb-3">
            <MapIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Location</div>
            <div className="text-sm col-span-4">
              {profileData.Meta?.Location ? profileData.Meta.Location : "-"}
            </div>
          </li>

          <li className="grid grid-cols-9 pb-3">
            <CurrencyDollarIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Hourly Rate</div>
            <div className="text-sm col-span-4">
              {profileData.Meta?.HourlyRate
                ? `$${profileData.Meta.HourlyRate}`
                : "-"}
            </div>
          </li>

          <li className="grid grid-cols-9 pb-3">
            <PhoneIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Phone</div>
            <div className="text-sm col-span-4">
              {profileData.Meta?.Mobile
                ? formatPhone(profileData.Meta.Mobile)
                : "-"}
            </div>
          </li>

          <li className="grid grid-cols-9 pb-3">
            <InboxIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Email</div>
            <div className="text-sm col-span-4">
              <a target="_blank">
                {profileData.Meta?.Email
                  ? profileData.Meta.Email.substring(0, 20) + "..."
                  : "-"}
              </a>
            </div>
          </li>

          <li className="grid grid-cols-9 pb-3">
            <LinkIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Portfolio</div>
            <a
              className="text-sm col-span-4 hover:teckpert-text-green"
              href={profileData?.Meta?.Portfolio}
              target="_blank"
            >
              {profileData.Meta?.Portfolio
                ? profileData.Meta.Portfolio.substring(0, 20) + "..."
                : "-"}
            </a>
          </li>
        </div>

        <div>
          <li className="grid grid-cols-9 pb-3">
            <UserCircleIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">LinkedIn</div>
            <a
              className="text-sm col-span-4 hover:teckpert-text-green cursor-pointer"
              href={profileData?.Meta?.LinkedIn}
              target="_blank"
            >
              Open LinkedIn Profile
            </a>
          </li>
          <li className="grid grid-cols-9 pb-3">
            <DocumentIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Resume</div>
            <a
              className="text-sm col-span-4 hover:teckpert-text-green cursor-pointer"
              href={resumeUrl}
              target="_blank"
              download={resumeUrl}
            >
              Download Resume
            </a>
          </li>

          <li className="grid grid-cols-9 pb-3">
            <UserAddIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">Recruiter</div>
            <select
              className="max-h-60 w-48 mr-3 rounded-full justify-center rounded-md 
              border shadow-sm px-2.5 py-3 text-sm bg-white inline cursor-pointer
              text-gray-900 hover:teckpert-bg-green hover:text-white"
              onChange={async (e) => {
                let temp = tempStatusData;
                temp.RecruiterId = e.target.value;
                setTempStatusData({ ...temp });
                await API.graphql(
                  graphqlOperation(`mutation {
                    update_Members_by_pk(pk_columns: {ItemId: "${tempStatusData.ItemId}"}, 
                      _set: { RecruiterId: "${tempStatusData.RecruiterId}" } )       
                      {
                        ItemId
                        RecruiterId
                      }            
                }`)
                ).then(({ data }) => {
                  setConfirmButtonMessage(true);
                });
              }}
              value={tempStatusData.RecruiterId}
            >
              <option value="" className="text-gray-800">
                Select Recruiter
              </option>
              {admins
                ?.sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                .map((obj) => (
                  <option key={obj.ItemId} value={obj.ItemId}>
                    {obj.FirstName} {obj.LastName}
                  </option>
                ))}
            </select>
          </li>

          <li className="grid grid-cols-9 pb-3">
            <SearchIcon
              className="h-4 w-4 teckpert-text-green col-span-1"
              aria-hidden="true"
            />
            <div className="text-gray-400 text-xs col-span-3">
              Employment
              <br /> Status
            </div>
            <select
              className="max-h-60 w-48 mr-3 rounded-full justify-center rounded-md 
              border shadow-sm px-2.5 py-3 text-sm bg-white inline cursor-pointer
              text-gray-900 hover:teckpert-bg-green hover:text-white"
              onChange={async (e) => {
                let temp = tempStatusData;
                temp.EmploymentType = e.target.value;
                setTempStatusData({ ...temp });
                await API.graphql(
                  graphqlOperation(`mutation {
                    update_Members_by_pk(pk_columns: {ItemId: "${tempStatusData.ItemId}"}, 
                      _set: { EmploymentType: "${tempStatusData.EmploymentType}" } )       
                      {
                        ItemId
                        EmploymentType
                      }            
                }`)
                ).then(({ data }) => {
                  setConfirmButtonMessage(true);
                });
              }}
              value={tempStatusData.EmploymentType}
            >
              <option value="" className="text-gray-800">
                Select Status
              </option>
              <option value="Not Employed">Not Employed</option>
              <option value="W-2">W-2</option>
              <option value="1099">1099</option>
            </select>
          </li>
        </div>
      </ul>
      <div>
        <div className="flex justify-center mx-auto flex-wrap">
          <div className="mr-3">
            <div className="text-gray-400 text-xs col-span-3 text-left">
              User Status
            </div>
            <select
              className="max-h-60 w-48 mr-3 rounded-full justify-center rounded-md 
              border shadow-sm px-2.5 py-3 text-sm bg-white inline cursor-pointer
              text-gray-900 hover:teckpert-bg-green hover:text-white"
              onChange={async (e) => {
                let temp = tempStatusData;
                temp.AccountStatus = e.target.value;
                setTempStatusData({ ...temp });
                await API.graphql(
                  graphqlOperation(`mutation {
                    update_Members_by_pk(pk_columns: {ItemId: "${tempStatusData.ItemId}"}, 
                      _set: { AccountStatus: "${tempStatusData.AccountStatus}" } )       
                      {
                        ItemId
                        AccountStatus
                      }            
                }`)
                ).then(({ data }) => {
                  setConfirmButtonMessage(true);
                  setProfileData({
                    ...profileData,
                    AccountStatus: e.target.value,
                  });
                });
              }}
              value={tempStatusData.AccountStatus}
            >
              <option value="" className="text-gray-800">
                Select User Status
              </option>
              {userStatusList?.map((obj, i) => (
                <option key={i} value={obj}>
                  {obj}
                </option>
              ))}
            </select>
          </div>

          <div className="mr-3">
            <div className="text-gray-400 text-xs col-span-3 text-left">
              Workflow Status
            </div>
            {location.pathname.includes("/people") ? (
              <button
                type="button"
                className={`${
                  applicantData?.length > 0
                    ? "hover:teckpert-bg-green hover:text-white"
                    : "opacity-70"
                } max-h-60 w-48 mr-3 text-left rounded-md border shadow-sm px-2.5 py-3 text-sm bg-white
                cursor-pointer hover:text-white text-gray-900`}
                onClick={() => {
                  if (applicantData?.length > 0) {
                    setEditModalOpen(true);
                  } else return;
                }}
                value={workFlowStatus}
              >
                <option value="" className="">
                  {workFlowStatus || "Select Workflow Status"}
                </option>
              </button>
            ) : (
              <select
                className="max-h-60 w-48 mr-3 rounded-full justify-center rounded-md border shadow-sm px-2.5 py-3 text-sm bg-white inline 
                cursor-pointer text-gray-900 hover:teckpert-bg-green hover:text-white"
                onChange={async (e) => {
                  let index = applicantsData?.findIndex(
                    (x) => profileData.ItemId === x.MemberId
                  );
                  let temp = applicantsData;
                  temp[index].WorkFlowStatus = e.target.value;

                  setApplicantsData([...applicantsData, temp]);
                  await API.graphql(
                    graphqlOperation(`mutation {
                    update_Applicants_by_pk(pk_columns: {ItemId: "${applicantsData[index].ItemId}"},
                      _set:
                        {
                          WorkFlowStatus: "${temp[index].WorkFlowStatus}",
                        })
                        {
                          ItemId
                          WorkFlowStatus
                        }
                }`)
                  ).then(({ data }) => {
                    setConfirmButtonMessage(true);
                  });
                }}
                value={
                  applicantsData[
                    applicantsData.findIndex(
                      (x) => profileData.ItemId === x.MemberId
                    )
                  ]?.WorkFlowStatus
                }
              >
                <option value="" className="text-gray-800">
                  Select Workflow Status
                </option>
                {workflows?.map((obj, i) => (
                  <option key={i} value={obj}>
                    {obj}
                  </option>
                ))}
              </select>
            )}
          </div>
          <button
            onClick={() => setMsgModalOpen(true)}
            className="w-auto h-12 mt-3.5 mr-3 rounded justify-center rounded-md 
            border shadow-sm px-2.5 py-1 text-xs text-gray-900 hover:teckpert-bg-green hover:text-white"
          >
            Message
          </button>
          <button
            onClick={() => setLogModalOpen(true)}
            className="w-auto h-12 mt-3.5  mr-3 rounded justify-center rounded-md
            border shadow-sm px-2.5 py-1 text-xs text-gray-900 hover:teckpert-bg-green hover:text-white"
          >
            Log a Call
          </button>
        </div>
        <div>
          <ul className="flex justify-center mt-9 pb-3">
            <li
              onClick={() => {
                window.print();
              }}
            >
              <PrinterIcon
                className="h-6 w-6 text-gray-300 mr-3 cursor-pointer hover:teckpert-text-green"
                aria-hidden="true"
              />
            </li>
            <li>
              <DocumentTextIcon
                className={`${
                  profileData.Notes?.length > 0
                    ? "teckpert-text-green"
                    : "text-gray-300"
                }  h-6 w-6 mr-3 cursor-pointer`}
                aria-hidden="true"
                onClick={() => setNotesModalOpen(true)}
              />
            </li>
            <li>
              <PencilIcon
                onClick={() => setModalOpen(!modalOpen)}
                className="h-6 w-6 text-gray-300 mr-3 cursor-pointer hover:teckpert-text-green"
                aria-hidden="true"
              />
            </li>
          </ul>
        </div>
        <EditProfile
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          originalData={originalData}
          tempData={profileData}
          setTempData={setProfileData}
          onSave={onSave}
          inputField={[
            "PrimaryRole",
            "Location",
            "HourlyRate",
            "Mobile",
            "Email",
            "Portfolio",
            "LinkedIn",
          ]}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        {opportunitiesData ? (
          <EditWorkFlow
            modalOpen={editModalOpen}
            setModalOpen={setEditModalOpen}
            applicantData={applicantData}
            setApplicantData={setApplicantData}
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            opportunitiesData={opportunitiesData.filter((obj) => {
              if (opportunitiesApplied.get(obj.ItemId)) {
                let tempObj = obj;
                obj.WorkFlowStatus = opportunitiesApplied.get(
                  obj.ItemId
                ).WorkFlowStatus;
                return tempObj;
              }
            })}
            tempData={profileData}
            setTempData={setProfileData}
            workFlowList={workFlowList}
            defaultWorkFlow={defaultWorkFlow}
            profileData={profileData}
            setConfirmButtonMessage={setConfirmButtonMessage}
            setRefresh={setRefresh}
          />
        ) : null}
        {profileData.Notes?.length > 0 && (
          <NoteView
            profileData={profileData}
            setNotesModalOpen={setNotesModalOpen}
            notesModalOpen={notesModalOpen}
          />
        )}
        <CreateNoteModal
          open={logModalOpen}
          setOpen={setLogModalOpen}
          profileData={profileData}
          setProfileData={setProfileData}
          url={url}
          newData={newLog}
          setNewData={setNewLog}
          title={"Log a Call"}
          datePicker={true}
          logDate={logDate}
          setLogDate={setLogDate}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
        <SendMessage
          open={msgModalOpen}
          setOpen={setMsgModalOpen}
          defaultTo={profileData.ItemId}
          setRefresh={setRefresh}
          setConfirmButtonMessage={setConfirmButtonMessage}
        />
      </div>
    </div>
  );
};
export default Info;
