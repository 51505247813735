import { useState, useEffect } from "react";
import {
  PencilIcon,
  MailIcon,
  DocumentSearchIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import { CalendarIcon } from "@heroicons/react/outline";
import { API, Storage } from "aws-amplify";
import { formatDate } from "../../../utils/FormatDate";
import UpdateContractModal from "./Modals/UpdateContractModal";
import Checklist from "../../People/Profile/Checklist";

const ContractInfo = ({ tableData, setTableData, setConfirmButtonMessage, checklists, selectedChecklist,
    setSelectedChecklist,
    checklistResponses,
    newResponses,
    setNewResponses, }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [member, setMember] = useState([]);
  const [CTOImg, setCTOImg] = useState([]);
  const [PMImg, setPMImg] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [supervisorImg, setSupervisorImg] = useState([]);

  useEffect(() => {
    API.graphql({
      query: `{
        Users(where: {UserType: {_neq: "member"}}) {
          ItemId
          FirstName
          LastName
        }
        Clients {
          ItemId
          Name
        }
        Contacts {
          ItemId
          ClientId
          Title
          FirstName
          LastName
        }
      }      
    `,
    }).then(({ data }) => {
      setUsers(data.Users);
      setClients(data.Clients);
      setContacts(data.Contacts);
      getStorageFile(
        data.Contacts.filter(
          (obj) => obj.ItemId === tableData.PrimaryContact
        )[0]?.ProfilePicture
      ).then((url) => {
        setSupervisorImg(url);
      });
    });
  }, []);

  // GET/SET CONTACT PICTURE
  useEffect(() => {
    API.graphql({
      query: `{
        Members {
          FirstName
          ItemId
          LastName
          Meta
        }
      }
    `,
    }).then(({ data }) => {
      setMember(data.Members);
      getStorageFile(
        data.Members?.filter((obj) => obj.ItemId === tableData.CTO)[0]?.Meta
          ?.ProfilePicture
      ).then((url) => {
        setCTOImg(url);
      });
      getStorageFile(
        data.Members?.filter((obj) => obj.ItemId === tableData.PM)[0]?.Meta
          ?.ProfilePicture
      ).then((url) => {
        setPMImg(url);
      });
    });
  }, []);

  const getStorageFile = async (key, redirect = false) => {
    const url = await Storage.get(key, {
      level: "public",
      expires: 60,
    });
    if (redirect === true && url !== "") {
      window.open(url, "_blank");
    }
    return url;
  };

  return (
    <div>
      {Array.isArray(users) &&
      Array.isArray(contacts) &&
      Array.isArray(clients) ? (
        <UpdateContractModal
          open={modalOpen}
          setOpen={setModalOpen}
          tableData={tableData}
          setTableData={setTableData}
          setConfirmButtonMessage={setConfirmButtonMessage}
          users={users}
          clients={clients}
          contacts={contacts}
        />
      ) : null}
      <div className="lg:flex lg:items-center lg:justify-between p-12 rounded">
        <div className="min-w-0 flex-1">
          <h2 className="text-3xl mb-1 font-bold leading-7 teckpert-text-green sm:truncate sm:text-3xl sm:tracking-tight">
            {tableData.Name ? tableData.Name : "-"}
          </h2>
          <h2 className="text-xl mb-1 font-bold leading-7 text-gray-600 sm:truncate sm:text-xl sm:tracking-tight">
            {tableData.Clients.Name ? tableData.Clients.Name : "-"}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <DocumentSearchIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {tableData.Status ? tableData.Status : "-"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              Start:&nbsp;
              {tableData.StartDate ? formatDate(tableData.StartDate) : "-"}{" "}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              End:&nbsp;
              {tableData.EndDate ? formatDate(tableData.EndDate) : "-"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ClockIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              Length:&nbsp;
              {Math.floor(
                (new Date(tableData.EndDate).getTime() -
                  new Date(tableData.StartDate).getTime()) /
                  (1000 * 3600 * 24)
              )}
              &nbsp; Days
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ClockIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              Hours: {tableData.Hours ? tableData.Hours : "-"}
            </div>
            {/*<div className="mt-2 flex items-center text-sm text-gray-500">*/}
            {/*  <MailIcon*/}
            {/*    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"*/}
            {/*    aria-hidden="true"*/}
            {/*  />*/}
            {/*  Default To:&nbsp;*/}
            {/*  {contacts?.map((obj) => {*/}
            {/*    if (obj.ItemId === tableData.DefaultTo)*/}
            {/*      if (*/}
            {/*        (obj.FirstName != undefined) &*/}
            {/*        (obj.LastName != undefined)*/}
            {/*      )*/}
            {/*        return obj.FirstName + " " + obj.LastName;*/}
            {/*      else return "-";*/}
            {/*  })}*/}
            {/*</div>*/}
            {/*<div className="mt-2 flex items-center text-sm text-gray-500">*/}
            {/*  <MailIcon*/}
            {/*    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"*/}
            {/*    aria-hidden="true"*/}
            {/*  />*/}
            {/*  Default From:&nbsp;*/}
            {/*  {users.map((obj) => {*/}
            {/*    if (obj.ItemId === tableData.DefaultFrom)*/}
            {/*      if (*/}
            {/*        (obj.FirstName != undefined) &*/}
            {/*        (obj.LastName != undefined)*/}
            {/*      )*/}
            {/*        return obj.FirstName + " " + obj.LastName;*/}
            {/*      else return "-";*/}
            {/*  })}*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="block">
            <button
              onClick={() => setModalOpen(!modalOpen)}
              type="button"
              className="inline-flex items-center rounded-md border teckpert-bg-green px-4 py-2 text-sm font-medium text-white
              shadow-sm hover:opacity-90 focus:outline-none"
            >
              <PencilIcon
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              Edit Details
            </button>
          </span>
        </div>
      </div>          
          <div className="flex flex-row flex-wrap lg:flex-nowrap justify-between pb-6">
        <div className="flex flex-wrap w-full lg:w-3/4">
          {tableData.PrimaryContact && tableData.PrimaryContact !== "null" && (
            <div>
              <img
                src={supervisorImg}
                className="w-32 h-32 mx-10 mb-5 mt-0 rounded-full bg-gray-300"
              />
              <div className="relative">
                <div className="text-center">
                  {contacts.map((obj) => {
                    if (obj.ItemId === tableData.PrimaryContact)
                      return obj.FirstName + " " + obj.LastName;
                  })}
                </div>
                <div className="text-center">Supervisor</div>
              </div>
            </div>
          )}
          {tableData.CTO && tableData.CTO !== "null" && (
            <div>
              <img
                src={CTOImg}
                className="w-32 h-32 mx-10 mb-5 mt-0 rounded-full bg-gray-300"
              />
              <div className="relative">
                <div className="text-center">
                  {member.map((obj) => {
                    if (obj.ItemId === tableData.CTO)
                      return obj.FirstName + " " + obj.LastName;
                  })}
                </div>
                <div className="text-center">CTO ({tableData.CTOFee}%)</div>
              </div>
            </div>
          )}
          {tableData.PM && tableData.PM !== "null" && (
            <div>
              <img
                src={PMImg}
                className="w-32 h-32 mx-10 mb-5 mt-0 rounded-full bg-gray-300"
              />
              <div className="relative">
                <div className="text-center">
                  {member.map((obj) => {
                    if (obj.ItemId === tableData.PM)
                      return obj.FirstName + " " + obj.LastName;
                  })}
                </div>
                <div className="text-center">PM ({tableData.PMFee}%)</div>
              </div>
            </div>
          )}
        </div>
              {/* Checklists */}
              <div className=" w-full lg:w-3/12 justify-center ">
                  <Checklist 
                      profileData={tableData }
                    setConfirmButtonMessage={setConfirmButtonMessage}
                    checklists={checklists}
                    selectedChecklist={selectedChecklist}
                    setSelectedChecklist={setSelectedChecklist}
                    checklistResponses={checklistResponses}
                    newResponses={newResponses}
                    setNewResponses={setNewResponses}
                  />
              </div>
      </div>
    </div>
  );
};

export default ContractInfo;
