import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Modal from "react-modal";
import { XCircleIcon } from "@heroicons/react/outline";

const ResetPassword = ({ modalOpen, setModalOpen, tempData, setTempData }) => {
  const customStyles = {
    content: {
      zIndex: 10,
      width: "600px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const onSave = async () => {
    let tempOutput = {
      ItemId: tempData.ItemId,
      FirstName: tempData.FirstName,
      LastName: tempData.LastName,
      UserType: tempData.UserType,
      Email: tempData.Email,
    };

    try {
      API.put("auth", `/usersSQL`, {
        body: tempOutput,
      }).then((responseJson) => {
        return responseJson;
      });
    } catch (error) {
      console.log("Error updating: ", error);
    }
    setModalOpen(false);
  };

  return (
    <Modal isOpen={modalOpen} style={customStyles}>
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-red-500"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <span className="sr-only">Close</span>
          <XCircleIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <div
            as="h3"
            className="mb-5 text-lg font-medium leading-6 text-gray-900"
          >
            Reset Password
          </div>
        </div>
      </div>

      <div className="sm:col-span-2 flex-grow">
        <div className="mt-2">
          <label className="block text-sm font-medium text-gray-700">
            Please enter your email or mobile number to search for your account.
          </label>
          <input
            onChange={(e) => {
              let temp = tempData;
              temp.Email = e.target.value;
              setTempData({ ...temp });
            }}
            value={tempData.Email}
            type="text"
            name="email"
            id="email"
            autoComplete="email"
            className="w-full mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900
            border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
            text-gray-900 hover:teckpert-bg-green hover:text-white"
          />
        </div>
      </div>

      <div className="pt-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent 
          teckpert-bg-green px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onSave}
        >
          Search
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 
          bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 
          focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
export default ResetPassword;
