import { CheckCircleIcon } from "@heroicons/react/outline";

const ConfirmMessage = () => {
  return (
    <div
      id="section-top"
      className="bg-[#D3EBC7] border-t-4 border-[#6fbe47] rounded-b teckpert-text-green px-4 py-3 shadow-md mb-2"
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <CheckCircleIcon
            className="h-6 w-6 cursor-pointer mr-3"
            aria-hidden="true"
          />
        </div>
        <div>
          <p className="font-bold">Your changes have been saved.</p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmMessage;
