/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect, useRef } from "react";
import {
  MapIcon,
  MailIcon,
  PhoneIcon,
  StarIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import { API } from "aws-amplify";
import { useLocation, useNavigate, Link } from "react-router-dom";
import demoFile from "./demo.pdf";
import CompleteInterviewModal from "./Modals/CompleteInterviewModal";
import ConfirmationModal from "./Modals/ConfirmationModal";
import Loading from "../../../utils/Loading";

export default function InterviewGuideView() {
  //   const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState("");
  const [completeModal, setCompleteModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [hidden, setHidden] = useState("");
  const [members, setMembers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [img, setImg] = useState("");
  const [lastContacted, setLastContacted] = useState(" ");
  const [profileData, setProfileData] = useState([]);
  const [resumeUrl, setResumeUrl] = useState([]);
  const location = useLocation();

  const [responsesData, setResponsesData] = useState({
    InterviewGuideId: location.pathname.split("/")[2],
    Title: "",
    Description: "",
    DateCreated: "",
    DateUpdated: "",
    DateCompleted: "",
    Rating: "",
    Notes: "",
    MemberId: location.state,
    Responses: [
      {
        Question: "",
        Rating: "",
        Note: "",
      },
    ],
  });

  // GET PROFILE PICTURE
  //   useEffect(() => {

  // getStorageFile(profileData.Meta.ProfilePicture).then((url) => {
  //   setImg(url);
  // });
  //   }, []);

  // GET/SET MEMBER DATA
  // useEffect(() => {
  //   API.get("1", `/members?ItemId=000b3043-23b6-4ed6-b1fa-ac7a4583ed86`).then(
  //     (responseJson) => {
  //       getStorageFile(responseJson.Output.Meta.ProfilePicture).then((url) => {
  //         setImg(url);
  //       });
  //       Promise.all(
  //         responseJson.Output.filter((obj) => obj.Resume === "").map((obj) =>
  //           getStorageFile(obj.Key, obj.ItemId)
  //         )
  //       ).then((url) => setResumeUrl(url));
  //       setMembers(responseJson.Output, () => {
  //         setLastContacted(responseJson.Output.LastContacted);
  //       });
  //     }
  //   );
  //   const getStorageFile = async (key, redirect = false) => {
  //     const url = await Storage.get(key, {
  //       level: "public",
  //       expires: 60,
  //     });
  //     if (redirect === true && url !== "") {
  //       window.open(url, "_blank");
  //     }
  //     return url;
  //   };
  // }, [location.pathname]);

  //   API.get("1", `/members?ItemId=${location.pathname.split("/")[2]}`).then(
  //     (responseJson) => {
  //       setProfileData(responseJson.Output[0]);
  //       Promise.all(
  //         responseJson.Output.filter(
  //           (obj) => obj.Resume === location.pathname.split("/")[2]
  //         ).map((obj) => getStorageFile(obj.Key, obj.ItemId))
  //       ).then((url) => setResumeUrl(url));
  //     }
  //   );

  useEffect(() => {
    API.graphql({
      query: `{
        InterviewGuides(where: {ItemId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          DateUpdated
          Title
          Type
          Duration
          Questions
          Notes
          Description
          CreatedBy
        }
      }      
    `,
    }).then(({ data }) => {
      setTableData(data.InterviewGuides[0]);

      let tempObj = data.InterviewGuides[0].Questions?.map((obj, i) => ({
        Question: obj.Question,
        Note: "",
        Rating: "",
      }));

      let tempNewResponse = responsesData;
      tempNewResponse.Responses = tempObj;
      setResponsesData({ ...tempNewResponse });
      setLoading(false);
    });
  }, []);

  // GET/SET MEMBER DATA AND RESUME FILE TO RENDER PROFILE, AND OPPORTUNITES DATA
  useEffect(() => {
    if (location.state)
      API.graphql({
        query: `{
          Members(where: {ItemId: {_eq: "${location.state}"}}) {
          ItemId
          DateCreated
          DateUpdated
          Meta
          FirstName
          LastName
          PrimaryTitle
          ScreeningStatus
          AccountStatus
          Documents
          EmploymentType
          RecruiterId
          Resume
        }
      }      
    `,
      }).then(({ data }) => {
        setMembers(data.Members[0]);
      });
  }, [location.pathname, location.state]);

  if (!tableData) return <Loading />;

  return (
    <div className="grid grid-cols-7">
      <div className="col-span-2">
        <div className="">
          {/* Member Bio */}
          <div className="px-12 text-center">
            <div className="justify-center m-auto flex space-around">
              {/* {!img.includes("undefined") ? (
                <img
                  src={img}
                  width="120"
                  height="60"
                  className="rounded-full"
                />
              ) : (
                <img
                  src={"/logo512.png"}
                  width="120"
                  height="60"
                  className="rounded-full"
                />
              )} */}
              <img
                src={"/logo512.png"}
                width="130"
                height="70"
                className="rounded-full"
              />
            </div>
            <div className="mb-6 mt-2">
              <h5 className="text-xl font-bold text-gray-800">
                {members.FirstName + " " + members.LastName}
              </h5>
              <h5 className="text-xl font-bold text-[#6fbe47]">
                {members.Meta?.PrimaryRole?.label
                  ? members.Meta?.PrimaryRole?.label
                  : "-"}
              </h5>
            </div>
            <div className="w-[260px] grid grid-cols-6 text-left text-sm mt-3 mb-9 m-auto">
              <div className="col-span-1 relative left-2">
                <div>
                  <MapIcon
                    className={`h-5 w-5 teckpert-text-green`}
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <PhoneIcon
                    className={`h-5 w-5 teckpert-text-green`}
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <MailIcon
                    className={`h-5 w-5 teckpert-text-green`}
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="text-gray-400">Location</div>
                <div className="text-gray-400">Phone</div>
                <div className="text-gray-400">Email</div>
              </div>
              <div className="col-span-3">
                <div>
                  {members.Meta?.Location ? members.Meta.Location : "-"}
                </div>
                <div>{members.Meta?.Mobile ? members.Meta.Mobile : "-"}</div>
                <div>{members.Meta?.Email ? members.Meta.Email : "-"}</div>
              </div>
            </div>
            <div className="text-center mx-auto text-sm my-1">
              <button
                // onClick={() => navigate("/people/" + members.ItemId)}
                className="w-[200px] justify-center rounded-md border border-transparent teckpert-bg-green py-2.5 m-auto
                text-base font-medium text-white shadow-sm sm:ml-3 sm:text-sm hover:opacity-90"
                type="button"
              >
                <a href={demoFile} download="demoFile" target="_blank">
                  Download Resume
                </a>
              </button>
            </div>
            <div className="text-center mx-auto text-sm my-1">
              <button
                // onClick={() => navigate("/people/" + members.ItemId)}
                className="w-[200px] justify-center rounded-md border border-transparent bg-gray-500 py-2.5 m-auto
                text-base font-medium text-white shadow-sm sm:ml-3 sm:text-sm"
                type="button"
              >
                View Job Details
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3">
        <div className="border border-gray-200 border-solid rounded pt-4 p-7 mb-2">
          <div className="font-bold text-2xl text-gray-800 mb-1 flex">
            <div className="flex-grow">{tableData?.Title}</div>
            <div className="flex-none">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-6 py-2.5 m-auto
                text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                onClick={() => setCompleteModal(true)}
              >
                Complete Interview
              </button>
              <CompleteInterviewModal
                open={completeModal}
                setOpen={setCompleteModal}
                openConfirmation={setConfirmationModal}
                tempData={responsesData}
                setTempData={setResponsesData}
              />
              <ConfirmationModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                state={location.state}
              />
            </div>
          </div>
          <div className="flex gap-9 text-sm pb-1">
            <div className="flex gap-2">
              <StarIcon
                className={`h-5 w-5 text-gray-800`}
                aria-hidden="true"
              />
              <div>{tableData?.Type ? tableData.Type : "-"}</div>
            </div>
            <div className="flex gap-2">
              <ClockIcon
                className={`h-5 w-5 text-gray-800`}
                aria-hidden="true"
              />
              <div>
                {tableData?.Duration !== 1
                  ? tableData.Duration + " min"
                  : tableData.Duration + " hr"}
              </div>
            </div>
          </div>
          <div className="text-sm p-1 mt-2">
            {tableData.Description ? tableData.Description : "-"}
          </div>
        </div>
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="">
            <div className="pt-2">
              <div className="mt-2 pb-6">
                {responsesData?.Responses &&
                  responsesData.Responses
                    // .sort((a, b) => a.Order - b.Order)
                    .map((obj, i) => {
                      // let index = tableData.Questions.findIndex(
                      //   (x) => x.Question === obj.Question
                      // );
                      return (
                        <div
                          className={`${
                            i === hidden
                              ? "teckpert-border-green"
                              : responsesData.Responses[i].Question === ""
                              ? "border-gray-500"
                              : "border-gray-200"
                          } border border-solid rounded pt-4 p-7 mb-2`}
                          key={i}
                        >
                          <div className="flex mb-2">
                            <label className="flex-grow text-sm font-medium text-gray-700">
                              {obj.Question ? obj.Question : "Question Text"}
                            </label>
                          </div>
                          <div>
                            <textarea
                              onChange={(e) => {
                                let temp = responsesData;
                                temp.Responses[i].Note = e.target.value;
                                setResponsesData({ ...temp });
                              }}
                              value={obj.Note}
                              type="text"
                              name="description"
                              placeholder="Notes..."
                              className="h-24 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                              rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                              id="message"
                              autoComplete="description"
                            />
                          </div>
                          <div className={`sm:col-span-2 flex-grow`}>
                            <div className="mt-2 sm:mt-4 flex">
                              <div className="flex-grow">
                                <select
                                  onChange={(e) => {
                                    let temp = responsesData;
                                    temp.Responses[i].Rating = e.target.value;
                                    setResponsesData({ ...temp });
                                  }}
                                  value={obj.Rating}
                                  className="w-full placeholder:text-gray-300 mr-3 rounded-full justify-center rounded-md 
                                  border shadow-sm px-2.5 py-3 text-sm bg-white hover:placeholder:text-white
                                  text-gray-900 hover:teckpert-bg-green hover:text-white cursor-pointer"
                                >
                                  <option className="text-gray-300" value="">
                                    (Optional) How would you rate this
                                    candidate's answer?
                                  </option>
                                  {[
                                    "Excellent",
                                    "Very Good",
                                    "Good",
                                    "Fair",
                                    "Poor",
                                  ].map((obj, i) => (
                                    <option key={i} value={obj}>
                                      {obj}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* <div className="flex-none">
                                <button
                                  type="button"
                                  className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-6 py-2.5 m-auto
                                  text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                                  onClick={() => {}}
                                >
                                  Saved
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="p-5 col-span-2">
        <div className="font-bold text-xl text-gray-800 mb-2">Notes</div>
        <div>
          <textarea
            value={tableData.Notes}
            type="text"
            name="description"
            placeholder="Leave some notes here"
            className="h-96 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
            rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
            id="message"
            autoComplete="description"
          />
        </div>
      </div>
    </div>
  );
}
