import { useEffect, useState } from "react";
import { API, Storage } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { PlusCircleIcon } from "@heroicons/react/outline";
import UploadResumeModal from "../Modals/UploadResumeModal";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import demoFile from "./demo.pdf";

export default function PDFViewer({
  profileData,
  setProfileData,
  setConfirmButtonMessage,
}) {
  const [resumePDF, setResumePDF] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const getStorageFile = async (key, redirect = false) => {
    if (!key) return;
    const url = await Storage.get(key, {
      level: "public",
      expires: 6000,
    });
    if (redirect === true && url !== "") {
      window.open(url, "_blank");
    }
    setResumePDF(url);
  };

  useEffect(() => {
    getStorageFile(profileData.Resume);
  }, [resumePDF, profileData]);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const { ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={props.onClick}
                  >
                    Zoom out
                  </button>
                )}
              </ZoomOut>
            </div>
            ...
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div className="pb-8 mb-8 mt-5 mx-8 p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      <div className="flex justify-between mb-4">
        <h4 className="text-2xl mt-0 font-bold text-gray-800 ml-5">Resume</h4>
        <div
          onClick={() => setModalOpen(!modalOpen)}
          className="flex flex-none mr-5"
        >
          <PlusCircleIcon
            className="h-8 w-8 cursor-pointer mr-3 teckpert-text-green"
            aria-hidden="true"
          />
        </div>
      </div>
      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
        <div>
          <Viewer
            fileUrl={resumePDF.length > 0 ? resumePDF : demoFile}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>

      <UploadResumeModal
        open={modalOpen}
        setOpen={setModalOpen}
        tableData={profileData}
        setTableData={setProfileData}
        id={profileData.ItemId}
        setConfirmButtonMessage={setConfirmButtonMessage}
      />
    </div>
  );
}
