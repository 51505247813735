import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { FilterLogic } from "./FilterLogic";

export default function AdvancedFiltersModal(props) {
  const advancedFilters = [
    { name: "Recruiter" },
    { name: "Employment Preference" },
    { name: "TECKpert Employment" },
    { name: "Has References" },
    { name: "Registered" },
    { name: "Currently Onboarding" },
  ];

  return (
    <Transition.Root show={props.filtersModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.setFiltersModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:w-[800px] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => {
                      props.setFiltersModal(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start mb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Filter Opportunities
                    </Dialog.Title>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-8 p-8">
                  {advancedFilters.map((obj, i) => (
                    <div className="flex">
                      <div className="basis-1/2">{obj.name}</div>
                      <div className="basis-1/2">
                        {obj.name == "Has References" ||
                        obj.name == "Currently Onboarding" ? (
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              props.setAdvFilterSearch([
                                ...props.advFilterSearch,
                                obj.name,
                              ]);
                              if (
                                (e.target.value === "on") &
                                !props.advFilterSearch.includes(obj.name)
                              ) {
                                FilterLogic(
                                  obj.name,
                                  props.tableData,
                                  props.tempTable,
                                  props.setTempTable,
                                  e.target.value,
                                  props.advFilterSearch,
                                  props.setAdvFilterSearch,
                                  props.isSelected
                                );
                              } else if (
                                props.advFilterSearch.includes(obj.name)
                              ) {
                                props.setFilterSearch(
                                  props.advFilterSearch.filter(
                                    (search) => search !== e.target.value
                                  )
                                );
                                props.setTempTable(props.tableData);
                                props.setAdvFilterSearch([]);
                              }
                            }}
                            className="w-5 h-5 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                          />
                        ) : (
                          <form>
                            <select
                              onChange={(e) => {
                                props.setAdvFilterSearch([
                                  ...props.advFilterSearch,
                                  obj.name,
                                ]);

                                if (obj.name === "Recruiter")
                                  props.setRecruiter(e.target.value);
                                if (obj.name === "Employment Preference")
                                  props.setEmploymentType(e.target.value);
                                if (obj.name === "TECKpert Employment")
                                  props.setEmploymentStatus(e.target.value);
                                if (obj.name === "Registered")
                                  props.setDateRegistered(e.target.value);

                                if (!props.advFilterSearch.includes(obj.name)) {
                                  FilterLogic(
                                    obj.name,
                                    props.tableData,
                                    props.tempTable,
                                    props.setTempTable,
                                    e.target.value,
                                    props.advFilterSearch,
                                    props.setAdvFilterSearch,
                                    props.isSelected
                                  );
                                } else if (
                                  props.advFilterSearch.includes(e.target.value)
                                ) {
                                  props.setAdvFilterSearch(
                                    props.advFilterSearch.filter(
                                      (search) => search !== e.target.value
                                    )
                                  );
                                  props.setTempTable(props.tableData);
                                  props.setAdvFilterSearch([]);
                                }
                              }}
                              name="status"
                              id="status"
                              className="w-full mr-3 rounded-full justify-center rounded-md 
                              border shadow-sm px-2.5 py-3 text-sm placeholder:text-gray-900 hover:placeholder:text-white
                              hover:teckpert-bg-green hover:text-white cursor-pointer text-gray-900"
                            >
                              <option value={obj.name} defaultValue>
                                {obj.name}
                              </option>
                              {obj.name === "Recruiter"
                                ? props.recruitersList.map(
                                    (person) =>
                                      person && (
                                        <option value={person}>{person}</option>
                                      )
                                  )
                                : obj.name === "Employment Preference"
                                ? props.employmentPref.map(
                                    (type) =>
                                      type && (
                                        <option
                                          className="capitalize"
                                          value={type}
                                        >
                                          {type}
                                        </option>
                                      )
                                  )
                                : obj.name === "TECKpert Employment"
                                ? props.sysEmplmntList.map(
                                    (type) =>
                                      type && (
                                        <option value={type}>{type}</option>
                                      )
                                  )
                                : obj.name === "Registered"
                                ? [
                                    "Last 7 Days",
                                    "Last 30 Days",
                                    "Last 60 Days",
                                  ].map(
                                    (date) =>
                                      date && (
                                        <option value={date}>{date}</option>
                                      )
                                  )
                                : null}
                            </select>
                            <br />
                          </form>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-6 justify-end flex ml-8">
                  <button
                    onClick={() => {
                      props.setAdvFilterSearch([]);
                      props.setRecruiter("Recruiter");
                      props.setEmploymentType("Employment Preference");
                      props.setEmploymentStatus("TECKpert Employment");
                      props.setDateRegistered("Registered");
                      props.setTempTable(props.tableData);
                    }}
                    className="rounded-full inline-flex justify-center rounded-md border border-white shadow-sm px-4 py-2 bg-gray-400 
                    text-white hover:opacity-50 mr-3"
                  >
                    Clear
                  </button>
                  <button
                    onClick={() => {
                      props.setFiltersModal(false);
                    }}
                    className="rounded-full inline-flex justify-center rounded-md 
                    border border-white shadow-sm px-4 py-2 bg-[#6fbe47] text-white
                    hover:opacity-50 mr-3"
                  >
                    Update
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
