import {  useNavigate } from 'react-router-dom';
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

const Reports = () => {
    
    const navigate = useNavigate(); 
    const goToPayrollW2Report = () => {
        navigate('/reports/payrollW2Report');
    };
   

    return (
        <div className="ml-5 mt-10 lg:mt-10">
            <h1 className="text-3xl font-sans">Payroll Reports</h1>
            <div className="flex items-center ml-5 mt-5">
                <h1 className="text-2xl font-custom-serif">Payroll - W2</h1>
                <ArrowCircleRightIcon
                    className="h-5 w-6 teckpert-text-green ml-2 cursor-pointer"
                    onClick={goToPayrollW2Report}
                />
            </div>

            
        </div>
    );
};

export default Reports;
