import { useEffect, useState } from "react";
import logo from "../../img/teckpert-logo.png";
import ForgotPassword from "./ForgotPassword";
import LoginFields from "./LoginFields";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="h-screen">
      <div className="p-3 bg-zinc-700 h-1/5 max-h-24 w-full relative top-0">
        <img src={logo} className="mx-auto h-full py-3" />
      </div>
      <div className="h-[73%]">
        {location.pathname === "/login" && <LoginFields />}
        {location.pathname === "/forgot-password" && <ForgotPassword />}
      </div>
      <div className="p-5 bg-zinc-700 h-1/6 max-h-60 w-full relative bottom-0">
        <div className="text-2xl text-neutral-100 text-center p-3">
          Need to speak with someone?
        </div>
        <div className="text-lg text-neutral-100 text-center teckpert-text-green">
          Get in touch with us
        </div>
      </div>
    </div>
  );
};

export default Login;
