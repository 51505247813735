import { useEffect, useState } from "react";
import { API } from "aws-amplify";

const QuestionnaireForm = ({
  newResponse,
  setNewResponse,
  questionnaire,
  setQuestionnaire,
  knockout,
  setKnockout,
}) => {
  return (
    <div className="mt-5">
      <div>
        {newResponse.Responses?.length > 0 &&
          newResponse.Responses.map((obj, i) => {
            let index = questionnaire.Questions?.findIndex(
              (x) => x.Question === obj.Question
            );
            return (
              <div key={i} className="sm:col-span-2 flex-grow my-2">
                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    {obj.Question}
                    {questionnaire.Questions[i].Required ? (
                      <span className="ml-1 text-red-600 text-md">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  {obj.AnswerType === "Single Lane" ||
                  obj.AnswerType === "Number" ? (
                    <input
                      onChange={(e) => {
                        let temp = newResponse;
                        if (obj.AnswerType === "Number") {
                          if (
                            e.target.value === "" ||
                            /^[0-9]+$/.test(
                              e.target.value || e.target.value.length <= 10
                            )
                          ) {
                            obj.Answer = e.target.value;
                            setNewResponse({ ...temp });
                          }
                        } else if (
                          (obj.AnswerType === "Single Lane") &
                          (e.target.value.length <= 80)
                        ) {
                          obj.Answer = e.target.value;
                          setNewResponse({ ...temp });
                        }
                      }}
                      value={obj.Answer}
                      type="text"
                      name="answer"
                      id="answer"
                      autoComplete="answer"
                      className={`${
                        obj.AnswerType === "Number" ? "w-auto" : "w-full"
                      } mr-3 rounded-full justify-center rounded-md placeholder:text-gray-900 border shadow-sm px-2.5 py-3 text-sm bg-white text-gray-900`}
                    />
                  ) : obj.AnswerType === "Paragraph" ? (
                    <div className="mt-2">
                      <textarea
                        onChange={(e) => {
                          let temp = newResponse;
                          if (e.target.value.length <= 300) {
                            obj.Answer = e.target.value;
                            setNewResponse({ ...temp });
                          }
                        }}
                        value={obj.Answer}
                        type="text"
                        name="description"
                        className="h-48 appearance-none block w-full bg-white text-gray-700 border border-gray-200 
                        rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                        id="message"
                        autoComplete="description"
                      />
                    </div>
                  ) : obj.AnswerType === "Single Option" ||
                    obj.AnswerType === "Multiple Options" ? (
                    <div className="mt-1">
                      {questionnaire &&
                        questionnaire.Questions[index]?.AnswerOptions?.filter(
                          (item) => item.Option != ""
                        ).map((option, num) => (
                          <div className="form-check ml-3 mt-1" key={num}>
                            <input
                              id="bordered-checkbox"
                              type="checkbox"
                              name="bordered-checkbox"
                              className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                              onChange={(e) => {
                                let temp = newResponse;
                                if (obj.AnswerType === "Single Option") {
                                  obj.Answer = option.Option;
                                  temp.Responses[i].Knockout = option.Knockout;
                                }
                                if (obj.AnswerType === "Multiple Options")
                                  if (!obj.Answer.includes(option.Option)) {
                                    obj.Answer = [...obj.Answer, option.Option];
                                  } else if (
                                    obj.Answer.includes(option.Option)
                                  ) {
                                    obj.Answer = obj.Answer.filter(
                                      (obj) => obj != option.Option
                                    );
                                  }
                                setNewResponse({ ...temp });
                              }}
                              checked={
                                option.Option === obj.Answer ||
                                obj.Answer.includes(option.Option)
                              }
                            ></input>
                            <label className="text-sm text-gray-700 ml-2">
                              {option.Option}
                            </label>
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default QuestionnaireForm;
