import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import Table from "./Table";

const EmailTemplates = () => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    API.graphql({
      query: `{
        EmailTemplates {
          ItemId
          DateCreated
          DateUpdated
          From
          To
          Subject
          Body
          Type
          Name
          FromUser {
            FirstName
            LastName
            Email
          }
        }
    }      
  `,
    }).then(({ data }) => {
      setData(data.EmailTemplates);
    });
  }, []);

  return (
    <div className="p-6">
      <div className="cursor-pointer absolute top-5 left-20 z-10">
        <Link className="italic text-sm ml-10" to="/tools">
          <ChevronLeftIcon
            className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
            aria-hidden="true"
          />
        </Link>
      </div>
      <div className="flex justify-end mx-6 mb-4">
        <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
          <button
            onClick={() => navigate("/emailtemplates/new")}
            type="button"
            className="hover:opacity-90 rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto"
          >
            Create
          </button>
        </div>
      </div>
      <Table
        data={data}
        setData={setData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};
export default EmailTemplates;
