import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import Table from "./Table";
import BasicSearchBar from "../../utils/BasicSearchbar";
import CreateContractModal from "../Contracts/CreateContractModal";
import Loading from "../../utils/Loading";

const Contracts = ({ userData, setUserData }) => {
    // const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [contractData, setContractData] = useState([]);
    const [tempContractData, setTempContractData] = useState([]);
    const [searchVal, setSearchVal] = useState("");
    const [statuses, setStatuses] = useState([]);
    const [jobOrders, setJobOrders] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableFilters, setTableFilters] = useState({});
    const [filterSearch, setFilterSearch] = useState([]);
    const [updateTableData, setUpdateTableData] = useState(false);

    useEffect(() => {
        setLoading(true);

        const sharedWhere = `{
      ${searchVal ? `Name: {_ilike: "${searchVal}%"},` : ""}
      ${filterSearch?.length
                ? `Status: {_in: [${filterSearch
                    .map((obj) => `"${obj}"`)
                    .join(",")}]}`
                : ""
            }
    }`;

        API.graphql({
            query: `{
        Contracts(offset: 0, limit: 100, order_by: {DateCreated: desc},where: ${sharedWhere}) {
          ItemId
          DateCreated
          DateUpdated
          Name
          ClientId
          Documents
          Notes
          StartDate
          EndDate
          Hours
          Status
          PrimaryContact
          PM
          CTO
          Details
          Categories
          Type
          People
          PMFee
          CTOFee
          DefaultTo
          DefaultFrom
          CreatorName    
          Clients {
            Name
          }
        }
        Contacts (offset: 0, limit: 1000) {
          ItemId
          FirstName
          LastName
        }
      }      
    `,
        }).then(({ data }) => {
            setContractData(data.Contracts);
            setTempContractData(data.Contracts);
            setLoading(false);
            setContacts(data.Contacts);
            setUpdateTableData(false);
        });

        // GET/SET WORKERS DATA
        API.graphql({
            query: `{
        JobOrders {  
          ItemId
          ContractId
          MemberId
          LocationId
          Title
        }
      }      
    `,
        }).then(({ data }) => {
            console.log();
            setJobOrders(data.JobOrders);
            setUpdateTableData(false);
        });
    }, [searchVal, statuses, updateTableData]);

    
    useEffect(() => {
        setTableFilters(userData.TableFilters);
        setStatuses(userData.TableFilters?.contracts || []);
    }, [userData]);

    useEffect(() => {

        if (userData.TableFilters &&  userData.TableFilters?.contracts.length === 0) {
            var temp = [];
            temp.push("In Progress");
            userData.TableFilters.contracts = temp;
        }
        setFilterSearch(userData.TableFilters?.contracts || []);
    }, [userData]);

    return (
        <div>
            <CreateContractModal
                open={modalOpen}
                setOpen={setModalOpen}
                tableData={contractData}
                setTableData={setContractData}
                setUpdateTableData={setUpdateTableData}
                updateTableData={updateTableData }
            />
            <div className="flex justify-end mx-4">
                <div className="mt-4 sm:mt-0 sm:ml-16 flex justify-center">
                    <button
                        onClick={() => setModalOpen(!modalOpen)}
                        type="button"
                        className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white shadow-sm 
            focus:outline-none sm:w-auto hover:opacity-90"
                    >
                        Create
                    </button>
                </div>
            </div>
            <BasicSearchBar searchVal={searchVal} setSearchVal={setSearchVal} />
            <div style={{ margin: "0px 20px" }} className="sm:flex sm:items-center">
                {[
                    { name: "Draft", dataStatus: "hasDraft" },
                    { name: "Building", dataStatus: "hasBuilding" },
                    { name: "In Progress", dataStatus: "hasInProgress" },
                    { name: "Paused", dataStatus: "hasPaused" },
                    { name: "Ended", dataStatus: "hasEnded" },
                ].map((obj, i) => (
                    <div
                        key={i}
                        className="relative flex mb-3"
                        onClick={async (e) => {
                            let tempData = userData;
                            let tempStatuses = [...statuses];

                            const index = tempStatuses.indexOf(obj.name);
                            if (index !== -1) {
                                tempStatuses.splice(index, 1);
                            } else {
                                tempStatuses.push(obj.name);
                            }

                            tempData.TableFilters.contracts = tempStatuses;

                            await API.graphql(
                                graphqlOperation(`mutation {
                  update_Users_by_pk(pk_columns: {ItemId: "${userData.ItemId
                                    }"}, 
                    _set: 
                      {
                        TableFilters: {
                          people: [${tableFilters.people
                                        .map((obj) => `"${obj}"`)
                                        .join(",")}],
                          opportunities: [${tableFilters.opportunities
                                        .map((obj) => `"${obj}"`)
                                        .join(",")}],
                          contracts: [${tempStatuses.map((obj) => `"${obj}"`).join(",")}]
                        }    
                      }
                    )       
                    {
                      ItemId
                      TableFilters
                    }            
              }`)
                            ).then(({ data }) => {
                                console.log("data", data);
                                setUserData({ ...tempData });
                            });
                        }}
                    >
                        <ul className="mx-2">
                            <li>
                                <button
                                    className={`${filterSearch.includes(obj.name)
                                        ? "teckpert-bg-green text-white"
                                        : "bg-white text-gray-900"
                                        } w-[95px] mr-3 rounded-full justify-center rounded-md 
                  border shadow-sm px-2.5 py-3 text-sm
                  hover:border-[#6fbe47] hover:border-solid`}
                                    data-status={obj.dataStatus}
                                >
                                    {obj.name}
                                </button>
                            </li>
                        </ul>
                    </div>
                ))}
            </div>
            <Table
                tableData={tempContractData}
                jobOrders={jobOrders}
                contacts={contacts}
                setFilterSearch={setFilterSearch}
            />
            {loading ? <Loading /> : null}
        </div>
    );
};
export default Contracts;
