import React, { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  MenuIcon,
  PlusCircleIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
  MailIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import Loading from "../../../utils/Loading";
import MissingFieldPrompt from "../../../utils/MissingFieldPrompt";
// Modals
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import ConfirmMessage from "../../../utils/ConfirmMessage";
import SendMessageModal from "../Messages/SendMessage";
import CreateWorkflowModal from "./Modals/CreateWorkflowModal";
import UnsavedChangesModal from "../Checklists/Modals/UnsavedChangesModal";
import TemplateModal from "./Modals/TemplateModal";
import JobsListModal from "../Checklists/Modals/UnsavedChangesModal";

const Stages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [originalData, setOriginalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allWorkflows, setAllWorkflows] = useState([]);
  const [activelyUsed, setActivelyUsed] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState("");
  const [id, setId] = useState("");
  const [jobsData, setJobsData] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [newStage, setNewStage] = useState("");
  const [inputBox, setInputBox] = useState(true);
  const [editModeOn, setEditModeOn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmButtonMessage, setConfirmButtonMessage] = useState(false); //Shows/Hides saved confirmation message
  const [error, setError] = useState([]);
  //Modals
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); //ConfirmDeleteModal
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [newAction, setNewAction] = useState("");
  const [actionType, setActionType] = useState("");
  const [actionId, setActionId] = useState(0);
  const [mode, setMode] = useState("");
  const [list, setList] = useState([]);
  let newTempList = tableData;

  // Drag and Drop
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    originalOrderList: [],
    updatedOrder: [],
    updatedOrderList: [],
  };
  const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);

  // SCROLL TO TOP AND SHOW CONFIRMATION BANNER WHEN CHANGES ARE SAVED
  useEffect(() => {
    const element = document.getElementById("section-top");
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    setTimeout(() => {
      setConfirmButtonMessage(false);
    }, 5000);
  }, [confirmButtonMessage]);

  // GET/SET WORKFLOW DATA
  useEffect(() => {
    API.graphql({
      query: `{
        Workflows(where: {ItemId: {_eq: "${
          location.pathname.split("/")[2]
        }"}}) {
          ItemId
          DateCreated
          Title
          Stages
          Description
          CreatedBy
          Default
      }
    }      
  `,
    }).then(({ data }) => {
      // if workflow doesn't exist, create new
      setTableData(
        data.Workflows[0] || {
          ItemId: location.pathname.split("/")[2],
          Title: "",
          Description: "",
          Default: false,
          DateCreated: new Date().toISOString(),
          CreatedBy: localStorage.getItem("name"),
          Stages: [],
        }
      );
    });
  }, [location.pathname.split("/")[2], confirmButtonMessage]);

  // Initial data (before edit)
  useEffect(() => {
    API.graphql({
      query: `{
        Workflows {
          ItemId
          DateCreated
          Title
          Stages
          Description
          CreatedBy
          Default
      }
    }      
  `,
    }).then(({ data }) => {
      setAllWorkflows(data.Workflows);
      setOriginalData(
        data.Workflows.filter(
          (obj) => obj.ItemId == location.pathname.split("/")[2]
        )[0]
      );
    });
  }, [confirmButtonMessage]);

  useEffect(() => {
    API.graphql({
      query: `{
        EmailTemplates {
          ItemId
          DateCreated
          From
          To
          Body
          Subject
          Type
        }
        Opportunities {
          ItemId
          Title
          WorkflowId
        }
    }      
  `,
    }).then(({ data }) => {
      setJobsData(data.Opportunities);
      setEmailTemplates(data.EmailTemplates);
    });
  }, []);

  // Update list order every time stages are reordered or a new stage is added
  useEffect(() => {
    let temp = [];
    if (tableData.Stages) {
      tableData.Stages.map((obj, i) => {
        temp.push(obj.Order);
      });
      setList(temp);
    }
  }, [tableData.Stages, newStage]);

  // On Create, open edit modal to add title and description
  useEffect(() => {
    if (tableData.Title == "") {
      setEditModeOn(true);
      setEditModal(true);
    }
  }, [tableData.Title]);

  // Check if workflow is actively being used
  useEffect(() => {
    if (jobsData?.filter((x) => x.WorkflowId === tableData.ItemId).length > 0) {
      setActivelyUsed(true);
    }
  }, [jobsData]);

  useEffect(() => {
    setErrorMessage(false);
  }, [newStage]);

  // onDragStart fires when an element starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    if (editModeOn & !activelyUsed) {
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition,
        isDragging: true,
        originalOrder: list,
      });
      event.dataTransfer.setData("text/html", "");
    }
  };

  // onDragOver fires when an element being dragged enters a droppable area.
  const onDragOver = (event) => {
    // in order for the onDrop event to fire, we have
    // to cancel out this one
    event.preventDefault();
    if (editModeOn & !activelyUsed) {
      let newList = dragAndDrop.originalOrder;
      // index of the item being dragged
      const draggedFrom = dragAndDrop.draggedFrom;
      // index of the droppable area being hovered
      const draggedTo = Number(event.currentTarget.dataset.position);
      const itemDragged = newList[draggedFrom];
      const remainingItems = newList.filter(
        (item, index) => index !== draggedFrom
      );
      newList = [
        ...remainingItems.slice(0, draggedTo),
        itemDragged,
        ...remainingItems.slice(draggedTo),
      ];
      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    }
  };

  const onDrop = (event) => {
    if (editModeOn & !activelyUsed) {
      newTempList = tableData;
      setList(dragAndDrop.updatedOrder);
      tableData.Stages = newTempList.Stages.map(
        (obj, i) => tableData.Stages[dragAndDrop.updatedOrder[i]]
      );
      tableData.Stages.map((obj, i) => (obj.Order = i));
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
      });
      setTableData({ ...newTempList });
    }
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  if (!tableData) return <Loading />;

  return (
    <div>
      {confirmButtonMessage && <ConfirmMessage />}
      <div className="h-full">
        <div className="cursor-pointer absolute top-5 left-20 z-10">
          <Link className="italic text-sm ml-10" to="/workflows">
            <ChevronLeftIcon
              className="h-5 w-5 cursor-pointer mr-1 flex-none inline"
              aria-hidden="true"
            />
          </Link>
        </div>
        <div className="mx-4 mt-4">
          <div className="border border-gray-200 shadow-md rounded p-3 w-full flex justify-between">
            <div>
              {/* Title */}
              <div className="flex gap-10">
                <div
                  className={`${
                    tableData.Title ? "text-gray-700" : "text-gray-400"
                  } min-w-[200px]`}
                >
                  <h1
                    onClick={() => {
                      if (editModeOn) setEditModal(true);
                    }}
                    className={`${
                      editModeOn
                        ? "border border-gray-300 rounded cursor-pointer"
                        : ""
                    } text-2xl font-medium p-1 flex gap-20`}
                  >
                    {tableData.Title ? tableData.Title : "Write Title..."}
                  </h1>
                </div>
                <p className="text-sm font-medium mt-2">
                  Used by&nbsp;
                  {
                    jobsData?.filter((x) => x.WorkflowId === tableData.ItemId)
                      .length
                  }
                  &nbsp;jobs
                </p>
                <div className="flex mt-2">
                  <input
                    id="bordered-checkbox"
                    type="checkbox"
                    name="bordered-checkbox"
                    className="mr-1 w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300"
                    onChange={(e) => {
                      if (editModeOn) {
                        let temp = tableData;
                        // if there is a default workflow already set
                        // then allow to check/uncheck
                        if (
                          allWorkflows?.filter((obj) => obj.Default == true)
                            .length > 0
                        ) {
                          temp.Default = !temp.Default;
                          setTableData({ ...temp });
                          // uncheck previous default workflow
                          // if (temp.Default) {
                          //   let tempWorkflows = allWorkflows
                          //     ?.filter((obj) => obj.Default == true)
                          //     ?.filter(
                          //       (item) => item.ItemId != tableData.ItemId
                          //     )[0];
                          //   tempWorkflows.Default = !tempWorkflows.Default;
                          // setAllWorkflows([
                          //   ...allWorkflows.filter(
                          //     (obj) => obj.ItemId != tempWorkflows.ItemId
                          //   ),
                          //   tempWorkflows,
                          // ]);
                          // }
                        } else {
                          return;
                        }
                        console.log(temp.Default);
                      }
                    }}
                    checked={tableData.Default}
                  />
                  <label className="block text-sm font-medium teckpert-text-green">
                    Make default
                  </label>
                </div>
              </div>
              <CreateWorkflowModal
                open={editModal}
                setOpen={setEditModal}
                tableData={originalData}
                setTableData={setOriginalData}
                tempData={tableData}
                setTempData={setTableData}
                setConfirmButtonMessage={setConfirmButtonMessage}
              />
              {/* Description */}
              <div
                className={`${
                  tableData.Description ? "text-gray-700" : "text-gray-400"
                } max-w-[950px] text-sm`}
                onClick={() => {
                  if (editModeOn) setEditModal(true);
                }}
              >
                <p
                  className={`${
                    editModeOn
                      ? "border border-gray-300 rounded cursor-pointer"
                      : ""
                  } p-1 flex`}
                >
                  {tableData.Description
                    ? tableData.Description
                    : "Write Description..."}{" "}
                  {editModeOn && (
                    <div className="animate-[pulse_1s_infinite] text-blue-700 font-light text-lg relative bottom-1.5">
                      |
                    </div>
                  )}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                let equals =
                  originalData.Stages.length === tableData.Stages.length &&
                  originalData.Stages.sort((a, b) => a.Order - b.Order).every(
                    (e, i) => e.Name === tableData.Stages[i].Name
                  );
                if (equals) {
                  setEditModeOn(!editModeOn);
                  setNewStage("");
                } else setConfirmModal(true);
              }}
              type="button"
              className="rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium text-white hover:opacity-90
              shadow-sm focus:outline-none sm:w-auto h-[40px]"
            >
              Edit Items
            </button>
            <UnsavedChangesModal
              open={confirmModal}
              setOpen={setConfirmModal}
              confirm={() => {
                setTableData(originalData);
                setEditModeOn(false);
              }}
            />
          </div>
        </div>

        {activelyUsed && (
          <div className="bg-gray-200 border-t-4 border border-gray-400 rounded-b teckpert-text-green px-4 py-3 shadow-md mx-4 relative top-2">
            <div className="flex">
              <div className="py-1">
                <ExclamationCircleIcon
                  className="h-6 w-6 cursor-pointer mr-3 text-gray-500"
                  aria-hidden="true"
                />
              </div>
              <div>
                <p className="text-gray-500 mt-1">
                  This Workflow is being used by an Active Opportunity. You
                  cannot:{" "}
                  <span className="font-medium">EDIT, REMOVE or REORDER</span>{" "}
                  stages.
                </p>
              </div>
            </div>
          </div>
        )}

        <div className={`h-screen bg-[#f5f5f5] pt-3 p-4`}>
          <div className="pb-8">
            {tableData?.Stages?.length > 0 ? (
              tableData.Stages.map((obj, i) => (
                <div
                  key={obj.ItemId}
                  className={`${
                    dragAndDrop && dragAndDrop.draggedTo === Number(i)
                      ? "relative rounded text-gray-500 bg-white border border-dashed border-blue-800 align-center w-100 before:h-100"
                      : ""
                  } p-4 bg-white rounded-lg mt-1 border border-gray-200 shadow-md`}
                  data-position={i}
                  draggable
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  onDragLeave={onDragLeave}
                >
                  <div className="flex">
                    <div className="grow mt-1">
                      {!editModeOn ? (
                        <h4 className="text-lg font-bold text-gray-800 ml-5 p-2">
                          {obj.Name}
                        </h4>
                      ) : (
                        <input
                          className="text-lg font-bold text-gray-800 ml-5 border border-gray-200 rounded p-2 w-[50%]"
                          type="text"
                          onChange={(e) => {
                            let temp = tableData;
                            // setMode("edit");
                            // let stagesList = originalData.Stages.map(
                            //   (obj) => obj.Name
                            // );
                            if (!activelyUsed) {
                              temp.Stages[i].Name = e.target.value;
                              setTableData({ ...temp });
                            }
                          }}
                          value={obj.Name}
                        />
                      )}
                    </div>
                    <TemplateModal
                      open={templateModal}
                      setOpen={setTemplateModal}
                      i={id}
                      actionId={actionId}
                      tempData={tableData}
                      setTempData={setTableData}
                      templates={emailTemplates}
                      defaultTemplate={defaultTemplate}
                      newAction={newAction}
                      setNewAction={setNewAction}
                      actionType={actionType}
                      mode={mode}
                    />
                    <div
                      className={`${
                        !editModeOn ? "" : "hidden"
                      } flex flex-none mr-5 gap-5`}
                    >
                      {/* Options Menu */}
                      <Menu as="div" className="relative flex-shrink-0 mr-3">
                        <Menu.Button
                          onClick={() => {
                            if (activelyUsed) return;
                          }}
                          className="teckpert-bg-green text-white px-3.5 py-2 shadow-sm rounded flex flex-row-reverse text-sm focus:outline-none"
                        >
                          <span className="sr-only">Open user menu</span>
                          <div>Add</div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item key="Email">
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active
                                      ? "teckpert-bg-green text-white"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={() => {
                                    setId(i);
                                    setMode("Add");
                                    setActionType("Email");
                                    setTemplateModal(true);
                                  }}
                                >
                                  Email
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>

                    <div
                      className={`${
                        editModeOn ? "" : "hidden"
                      } flex gap-5 mr-4`}
                    >
                      <TrashIcon
                        onClick={() => {
                          let temp = tableData;
                          if (!activelyUsed) {
                            temp.Stages = temp.Stages.filter(
                              (item) => item.Name != obj.Name
                            );
                            setTableData({ ...temp });
                          }
                        }}
                        className="h-5 w-5 cursor-pointer mt-2 text-gray-300 hover:text-red-500"
                        aria-hidden="true"
                      />
                      <MenuIcon
                        className="h-5 w-5 cursor-pointer mt-2"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  {/* Content Section */}
                  <div
                    className={`${
                      obj.Actions?.length > 0 ? "mt-5" : ""
                    } mx-5 flex flex-col`}
                  >
                    <div className="sm:-mx-6 lg:-mx-8 flex justify-center">
                      <table className="w-[94%] bg-[#f5f5f5] mx-3">
                        <tbody className="divide-y divide-gray-200 bg-[#f5f5f5] border border-[#f5f5f5]">
                          {obj.Actions?.map((item, itemId) => (
                            <tr key={item.ItemId}>
                              <td
                                onClick={() => {
                                  setDefaultTemplate(item.TemplateId);
                                  setEmailModalOpen(true);
                                }}
                                className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900 w-15 cursor-pointer"
                              >
                                <MailIcon
                                  className="mr-2 mb-1 mt-1 h-4 w-4 inline teckpert-text-green"
                                  aria-hidden="true"
                                />
                                Send Email
                              </td>
                              <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900 font-medium">
                                {console.log("testing", item)}
                                {
                                  emailTemplates?.filter(
                                    (email) => email.ItemId == item.TemplateId
                                  )[0]?.Type
                                }
                              </td>
                              {editModeOn ? (
                                <td className="w-[60px] py-4 text-sm">
                                  <PencilIcon
                                    onClick={() => {
                                      if (!activelyUsed) {
                                        setMode("Edit");
                                        setActionId(itemId);
                                        setTemplateModal(true);
                                      }
                                    }}
                                    className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                                    aria-hidden="true"
                                  />
                                </td>
                              ) : (
                                <div className="w-[60px]"></div>
                              )}
                              {editModeOn && (
                                <td className="w-[60px] py-4 text-sm">
                                  <XCircleIcon
                                    value={item.ItemId}
                                    onClick={() => {
                                      let temp = tableData;
                                      temp.Stages[i].Actions =
                                        obj.Actions.filter(
                                          (action) => action !== item
                                        );
                                      console.log("options", temp);
                                      setTableData({ ...temp });
                                    }}
                                    className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                                    aria-hidden="true"
                                  />
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className={`${
                  !editModeOn ? "" : "hidden"
                } w-full py-5 justify-center text-center mb-8 font-bold text-gray-300 text-lg border boder-gray-300 rounded`}
              >
                No Stages have been added.
              </div>
            )}

            <div
              className={`${
                editModeOn & inputBox ? "" : "hidden"
              } bg-gray-200 p-3 rounded-lg mt-1 border border-gray-200 shadow-md`}
            >
              <div className="flex">
                <div className="grow mt-1 flex">
                  <input
                    placeholder="Enter an item"
                    className="text-lg font-medium placeholder:text-gray-400 ml-5 bg-white rounded p-2 w-[50%]"
                    type="text"
                    onChange={(e) => {
                      setNewStage(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      let temp = tableData;
                      let stagesList = tableData.Stages.map((obj) => obj.Name);
                      if (e.key === "Enter") {
                        if (newStage.length > 0) {
                          if (!stagesList.includes(newStage.trim())) {
                            temp.Stages.push({
                              Name: newStage,
                              Order: tableData.Stages.length,
                              Actions: [],
                            });
                            let tempList = list;
                            tempList.push(tableData.Stages.length - 1);
                            setList({ ...temp });
                            setTableData({ ...temp });
                            setNewStage("");
                          } else {
                            setErrorMessage("Stage name already exists.");
                          }
                        } else {
                          setErrorMessage("Stage name cannot be blank.");
                        }
                      }
                      console.log("new & list", newTempList, list);
                    }}
                    value={newStage}
                  />
                  <div
                    className={`${
                      errorMessage?.length > 0 ? "" : "hidden"
                    } m-auto flex gap-3 border border-red-600 rounded p-2`}
                  >
                    <XCircleIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                    <div className="font-medium text-red-600">
                      {errorMessage}
                    </div>
                  </div>
                </div>

                <div className="flex flex-none mr-6 gap-5">
                  <XCircleIcon
                    onClick={() => setInputBox(false)}
                    className="h-6 w-6 text-gray-400 hover:text-red-500 mt-3 cursor-pointer"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>

          {error.length > 0 && MissingFieldPrompt(error)}

          <div className={`${editModeOn ? "" : "hidden"}  mt-2`}>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between">
              <div>
                <button
                  onClick={() => {
                    let equals =
                      originalData.Stages.length === tableData.Stages.length &&
                      originalData.Stages.sort(
                        (a, b) => a.Order - b.Order
                      ).every((e, i) => e.Name === tableData.Stages[i].Name);
                    if (equals) setEditModeOn(!editModeOn);
                    else setConfirmModal(true);
                  }}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 
                  shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    let errorArray = [];
                    if (tableData.Title?.trim().length === 0)
                      errorArray.push("Title");
                    if (tableData.Description?.trim().length === 0)
                      errorArray.push("Description");
                    setError(errorArray);

                    if (errorArray.length > 0) {
                      return;
                    }

                    let temp = tableData;
                    await API.graphql(
                      graphqlOperation(`mutation {
                        update_Workflows_by_pk(pk_columns: {ItemId: "${
                          temp.ItemId
                        }"}, 
                           _set: { 
                            Default: "${temp.Default}",
                            Stages: [${temp.Stages?.map(
                              (obj) =>
                                `{ 
                                  Name: "${obj.Name}", 
                                  Order: ${obj.Order},
                                  Actions: [
                                    ${obj.Actions?.map(
                                      (item) => `{
                                        TemplateId: "${item.TemplateId}",
                                        Type: ${item.Type},
                                      }`
                                    ).join(`,`)}
                                  ]}`
                            ).join(`,`)}]                                      
                            })
                            {
                              ItemId
                              Stages
                            }
                      }`)
                    ).then(({ data }) => {
                      console.log("response", data);
                      setConfirmButtonMessage(true);
                      setEditModeOn(false);
                      setNewStage("");
                    });
                  }}
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                  font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm hover:opacity-90"
                >
                  Save Changes
                </button>
              </div>
              <button
                onClick={() => {
                  if (!inputBox) setInputBox(true);
                  else {
                    let temp = tableData;
                    let stagesList = tableData.Stages.map((obj) => obj.Name);
                    if (newStage.length > 0) {
                      if (!stagesList.includes(newStage.trim())) {
                        temp.Stages.push({
                          Name: newStage,
                          Order: temp.Stages.length,
                          Actions: [],
                        });
                        setTableData({ ...temp });
                        setNewStage("");
                      } else {
                        setErrorMessage("Stage name already exists.");
                      }
                    } else {
                      setErrorMessage("Stage name cannot be blank.");
                    }
                  }
                }}
                type="button"
                className="mt-3 flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base
                font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
              >
                <PlusCircleIcon
                  className={`mt-1 mr-2 h-7 w-7 m-auto teckpert-text-green cursor-pointer`}
                  aria-hidden="true"
                />
                <span className="mt-2">Add Stage</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteItem={async () => {
          let response = await API.graphql(
            graphqlOperation(`
              mutation {
                delete_Workflows_by_pk(ItemId: "${tableData.ItemId}") {
                  ItemId
                  Title
                }
              }
            `)
          );
          if (response) {
            navigate("/workflows");
            setConfirmButtonMessage(true);
          }
        }}
      />
      <SendMessageModal
        open={emailModalOpen}
        setOpen={setEmailModalOpen}
        setRefresh={false}
        defaultTo={""}
        setConfirmButtonMessage={setConfirmButtonMessage}
        defaultTemplate={defaultTemplate}
      />
    </div>
  );
};
export default Stages;
