import { DocumentTextIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import TableDetails from "./TableDetails";

export const Table = ({
    timesheets,
    selectedTimesheets,
    setSelectedTimesheets,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [timesheet, setTimesheet] = useState({});
    useEffect(() => {
        // Select all timesheets initially
        setSelectedTimesheets(timesheets);
    }, [timesheets, setSelectedTimesheets]);

    const toggleTimesheetSelection = (timesheet) => {
        if (selectedTimesheets.find(({ ItemId }) => ItemId === timesheet.ItemId)) {
            setSelectedTimesheets(selectedTimesheets.filter(({ ItemId }) => ItemId !== timesheet.ItemId));
        } else {
            setSelectedTimesheets([...selectedTimesheets, timesheet]);
        }
    };
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {modalOpen && (
                <TableDetails
                    open={modalOpen}
                    setOpen={setModalOpen}
                    timesheet={timesheet}
                />
            )}
            <div className="mt-8 flex flex-col pb-4">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th>

                                        </th>
                                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Person
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Contract
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Client
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Period
                                        </th>
                                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Hours
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Details
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {timesheets?.map((timesheet, i) => (
                                        <tr key={timesheet?.ItemId}>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input appearance-none h-4 w-4 border 
                            border-gray-300 rounded-sm bg-white checked:bg-[#6FBE47]
                            checked:border-[#6FBE47] focus:outline-none transition 
                            duration-200 mt-1 align-top bg-no-repeat bg-center 
                            bg-contain float-left ml-4 cursor-pointer"
                                                        type="checkbox"
                                                        checked={selectedTimesheets.map(({ ItemId }) => ItemId).includes(timesheet.ItemId)}
                                                        id="flexCheckDefault"
                                                        onChange={() => toggleTimesheetSelection(timesheet)}
                                                    />
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                <div className="flex items-center">
                                                    <div className="font-medium text-[#6FBE47]">
                                                        {timesheet?.JobOrder?.Member?.FirstName} {timesheet?.JobOrder?.Member?.LastName}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                {timesheet?.JobOrder?.Contract?.Name}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {timesheet?.JobOrder?.Contract?.Clients?.Name}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {new Date(timesheet?.PeriodStart).toLocaleDateString('en-US', { day: 'numeric', month: 'short', timeZone: "utc" })} - {new Date(timesheet?.PeriodEnd).toLocaleDateString('en-US', { day: 'numeric', month: 'short', timeZone: "utc"})}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                {timesheet?.Hours?.aggregate?.sum?.Hours?.toFixed(2)}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                {timesheet?.Status}
                                            </td>
                                            <td
                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                                                onClick={() => {
                                                    setTimesheet(timesheet);
                                                    setModalOpen(!modalOpen);
                                                }}
                                            >
                                                <DocumentTextIcon className="w-7 h-7 teckpert-text-green cursor-pointer" />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
