import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  XCircleIcon,
  PencilIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/outline";
import { formatDate } from "../../../utils/FormatDate";
import { v4 as uuidv4 } from "uuid";
import UpdateQuestionnaireModal from "./Modals/UpdateQuestionnaireModal";
import EditQuestionsModal from "./Modals/EditQuestionsModal";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";

const Table = ({
  selectedJob,
  setSelectedJob,
  jobsData,
  editModalOpen,
  setEditModalOpen,
  questionsData,
  setQuestionsData,
  editQuestionsModalOpen,
  setEditQuestionsModalOpen,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Title",
                      "Date Created",
                      "Creator",
                      "Questions",
                      "Opportunites",
                      "",
                      "",
                      "",
                    ].map((header, i) => (
                      <th
                        key={i}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {questionsData?.map((obj) => (
                    <tr key={obj.ItemId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center font-medium teckpert-text-green">
                          {obj.Title ? obj.Title : "-"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.DateCreated ? formatDate(obj.DateCreated) : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.CreatedBy ? obj.CreatedBy : "-"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {obj.Questions
                          ? obj.Questions[0]?.Question !== ""
                            ? obj.Questions.length
                            : "0"
                          : "0"}
                      </td>
                      <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-900">
                        {
                          jobsData?.filter(
                            (x) => x.QuestionnaireFormId === obj.ItemId
                          ).length
                        }
                      </td>
                      <td className="w-[100px] cursor-pointer whitespace-nowrap py-2 text-sm">
                        {/* Clone Questionnaire */}
                        <button
                          onClick={async () => {
                                      let temp = obj;
                                      const Description = JSON.stringify(temp?.Description);
                            await API.graphql(
                              graphqlOperation(`mutation {
                                insert_ApplicationQuestionnaire_one(
                                  object: 
                                  {
                                    ItemId: "${uuidv4()}",
                                    CreatedBy: "${temp.CreatedBy}",
                                    DateCreated: "${new Date().toISOString()}",
                                    Title: "Copy of ${temp.Title}", 
                                    Description: ${Description}, 
                                    Questions: [${temp.Questions?.map(
                                      (obj) =>
                                        `{
                                          Question: "${obj.Question}",
                                          Required: ${obj.Required},
                                          AnswerType: "${obj.AnswerType}",
                                          Order: ${obj.Order},
                                          AnswerOptions: [
                                            ${obj.AnswerOptions?.map(
                                              (item) => `{
                                                Option: "${item.Option}",
                                                Knockout: ${item.Knockout},
                                                Order: ${item.Order},
                                              }`
                                            ).join(`,`)}
                                          ]
                                        }`
                                    ).join(`,`)}] 
                                  })
                                  {
                                    ItemId
                                    Title
                                  }         
                            }`)
                            ).then(({ data }) => {
                              setQuestionsData([
                                ...questionsData,
                                {
                                  ItemId:
                                    data.insert_ApplicationQuestionnaire_one
                                      .ItemId,
                                  Title: `Copy of ${temp.Title}`,
                                  Description: temp.Description,
                                  DateCreated: new Date().toISOString(),
                                  CreatedBy: temp.CreatedBy,
                                  Questions: temp.Questions,
                                },
                              ]);
                            });
                          }}
                          type="button"
                          className="mr-8 mt-2 items-center justify-center rounded-md border border-transparent teckpert-bg-green 
                          px-4 py-2 font-light text-white shadow-sm focus:outline-none sm:w-auto hover:border-white"
                        >
                          Clone
                          <DocumentDuplicateIcon
                            className="h-6 w-6 inline cursor-pointer mx-3"
                            aria-hidden="true"
                          />
                        </button>
                      </td>
                      <td className="w-[60px] py-4 text-sm">
                        <PencilIcon
                          onClick={() => {
                            setSelectedJob(obj);
                            setEditModalOpen(true);
                          }}
                          className="h-5 w-5 cursor-pointer mr-3 text-gray-300 hover:teckpert-text-green"
                          aria-hidden="true"
                        />
                        {selectedJob ? (
                          <UpdateQuestionnaireModal
                            open={editModalOpen}
                            setOpen={setEditModalOpen}
                            questionsData={questionsData}
                            setQuestionsData={setQuestionsData}
                            selectedJob={selectedJob}
                            setSelectedJob={setSelectedJob}
                            setEditQuestionsModalOpen={
                              setEditQuestionsModalOpen
                            }
                          />
                        ) : null}
                        {selectedJob ? (
                          <EditQuestionsModal
                            open={editQuestionsModalOpen}
                            setOpen={setEditQuestionsModalOpen}
                            questionsData={questionsData}
                            setQuestionsData={setQuestionsData}
                            selectedJob={selectedJob}
                            setSelectedJob={setSelectedJob}
                            editModalOpen={editModalOpen}
                            setEditModalOpen={setEditModalOpen}
                          />
                        ) : null}
                      </td>
                      <td className="w-[60px] py-4 text-sm">
                        <XCircleIcon
                          value={obj.ItemId}
                          onClick={() => {
                            setSelectedJob(obj);
                            setDeleteModalOpen(true);
                          }}
                          className="h-6 w-6 cursor-pointer mr-3 text-gray-300 hover:text-red-500"
                          aria-hidden="true"
                        />
                        <ConfirmDeleteModal
                          open={deleteModalOpen}
                          setOpen={setDeleteModalOpen}
                          deleteItem={async () => {
                            if (selectedJob.ItemId) {
                              let response = await API.graphql(
                                graphqlOperation(`
                                mutation {
                                    delete_ApplicationQuestionnaire_by_pk(ItemId: "${selectedJob.ItemId}") {
                                      ItemId
                                      Title
                                    }
                                  }
                              `)
                              );
                              if (response) {
                                setQuestionsData(
                                  questionsData.filter(
                                    (item) => item.ItemId !== selectedJob.ItemId
                                  )
                                );
                                setSelectedJob([]);
                              }
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
