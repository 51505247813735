/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, XCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { API } from "aws-amplify";

const UpdateSpecializationsModal = ({
  open,
  setOpen,
  originalData,
  tableData,
  setTableData,
  setConfirmButtonMessage,
}) => {
  const [data, setData] = useState(tableData.Meta?.Specializations || []);
  const [input, setInput] = useState("");

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none"
                    onClick={() => {
                      setTableData(originalData);
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start mb-2">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Specializations
                    </div>
                  </div>
                </div>

                <div>
                  <div className="border border-solid border-gray-300 rounded mb-1 flex">
                    <input
                      onChange={(e) => {
                        setInput(e.target.value);
                        let temp = tableData;
                        if (
                          (temp.Meta.Specializations !== "") &
                          (temp.Meta.Specializations !== ",")
                        ) {
                          if (e.nativeEvent.data === ",") {
                            input.trim();
                            if (!data.includes(input)) {
                              setData([...data, input]);
                              setInput("");
                              temp.Meta.Specializations = [...data, input];
                              setTableData({ ...temp });
                            }
                          } else return;
                        }
                      }}
                      onKeyPress={(e) => {
                        let temp = tableData;
                        if (input !== "") {
                          if (e.key === "Enter") {
                            if (!data.includes(input)) {
                              setData([...data, input]);
                              setInput("");
                              temp.Meta.Specializations = [...data, input];
                              setTableData({ ...temp });
                            }
                          }
                        }
                      }}
                      value={input}
                      type="text"
                      className={`${
                        data?.includes(input)
                          ? "placeholder-red-500 text-red-500"
                          : "placeholder-gray-400"
                      } block w-full appearance-none rounded-md border-none px-3 py-2 
                      shadow-sm focus:outline-none sm:text-sm`}
                    />{" "}
                    <PlusCircleIcon
                      onClick={(e) => {
                        let temp = tableData;
                        if (input !== "") {
                          if (!data.includes(input)) {
                            setData([...data, input]);
                            setInput("");
                            temp.Meta.Specializations = [...data, input];
                            setTableData({ ...temp });
                          }
                        }
                      }}
                      className="h-7 w-7 teckpert-text-green mt-1 mr-2 cursor-pointer"
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <div className="flex max-w-full flex-wrap mb-6 text-white">
                  {Array.isArray(data) &&
                    data?.map((obj) => (
                      <div
                        className="whitespace-nowrap flex border teckpert-border-green teckpert-bg-green p-2 rounded text-sm"
                        key={obj}
                        onClick={() => {
                          let temp = data;
                          temp = temp.filter((item) => item !== obj);
                          setData(temp);
                          let tempTableData = tableData;
                          tempTableData.Meta.Specializations = temp;
                          setTableData({ ...tempTableData });
                        }}
                      >
                        <div className="">{obj}</div>
                        <XIcon
                          className="h-4 w-4 ml-3 mt-1 text-gray-200 hover:text-red-500 cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    ))}
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent teckpert-bg-green px-4 py-2 text-base 
                    font-medium text-white shadow-sm hover:opacity-90 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      setInput("");

                      let temp = tableData;
                      if (!Array.isArray(tableData.Meta.Specializations))
                        temp.Meta.Specializations = [];
                      API.graphql({
                        query: `mutation($meta: jsonb) {
                          update_Members_by_pk(pk_columns: {ItemId: "${tableData.ItemId}"}, 
                            _set: { Meta: $meta } )       
                              {
                                ItemId
                                Meta
                              }            
                          }`,
                        variables: { meta: temp.Meta },
                      }).then(async ({ data }) => {
                        setOpen(false);
                        setTableData({ ...temp, Specializations: data });
                        setConfirmButtonMessage(true);
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base 
                    font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setTableData(originalData);
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateSpecializationsModal;
