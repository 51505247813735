/* This example requires Tailwind CSS v2.0+ */
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import CreateOpportunitiesModal from "./Details/Modals/CreateOpportunitiesModal";

const StatsDisplay = ({ modalOpen, setModalOpen, tableData, setTableData }) => {
  const [stats, setStats] = useState({
    "My Opportunities": {
      "Assigned to me": "-",
      "Due this week": "-",
      "Due next week": "-",
      "Candidate Pending": "-",
    },
    "All Opportunities": {
      New: "-",
      Active: "-",
      "Recently Submitted": "-",
      "Most Active Contract": "-",
    },
    "KPI Tracker": {
      Prescreens: "-",
      Emails: "-",
      Calls: "-",
      Submissions: "-",
    },
  });
  useEffect(() => {
    const async = async () => {
      const response = await API.get("auth", "/opportunityStats");
      setStats(response);
    };
    async();
  }, []);

  return (
    <div>
      <button
        type="button"
        onClick={() => setModalOpen(true)}
        className="float-right mr-6 inline-flex items-center justify-center rounded-md border 
        border-transparent teckpert-bg-green px-4 py-2 text-sm font-medium hover:opacity-90
        text-white shadow-sm focus:outline-none sm:w-auto"
      >
        Create
      </button>
      <CreateOpportunitiesModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        tableData={tableData}
        setTableData={setTableData}
        action={"creating"}
      />
      <div className="mx-20 grid lg:grid-cols-4 sm:grid-cols-2 gap-5">
        {Object.keys(stats).map((key, id) => {
          if (["My Opportunities", "All Opportunities"].includes(key)) {
            return (
              <dl className="mt-5 text-center" key={id}>
                <div className="px-4 py-5 rounded-lg overflow-hidden sm:p-6">
                  <dd className="mt-1 text-2xl tracking-tight font-semibold text-gray-800 mb-3">
                    {key}
                  </dd>
                  <dd className="mt-1 text-4xl tracking-tight font-semibold teckpert-text-green mb-3 grid grid-cols-3 gap-5">
                    {Object.values(stats[key])
                      .slice(0, 3)
                      .map((obj, i) => (
                        <div key={i}>{obj}</div>
                      ))}
                  </dd>
                  <dd className="mt-1 text-sm tracking-tight font-semibold text-gray-800 mb-3 grid grid-cols-3 gap-5">
                    {Object.keys(stats[key])
                      .slice(0, 3)
                      .map((obj, i) => (
                        <div key={i}>{obj}</div>
                      ))}
                  </dd>
                  <dd className="mt-1 text-sm tracking-tight font-semibold text-gray-800 mb-3">
                    {Object.entries(stats[key])
                      .slice(3, 4)
                      .map(([key, value], i) => (
                        <>
                          <span className="font-bold" key={i}>
                            {value}{" "}
                          </span>
                          {key}
                        </>
                      ))}
                  </dd>
                  <dt className="text-lg font-medium text-gray-900 truncate"></dt>
                </div>
              </dl>
            );
          } else if (["KPI Tracker"].includes(key)) {
            return (
              <dl className="mt-5 text-center">
                <div className="px-4 py-5 rounded-lg overflow-hidden sm:p-6">
                  <dd className="mt-1 text-2xl tracking-tight font-semibold text-gray-800 mb-3">
                    {key}
                  </dd>
                  <dd className="mt-1 text-4xl tracking-tight font-semibold teckpert-text-green mb-3 grid grid-cols-4 gap-5">
                    {Object.values(stats[key]).map((obj, i) => (
                      <div key={i}>{obj}</div>
                    ))}
                  </dd>
                  <dd className="mt-1 text-sm tracking-tight font-semibold text-gray-800 mb-3 grid lg:grid-cols-4 sm:grid-cols-2 gap-3">
                    {Object.keys(stats[key]).map((obj, i) => (
                      <div key={i}>{obj}</div>
                    ))}
                  </dd>
                  <dd className="mt-1 text-sm tracking-tight font-semibold text-gray-800 mb-3"></dd>
                  <dt className="text-lg font-medium text-gray-900 truncate"></dt>
                </div>
              </dl>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default StatsDisplay;
