import {
  BriefcaseIcon,
  FlagIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/FormatDate";
import ReactTooltip from "react-tooltip";

const MemberBio = ({
  profileData,
  lastContacted,
  addModalOpen,
  setAddModalOpen,
  img,
}) => {
  return (
    <div
      className="bg-[#333333] flex justify-center items-center align-middle text-center col-span-2
      bg-white rounded-lg border border-gray-200 shadow-md "
    >
      {/* Member Bio */}
      <div className="px-12">
        <div
          data-tip
          data-for="registerTip"
          onClick={() => setAddModalOpen(!addModalOpen)}
          className="justify-center m-auto w-7 text-white rounded-full p-1 h-7 cursor-pointer hover:teckpert-text-green"
        >
          <BriefcaseIcon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="justify-center m-auto flex space-around">
          {!img.includes("undefined") ? (
            <img src={img} width="120" height="60" className="rounded-full" />
          ) : (
            <img
              src={"/logo512.png"}
              width="120"
              height="60"
              className="rounded-full"
            />
          )}
        </div>
        {/* {profileData?.Starred ? (
        <div
          className="w-20 px-2 py-1 rounded-full teckpert-bg-green
          text-white text-sm justify-center mx-auto relative bottom-3"
        >
          Short List
        </div>
      ) : profileData?.Flagged ? (
        <div
          className="w-20 px-2 py-1 rounded-full bg-red-500
          text-white text-sm justify-center mx-auto relative bottom-3"
        >
          Flagged
        </div>
      ) : profileData?.ScreeningStatus == "approved" ? (
        <div
          className="w-[100px] px-2 py-1 rounded-full bg-yellow-400
          text-white text-sm justify-center mx-auto relative bottom-3"
        >
          Prescreened
        </div>
      ) : (
        <div></div>
      )} */}
        <h5 className="text-xl font-bold text-white">
          {profileData.FirstName + " " + profileData.LastName}
        </h5>
        <h5 className="text-xl font-bold teckpert-text-green">
          {profileData.Meta?.PrimaryRole?.label
            ? profileData.Meta?.PrimaryRole?.label
            : "-"}
        </h5>
        <p className="text-white text-sm">{profileData.Meta?.Description}</p>
        <div className="mt-2">
          <p className="italic text-gray-400 text-sm">
            Registered on&nbsp;
            <Link target="_blank" to="/tools/emails">
              {profileData?.DateCreated
                ? formatDate(profileData.DateCreated)
                : ""}
            </Link>
          </p>
          <p className="italic text-gray-400 text-sm">
            Last Contacted on&nbsp;
            {lastContacted ? (
              <Link target="_blank" to="/tools/emails">
                {lastContacted ? formatDate(lastContacted) : "-"}
              </Link>
            ) : (
              "N/A"
            )}
          </p>
        </div>
        {profileData?.AccountStatus === "Short Listed" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              margin: 20,
            }}
          >
            <UserCircleIcon
              className={`${
                profileData.Starred > 0
                  ? "teckpert-text-green"
                  : "text-gray-300"
              }  h-5 w-5 mr-2`}
              aria-hidden="true"
            />
            Short Listed
          </div>
        )}
        {profileData?.AccountStatus === "Do not Hire / Flagged" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              margin: 20,
            }}
          >
            <FlagIcon
              className={`${
                profileData?.Flagged > 0 ? "text-red-500" : "text-gray-300"
              }  h-5 w-5 mr-2`}
              aria-hidden="true"
            />
            Flagged
          </div>
        )}
      </div>
      <ReactTooltip id="registerTip" place="top" effect="solid">
        Add to Opportunities
      </ReactTooltip>
    </div>
  );
};
export default MemberBio;
