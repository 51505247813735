/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";

export default function ReviewInterviewModal({
  open,
  setOpen,
  selectedJob,
  tableData,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-x-hidden lg:w-[700px] sm:w-[500px] min-h-[500px] max-h-[850px] relative transform overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-red-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900 mb-1"
                    >
                      {tableData[
                        tableData.findIndex(
                          (x) => x.ItemId === selectedJob.InterviewGuideId
                        )
                      ].Title
                        ? tableData[
                            tableData.findIndex(
                              (x) => x.ItemId === selectedJob.InterviewGuideId
                            )
                          ].Title
                        : "-"}
                    </Dialog.Title>

                    <div className="lg:min-w-[500px]">
                      <div className="flex my-3 font-bold">
                        {selectedJob?.Rating === "Passed" ? (
                          <div className="flex teckpert-text-green">
                            <CheckCircleIcon
                              className="cursor-pointer h-5 w-5 mx-1 mt-1"
                              aria-hidden="true"
                            />
                            {selectedJob.Rating}
                          </div>
                        ) : selectedJob?.Rating === "Failed" ? (
                          <div className="flex text-red-500">
                            <XCircleIcon
                              className="cursor-pointer h-5 w-5 mx-1 mt-1"
                              aria-hidden="true"
                            />
                            {selectedJob.Rating}
                          </div>
                        ) : (
                          <div>No Rating</div>
                        )}
                      </div>
                      {selectedJob.Responses?.map((obj, i) => (
                        <div
                          key={i}
                          className="border border-gray-200 rounded mb-1"
                        >
                          <div className="p-3 bg-gray-100">
                            Rating:&nbsp;
                            <span
                              className={`${
                                obj.Rating === "Poor"
                                  ? "text-red-500"
                                  : obj.Rating === "Fair"
                                  ? "text-gray-400"
                                  : "teckpert-text-green"
                              } font-bold`}
                            >
                              {obj.Rating ? obj.Rating : "-"}
                            </span>
                          </div>
                          <div className="px-3 pt-2 font-bold text-gray-800">
                            {obj.Question ? obj.Question : "-"}
                          </div>
                          <div className="p-3">{obj.Note ? obj.Note : "-"}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
